import {Component} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {Router} from "@angular/router";
import {EnvironmentService} from "../../services/environment.service";

@Component({
    selector: "app-header",
    templateUrl: "./app.header.html"
})
export class AppHeader {
    title = "iTolc";
    isLogged = false;

    constructor(private authService: AuthService,
                private environmentService: EnvironmentService,
                private router: Router) {
        this.title = environmentService.get('title');
    }

    onClickLogout(event: Event) {
        event.preventDefault();

        if (this.authService.token) {
            this.authService.logout().subscribe(() => {
                this.isLogged = false;
                this.router.navigateByUrl("/");
            });
        }
    }
}
