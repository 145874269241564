import {catchError, map} from 'rxjs/operators';
import {RestHttp} from "./rest.http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {RestConfig} from "./rest_config.http";

export class ExamHttpApi extends RestHttp {

  setDefaultUrl() {
      let prefix = environment.examType === 'mmk' ? `mmk/` : '';
      this.defaultUrl = `${environment.apiPrefixUrl}${prefix}exams`;
  }

  getExamPageUrl(page) {
      this.defaultUrl = `${environment.apiPrefixUrl}exam/getExamPage/${page}`;
  }

    setExamListUrl() {
        this.defaultUrl = `${environment.apiPrefixUrl}mmk/exam/getExamsList`;
    }

    setExamsToSelectUrl() {
        this.defaultUrl = `${environment.apiPrefixUrl}mmk/exam/examsToSelect`;
    }
  // setAllTaskUrl() {
  //   this.defaultUrl = `${environment.apiPrefixUrl}task/getAllCandidateTasks`;
  // }

  setExamsForDropDownListUrl() {
    this.defaultUrl = `${environment.apiPrefixUrl}exam/getExamsForDropDownList`;
  }

  setSelectedTaskUrl() {
    this.defaultUrl = `${environment.apiPrefixUrl}task/getActiveTask`;
  }

  setSelectedFilesUrl(filesUrl: string) {
    this.defaultUrl = `${environment.apiPrefixUrl}${filesUrl}`;
  }

  setInspectorsUrl() {
    this.defaultUrl = `${environment.apiPrefixUrl}users/getInspectors`;
  }

  setExaminersUrl() {
    this.defaultUrl = `${environment.apiPrefixUrl}users/getExaminers`;
  }

  setExamDataUrl(examId: number) {
    this.defaultUrl = `${environment.apiPrefixUrl}task/getExamTasks/${examId}`;
  }

  setCandidateIdentityCheckUrl(examId: number) {
    this.defaultUrl = `${environment.apiPrefixUrl}exam/getCandidateIdentityCheck/${examId}`;
  }

  setExamIdentityDataUrl(examId: number) {
    this.defaultUrl = `${environment.apiPrefixUrl}exam/getExamCandidatesIdentity/${examId}`;
  }

  setSaveExamIdentityUrl() {
    this.defaultUrl = `${environment.apiPrefixUrl}exam/postSaveCandidatesIdentity`;
  }

  setAnalysisUrl() {
    this.defaultUrl = `${environment.apiPrefixUrl}exam/getExamsWithFacets`;
  }

  setExamAnswersUrl(examId: number) {
      this.defaultUrl = `${environment.apiPrefixUrl}mmk/exam/getExamAnswers/${examId}`;
  }

  getExamPage(page): Observable<any> {
    this.getExamPageUrl(page);
    let requestOptions = RestConfig.getReqOpt();

    return this.http.get(this.defaultUrl, requestOptions).pipe(
      map((response: any) => {
        return response;
      }));
  }

  getExamSyncPage(page): Observable<any> {
    let url = `${environment.apiPrefixUrl}sync/getExamSyncList/${page}`;
    let requestOptions = RestConfig.getReqOpt();

    return this.http.get(url, requestOptions).pipe(
      map((response:any) => {
        return response;
      }));
  }

    setExamPlaceStatus(examId: number) {
        let url = `${environment.apiPrefixUrl}exam/markFinished/` + examId;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(map((response:any) => {
            return response.data;
        }));
    }

    /**
     * Simplified model list
     *
     * @param page
     * @returns {Observable<any>}
     */
  getExamListForPAginate(page): Observable<any> {
    this.getExamPageUrl(page);
    let requestOptions = RestConfig.getReqOpt();

    return this.http.get(`${environment.apiPrefixUrl}/diaries/getExamPage/${page}`, requestOptions).pipe(
      map((response:any) => {
        return response;
      }));
  }

  setTaskById(taskId, examId): Observable<any> {
    let requestOptions = RestConfig.getReqOpt();

    requestOptions['params'] = {
        'taskId': taskId,
        'examId': examId
    };

    console.log('this.defaultUrl: ', this.defaultUrl);
    return this.http.get(this.defaultUrl, requestOptions).pipe(
      map((response:any) => {
        let data = <Array<any>> response.data;
        return data;
      }));
  }

  setAnswerFiles(c_id,item_id): Observable<any> {
    this.setSelectedFilesUrl("files");
    let requestOptions = RestConfig.getReqOpt();

    requestOptions['params'] = {
        'candidate_exam_id': c_id,
        'item_id': item_id
    };

    return this.http.get(`${environment.apiPrefixUrl}getRecordingFiles`, requestOptions).pipe(
      map((response:any) => {
        let data = <Array<any>> response.data;
        return data;
      }));
  }

  getExaminers(): Observable<any> {
    this.setExaminersUrl();
    let requestOptions = RestConfig.getReqOpt();

    return this.http.get(this.defaultUrl, requestOptions).pipe(
      map((response:any) => {
        let data = response.users;
        return data;
      }));

  }

    getInspectors(): Observable<any> {
        this.setInspectorsUrl();
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(this.defaultUrl, requestOptions).pipe(
            map((response:any) => {
                return response.users;
            }));

    }

  getExamsForDropDownList(): Observable<any> {
    this.setExamsForDropDownListUrl();
    let requestOptions = RestConfig.getReqOpt();

    return this.http.get(this.defaultUrl, requestOptions).pipe(
      map((response:any) => {
          return response.data;
      }));

  }

  updateExaminer(examId: number, part: string, examinerId1: number, examinerId2: number, examinerId3: number) {
    let url = `${environment.apiPrefixUrl}exam/setExaminers`;
    let requestOptions = RestConfig.getReqOpt();
    let body = {
      exam_id: examId,
      part: part,
      examiner1: examinerId1,
      examiner2: examinerId2,
      examiner3: examinerId3
    };

    this.http.post(url, body, requestOptions).subscribe();
  }

    sendIdentityCheck(candidateExamId: number, identityCheck:number) {
        this.setSaveExamIdentityUrl();
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_exam_id: candidateExamId,
            identity_check: identityCheck,
        };

        return this.http.post(this.defaultUrl, body, requestOptions).pipe(
            map((response:any) => {
                return response;
            }));
  }

  saveManualCorrectionOptions(createOptionsForm: any[]) {
    let url = `${environment.apiPrefixUrl}reviews/insertReviews`;
    let requestOptions = RestConfig.getReqOpt();

    return this.http.post(url, createOptionsForm, requestOptions).pipe(
      map((response:any) => {
        return response;
      }));
  }

  getExamData(examId: number) {
    this.setExamDataUrl(examId);
    let requestOptions = RestConfig.getReqOpt();

    return this.http.get(this.defaultUrl, requestOptions).pipe(
      map((response:any) => {
        let data = response.data;
        return data;
      }));
  }

  getExamIdentityData(examId: number) {
    this.setExamIdentityDataUrl(examId);
    let requestOptions = RestConfig.getReqOpt();

    return this.http.get(this.defaultUrl, requestOptions).pipe(
      map((response:any) => {
          return response.data;
      }));
  }

    getCandidateIdentityCheck(candidateExamId: number) {
        this.setCandidateIdentityCheckUrl(candidateExamId);
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(this.defaultUrl, requestOptions).pipe(
          map((response:any) => {
              return response.data;
          }));
    }

  approveAutomaticCorrection(examAnswerId: number, correction: any) {
    let url = `${environment.apiPrefixUrl}examanswers/${examAnswerId}`;
    let requestOptions = RestConfig.getReqOpt();

    return this.http.patch(url, correction, requestOptions).subscribe();
  }

    getExamRoster(page): Observable<any> {
        let url = `${environment.apiPrefixUrl}exam/getExamRoster/${page}`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return response;
            }));
    }

    getCheckingIdentityListForInspector(page): Observable<any> {
        let url = `${environment.apiPrefixUrl}exam/getCheckingIdentityListForInspector/${page}`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return response;
            }));
    }

    getCandidatesForInspector(examId): Observable<any> {
        let url = `${environment.apiPrefixUrl}exam/getCandidatesForInspector/${examId}`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return response;
            }));
    }

    getDiariesList(exam: number): Observable<any> {
        let url = `${environment.apiPrefixUrl}diaries/getExam/` + exam;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return response;
            }));
    }

    getExamListOnDiary(): Observable<any> {
        let url = `${environment.apiPrefixUrl}diaries/getExamListOnDiary`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response: any) => {
                return response.result;
            }));
    }

    candidateImport(examId, file){
        let url = `${environment.apiPrefixUrl}candidates/candidateImport`;
        let requestOptions = RestConfig.getReqOpt();
        delete requestOptions.headers['Content-Type'];

        let formData = new FormData();
        formData.append('file', file, file.name);
        formData.append('exam_id', examId);

        return this.http.post(url, formData, requestOptions).pipe(
            map((response:any) => {
                return response;
            }));
    }

    exportTestCandidates(examId){
        let url = `${environment.apiPrefixUrl}candidates/exportTestCandidates`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: examId
        };

        return this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            return response;
        }));
    }

    examReadyPost(examId){
        let url = `${environment.apiPrefixUrl}exam/examReadyPost`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: examId
        };

        return this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            return response.data;
        }));
    }

    getExam(examId: number) {
        let prefix = environment.examType === 'mmk' ? `mmk/` : '';
        let url  = `${environment.apiPrefixUrl}${prefix}exams/${examId}`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions)
            .map((response: any) => {
                return response.data;
            });
    }

    updateExam(body: any, examId?: number): Observable<any> {
        let prefix = environment.examType === 'mmk' ? `mmk/` : '';
        let url  = `${environment.apiPrefixUrl}${prefix}exams/${examId}`;

        return this.http.patch(url, body, RestConfig.getReqOpt())
            .pipe(map((response:any) => {
                return response.data;
            }));
    }

    checkExamBeCreated(data) {
        let url = `${environment.apiPrefixUrl}mmk/exam/checkExamBeCreated`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.post(url, data, requestOptions)
            .map((response: any) => {
                return response.data;
            });
    }

    postExamTaskPieces(studyFieldId, subtypeId) {
        let url = `${environment.apiPrefixUrl}mmk/exam/postExamTaskPieces`;
        let requestOptions = RestConfig.getReqOpt();

        let data = {
            study_field_id: studyFieldId,
            subtype_id: subtypeId,
        };

        return this.http.post(url, data, requestOptions)
            .map((response: any) => {
                return response.data;
            });
    }

    setExamList(): Observable<any> {
        this.setExamListUrl();
        let requestOptions = RestConfig.getReqOpt();

        let urlSearchParams = new URLSearchParams();
        // requestOptions = requestOptions.merge({search: urlSearchParams});

        return this.http.get(this.defaultUrl, requestOptions)
            .map((response: any) => {
                return <Array<any>> response.data;
            });
    }

    // DEPRECATED
    postTasksExport(tasks){
        let url = `${environment.apiPrefixUrl}export/tasksExport`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            tasks: tasks,
        };

        return this.http.post(url, body, requestOptions).map((response:  any) => {
            return response;
        });
    }

    getExamAnswers(exam_id, adaptive = false): Observable<any> {
        this.setExamAnswersUrl(exam_id);
        let requestOptions= RestConfig.getReqOpt();

        let urlSearchParams = new URLSearchParams();
        // requestOptions = requestOptions.merge({search: urlSearchParams});

        return this.http.get(this.defaultUrl + (adaptive ? '?adaptive=1' : ''), requestOptions)
            .map((response: any) => {
                return <Array<any>> response.data;
            });
    }

    setCandidateAllAnswers(candidateExamID, examId): Observable<any> {
        let requestOptions = RestConfig.getReqOpt();
        let urlSearchParams = new URLSearchParams();

        urlSearchParams.set("candidateExamID", candidateExamID);
        urlSearchParams.set("examId", examId);

        return this.http.get(`${environment.apiPrefixUrl}task/getCandidateAllAnswers/${examId}/${candidateExamID}`, requestOptions)
            .map((response: any) => {
                return <Array<any>> response.data;
            });

    }

    saveMmkExaminer(data) {
        let url = `${environment.apiPrefixUrl}mmk/exam/addExaminers`;
        let requestOptions = RestConfig.getReqOpt();


        return this.http.post(url, data, requestOptions).pipe(map((response:any) => {
            if(response.success){
                alert('Sikeres hozzárendelés!');
            }

            return response;
        }));
    }
}
