
import {catchError, map} from 'rxjs/operators';
import { Injectable, EventEmitter } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { environment } from "../../environments/environment";
import { RestConfig } from "../http_apis/rest_config.http";
import {Observable, of, Subject} from "rxjs";
import {HttpClient} from "@angular/common/http";
import "rxjs/Rx";

@Injectable()
export class AuthService {
    public token: string;

    private jwtHelper = new JwtHelperService();

    private refreshTokenTimer;
    private tokenExpireDate: Date;

    private loginUrl: string = `${environment.apiPrefixUrl}login`;
    private refreshUrl: string = `${environment.apiPrefixUrl}refresh`;
    private userStatus: string;
    private userId: number;

    loggedEvent: EventEmitter<boolean> = new EventEmitter();
    public userDataLoaded = new Subject<any>();
    userData = null;

    get $userStatus() {
        return this.userStatus;
    }

    set $userStatus(status) {
        this.userStatus = status;
    }

    get $userId() {
        return this.userId;
    }

    constructor(private http: HttpClient) {
    }

    /**
     * Makes it possible to subscribe for this event
     */
    getuserDataLoadedSubject(): Observable<any> {
        return this.userDataLoaded.asObservable();
    }

    isTokenExpired(token?: string) {
        let _token = (token) ? token : this.token;

        return this.jwtHelper.isTokenExpired(_token);
    }

    login(loginValues): Observable<any> {
        let options = RestConfig.getReqOpt();

        return this.http.post(`${this.loginUrl}`, loginValues, options).pipe(map((response:any) => {
            let token = response.token;
            let userStatus = response.user_role;

            if (token) {
                RestConfig.setReqOpt(options, token);
                this.token = token;
                this.userStatus = userStatus;

                window.localStorage.setItem("itolc_token", token);
                window.localStorage.setItem("itolc_status", userStatus);

                this.refreshToken();
                this.getUserStatus().subscribe();

                return true;
            } else {
                return false;
            }
        }));
    }

    refreshToken() {
        this.tokenExpireDate =  this.jwtHelper.getTokenExpirationDate(this.token);
        let currentDate;

        if (this.refreshTokenTimer) {
            window.clearInterval(this.refreshTokenTimer);
            delete this.refreshTokenTimer;
        }

        this.refreshTokenTimer = window.setInterval(() => {

            currentDate = new Date();

            let diff = this.tokenExpireDate.getTime() - currentDate.getTime();

            if (diff < 100000) {
                this.getNewToken().subscribe((result) => {
                    window.localStorage.setItem("itolc_token", result);
                    this.token = result;
                    this.tokenExpireDate = this.jwtHelper.getTokenExpirationDate(this.token);
                },
                (error) => {
                    this.token = null;
                    alert($localize`Autentikációs hiba történt. Kérjük jelentkezzen be újra.` + error.toString());
                    window.location.reload();
                });
            }

        // tslint:disable-next-line:align
        }, 50000);

    }

    private getNewToken(): Observable<any> {

        return this.http.get(`${environment.apiPrefixUrl}token`, RestConfig.getReqOpt()).pipe(
            map((response:any) => response.token));
    }

    getUserStatus(): Observable<any> {
        return this.http.get(`${environment.apiPrefixUrl}user`, RestConfig.getReqOpt()).pipe(
            map((response:any) => {
                let userData = response.data;
                this.userStatus = userData.role;
                this.userId = userData.id;
                this.userData = userData;

                this.userDataLoaded.next(userData);
                console.log('User data loaded');

                return userData;
            })).pipe(catchError((error: any) => {
                if (error.ok === false) {
                    console.log('User status fetch failed, lets logout.');
                    this.logout();
                }
                return of(error);
            }));
    }

    logout(): Observable<boolean> {
        return this.http.post(`${environment.apiPrefixUrl}logout`, {logout: true}, RestConfig.getReqOpt()).pipe(
            map(() => {
                window.localStorage.removeItem("itolc_token");
                window.localStorage.removeItem("itolc_status");
                delete this.token;
                delete this.userStatus;
                return true;
            }));
    }
}
