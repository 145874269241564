export class RestConfig {
    private static url: string;
    private static reqOpt;

    static setUrl(value: string) {
        this.url = value;
    }

    static getUrl(): string {
        return this.url;
    }

    static setReqOpt(value, token?: string) {
        if (token) {
            value.headers['Authorization'] = `Bearer ${token}`;
        }
        this.reqOpt = value;
    }

    static getReqOpt() {
        return this.reqOpt;
    }
}