/**
 * Responsible to build up Websocket connection with the server for real time communication
 */
import {Injectable} from "@angular/core";
import * as io from 'socket.io-client';
import {EnvironmentService} from "./environment.service";
import {AuthService} from "./auth.service";
import {ExamService} from "./exam_service/exam.service";
import {Observable, Subject} from "rxjs";

@Injectable()
export class WebsocketService {
    private socket: SocketIOClient.Socket;
    public websocketInitialized = false;
    private identityDataSubscription = null;

    connected = false;

    private websocketCreated = new Subject<any>();

    constructor(
        private environmentService: EnvironmentService,
        private examService: ExamService,
        private authService: AuthService,
    ) {
        // If no valid URL is provided, dont do anything.
        if (this.environmentService.get('websocket') === null) {
            this.socket = null;
            return;
        }

        // Subscribe to the login event.
        this.authService.getuserDataLoadedSubject().subscribe((result) => {

            console.log('UserData Loaded, registering websocket');

            if (!result) {
                return false;
            }

            if (!this.websocketInitialized) {
                // @TODO: Disconnect from previous session
            }

            this.initializeWebsocket(() => {
                this.registerUser();
            });

        });
    }

    /**
     * Triggers the callback when the connection is estabilished
     * If it already is, triggers immediately
     * When new connection is estabilished, also called
     * @param callback
     */
    readyCallback(callback) {
        if (this.websocketInitialized) {
            callback();
        }

        this.getWebsocketCreatedSubject().subscribe(() => {
            callback();
        });
    }

    getWebsocketCreatedSubject(): Observable<any> {
        return this.websocketCreated.asObservable();
    }

    /**
     * Build up the connection
     */
    initializeWebsocket(callback?) {
        this.socket = io(this.environmentService.get('websocket'));
        console.log('Initialize websocket', this.environmentService.get('websocket'));
        this.websocketInitialized = true;
        this.websocketCreated.next();   // Fire event

        this.initializeHandlers();

        this.socket.on('connect', function () {
            console.log('Websocket Connected');
            if (callback) {
                callback();
            }
        });
    }

    /**
     * Add listeners here to websocket events
     */
    initializeHandlers() {
        this.socket.on('identificationFinished', (data) => {
            this.examService.identityCardImagesReload.emit(data.candidate_exam_id);
        });

        this.socket.on('startedExamPart', (data) => {
            this.examService.candidateStartedExamPart.emit(data);
        });

        this.socket.on('setCandidateStatus', (data) => {
            console.log('Candidate statuses', data);
            this.examService.candidateExamStatus.emit(data);
        });

        // We receive user statuses in an array
        this.socket.on('setCandidateStatusArray', (data) => {
            console.log('Candidate status array', data);
            this.examService.candidateExamStatusArray.emit({data: data});
        });

    }

    /**
     * Subscribe for an event
     * @param tag
     * @param callback
     */
    addHandler(tag, callback) {
        if (!this.socket) {
            return false;
        }

        this.socket.on(tag, callback);
        return true;
    }

    /**
     * After login authenticate ourselves with the token
     */
    registerUser(callback = null) {
        this.sendMessage('register', {
            'token': this.authService.token,
            'user_id': this.authService.$userId,
            'privileges': this.authService.$userStatus
        }, (confirmation) => {
            console.log('Admin registered on websocket', confirmation);
            this.connected = true;
            if (callback !== null) {
                callback();
            }
        });
    }

    subscribeForSocketEvent(event: string, callback) {
        console.log('Subscribing for event', event);
        if (!this.socket) {
            console.warn('Socket is not initiated, cannot subscribe');
            return;
        }

        this.socket.on(event, callback);
    }

    sendChatMessage(data) {
        this.sendMessage('chat', data);
    }

    sendMessage(tag: string, data: any = null, callback = null) {
        if (!this.socket) {
            return false;
        }

        this.socket.emit(tag, data, callback);
        return true;
    }

    private processMessage(data) {
        console.log('Incoming websocket message: ', data);
    }
}
