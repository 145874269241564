import {Component, OnInit, ViewChild} from "@angular/core";
import {AuthService} from "../../services/auth.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AppHeader} from "../header/app.header";
import {Router} from "@angular/router";
import {EnvironmentService} from "../../services/environment.service";

@Component({
    selector: "app-root",
    moduleId: module.id,
    templateUrl: "./app.login.html"
})
export class AppLogin implements OnInit {

    loginForm: FormGroup;

    @ViewChild(AppHeader) appHeaderComponent: AppHeader;

    constructor(private authService: AuthService,
                private router: Router,
                public environmentService: EnvironmentService,
                private formBuilder: FormBuilder) {

        this.loginForm = formBuilder.group({
            email: new FormControl(null, Validators.required),
            password: new FormControl(null, Validators.required),
            site: 'admin'
        });
    }

    onSubmit(event: Event) {
        event.preventDefault();

        if (this.loginForm.valid) {
            this.authService.login(this.loginForm.value).subscribe((isLogin) => {
                if (typeof(isLogin) === "boolean" && isLogin) {
                    this.authService.loggedEvent.emit(isLogin);
                }
            });
        }
    }

    ngOnInit(): void {
        if (this.authService.token) {
            this.router.navigateByUrl("/landing");
        }
    }
}
