<div class="">
    <div class="" *ngIf="typeList === 'tasks'">
        <h2 i18n>Keresés</h2>
        <div class="form-group col-xs-12 col-md-4">
            <label for="id" i18n>Feladat azonosító: </label><input class="form-control" type="number" id="id" [(ngModel)]="filters.id.value" (change)="loadListData()">
        </div>
        <div *ngIf="taskListColumns.filename" class="form-group col-xs-12 col-md-4">
            <label for="filename" i18n>Fájlnév: </label><input class="form-control" type="text" id="filename" [(ngModel)]="filters.filename.value" (change)="loadListData()">
        </div>
        <div class="form-group col-xs-12 col-md-4">
            <label for="created_at" i18n>Létrehozás dátuma: </label>
            <input class="form-control" type="text" id="created_at"
                (dateChange)="filters.created_at.value = $event.value ? $event.value.format('YYYY-MM-DD') : null;loadListData()"
                [matDatepicker]="createdAtPicker" (focus)="createdAtPicker.open()" (click)="createdAtPicker.open()">
            <mat-datepicker #createdAtPicker></mat-datepicker>
        </div>
        <div class="form-group col-xs-12 col-md-4">
            <label for="updated_at" i18n>Módosítás dátuma: </label>
            <input class="form-control" type="text" id="updated_at"
                (dateChange)="filters.updated_at.value = $event.value ? $event.value.format('YYYY-MM-DD') : null;loadListData()"
                [matDatepicker]="updatedAtPicker" (focus)="updatedAtPicker.open()" (click)="updatedAtPicker.open()">
            <mat-datepicker #updatedAtPicker></mat-datepicker>
        </div>
        <div *ngIf="taskListColumns.item_count" class="form-group col-xs-12 col-md-4">
            <!--<label for="updated_at">Létrehozó neve: <input type="text" id="updated_at" [(ngModel)]="filters.updated_at.value"></label><br />-->
            <label for="item_count" i18n>Itemszám: </label><input class="form-control" type="number" id="item_count" [(ngModel)]="filters.item_count.value" (change)="loadListData()">
        </div>
        <div *ngIf="taskListColumns.name" class="form-group col-xs-12 col-md-4">
            <label for="name" i18n>Feladatnév: </label><input class="form-control" type="text" id="name" [(ngModel)]="filters.name.value" (change)="loadListData()">
        </div>
        <div *ngIf="taskListColumns.language" class="form-group col-xs-12 col-md-4">
            <label for="language" i18n>Nyelv: </label><select class="form-control" name="language" id="language" [(ngModel)]="filters.language.value" (change)="loadListData()">
                <option value="">---</option>
                <option value="English" i18n>English</option>
                <option value="German" i18n>German</option>
            </select>
        </div>
        <div *ngIf="taskListColumns.level" class="form-group col-xs-12 col-md-4">
            <label for="level" i18n>Szint: </label><input class="form-control" type="text" id="level" [(ngModel)]="filters.level.value" (change)="loadListData()">
        </div>
        <div *ngIf="taskListColumns.exam_scheme" class="form-group col-xs-12 col-md-4">
            <label for="exam_scheme_id" i18n>Témakör: </label>
            <select class="form-control" name="exam_scheme_id" id="exam_scheme_id" [(ngModel)]="filters.exam_scheme_id.value" (change)="loadListData()">
                <option value="null" i18n>Összes</option>
                <option *ngFor="let s of examSchemes"
                        [value]="s.id">{{s.study_fields_name}} / {{s.questions_subtype_name}}</option>
            </select>
        </div>
        <div *ngIf="taskListColumns.format" class="form-group col-xs-12 col-md-4">
            <label for="format" i18n>Típus: </label>
            <select class="form-control" name="format" id="format" [(ngModel)]="filters.format.value" (change)="loadListData()">
                <option value="" i18n>Összes</option>
                <option value="trueFalse" i18n>Igaz-Hamis</option>
                <option value="multipleChoice" i18n>Feleletválasztós</option>
                <option value="writing" i18n>Kifejtős</option>
            </select>
        </div>
        <div *ngIf="taskListColumns.section" class="form-group col-xs-12 col-md-4">
            <label for="created_at" i18n>Szakasz: </label><input class="form-control" type="number" id="section" [(ngModel)]="filters.section.value" (change)="loadListData()">
        </div>
        <div *ngIf="taskListColumns.question_search" class="form-group col-xs-12 col-md-4">
            <label for="question_text" i18n>Kérdés szövege: </label><input class="form-control" type="text" id="question_text" [(ngModel)]="filters.question_text.value" (change)="loadListData()">
        </div>
    </div>
</div>

<div *ngIf="typeList === 'exam-modules'">
    <a class="btn btn-large btn-default" href="javascript:void(0);" role="button" routerLink="/analysis/groups" i18n>Csoportos elemzések</a>
</div>

<div *ngIf="environmentService.get('allow_task_export') === true && typeList === 'tasks'">
    <div class="col-md-offset-10 col-md-2 text-right">
        <button type="button" class="btn btn-danger" (click)="exportTask()">Export</button>
    </div>
</div>

<table class="table table-hover">
    <thead>
    <tr>
        <th *ngFor="let item of tableHeaders">{{item}}</th>
        <th *ngIf="environmentService.get('allow_task_export') === true && typeList === 'tasks'">
            <input type="checkbox" (change)="selectAllCheckbox(elem)" #elem> Összes kijelölése
        </th>
        <th>-</th>
    </tr>
    </thead>
    <tbody class="exam-table-body task-list-table" *ngIf="typeList === 'tasks'">
    <tr *ngFor="let item of getFilteredList()">
        <td *ngIf="taskListColumns.id"> {{item.exam.id}} </td>
        <td *ngIf="taskListColumns.exam_scheme"> {{item.exam.subtype_name}} </td>
        <td *ngIf="taskListColumns.section"> {{item.exam.section}} </td>
        <td *ngIf="taskListColumns.exam_part"> {{item.exam.exam_part}} </td>
        <td *ngIf="taskListColumns.user_id"> {{item.exam.user_id}} </td>
        <td *ngIf="taskListColumns.user_name">
            <ng-container *ngIf="item.exam.user">
                {{item.exam.user.first_name}}&nbsp;{{item.exam.user.last_name}}
            </ng-container>

            <ng-container *ngIf="!item.exam.user">
                (Unknown user)
            </ng-container>
        </td>
        <td *ngIf="taskListColumns.filename"> {{item.exam.filename}} </td>
        <td *ngIf="taskListColumns.level"> {{item.exam.level}} </td>
        <td *ngIf="taskListColumns.language"> {{item.exam.language}} </td>
        <td *ngIf="taskListColumns.part"> {{item.exam.part}} </td>
        <td *ngIf="taskListColumns.number"> {{item.exam.number}} </td>
        <td *ngIf="taskListColumns.name"> {{item.exam.name}} </td>
        <td *ngIf="taskListColumns.source"> {{item.exam.source}} </td>
        <td *ngIf="taskListColumns.view_date"> {{item.exam.view_date}} </td>
        <td *ngIf="taskListColumns.source_type"> {{item.exam.source_type}} </td>
        <td *ngIf="taskListColumns.word_count"> {{item.exam.word_count}} </td>
        <td *ngIf="taskListColumns.item_count"> {{item.exam.item_count}} </td>
        <td *ngIf="taskListColumns.topic"> {{item.exam.topic}} </td>
        <td *ngIf="taskListColumns.text_breed"> {{item.exam.text_breed}} </td>
        <td *ngIf="taskListColumns.instruction"> {{item.exam.instruction}} </td>
        <td *ngIf="taskListColumns.task_data"> {{item.exam.task_data}} </td>
        <td *ngIf="taskListColumns.format && environmentService.get('examType') === 'mmk'"> {{item.exam.format | taskTypeName }} </td>
        <td *ngIf="taskListColumns.format && environmentService.get('examType') !== 'mmk'"> {{item.exam.type ? taskTypes[item.exam.type] : '-' }} </td>
        <td *ngIf="taskListColumns.format_code_id"> {{item.exam.format_code_id}} </td>
        <td *ngIf="taskListColumns.text_source_id"> {{item.exam.text_source_id}} </td>
        <td *ngIf="taskListColumns.comment"> {{item.exam.comment}} </td>
        <td *ngIf="taskListColumns.archived_at"> {{item.exam.archived_at}} </td>
        <td *ngIf="taskListColumns.created_at"> {{item.exam.created_at}} </td>
        <td *ngIf="taskListColumns.updated_at"> {{item.exam.updated_at}} </td>
        <td *ngIf="taskListColumns.last_used_day"> {{item.exam.last_used_day}} </td>
        <td *ngIf="environmentService.get('allow_task_export') === true">
            <input type="checkbox" (change)="changeCheckbox(elem)" #elem [id]="item.exam.id" value="{{item.exam.id}}" class="task-checkbox">
        </td>
        <td>
            <span class="btn btn-sm btn-primary" (click)="openFilesOnNewPage(item.examUrl, null, false)"><i class="fa fa-pencil"></i></span>
            <span class="btn btn-sm btn-warning" (click)="openFilesOnNewPage(item.examUrl, null, true)"><i class="fa fa-copy"></i></span>
        </td>
    </tr>
    </tbody>

    <tbody class="exam-table-body corrector" *ngIf="typeList === 'exam-answers'">
        <ng-container *ngFor="let item of list; let i = index">
        <tr>
            <td>
                {{item.id}}
            </td>
            <td>
                {{item.date}}
            </td>
            <td>
                {{item.places}}
            </td>
            <td>
                {{item.language}}
            </td>
            <td>
                {{item.level}}
            </td>
            <td>
                {{ examTypes[item.type] }}
            </td>
            <td>
                <a (click)="loadExamData(i, item.id)" [ngSwitch]="areExamsShown[i]">
                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                    <span *ngSwitchCase="true" i18n>Elrejt</span>
                </a>
            </td>
        </tr>

        <tr [class.hide]="!areExamsShown[i]">
            <td colspan="8" class="grey-bkg">
                <table class="table-model white-bkg">
                    <thead>
                    <tr>
                        <th i18n>Modul</th>
                        <th i18n>Művelet</th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr>
                        <td i18n>Személyazonosságok</td>
                        <td>
                            <a (click)="loadExamIdentityData(i, item.id, true)" [ngSwitch]="areExamsIdentityShown[i]">
                                <span *ngSwitchCase="false" i18n>Megtekint</span>
                                <span *ngSwitchCase="true" i18n>Elrejt</span>
                            </a>

                            <!--IDENTITY CHECK-->
                            <app-examiner-select
                                [exam_id]="item.id"
                                [isUserAdmin]="isUserAdmin"
                                [exam_part]="'identity_check'"
                                >
                            </app-examiner-select>
                            <!--IDENTITY CHECK-->
                        </td>
                    </tr>

                    <!-- areExamsIdentityShown -->
                    <ng-container>
                        <tr [class.hide]="!areExamsIdentityShown[i]">
                        <td colspan="8" class="grey-bkg">
                            <table class="table-model white-bkg">
                                <thead>
                                <tr>
                                    <th i18n>Vizsgázó neve</th>
                                    <th i18n>Vizsgázó azonosítója</th>
                                    <th i18n>Ellenőrzés állapota</th>
                                </tr>
                                </thead>
                                <tbody>
                                <ng-container>
                                    <tr *ngFor="let candidateIdentity of identityData[i]">
                                        <td>
                                            <a href="/candidate-identity-page/{{ candidateIdentity.id }}" target="_blank">
                                                {{ candidateIdentity.name}}
                                            </a>
                                        </td>
                                        <td>{{ candidateIdentity.user_id}}</td>
                                        <td>
                                            <span *ngIf="!candidateIdentity.identity_check" i18n>Ellenőrzésre vár</span>
                                            <span *ngIf="candidateIdentity.identity_check" i18n>Ellenőrizve</span>
                                        </td>
                                    </tr>
                                </ng-container>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                    </ng-container>

                    <!-- WRITING -->
                    <ng-container>
                        <tr *ngIf="examData[i].writing">
                            <td> writing</td>
                            <td>
                                <a (click)="toggleModules(i, 'writing')" [ngSwitch]="areModulesShown[i].writing">
                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                    <span *ngSwitchCase="true" class="hide-text" i18n>Elrejt</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="createPostExcel(examData[i].writing[0]['exam_id'], 'writing')">
                                    <span i18n>Válaszok exportálása</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="openMoveExamAnswersPopup(examData[i].writing[0]['exam_id'], 'writing')">
                                    <span i18n>Válaszok áthelyezése</span>
                                </a>

                                <!--EXAMINER DIV-->
                                <app-examiner-select
                                    [exam_id]="item.id"
                                    [isUserAdmin]="isUserAdmin"
                                    [exam_part]="'writing'"
                                    >
                                </app-examiner-select>
                                <!--EXAMINER DIV-->
                            </td>
                        </tr>
                        <tr *ngIf="examData[i].writing" [class.hide]="!areModulesShown[i].writing">
                            <td colspan="3">
                                <table class="table-task grey-bkg">
                                    <thead>
                                    <tr>
                                        <th i18n>Feladat sorszáma</th>
                                        <th i18n>Feladat típusa</th>
                                        <th i18n>Művelet</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <ng-container *ngFor="let task of examData[i].writing; let j = index">
                                        <tr>
                                            <td>{{task.filename}} ({{task.id}})</td>
                                            <td *ngIf="task.type === 'writing'" i18n>Szövegírás</td>
                                            <td *ngIf="task.type === 'form'" i18n>Formanyomtatvány</td>
                                            <td>
                                                <a (click)="toggleTasks(i, j, 'writing')"
                                                   [ngSwitch]="areTasksShown[i]['writing'][j]">
                                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                                    <span *ngSwitchCase="true" i18n>Elrejt</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr [class.hide]="!areTasksShown[i]['writing'][j]">
                                            <td colspan="5">
                                                <table class="table-candidate white-bkg">
                                                    <thead>
                                                    <tr>
                                                        <th i18n>Vizsgázó azonosítója</th>
                                                        <th *ngIf="this.isUserAdmin" i18n>Vizsgázó neve</th>
                                                        <th i18n>Értékelés állapota</th>
                                                        <th i18n>Pontszám</th>
                                                        <th i18n>Művelet</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let candidate of task.candidates">
                                                        <ng-container *ngIf="checkModuleExaminers(candidate.examiners, 'writing')">
                                                            <td>{{candidate.user_id}}</td>
                                                            <td *ngIf="this.isUserAdmin">{{candidate.first_name}}
                                                                {{candidate.last_name}}
                                                            </td>
                                                            <td>
                                                                <ng-container *ngIf="candidate.rating_count === 0">
                                                                    <span i18n>Kézi értékelésre vár</span>
                                                                </ng-container>
                                                                <ng-container *ngIf="candidate.rating_count > 0">
                                                                    <span i18n>Kézzel javítva</span>
                                                                </ng-container>
                                                            </td>
                                                            <td>
                                                                <ng-container *ngIf="!isUserAdmin && candidate.rating && candidate.rating_count > 0">
                                                                    <span>{{candidate.rating}}/16</span>
                                                                </ng-container>
                                                                <ng-container *ngIf="isUserAdmin && candidate.rating && candidate.rating_count > 0">
                                                                    <span>{{candidate.rating}}/32</span>
                                                                </ng-container>
                                                                <ng-container *ngIf="candidate.rating === null && candidate.rating_count > 0">
                                                                    <span>értékelhetetlen</span>
                                                                </ng-container>
                                                            </td>
                                                            <td>
                                                                <a (click)="navigateToTaskCorrector(task.id, candidate.candidate_exam_id)" i18n>Megtekint</a>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </ng-container>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>

                    <ng-container>
                        <tr *ngIf="examData[i].speaking">
                            <td>speaking</td>
                            <td>
                                <a (click)="toggleModules(i, 'speaking')" [ngSwitch]="areModulesShown[i].speaking">
                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                    <span *ngSwitchCase="true" class="hide-text" i18n>Elrejt</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="createPostExcel(examData[i].speaking[0]['exam_id'], 'speaking')">
                                    <span i18n>Válaszok exportálása</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="openMoveExamAnswersPopup(examData[i].speaking[0]['exam_id'], 'speaking')">
                                    <span i18n>Válaszok áthelyezése</span>
                                </a>

                                <!--EXAMINER DIV-->
                                <app-examiner-select
                                    [exam_id]="item.id"
                                    [isUserAdmin]="isUserAdmin"
                                    [exam_part]="'speaking'"
                                    >
                                </app-examiner-select>
                                <!--EXAMINER DIV-->
                            </td>
                        </tr>
                        <tr *ngIf="examData[i].speaking" [class.hide]="!areModulesShown[i].speaking">
                            <td colspan="3">
                                <table class="table-task grey-bkg">
                                    <thead>
                                    <tr>
                                        <th i18n>Feladat sorszáma</th>
                                        <th i18n>Feladat típusa</th>
                                        <th i18n>Művelet</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <ng-container *ngFor="let task of examData[i].speaking; let j = index">
                                        <tr>
                                            <td>{{task.filename}} ({{task.id}})</td>
                                            <td i18n>Beszédkészség</td>
                                            <td>
                                                <a (click)="toggleTasks(i, j, 'speaking')"
                                                   [ngSwitch]="areTasksShown[i]['speaking'][j]">
                                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                                    <span *ngSwitchCase="true" i18n>Elrejt</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr [class.hide]="!areTasksShown[i]['speaking'][j]">
                                            <td colspan="5">
                                                <table class="table-candidate white-bkg">
                                                    <thead>
                                                    <tr>
                                                        <th i18n>Vizsgázó azonosítója</th>
                                                        <th *ngIf="this.isUserAdmin" i18n>Vizsgázó neve</th>
                                                        <th i18n>Értékelés állapota</th>
                                                        <th i18n>Pontszám</th>
                                                        <th i18n>Művelet</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let candidate of task.candidates">
                                                        <ng-container *ngIf="checkModuleExaminers(candidate.examiners, 'speaking')">
                                                            <td>{{candidate.user_id}}</td>
                                                            <td *ngIf="this.isUserAdmin">{{candidate.first_name}}
                                                                {{candidate.last_name}}
                                                            </td>
                                                            <td>
                                                                <ng-container *ngIf="candidate.rating_count === 0">
                                                                    <span i18n>Kézi értékelésre vár</span>
                                                                </ng-container>
                                                                <ng-container *ngIf="candidate.rating_count > 0">
                                                                    <span i18n>Kézzel javítva</span>
                                                                </ng-container>
                                                            </td>
                                                            <td>
                                                                <ng-container *ngIf="!isUserAdmin && candidate.rating && candidate.rating_count > 0">
                                                                    <span>{{candidate.rating}}/
                                                                        <span *ngIf="j === 0">20</span>
                                                                        <span *ngIf="j !== 0">16</span>
                                                                    </span>
                                                                </ng-container>
                                                                <ng-container *ngIf="isUserAdmin && candidate.rating && candidate.rating_count > 0">
                                                                    <span>{{candidate.rating}}/
                                                                        <span *ngIf="j === 0">40</span>
                                                                        <span *ngIf="j !== 0">32</span>
                                                                    </span>
                                                                </ng-container>
                                                                <ng-container *ngIf="candidate.rating === null && candidate.rating_count > 0">
                                                                    <span i18n>értékelhetetlen</span>
                                                                </ng-container>
                                                            </td>
                                                            <td>
                                                                <a (click)="navigateToTaskCorrector(task.id, candidate.candidate_exam_id)" i18n>Megtekint</a>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </ng-container>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>

                    <ng-container>
                        <tr *ngIf="examData[i].listening">
                            <td i18n>listening</td>
                            <td>
                                <a (click)="toggleModules(i, 'listening')" [ngSwitch]="areModulesShown[i].listening">
                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                    <span *ngSwitchCase="true" class="hide-text" i18n>Elrejt</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="createPostExcel(examData[i].listening[0]['exam_id'], 'listening')">
                                    <span i18n>Válaszok exportálása</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="openMoveExamAnswersPopup(examData[i].listening[0]['exam_id'], 'listening')">
                                    <span i18n>Válaszok áthelyezése</span>
                                </a>

                                <!--EXAMINER DIV-->
                                <app-examiner-select
                                    [exam_id]="item.id"
                                    [isUserAdmin]="isUserAdmin"
                                    [exam_part]="'listening'"
                                    >
                                </app-examiner-select>
                                <!--EXAMINER DIV-->
                            </td>
                        </tr>
                        <tr *ngIf="examData[i].listening" [class.hide]="!areModulesShown[i].listening">
                            <td colspan="3">
                                <table class="table-task grey-bkg">
                                    <thead>
                                    <tr>
                                        <th i18n>Feladat sorszáma</th>
                                        <th i18n>Feladat típusa</th>
                                        <th i18n>Művelet</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <ng-container *ngFor="let task of examData[i].listening; let j = index">
                                        <tr>
                                            <td>{{task.filename}} ({{task.id}})</td>
                                            <td *ngIf="task.type === 'short_answer'" i18n>Rövid Válasz</td>
                                            <td *ngIf="task.type === 'pair'" i18n>Párosítás</td>
                                            <td *ngIf="task.type === 'select'" i18n>Feleletválasztás</td>
                                            <td *ngIf="task.type === 'select_group'" i18n>Csoportosított Feleletválasztás
                                            </td>
                                            <td *ngIf="task.type === 'extend'" i18n>Szöveg kiegészítés</td>
                                            <td>
                                                <a (click)="toggleTasks(i, j, 'listening')"
                                                   [ngSwitch]="areTasksShown[i]['listening'][j]">
                                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                                    <span *ngSwitchCase="true" i18n>Elrejt</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr [class.hide]="!areTasksShown[i]['listening'][j]">
                                            <td colspan="5">
                                                <table class="table-candidate white-bkg">
                                                    <thead>
                                                    <tr>
                                                        <th i18n>Vizsgázó azonosítója</th>
                                                        <th *ngIf="this.isUserAdmin" i18n>Vizsgázó neve</th>
                                                        <th i18n>Értékelés állapota</th>
                                                        <th i18n>Pontszám</th>
                                                        <th i18n>Művelet</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let candidate of task.candidates">
                                                        <ng-container *ngIf="checkModuleExaminers(candidate.examiners, 'listening')">
                                                            <td>{{candidate.user_id}}</td>
                                                            <td *ngIf="this.isUserAdmin">{{candidate.first_name}}
                                                                {{candidate.last_name}}
                                                            </td>

                                                            <td *ngIf="task.type === 'select' && candidate.auto_correct === 1 ||
                                           task.type === 'select_group' && candidate.auto_correct === 1 ||
                                           task.type === 'pair' && candidate.auto_correct === 1 ||
                                           task.type === 'extend' && task.task_data.indexOf(':true') > 0  && candidate.auto_correct === 1" i18n>
                                                                <!--"task_checkbox":true => drag n drop-->
                                                                Automatikusan javítva
                                                            </td>
                                                            <td *ngIf="task.type === 'short_answer' && candidate.auto_correct === 1 ||
                                           task.type === 'extend' && task.task_data.indexOf(':true') < 0 && candidate.auto_correct === 1" i18n>
                                                                Automatikusan becsülve (kézi értékelésre vár)
                                                            </td>
                                                            <td *ngIf="task.type === 'short_answer' && candidate.auto_correct === 0||
                                           task.type === 'extend' && task.task_data.indexOf(':true') < 0 && candidate.auto_correct === 0" i18n>
                                                                Felülbírált kézi értékelés
                                                            </td>
                                                            <td *ngIf="candidate.auto_correct === null"></td>
                                                            <!--if no correction keys added to the task-->

                                                            <td *ngIf="candidate.score">
                                                                {{candidate.score}}/{{task.item_count}}
                                                            </td>
                                                            <td *ngIf="!candidate.score"></td>

                                                            <td>
                                                                <a (click)="navigateToTaskCorrector(task.id, candidate.candidate_exam_id)" i18n>Megtekint</a>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </ng-container>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>

                    <ng-container>
                        <tr *ngIf="examData[i].reading">
                            <td>reading</td>
                            <td>
                                <a (click)="toggleModules(i, 'reading')" [ngSwitch]="areModulesShown[i].reading">
                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                    <span *ngSwitchCase="true" class="hide-text" i18n>Elrejt</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="createPostExcel(examData[i].reading[0]['exam_id'], 'reading')">
                                    <span>Válaszok exportálása</span>
                                </a>
                                <a *ngIf="isUserAdmin" (click)="openMoveExamAnswersPopup(examData[i].reading[0]['exam_id'], 'reading')">
                                    <span i18n>Válaszok áthelyezése</span>
                                </a>

                                <!--EXAMINER DIV-->
                                <app-examiner-select
                                    [exam_id]="item.id"
                                    [isUserAdmin]="isUserAdmin"
                                    [exam_part]="'reading'"
                                    >
                                </app-examiner-select>
                            </td>
                        </tr>
                        <tr *ngIf="examData[i].reading" [class.hide]="!areModulesShown[i].reading">
                            <td colspan="3">
                                <table class="table-task grey-bkg">
                                    <thead>
                                    <tr>
                                        <th i18n>Feladat sorszáma</th>
                                        <th i18n>Feladat típusa</th>
                                        <th i18n>Művelet</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <ng-container *ngFor="let task of examData[i].reading; let j = index">
                                        <tr>
                                            <td>{{task.filename}} ({{task.id}})</td>
                                            <td *ngIf="task.type === 'short_answer'" i18n>Rövid Válasz</td>
                                            <td *ngIf="task.type === 'pair'" i18n>Párosítás</td>
                                            <td *ngIf="task.type === 'select'" i18n>Feleletválasztás</td>
                                            <td *ngIf="task.type === 'extend'" i18n>Szöveg kiegészítés</td>
                                            <td>
                                                <a (click)="toggleTasks(i, j, 'reading')"
                                                   [ngSwitch]="areTasksShown[i]['reading'][j]">
                                                    <span *ngSwitchCase="false" i18n>Megtekint</span>
                                                    <span *ngSwitchCase="true" i18n>Elrejt</span>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr [class.hide]="!areTasksShown[i]['reading'][j]">
                                            <td colspan="5">
                                                <table class="table-candidate white-bkg">
                                                    <thead>
                                                    <tr>
                                                        <th i18n>Vizsgázó azonosítója</th>
                                                        <th *ngIf="this.isUserAdmin" i18n>Vizsgázó neve</th>
                                                        <th i18n>Értékelés állapota</th>
                                                        <th i18n>Pontszám</th>
                                                        <th i18n>Művelet</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr *ngFor="let candidate of task.candidates">
                                                        <ng-container *ngIf="checkModuleExaminers(candidate.examiners, 'reading')">
                                                            <td>{{candidate.user_id}}</td>
                                                            <td *ngIf="this.isUserAdmin">{{candidate.first_name}}
                                                                {{candidate.last_name}}
                                                            </td>

                                                            <td *ngIf="task.type === 'select' && candidate.auto_correct === 1 ||
                                           task.type === 'pair' && candidate.auto_correct === 1 ||
                                           task.type === 'extend' && task.task_data.indexOf(':true') > 0 && candidate.auto_correct === 1 ||
                                           task.type === 'extend' && task.task_data.indexOf(':true') > 0 && candidate.auto_correct === 0" i18n>
                                                                <!--task.task_data.indexOf(':true') -> "task_checkbox":true => drag n drop-->
                                                                Automatikusan javítva
                                                            </td>
                                                            <td *ngIf="task.type === 'short_answer' && candidate.auto_correct === 1 ||
                                           task.type === 'extend' && task.task_data.indexOf(':true') < 0 && candidate.auto_correct === 1" i18n>
                                                                Automatikusan becsülve (kézi értékelésre vár)
                                                            </td>
                                                            <td *ngIf="task.type === 'short_answer' && candidate.auto_correct === 0 ||
                                           task.type === 'extend' && task.task_data.indexOf(':true') < 0 && candidate.auto_correct === 0" i18n>
                                                                Felülbírált kézi értékelés
                                                            </td>
                                                            <td *ngIf="candidate.auto_correct === null"></td>
                                                            <!--if no correction keys added to the task-->

                                                            <td *ngIf="candidate.score">
                                                                {{candidate.score}}/{{task.item_count}}
                                                            </td>
                                                            <td *ngIf="!candidate.score"></td>

                                                            <td>
                                                                <a (click)="navigateToTaskCorrector(task.id, candidate.candidate_exam_id)" i18n>Megtekint</a>
                                                            </td>
                                                        </ng-container>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </ng-container>

                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>

                    </tbody>
                </table>
            </td>
        </tr>
    </ng-container>
    </tbody>

    <tbody class="exam-table-body" *ngIf="typeList === 'exams'">
    <tr *ngFor="let item of list" >
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.id"> {{item.id}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.study_field_id"> {{item.study_field_id}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.study_field_name"> {{item.study_field_name}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.level_name"> {{item.level_name}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.language"> {{item.language}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.level"> {{item.level}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.place"> {{item.places}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.date"> {{item.date}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.anchoring"> {{item.anchoring}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.structure"> {{item.structure}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.true_false_percent"> {{item.true_false_percent}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.multiple_choice_percent"> {{item.multiple_choice_percent}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.writing_percent"> {{item.writing_percent}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.status"> {{item.status}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.type"> {{ examTypes[item.type] }} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.comment" style="width: 400px;"> {{item.comment}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.ready"> {{item.ready}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.deployed_at"> {{item.deployed_at}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.created_at"> {{item.created_at}} </td>
        <td (click)="openFilesOnNewPage(item.id)" *ngIf="examListColumns.updated_at"> {{item.updated_at}} </td>
        <td *ngIf="environmentService.get('simple_identity_check') === true">
            <app-examiner-select
                [exam_id]="item.id"
                [isUserAdmin]="isUserAdmin"
                [exam_part]="'identity_check'"
                >
            </app-examiner-select>
        </td>
    </tr>
    </tbody>

    <!--ANALYSIS-->
    <tbody class="exam-table-body analysis" *ngIf="typeList === 'exam-modules'">
    <ng-container *ngFor="let exam of list; let i = index">
        <tr>
            <td>
                {{exam.id}}
            </td>
            <td>
                {{exam.date}}
            </td>
            <td>
                {{exam.place}}
            </td>
            <td>
                {{exam.level}}
            </td>
            <td>
                {{ examTypes[exam.type] }}
            </td>
            <td>
                {{exam.files}}
            </td>
            <td>
                <a (click)="loadExamData(i, exam.id)" [ngSwitch]="areExamsShown[i]">
                    <span *ngSwitchCase="false" (click)="subsToGetNumOfGeneratedFiles(exam.id)" i18n>Megtekint</span>
                    <span *ngSwitchCase="true" i18n>Elrejt</span>
                </a>
            </td>
        </tr>

        <tr [class.hide]="!areExamsShown[i]">
            <td colspan="8" class="grey-bkg">
                <table class="table-model white-bkg" [ngClass]="{'loader_table': waitingForSdFile === true}">
                    <thead>
                    <tr>
                        <th i18n>Modul</th>
                        <th i18n>Fájlok száma</th>
                        <th></th>
                        <th></th>
                        <th i18n>Művelet</th>
                    </tr>
                    </thead>

                    <tbody>
                    <ng-container *ngFor="let part of ['writing', 'reading', 'speaking', 'listening']; let partIndex = index">
                        <tr *ngIf="examData[i][part]">
                            <td i18n>{{part}}</td>
                            <td *ngIf="fileCounter[exam.id]">
                                {{fileCounter[exam.id][partIndex].files}}
                            </td>
                            <td></td>
                            <td>
                                <!--<a *ngIf="fileCounter[exam.id] && fileCounter[exam.id][0].files > 0"
                                   class="btn btn-small btn-block btn-default"
                                   (click)="openResultsOnNewPage(exam.id, 'writing')" role="button" i18n>
                                    Eredmények megtekintése
                                </a>-->
                            </td>
                            <td class="text-right">
                                <a
                                   class="btn btn-small btn-default mr-3"
                                   (click)="openFilesOnNewPage(exam.id, part)" role="button" i18n>
                                    Elemzés
                                </a>
                                <button class="btn btn-small btn-default" (click)="excelExport(exam.id, part)">
                                    <span>SPSS export</span>
                                </button>
                                 <!--<a *ngIf="fileCounter[exam.id] && fileCounter[exam.id][0].files === 0"
                                   class="btn btn-small btn-block btn-default"
                                   (click)="onClickGenerateSdFile(exam.id, 'writing', 0, i)" role="button" i18n>
                                    .sd fájl generálása
                                 </a>-->
                            </td>
                        </tr>
                    </ng-container>


                    <ng-container>
                        <tr>
                            <td colspan="5" class="text-center">
                                <p class="text-center aggregated">
                                    <span i18n>Összesítétt eredmények </span>
                                    <b (click)="aggregatedResults(exam.id)"> <span i18n> letöltése</span> </b>
                                </p>
                            </td>
                        </tr>
                    </ng-container>
                    </tbody>
                </table>
            </td>
        </tr>
    </ng-container>
    </tbody>
    <!--ANALYSIS END-->
</table>

<mat-paginator [length]="matPagination['length']"
              [pageSize]="matPagination['size']"
              [pageSizeOptions]="[5, 10, 25, 100, 250, 500]"
              (page)="matPaginate($event)"
              *ngIf="typeList === 'exam-answers' || typeList === 'exams' || typeList === 'tasks' || typeList === 'exam-modules'">
</mat-paginator>
