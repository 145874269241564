import { Injectable, EventEmitter } from "@angular/core";
import { UploadHttpApi } from "../../http_apis/upload.http";
import {HttpClient} from '@angular/common/http';

interface IUploadedData {
    id: number;
    url: string;
}

@Injectable()
export class FileUploadService {

    uploadedFile: EventEmitter<any> = new EventEmitter();

    private uploadHttpApi: UploadHttpApi;
    public isVideoLoading: string = null;

    constructor(private http: HttpClient) {
        this.uploadHttpApi = new UploadHttpApi(http,  "data");
        this.uploadHttpApi.setDefaultUrl("files");
    }

    onChangeInput(event: Event, name: string, type?: any, index?: number) {
        let inputEl = <HTMLInputElement> event.target;
        let file = inputEl.files[0];
        let formData = new FormData();

        formData.append(name, file, file.name);
        console.log("loading");
        this.isVideoLoading = type;

        this.uploadHttpApi.insert(formData, true).subscribe((uploadedFileData: IUploadedData) => {
            this.uploadedFile.emit({url: uploadedFileData.url, type, index});
            console.log("loaded");
            this.isVideoLoading = null;
        });

    }
}
