<div class="header-wrapper">
    <div class="container header-container">
        <div class="row header-row">
            <div class="col-xs-12 header-logo-wrapper">
                <h1>
                    <a href="#" routerLink="/landing/tasks">{{title}}</a> 
                </h1>
                <a class="btn btn-small btn-block btn-default" href="#" role="button"
                *ngIf="isLogged"
                (click)="onClickLogout($event)" i18n>Kijelentkezés ({{authService.userData ? authService.userData.email : '...'}})</a>
            </div>
        </div>
    </div>
</div>
