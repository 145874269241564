import {Component, OnInit, DoCheck, Input, ViewChild, TemplateRef, ElementRef, OnDestroy} from "@angular/core";
import {ApiService} from "../../../services/api.service";
import {ExamService} from "../../../services/exam_service/exam.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {WebsocketService} from "../../../services/websocket.service";

@Component({
    moduleId: module.id,
    selector: "session-list",
    host: {
        id: "session-list"
    },
    templateUrl: "session-list.component.html",
    styleUrls: ["session-list.component.css"]
})

/**
 * Class for listing live sessions for an exam
 */
export class SessionListComponent implements OnInit, OnDestroy {
    @Input("exam_id") exam_id: number;
    @ViewChild('liveSessionDateDialog') liveSessionDateDialog: TemplateRef<any>;

    sessions = [];
    sessionEdit = null;

    constructor(
        private apiService: ApiService,
        private examService: ExamService,
        private websocketService: WebsocketService,
        public dialog: MatDialog,
        private toastr: ToastrService,
    ) {

    }

    /**
     * Trigger the beginning of the live session
     * @param candidate_exam_id
     */
    startLiveSession(session) {
        this.examService.initLiveExam.emit({
            'candidate_exam_id': session.id,
            'user_id': session.candidate.user_id,
            'first_name': session.candidate.first_name,
            'last_name': session.candidate.last_name,
        });
    }

    /**
     * Trigger dialog for changing start time of the live session
     * @param session
     */
    changeLiveSession(session) {
        this.sessionEdit = session;
        const dialogRef = this.dialog.open(this.liveSessionDateDialog);
        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.sessionEdit.live = this.sessionEdit.live.format('YYYY-MM-DD HH:mm:ss');
                this.apiService.changeLiveSession(this.sessionEdit.id, this.sessionEdit.live).subscribe(() => {
                    this.toastr.success(this.sessionEdit.candidate.user_id + ' felhasználó szóbeli vizsgájának új kezdeti ideje: ' + this.sessionEdit.live, 'Művelet végrehajtva.', {onActivateTick: true});
                    this.sessionEdit = null;
                    this.loadSessionList();
                });
            }
        });
    }

    /**
     * Fetch and store sessions for this exam
     */
    loadSessionList() {
        this.apiService.getLiveSessions(this.exam_id).subscribe((data) => {
            this.sessions = data as any;
        });
    }

    ngOnInit(): void {
        this.loadSessionList();

        // Listen websocket for status updates
        this.websocketService.readyCallback(() => {
            this.websocketService.addHandler('live-session-changed', (data) => {
                console.log('live-session-changed event received', data, this.exam_id);

                // If we are displaying this exam, update the data
                if (data.exam_id === this.exam_id) {
                    console.log('Refreshing live sessions for exam', data.exam_id);
                    this.loadSessionList();
                }
            });
        });
    }

    ngOnDestroy(): void {
    }

}
