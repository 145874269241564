import {Component} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ITask} from "../../interfaces/task.interface";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {environment} from "../../../environments/environment";
import {AuthService} from "../../services/auth.service";
import {HttpClient} from "@angular/common/http";

@Component({
  moduleId: module.id,
  selector: "edit-exam-answer",
  templateUrl: "./corrector.component.html",
  styleUrls: ["./corrector.component.css"]
})
export class CorrectorComponent {

  private taskId: number;
  private examId: number;
  task: ITask;
  private examHttpApi: ExamHttpApi;
  private prefixUrl: string;
  private token: string;
  isUserAdmin: boolean;

  constructor(private http: HttpClient,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private authService: AuthService) {

    this.isUserAdmin = this.authService.$userStatus === "admin";

    this.examHttpApi = new ExamHttpApi(http,  "data");
    this.examHttpApi.setSelectedTaskUrl();
    this.token = window.localStorage.getItem("itolc_token");
    this.prefixUrl = environment.apiPrefixUrl;

    this.activatedRoute.params.subscribe(({id}) => {
      this.taskId = id.split("-")[0];
      this.examId = id.split("-")[1];
      this.loadTask();
    });
  }

  // Get task by task ID and candidate exam ID
  private loadTask() {
    this.examHttpApi.setTaskById(this.taskId, this.examId).subscribe((results) => {
      this.task = results;

      this.task["task_data"] = (typeof this.task["task_data"] === "string") ?
        JSON.parse(this.task["task_data"]) : this.task["task_data"];
    });
  }
}
