<h3 i18n>Csoportosított feleletválasztás / Alternatív választás</h3>
<form ngNativeValidate [formGroup]="multipleChoiceGroups" (submit)="onSubmit($event)">
  <app-registry-sheet [form-group]="mainForm"></app-registry-sheet>
  <div class="row">
    <div class="col-xs-12"><label i18n="@@task-questions-and-groups">Feladat kérdései és kérdéscsoportjai</label></div>
  </div>
  <div class="col-xs-12 group" *ngFor="let groupItem of itemFormArrays.controls; let j=index">
    <label i18n>Csoport neve:</label><span i18n>angol-a2 tesztfeleletválasztás</span>
    <input type="text" class="form-control"
           [formControl]="groupItem.controls.multipleGroupName">
    <div class="row">
      <div class="col-xs-12 form-group" *ngIf="hasUploadField">
        <label i18n>Válassza ki a feladathoz szükséges forrásanyagot(videó, kép):
          <input type="file" class="form-control"
                 (change)="fileUploadService.onChangeInput($event, 'file', 'file_url', j);">
        </label>
        <div class="video-loader"
             *ngIf="!groupItem.controls.file_url.value && fileUploadService.isVideoLoading === 'file_url'">
          <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
        </div>
        <div *ngIf="groupItem.controls.file_url.value !== null">
          <strong>{{groupItem.controls.file_url.value | filenameConverter}}</strong>
          <button *ngIf="taskData?.file_url != null" class="btn btn-danger" (click)="removeTaskFile('file_url')" i18n>Fájl
            törlése
          </button>
          <a class="btn btn-danger" target="_blank"
             href="{{prefixUrl}}{{groupItem.controls.file_url.value}}?token={{token}}" i18n>Előnézet</a>
         <video-converter [filename]="groupItem.controls.file_url.value" (newVideoEvent)="handleMediaUpload($event, 'file_url', j);"></video-converter>
        </div>
      </div>
      <div class="col-xs-12 form-group" *ngIf="hasUploadField">
        <label i18n>Válassza ki a feladathoz szükséges kiegészítő tartalmat(képet):
          <input type="file" class="form-control"
                 (change)="fileUploadService.onChangeInput($event, 'file', 'enlarge_file_url', j)">
        </label>
        <div class="video-loader"
             *ngIf="!groupItem.controls.enlarge_file_url.value && fileUploadService.isVideoLoading === 'enlarge_file_url'">
          <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
        </div>
        <div *ngIf="groupItem.controls.enlarge_file_url.value !== null">
          <strong>{{groupItem.controls.enlarge_file_url.value | filenameConverter}}</strong>
          <button *ngIf="groupItem.controls.enlarge_file_url.value != null" class="btn btn-danger"
                  (click)="removeTaskFile('enlarge_file_url')" i18n>Fájl törlése
          </button>
          <a class="btn btn-danger" target="_blank"
             href="{{prefixUrl}}{{groupItem.controls.enlarge_file_url.value}}?token={{token}}" i18n>Előnézet</a>
        </div>
      </div>
      <div class="col-xs-12 form-group">
        <label>
          <input [checked]="twoExampleItems.value" type="checkbox" (change)="twoExampleItemsChecked($event)" i18n><span i18n>Két mintamegoldás</span>
        </label>
      </div>
      <div class="col-xs-12"><label i18n="@@copy-source-text-here">Másolja be a feladathoz tartozó forrásszöveget ide:</label></div>
      <div class="col-xs-12 form-group">
        <app-tinymce class="app-tinymce" [formControl]="groupItem.controls.textInput"
                     [required]="sourceRequired"></app-tinymce>
      </div>
    </div>
    <section *ngFor="let itemFormGroup of groupItem.controls.questions.controls; let i=index">
      <div class="col-xs-12 form-group question-answer-group">
        <div class="row">
          <div class="col-xs-12">
            <label i18n="@@question">Kérdés:</label>
            <input type="text" class="form-control"
                   [formControl]="itemFormGroup.controls.multipleQuestion" [required]="true">
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12">
            <strong i18n>Válaszok:</strong>
          </div>
        </div>
        <ul class="col-xs-12 form-group answer">
          <li class="row form-group" *ngFor="let answerItem of itemFormGroup.controls.answers.controls; let k=index"
              [formGroup]="answerItem">
            <div class="col-xs-12 col-sm-5 col-md-4 form-group">
              <label>
                <input type="checkbox" formControlName="multipleAnswerRadio">
                <input type="text" [formControl]="answerItem.controls.multipleAnswerText" [required]="true">
              </label>
            </div>
            <div class="col-xs-12 col-sm-7 col-md-8 form-group">
              <button type="button" class="btn btn-danger"
                      (click)="removedAnswerItem(k, itemFormGroup)" i18n>Válasz eltávolítása
              </button>
            </div>
          </li>
        </ul>
        <div class="col-xs-6 form-group button">
          <button type="button" class="btn btn-primary"
                  (click)="onClickNewAnswerOptionBtn($event, i, itemFormGroup)" i18n>Új válasz hozzáadása
          </button>
        </div>
        <div class="col-xs-6 form-group button">
          <button type="button" class="btn btn-danger"
                  (click)="removedQuestionItem(j,i)" i18n>Kérdés eltávolítása
          </button>
        </div>
      </div>
    </section>
    <div class="col-xs-6 form-group button">
      <button type="button" class="btn btn-primary"
              (click)="onClickNewOptionBtn($event, j)" i18n>Új kérdés hozzáadása
      </button>
      <div class="alert alert-danger" *ngIf="isShowError" i18n>Elérted a maximális
        feladatszámot.({{itemFormArrays.length}})
      </div>
    </div>
    <div class="col-xs-6 form-group button">
      <button type="button" class="btn btn-danger"
              (click)="removedGroupItem(j)" i18n>Csoport eltávolítása
      </button>
    </div>
  </div>
  <div class="col-xs-12 form-group button">
    <button type="button" class="btn btn-primary"
            (click)="onClickNewGroupOptionBtn($event)" i18n>Új csoport hozzáadása
    </button>
  </div>

    <ng-container [ngSwitch]="isDuplicate">
        <ng-container *ngSwitchCase="false">
            <button type="submit" class="btn btn-primary" [disabled]="!isFormValid || !isInputFieldRequired" i18n>Mentés</button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <button type="submit" class="btn btn-warning" [disabled]="!isFormValid || !isInputFieldRequired" i18n>Duplikálás mentése</button>
        </ng-container>
    </ng-container>

    <button type="button" class="btn btn-success" (click)="onPreview()" i18n>Előnézet</button>
  <div class="alert alert-success" *ngIf="this.examCreatorFormService.submittedResult != null && this.taskId != null">
      {{this.examCreatorFormService.submittedResult}}
  </div>
</form>
