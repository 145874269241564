<div class="col-xs-12 form-group align-right">
    <!-- Toggle button -->
    <button class="btn btn-warning m-3" type="button" (click)="toggleView()">
        Kulcsok menedzselése
    </button>

    <!--  Key management view  -->
    <div class="row" *ngIf="opened">

        <div *ngFor="let itemId of itemIds; let i = index" class="list">

                <!-- Item index indicator for multiple items -->
                <h2 *ngIf="itemIds.length > 1">{{ i+1 }}. item</h2>

                <!-- Input field for adding new key -->
                <div class="row align-items-start input-row">
                    <div class="col">
                        <input type="text" class="form-control" [(ngModel)]="inputFields[itemId]" placeholder="Adjon meg egy kulcsot">
                    </div>

                    <div class="col">
                        <button
                            class="btn btn-warning ml-2"
                            type="button"
                            [disabled]="!inputFields[itemId]"
                            (click)="addNewKeyToItem(itemId)"
                        >
                            Hozzáadás megoldásként
                        </button>
                    </div>
                </div>

                <mat-chip-list class="mat-chip-list-stacked" aria-label="Leadott válaszok">

                    <!-- Existing keys -->
                    <mat-chip
                        *ngFor="let key of data[itemId].keys.split('|').includes('') ? [] : data[itemId].keys.split('|')"
                        class="instruction"
                    >
                        {{ key }}
                        <mat-icon
                            (click)="removeKeyFromItem(itemId, key)"
                        >
                            dangerous
                        </mat-icon>
                    </mat-chip>

                    <!-- Available answers -->
                    <mat-chip
                        *ngFor="let answer of data[itemId].answers"
                        class="answer"
                    >
                        {{answer}}
                        <div>
                            <mat-icon
                                (click)="addNewAnswerToItem(itemId, answer)"
                            >
                                done
                            </mat-icon>

                            <mat-icon
                                (click)="removeAnswerFromItem(itemId, answer)"
                            >
                                dangerous
                            </mat-icon>
                        </div>
                    </mat-chip>

                    <!-- Removed answers -->
                    <mat-chip
                        *ngFor="let removedAnswer of data[itemId].removed"
                        class="removed"
                    >
                        <span>{{ removedAnswer }}</span>
                        <mat-icon
                            (click)="addRemovedAnswerToItem(itemId, removedAnswer)"
                        >
                            done
                        </mat-icon>
                    </mat-chip>

                </mat-chip-list>
        </div>

        <!-- Save dialog -->
        <ng-template #saveDialog>
            <h2 matDialogTitle>Megerősítés</h2>
            <mat-dialog-content>
                <p>A módosítások mentésével frissülni fog a kulcs, és a válaszok újra ki lesznek értékelve (ez eltarthat egy ideig). Biztos hogy ezt szeretnéd?</p>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <button mat-button matDialogClose color="primary">Mégse</button>
                <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Mentés</button>
            </mat-dialog-actions>
        </ng-template>

        <!-- Save button -->
        <button
            *ngIf="opened"
            class="btn btn-primary pull-right"
            type="button"
            [attr.disabled]="loading ? true : null"
            (click)="openSaveDialog()"
        >
            Kulcsok mentése
        </button>

    </div>
</div>
