// The list of which env maps to which file can be found in `.angular-cli.json`.

// Note: Environment.prod.ts will override environment.ts variables, but keep the ones that is not defined.
export const environment = {
    production: false,
    online_env_test: true,
    apiPrefixUrl: "https://api.onlineoktatas.itolc.hu/", // dev environment
    apiPrefixUrlCandidate: "https://onlineoktatas.itolc.hu/",
    websocket: null,
    examType: 'itolc',
    title: 'iTolc Admin',
    live_application: false,
    examListColumns: {
        id: true,
        study_field_id: false,
        level_name: false, // mmk category
        language: true,
        level: true,
        place: true,
        date: true,
        anchoring: false,
        structure: false,
        true_false_percent: false,
        multiple_choice_percent: false,
        writing_percent: false,
        status: true,
        type: true,
        comment: true,
        ready: true,
        deployed_at: false,
        created_at: true,
        updated_at: true
    },
    taskFormColumns: {
        id: true,
        exam_scheme: false, // mmk category
        section: false, // mmk section
        exam_part: true, // choose part
        format: true, // task format => subtype
        question_search : true, // Task list: Searches in the task text.
        user_id : true,
        user_name : true, // creator
        filename : true,
        level : true,
        language : true,
        part : true,
        number : true,
        name  : true,
        source  : true,
        view_date  : true,
        source_type  : true,
        word_count  : true,
        item_count  : true,
        topic  : true,
        text_breed  : true,
        instruction  : true,
        task_data  : true,
        format_code_id  : true,
        text_source_id  : true,
        comment  : true,
        archived_at  : true,
        created_at  : true,
        updated_at  : true,
        last_used_day: true
    },
    taskColumns: {
        id: true,
        exam_scheme: false, // mmk category
        section: false, // mmk section
        exam_part: false, // choose part
        format: true, // task format => subtype
        question_search : true, // Task list: Searches in the task text.
        user_id : false,
        user_name : true, // creator
        filename : true,
        level : true,
        language : true,
        part : true,
        number : true,
        name  : true,
        source  : false,
        view_date  : false,
        source_type  : false,
        word_count  : false,
        item_count  : true,
        topic  : true,
        text_breed  : false,
        instruction  : false,
        task_data  : false,
        format_code_id  : false,
        text_source_id  : false,
        comment  : false,
        archived_at  : false,
        created_at  : false,
        updated_at  : true,
        last_used_day: false
    },
    menu: {
        candidateManagement: false,
        candidateList: false,
        MMKExamAnswers: false,
        identityCheck: true,
    }
};
