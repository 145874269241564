import { Directive, ElementRef, Renderer2, Input } from "@angular/core";

@Directive({ selector: "[option-checked]" })
export class OptionCheckedDirective {

    @Input("taskId") taskId: number;
    @Input("currentTask") currentTask: any;

    constructor(private el: ElementRef,
                private renderer: Renderer2) { 
    }

    ngOnChanges() {
        if (this.taskId && this.currentTask) {
            if (this.taskId === this.currentTask.task_id && this.el.nativeElement.selected !== true) {
                this.el.nativeElement.selected = true;
            }
        }
    }
}