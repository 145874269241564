import {Component, OnInit} from "@angular/core";
import {LANGUAGES, LEVELS} from "../../form/app.form";
import {FormBuilder, FormGroup, FormControl, FormArray, Validators} from "@angular/forms";
import {TaskHttpApi} from "../../../http_apis/task.http";
import {ITaskGroup} from "../../../interfaces/task_groups.interface";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {Router, ActivatedRoute} from "@angular/router";
import {IExam, ICurrentExamTask} from "../../../interfaces/exam.interface";
import {WritingSelectionService} from "../../../services/writing-selection.service";
import {ExamCreatorFormService} from "../../../services/shared/exam_creator_form.service";
import {isNullOrUndefined} from "util";
import {environment} from "../../../../environments/environment";
import {RestConfig} from "../../../http_apis/rest_config.http";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../../../services/environment.service";

@Component({
    moduleId: module.id,
    host: {
        id: "create-exam",
    },
    selector: "create-web-exam",
    templateUrl: "create-mmk-exam.component.html",
    providers: [WritingSelectionService],
    styleUrls: ["create-mmk-exam.css"]
})

export class CreateMmkExamComponent implements OnInit {

    tasks: any;
    studyFields: any;
    subtypes: Array<any> = [];
    sections: Array<number> = [];
    createExamForm: FormGroup;
    disableButtons: boolean;
    isDatePickerVisible: boolean = false;
    examId: number;
    hourValue: number = 8;
    minuteValue: number = 0;
    submitButtonText: string;
    pageTitle: string;
    examData: any;
    studyFieldControl = new FormControl();
    subtypesControl = new FormControl();
    private questionTypeTrueFalseControl = new FormControl();
    private questionTypeMultipleChoiceControl = new FormControl();
    private questionTypeWritingControl = new FormControl();
    private sumQuestionControl = new FormControl();
    private taskHttpApi: TaskHttpApi;
    private examHttpApi: ExamHttpApi;
    private dateTimePickerOptions;
    filterControl: FormControl = new FormControl(false);
    dateValue: string;
    loader: boolean = false;
    returnMsg: boolean = false;
    private checkExamTasksMsg: Array<any> = [];
    taskCheckNumbers: Array<any> = [];

    constructor(private formBuilder: FormBuilder,
                private http: HttpClient,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private writingSelectionService: WritingSelectionService,
                public environmentService: EnvironmentService,
                private examCreatorFormService: ExamCreatorFormService) {

        this.taskHttpApi = new TaskHttpApi(http, "data");
        this.taskHttpApi.setDefaultUrl();

        this.examHttpApi = new ExamHttpApi(http, "data");
        this.examHttpApi.setDefaultUrl();

        this.dateTimePickerOptions = {
            format: "YYYY-MM-DD HH:mm:ss"
        };

        this.activatedRoute.params.subscribe(({id}) => {
            this.examId = id;
        });

        this.taskHttpApi.getGroupBySectionTasks().subscribe((results) => {
            this.tasks = results;
        });

        if(this.examId) {
            this.examHttpApi.getExam(this.examId).subscribe((results) => {
                this.examData = results;
            });
        }

        this.taskHttpApi.getStudyFields().subscribe((results) => {
            this.studyFields = results;

            if(this.examId){
                // Async
                setTimeout(() => {

                    this.createExamForm.controls['date'].setValue(this.examData['date']);
                    let examTime = `${this.examData['date']}`;

                    this.createExamForm.patchValue({
                        date: examTime
                    });

                    this.createExamForm.controls['studyField'].setValue(this.examData['study_field_id']);
                    this.createExamForm.controls['subtypes'].setValue(this.examData['level']);

                    this.createExamForm.controls['questionTypeTrueFalse'].setValue(this.examData['true_false_percent']);
                    this.createExamForm.controls['questionTypeMultipleChoice'].setValue(this.examData['multiple_choice_percent']);
                    this.createExamForm.controls['questionTypeWriting'].setValue(this.examData['writing_percent']);

                    const structureJson = JSON.parse(this.examData['structure']);
                    let newStructure = [];

                    structureJson.forEach((item)=>{
                        if (this.environmentService.get('sections_as_percent')) {
                            newStructure.push(new FormGroup({
                                section: new FormControl(item.section),
                                section2: new FormControl(item.section2),
                                percent: new FormControl(item.percent),
                            }));
                        } else {
                            newStructure.push(new FormGroup({
                                section: new FormControl(item.section),
                                section2: new FormControl(item.section2),
                                count: new FormControl(item.count),
                            }));
                        }
                    });

                    this.createExamForm.setControl('structure', this.formBuilder.array([]));
                    this.createExamForm.setControl('structure', this.formBuilder.array(newStructure));

                    this.onCheckExamTasks();

                }, 800);    // @TODO: Move this whole block to oninit, and build a solution to avoid fix timeouts...
            } else {
                this.createExamForm.controls['questionTypeTrueFalse'].setValue(0);
                this.createExamForm.controls['questionTypeMultipleChoice'].setValue(0);
                this.createExamForm.controls['questionTypeWriting'].setValue(0);
            }
        });

        this.activatedRoute.data.subscribe(({title, saveButtonText}) => {
            this.submitButtonText = saveButtonText;
            this.pageTitle = title;
        });
    }

    ngOnInit() {
        this.createExamForm = this.formBuilder.group({
            studyField: this.studyFieldControl,
            subtypes: this.subtypesControl,
            date: new FormControl(null, Validators.required),
            anchoring: this.filterControl,
            structure: this.formBuilder.array([]),
            questionTypeTrueFalse: null,
            questionTypeMultipleChoice: null,
            questionTypeWriting: null,
            sumQuestion: null
        });

        this.disableButtons = true;
        this.isDatePickerVisible = false;
        this.dateChange(new Date());

        this.studyFieldControl.valueChanges.subscribe(() => {
            this.changeStudyFields();
        });

        this.subtypesControl.valueChanges.subscribe(() => {
            this.changeSubtype();
        });
    }

    private changeStudyFields() {
        let studyFieldsValue = parseInt(this.studyFieldControl.value);

        let currentField = this.studyFields.filter((item) => item.id === studyFieldsValue);
        this.subtypes = currentField[0].subtype;
    }

    private changeSubtype() {
        let subtypeId = parseInt(this.subtypesControl.value);
        let studyFieldId = parseInt(this.studyFieldControl.value);

        let currentType = this.subtypes.filter((item) => item.id === subtypeId);
        let max = currentType[0].section;
        let sum = currentType[0].sum_question;

        let newStructure = [];
        this.sections = [];

        for (let i = 1; i <= max; i++) {
            this.sections.push(i);

            if (this.environmentService.get('sections_as_percent')) {
                newStructure.push(new FormGroup({
                    section: new FormControl(null),
                    percent: new FormControl(null),
                    section2: new FormControl(null),
                }));
            } else {
                newStructure.push(new FormGroup({
                    section: new FormControl(null),
                    count: new FormControl(null),
                    section2: new FormControl(null),
                }));
            }
        }

        this.createExamForm.setControl('structure', this.formBuilder.array([]));
        this.createExamForm.setControl('structure', this.formBuilder.array(newStructure));

        this.createExamForm.controls['sumQuestion'].setValue(sum);
        this.disableButtons = false;

        this.onSubtypesChangeCheck(studyFieldId,subtypeId);
    }

    onSubmit(event: Event, {value, valid}) {
        event.preventDefault();
        this.disableButtons = true;
        this.loader = true;
        this.returnMsg = false;


        if (!this.examId) {
            this.examHttpApi.insert(value, true).subscribe((result) => {
                let url = environment.examType === 'mmk' ? `edit-mmk-exam` : 'edit-exam';
                this.router.navigateByUrl(`/${url}/${result.exam}`);
                console.log("OK-", result);
                this.disableButtons = false;
                alert('Sikeres mentés!');
            });
        } else {
            this.examHttpApi.updateExam(value, this.examId).subscribe(() => {
                console.log("update");
                this.disableButtons = false;
                this.loader = false;
                this.returnMsg = true;

                this.checkExamTasksMsg['success'] = true;
                this.checkExamTasksMsg['msg'] = 'Mentés sikeres!';
            });
        }
    }

    dateChange(event: Date) {
        event = new Date(event.getTime() + 24 * 60 * 60 * 1000);
        this.dateValue = event.toISOString().split("T")[0];
        this.dateUpdate();
    }

    onChangeTimePicker(event: Event, type: string) {
        const inputEl = <HTMLInputElement> event.target;

        if (type === "hour") {
            this.hourValue = parseInt(inputEl.value);
        } else {
            this.minuteValue = parseInt(inputEl.value);
        }

        this.dateUpdate();
    }

    private dateUpdate() {
        const hourString = (this.hourValue < 10) ? `0${this.hourValue}` : this.hourValue;
        const minuteString = (this.minuteValue < 10) ? `0${this.minuteValue}` : this.minuteValue;
        const dateTimeStringValue = `${this.dateValue} ${hourString}:${minuteString}:00`;

        console.log('dateTimeStringValue', dateTimeStringValue);
        this.createExamForm.patchValue({
            date: dateTimeStringValue
        });
    }

    onCloseClickBtn() {
        this.isDatePickerVisible = false;
    }

    onFocusInput(event: Event) {
        const inputEl = <HTMLInputElement> event.target;
        this.isDatePickerVisible = inputEl.id === "date";
    }

    onCheckExamTasks(){
        let studyField = this.createExamForm.controls['studyField'].value;
        let subtypes = this.createExamForm.controls['subtypes'].value;
        let questionTypeTrueFalse = this.createExamForm.controls['questionTypeTrueFalse'].value;
        let questionTypeMultipleChoice = this.createExamForm.controls['questionTypeMultipleChoice'].value;
        let questionTypeWriting = this.createExamForm.controls['questionTypeWriting'].value;
        let structure = this.createExamForm.controls['structure'].value;

        let data = {
            studyField: studyField,
            subtypes: subtypes,
            questionTypeTrueFalse: questionTypeTrueFalse,
            questionTypeMultipleChoice: questionTypeMultipleChoice,
            questionTypeWriting: questionTypeWriting,
            structure: structure,
            sumQuestion: this.createExamForm.controls['sumQuestion'].value,
        };

        this.loader = true;
        this.examHttpApi.checkExamBeCreated(data).subscribe((msg) => {
            this.loader = false;

            this.returnMsg = true;
            this.checkExamTasksMsg['success'] = msg.success;
            this.checkExamTasksMsg['msg'] = msg.msg;
        });
    }

    onSubtypesChangeCheck(studyFieldId: number,subtypeId: number){
        this.loader = true;
        this.examHttpApi.postExamTaskPieces(studyFieldId,subtypeId).subscribe((msg) => {
            this.loader = false;
            this.taskCheckNumbers = msg;
        });
    }

    getTaskOptionsForSection(index: number) {
        const structure = <FormArray> this.createExamForm.get("structure");
        console.log('structure:', structure);

        let selectedAtOther = [];

        for (let i = 0; i < structure.length; i++) {
            const val = structure.at(i).get("section").value;

            if (i === index || !val) {
                continue;
            }
            selectedAtOther.push(parseInt(val));
        }

        console.log(structure.length);

        return this.tasks.slice().filter((task) => {
            return selectedAtOther.indexOf(task.sections) === -1;
        });
    }
}
