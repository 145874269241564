<section *ngIf="exam" class="container">

    <div class="row">
        <header class="analysis-sm-container">
            <ul>
                <li *ngIf="examId !== null && groupId === null">Vizsga ID: {{examId}}</li>
                <li *ngIf="groupId !== null">Csoport ID: {{groupId}}</li>
                <li *ngIf="exam.exams">
                     | Vizsgák:
                    <span *ngFor="let exam of exam.exams">{{exam}}, </span>

                </li>
                <li> | {{exam.date}}</li>
                <li>&nbsp;|&nbsp;{{exam.level}}</li>
                <li>&nbsp;|&nbsp;{{part}}</li>
                <li *ngIf="file && file.name">&nbsp;|&nbsp;{{file.name}}</li>
            </ul>
        </header>
    </div>

    <div class="row">

        <a
           class="btn btn-small btn-default"
           (click)="showNewInputPopup()" role="button" i18n>
            Új input fájl létrehozása
            <i class="fa fa-cog fa-spin fa-1x fa-fw" *ngIf="loadingNewInputFile"></i>
        </a>

        <table class="analysis-container">
            <thead>
                <tr>
                    <th>Input fájl</th>
                    <th [attr.colspan]="inputFileTableColSpan"></th>
                </tr>
            </thead>

            <tbody>
            <ng-container *ngFor="let file of files; let i = index;">
                <tr *ngIf="file">
                    <td colspan="2">{{file.name}}</td>
                    <td class="btn-cell">
                        <a class="btn btn-small btn-block btn-default"
                           role="button"
                           (click)="toggleEditorVisibility(i)">
                            <span *ngIf="!isEditorVisible[i]">Szerkesztés</span>
                            <span *ngIf="isEditorVisible[i]">Bezárás</span>
                        </a>
                    </td>
                    <td class="btn-cell">
                        <a class="btn btn-small btn-block btn-default"
                           role="button"
                           (click)="runFacet(file.id)">
                            Futtatás
                            <i class="fa fa-cog fa-spin fa-1x fa-fw" *ngIf="isLoading"></i>
                        </a>
                    </td>
                    <td class="btn-cell">
                        <a class="btn btn-small btn-block btn-default" role="button"
                           (click)="downloadFile(file.id, file.type)">
                            Letöltés
                        </a>
                    </td>
                    <td class="btn-cell" [class.border-right]="file.outputFiles.length === 0">
                        <a class="btn btn-small btn-block btn-default"
                           (click)="removeFile(file.id, i)" role="button">
                            Törlés
                        </a>
                    </td>
                    <td *ngIf="file.outputFiles.length > 0"
                        class="btn-cell">
                        <a class="btn btn-small btn-block btn-default"
                           (click)="toggleOutputFilesVisibility(i)">
                            <span *ngIf="!isOutputFilesVisible[i]">Kimeneti fájlok</span>
                            <span *ngIf="isOutputFilesVisible[i]">Fájlok elrejtése</span>
                        </a>
                    </td>
                    <td *ngIf="file.outputFiles.length > 0"
                        class="btn-cell">
                        <a class="btn btn-small btn-block btn-default"
                           (click)="toggleAnalyzerEditorVisibility(i)">
                            <span *ngIf="!isAnalyzerEditorVisible[i]">Elemzés</span>
                            <span *ngIf="isAnalyzerEditorVisible[i]">Elemzés bezárása</span>
                        </a>
                    </td>
                    <td *ngIf="file.outputFiles.length > 0"
                        class="btn-cell">
                        <a class="btn btn-small btn-block btn-default"
                           (click)="openResultsOnNewPage()">
                            <span >Eredmények megtekintése</span>
                        </a>
                    </td>
                </tr>

                <tr *ngIf="isOutputFilesVisible[i]">
                    <td [attr.colspan]="outputFileTableColSpan">
                        <table class="output-files-table">
                            <thead>
                            <tr>
                                <th>Output fájlok</th>
                                <th [attr.colspan]="outputFileNameColSpan"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let outputFile of file.outputFiles;">
                                <td [attr.colspan]="innerOutputFileNameColSpan">{{outputFile.name}}</td>
                                <td class="btn-cell">
                                    <a class="btn btn-small btn-block btn-default" role="button"
                                       (click)="downloadFile(outputFile.id, outputFile.type)">
                                        Letöltés
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <ng-container *ngIf="isEditorVisible[i] || isAnalyzerEditorVisible[i]">
                    <tr>
                        <td [attr.colspan]="textAreaColSpanSize">
                            <label>Új input fájlnév:
                                <input type="text" (input)="modifyInputFileName($event, i)" [(ngModel)]="inputFileNames[i]">
                            </label>
                            <label>
                                <textarea [(ngModel)]="file.data">
                                </textarea>
                            </label>
                        </td>
                        <td *ngIf="file.outputFiles.length > 0 && isAnalyzerEditorVisible[i]"
                            colspan="4">
                            <label>
                                <select (change)="selectOutputFile($event, i)">
                                    <option *ngFor="let outputFile of file.outputFiles; let j = index;"
                                            [value]="j">
                                        {{outputFile.name}}
                                    </option>
                                </select>
                            </label>
                            <label>
                                <textarea readonly>{{outputFileData[i]}}</textarea>
                            </label>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td [attr.colspan]="btnBeforeColSpanSize"></td>
                        <td class="btn-cell"
                            [class.border-right]="file.outputFiles.length === 0">
                            <button *ngIf="isEditorVisible[i] || isAnalyzerEditorVisible[i]"
                                    class="btn btn-small btn-block btn-default"
                                    (click)="saveEditedInputFile(file.id, file.data, i)">
                                <span>Meglévő fájl frissítése</span>
                            </button>
                            <button *ngIf="isEditorVisible[i] || isAnalyzerEditorVisible[i]"
                                    class="btn btn-small btn-block btn-default"
                                    (click)="saveAsNewInputFile(file.data, inputFileNames[i])">
                                <span>Módosítások mentése új fájlként</span>
                            </button>
                        </td>
                    </tr>
                </ng-container>

                <tr *ngIf="!file || files.length === 0">
                    <td colspan="8">
                        <i>Ehhez a vizsgarészhez nem tartoznak input fájlok</i>
                    </td>
                </tr>
            </ng-container>

            <ng-container>
                <tr *ngIf="files.length === 0">
                    <td colspan="8">
                        <i>Ehhez a vizsgarészhez nem tartoznak input fájlok</i>
                    </td>
                </tr>
            </ng-container>
            </tbody>
        </table>
    </div>

    <ng-template #newInputDialog>
          <h2 matDialogTitle>Bemeneti fájl paraméterezése</h2>
          <mat-dialog-content>
              <p>Új bemeneti fájl (*.sd) létrehozása az alábbi paraméterekkel:</p>
                <mat-form-field>
                  <mat-label>Fájlnév</mat-label>
                  <input matInput placeholder="Az SD fájl neve" value="" [(ngModel)]="newInputParams.filename">
                </mat-form-field>
                <hr>
                <h2>Facet fejlécek:</h2>
                <mat-form-field *ngFor="let heading of newInputParams.facet_headings; let hIndex = index">
                  <mat-label>{{hIndex+1}}. fejléc</mat-label>
                  <input matInput placeholder="Facets fejléc" value="" [ngModel]="newInputParams.facet_headings[hIndex]" (change)="newInputParams.facet_headings[hIndex] = $event.target.value">
                </mat-form-field>
                <hr>
                <h2>Facet adatblokkok:</h2>
                <div *ngFor="let block of newInputParams.block_values | keyvalue" [class.half-width]="block.key === 'Items, G' || block.key === 'Formatum, G'" class="facet-block">
                    <h3>{{block.key}}. facet</h3>
                    <div *ngIf="(block.key !== 'Items, G')">
                        <mat-form-field *ngFor="let data of block.value; let dataIndex = index" [class.full-width]="block.key === 'Formatum, G'">
                          <mat-label>{{dataIndex+1}}. sor</mat-label>
                          <input matInput placeholder="Facet adat" value="" [ngModel]="newInputParams.block_values[block.key][dataIndex]" (change)="newInputParams.block_values[block.key][dataIndex] = $event.target.value">
                        </mat-form-field>
                    </div>
                    <div *ngIf="(block.key === 'Items, G')">
                        <mat-form-field *ngFor="let data of block.value | keyvalue" class="full-width">
                          <mat-label>{{data.key}} feladathoz tartozó itemek</mat-label>
                          <input matInput placeholder="Facet adat" value="" [ngModel]="newInputParams.block_values[block.key][data.key]" (change)="newInputParams.block_values[block.key][data.key] = $event.target.value">
                        </mat-form-field>
                    </div>
                </div>
          </mat-dialog-content>
          <mat-dialog-actions align="end">
            <button mat-button matDialogClose color="primary">Mégse</button>
            <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Generálás</button>
          </mat-dialog-actions>
    </ng-template>

</section>
