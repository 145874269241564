<h3 i18n>Weboldal form/Formanyomtatvány</h3>
<form novalidate [formGroup]="formCreator" (submit)="onSubmit($event)">
    <app-registry-sheet [form-group]="mainForm"></app-registry-sheet>
    <div class="row">
        <div class="col-xs-12 form-group" id="type-group">
            <strong i18n>Típus</strong>
            <label *ngFor="let type of typeRadios; let i = index">
                <input type="radio" [value]="i" formControlName="type"/>{{type}}
            </label>
        </div>
    </div>
    <section *ngIf="typeValue === 0" id="web-form">
        <div class="col-xs-12 form-group">
            <label i18n>Logo:
                <input type="file" class="form-control"
                       (change)="fileUploadService.onChangeInput($event, 'file', 'logo')">
            </label>
            <div *ngIf="taskData && taskData.logo_file_url != '' && taskData.logo_file_url != null">
                <strong>{{taskData.logo_file_url | filenameConverter}}</strong>
                <button class="btn btn-danger" (click)="removeTaskFile('logo')" i18n>Fájl törlése</button>
            </div>
        </div>
        <div class="col-xs-12 form-group">
            <label i18n>Weboldal címe:
                <input type="text" class="form-control"
                       formControlName="site_title">
            </label>
        </div>
        <div class="col-xs-12 form-group">
            <strong i18n>Háttér színek:</strong>
            <input type="color" formControlName="background_color"/>
        </div>
        <div class="col-xs-12 form-group">
            <strong i18n>Szöveg színek:</strong>
            <input type="color" formControlName="color"/>
        </div>
        <div class="col-xs-12 form-group">
            <label i18n>Tartalom képe:
                <input type="file" class="form-control"
                       (change)="fileUploadService.onChangeInput($event, 'file', 'content')">
            </label>
            <div *ngIf="taskData && taskData.content_file_url != '' && taskData.content_file_url != null">
                <strong>{{taskData.content_file_url | filenameConverter}}</strong>
                <button class="btn btn-danger" (click)="removeTaskFile('content')" i18n>Fájl törlése</button>
            </div>
        </div>
        <div class="col-xs-12 form-group">
            <label i18n>Űrlap neve:
                <input type="text" class="form-control"
                       formControlName="form_title">
            </label>
        </div>
        <div class="item-container">
            <strong i18n>Form elemei:</strong>
            <div class="with-preview">
                <div class="creator-item-container">
                    <form-creator-item class="form-creator-item"
                                       *ngFor="let group of itemFormArrays.controls; let i = index"
                                       [form-group]="group"
                                       [groupIndex]="i"
                                       (onGeneratePreview)="generatePreview($event)"
                                       (onRemoveItem)="removedItem(i)"></form-creator-item>
                </div>
                <div class="preview-container"
                     [class.show]="generatedPreviews !== undefined">
                    <strong i18n>Előnézet</strong>
                    <ul>
                        <li *ngFor="let previewItem of generatedPreviews">
                            <label class="form-group" *ngIf="previewItem.single && previewItem.label !== null">
                                {{previewItem.label}}
                                <input type="text"/>
                            </label>
                            <label class="form-group" *ngIf="previewItem.content && previewItem.label !== null">
                                {{previewItem.label}}
                                <textarea></textarea>
                            </label>
                            <label class="form-group" *ngIf="previewItem.dropdown && previewItem.label !== null">
                                {{previewItem.label}}
                                <select>
                                    <option *ngFor="let option of previewItem.dropdown">{{option}}</option>
                                </select>
                            </label>
                            <div class="form-group" *ngIf="previewItem.radios && previewItem.label !== null">
                                {{previewItem.label}}
                                <label *ngFor="let radio of previewItem.radios">
                                    {{radio}}
                                    <input type="radio"/>
                                </label>
                            </div>
                            <div class="form-group" *ngIf="previewItem.checkbox && previewItem.label !== null">
                                {{previewItem.label}}
                                <label *ngFor="let checkbox of previewItem.checkbox">
                                    <input type="checkbox"/>{{checkbox}}
                                </label>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <button type="button" class="btn btn-large btn-block btn-default"
                    (click)="onClickNewOptionBtn($event)" i18n>Új opció hozzáadása
            </button>
        </div>
    </section>
    <section *ngIf="typeValue === 1">
        <div class="col-xs-12 form-group">
            <label i18n>Cég neve:
                <input type="text" class="form-control"
                       formControlName="company_title">
            </label>
        </div>
        <div class="col-xs-12 form-group">
            <label i18n>Logo:
                <input type="file" class="form-control"
                       (change)="fileUploadService.onChangeInput($event, 'file', 'logo')">
            </label>
            <div *ngIf="taskData && taskData.logo_file_url != '' && taskData.logo_file_url != null">
                <strong>{{taskData.logo_file_url | filenameConverter}}</strong>
                <button class="btn btn-danger" (click)="removeTaskFile('logo')" i18n>Kép törlése</button>
            </div>
        </div>
        <div class="col-xs-12"><label i18n>Elérhetőségek:</label></div>
        <div class="col-xs-12 form-group">
            <app-tinymce class="app-tinymce" [formControl]="formCreator.controls.address"></app-tinymce>
        </div>
        <div class="col-xs-12 form-group">
            <label i18n>Űrlap neve:
                <input type="text" class="form-control"
                       formControlName="form_title">
            </label>
        </div>
        <div class="col-xs-12"><label i18n>Űrlap leírás:</label></div>
        <div class="col-xs-12 form-group">
            <app-tinymce class="app-tinymce" [formControl]="formCreator.controls.form_description"></app-tinymce>
        </div>
        <div class="item-container col-xs-12" id="normal-form">
            <strong i18n>Form elemei:</strong>
            <div class="with-preview">
                <div class="section-container">
                    <section class="creator-item-container"
                             *ngFor="let sectionGroup of itemFormArrays.controls; let i = index;"
                             [formGroup]="sectionGroup">
                        <div class="form-group">
                            <label i18n>Szekció neve:
                                <input type="text" class="form-control"
                                       formControlName="section_name">
                            </label>
                        </div>
                        <form-creator-item class="form-creator-item"
                                           *ngFor="let group of sectionItems[i].controls; let y = index"
                                           [form-group]="group"
                                           [groupIndex]="y"
                                           (onGeneratePreview)="generatePreview($event, i)"
                                           (onRemoveItem)="removedItem(i, y)">
                        </form-creator-item>
                        <button type="button" class="btn btn-large btn-default"
                                (click)="onClickNewFieldBtn($event, sectionGroup, i)" i18n>Új mező hozzáadása
                        </button>
                        <button type="button" class="btn btn-large btn-danger floatRight"
                                (click)="onClickDeleteOptionBtn(i,sectionGroup)" i18n>Szekció eltávolítása
                        </button>
                    </section>
                </div>
                <div class="preview-container"
                     [class.show]="generatedPreviews !== undefined">
                    <strong i18n>Előnézet</strong>
                    <ul>
                        <li *ngFor="let sectionItem of generatedPreviews">
                            <strong>{{sectionItem.section}}</strong>
                            <ul>
                                <li *ngFor="let previewItem of sectionItem.previews">
                                    <label class="form-group"
                                           *ngIf="!previewItem.content && !previewItem.dropdown && !previewItem.radios && previewItem.label !== null">
                                        {{previewItem.label}}
                                        <input type="text"/>
                                    </label>
                                    <label class="form-group" *ngIf="previewItem.content && previewItem.label !== null">
                                        {{previewItem.label}}
                                        <textarea></textarea>
                                    </label>
                                    <label class="form-group"
                                           *ngIf="previewItem.dropdown && previewItem.label !== null">
                                        {{previewItem.label}}
                                        <select>
                                            <option *ngFor="let option of previewItem.dropdown">{{option}}</option>
                                        </select>
                                    </label>
                                    <div class="form-group" *ngIf="previewItem.radios && previewItem.label !== null">
                                        {{previewItem.label}}
                                        <label *ngFor="let radio of previewItem.radios">
                                            {{radio}}
                                            <input type="radio"/>
                                        </label>
                                    </div>
                                    <div class="form-group" *ngIf="previewItem.checkbox && previewItem.label !== null">
                                        {{previewItem.label}}
                                        <label *ngFor="let checkbox of previewItem.checkbox">
                                            <input type="checkbox"/>{{checkbox}}
                                        </label>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <button type="button" class="btn btn-large btn-block btn-default"
                    (click)="onClickNewOptionBtn($event)" i18n>Új szekció hozzáadása
            </button>
        </div>
    </section>
    <button type="submit" class="btn btn-primary" [disabled]="!isFormValid || !isRegistryFieldRequired" i18n>Mentés</button>
    <button type="button" class="btn btn-success" (click)="onPreview()" i18n>Előnézet</button>
    <div class="alert alert-success" *ngIf="this.examCreatorFormService.submittedResult != null && this.taskId != null">
        {{this.examCreatorFormService.submittedResult}}
    </div>
</form>
