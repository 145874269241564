
import {delay} from 'rxjs/operators';
import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {FileUploadService} from "../../services/shared/upload.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {isNullOrUndefined} from "util";
import {IKeyManagerInput} from "../../interfaces/key_manager.interface";
import {ApiService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";

@Component({
    moduleId: module.id,
    selector: "app-short-answer",
    templateUrl: "./short-answer.component.html",
    styleUrls: ["./short-answer.component.css"]
})
export class ShortAnswerComponent implements OnInit {

    shortAnswer: FormGroup;
    mainForm: FormGroup;
    twoExampleItems: FormControl = new FormControl();
    sourceShortAnswerControl: FormControl = new FormControl(null, Validators.required);
    itemFormArrays: FormArray = new FormArray([]);
    taskData: Object;
    taskId: number;
    isShowError: boolean;
    hasUploadField: boolean;
    isInputFieldRequired: boolean;
    isFormValid: boolean = true;
    prefixUrl: string;
    token: string;
    private sectionItems: Array<FormArray>;
    private defaultValue: number;
    isDuplicate: boolean = false;
    keyManagerInputData: IKeyManagerInput = {};
    keyManagerIsLoading: boolean = false;

    constructor(private formBuilder: FormBuilder,
                public examCreatorFormService: ExamCreatorFormService,
                private fileUploadService: FileUploadService,
                private activatedRoute: ActivatedRoute,
                private apiService: ApiService,
                private toast: ToastrService) {

        this.mainForm = this.examCreatorFormService.$examCreatorFrom;
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.shortAnswer = formBuilder.group({
            source_short_control: this.sourceShortAnswerControl,
            two_example_items: this.twoExampleItems,
        });

        this.fileUploadService.uploadedFile.subscribe(({url, type}) => {
            this.shortAnswer.patchValue({
                [type]: url
            });
        });

        this.activatedRoute.data.subscribe(({type}) => {
            this.hasUploadField = type !== "reading";

            if (!this.hasUploadField) {
                this.shortAnswer.removeControl("file_url");
                this.shortAnswer.removeControl("enlarge_file_url");
            } else {
                this.shortAnswer.setControl("file_url", new FormControl());
                this.shortAnswer.setControl("enlarge_file_url", new FormControl());
            }
        });

        this.activatedRoute.params.pipe(delay(10)).subscribe(({id, duplicate}) => {
            this.taskId = id;

            if (!isNullOrUndefined(duplicate)) {
                if (duplicate === "duplicate") {
                    this.isDuplicate = true;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                } else {
                    this.isDuplicate = false;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                }
            }

            let taskValues = this.examCreatorFormService.getTaskDataValue();

            if (taskValues !== null) {
                this.shortAnswer.patchValue(taskValues);
            }

            if (this.taskId) {
                this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {
                    this.taskData = JSON.parse(examTask.task_data);

                    examCreatorFormService.previousNumber = examTask.number;

                    this.shortAnswer.patchValue(this.taskData);
                    this.examCreatorFormService.setExamObject(examTask);
                    this.examCreatorFormService.setExistInstructionControl(examTask.instruction);

                    examTask.view_date = examTask.view_date.replace(/:[0-9]+$/, "");

                    this.mainForm.patchValue(examTask);

                    if (examTask.items) {
                        this.sectionItems = [];
                        this.setItemArraysWithExistData(examTask.items);
                        this.getKeyManagerInputData(this.itemFormArrays.value);
                    }
                });
            }

            if (!id) {
                this.examCreatorFormService.backToMainForm();
            }
        });

        this.examCreatorFormService.requiredRegistryField.subscribe((data) => {
            if (isNullOrUndefined(data)) {
                this.isInputFieldRequired = true;
            }

            this.isInputFieldRequired = data;
        });

        this.mainForm.valueChanges.subscribe(() => {
            this.isInputFieldRequired = this.mainForm.controls.instruction.value !== "" ||
                                         isNullOrUndefined(this.mainForm.controls.instruction.value);
            this.examCreatorFormService.registryFormSend.emit(this.mainForm);
        });

        this.shortAnswer.valueChanges.subscribe(() => this.onValueChanged());
    }

    removeTaskFile(type) {
        this.taskData[type] = null;
        this.shortAnswer.patchValue(this.taskData);
    }

    onClickNewOptionBtn(event: Event) {
        event.preventDefault();
        this.isShowError = false;

        this.itemFormArrays.push(new FormGroup({
            short_question: new FormControl(null, Validators.required),
            short_instruction: new FormControl(null, Validators.required),
            before_answer: new FormControl(null),
            after_answer: new FormControl(null)
        }));

        if (this.defaultValue === null) {
            this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
        }
    }

    removedItem(index: number) {
        console.log("Removed question item");

        if (this.itemFormArrays.value[index].id) {
            this.examCreatorFormService.itemHttpApi.del(this.itemFormArrays.value[index].id, true).subscribe(() => {
                console.log("Item removed!");
            });
        }

        this.itemFormArrays.removeAt(index);
        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    private setItemArraysWithExistData(items: Array<any>) {
        items.forEach((item, index) => {
            let itemData = JSON.parse(item.item_data);

            if (itemData.section_items) {
                this.sectionItems[index] = new FormArray([]);

                itemData.section_items.forEach((sectionItem, subIndex) => {
                    let sectionFormGroup = {};

                    for (let sItem in sectionItem) {
                        if (sectionItem.hasOwnProperty(sItem)) {
                        let sectionValue = sectionItem[sItem];
                        sectionFormGroup[sItem] = new FormControl(sectionValue);
                        }
                    }

                    let formGroup = new FormGroup(sectionFormGroup);

                    this.sectionItems[index].insert(subIndex, formGroup);
                });
            }
        });

        let itemArrays = items.map((item, index) => {
            let itemData = JSON.parse(item.item_data);
            itemData.id = item.id;
            let formGroupConfig = {};

            for (let dataItem in itemData) {
                if (itemData.hasOwnProperty(dataItem)) {
                    let value = itemData[dataItem];

                    if (dataItem !== "section_items") {
                        formGroupConfig[dataItem] = new FormControl(value);
                    } else {
                        formGroupConfig[dataItem] = this.sectionItems[index];
                    }
                }
            }

            return new FormGroup(formGroupConfig);
        });

        this.itemFormArrays = new FormArray(itemArrays);
    }

    twoExampleItemsChecked(event: Event) {
        let element = <HTMLInputElement> event.target;
        this.twoExampleItems.setValue(element.checked);
    }

    onSubmit(event: Event) {
        event.preventDefault();

        if (this.shortAnswer.valid) {
            this.examCreatorFormService.setTaskDataControlValue(JSON.stringify(this.shortAnswer.value));

            let items: Array<FormGroup> = this.itemFormArrays.value.map((item, index) => {
                return new FormGroup({
                    item_data: new FormControl(JSON.stringify(item)),
                    order: new FormControl(index),
                    id: new FormControl(item.id)
                });
            });

            const _formArray = new FormArray(items);

            this.mainForm.setControl("items", _formArray);

            if (this.mainForm.valid) {
                // Disable save button.
                this.isFormValid = false;

                if (this.taskId) {
                    if (this.isDuplicate) {
                        this.insertTask(_formArray, true);
                    } else {
                        this.updateTask(_formArray);
                    }
                } else {
                    this.insertTask(_formArray, false);
                }
            } else {
                this.examCreatorFormService.open.emit(true);
            }
        }
        if (!this.mainForm.valid) {
            this.examCreatorFormService.open.emit(true);
        }
    }

    async insertTask(_formArray, isDuplicate) {
        this.taskId = await this.examCreatorFormService.insertTask(this.mainForm.value, _formArray, isDuplicate);
        this.examCreatorFormService.createdTask.emit(this.taskId);
        this.submitted(true);
    }

    async updateTask(_formArray) {
        await this.examCreatorFormService.updateTask(this.mainForm.value, _formArray);
        this.submitted(false);
    }

    private submitted(isNew) {
        const submitBtnTextPrefix = isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`;
        const dateText = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
        });

        if (!isNew) {
            this.examCreatorFormService.taskHttpApi.rescanExamAnswersByTaskId({task_id: this.taskId}).subscribe(() => {
                console.log("Rescan completed");
            });
        }

        this.examCreatorFormService.submittedResult = `${submitBtnTextPrefix} ${dateText}-kor`;

        // Enable save button.
        this.isFormValid = true;
    }

    ngOnInit() {
        let itemCountControl = this.mainForm.get("item_count");

        this.defaultValue = itemCountControl.value;
        itemCountControl.valueChanges.subscribe((value) => {
            this.defaultValue = value;
        });
    }

    onValueChanged() {
        if (!this.shortAnswer) {
            return;
        }
        const form = this.shortAnswer;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = "";
            const control = form.controls[field];

            if (control && !control.valid) {
                this.formErrors[field] += "Required field.";
            }
        }
    }

    formErrors = {
        source_short_control: ""
    };

    onPreview() {
        let items: Array<FormGroup> = this.itemFormArrays.value.map((item, index) => {
            return new FormGroup({
                item_data: new FormControl(JSON.stringify(item)),
                order: new FormControl(index),
                id: new FormControl(item.id),
                exam_answer: new FormControl(null)
            });
        });

        const _formArray = new FormArray(items);
        this.examCreatorFormService.onPreviewTask(_formArray.value);
    }


    countQuestions() {
        return this.itemFormArrays.value.length;
    }

    /**
     * The video data has changed (possibly by converter), store it in the form
     * @param filename
     */
    updateVideoData(filename: string) {
        this.taskData['file_url'] = `files/videos/${filename}`;
        this.shortAnswer.patchValue(this.taskData);
    }

    /**
     * Updates the specific formGroup's short instruction property
     * @param data
     */
    updateShortInstruction(data) {
        //console.log(data.value, data.itemId);

        for(let control of this.itemFormArrays.controls) {
            if (control.value.id === data.itemId) {
                control.patchValue({
                    short_instruction: data.value
                });
                return;
            }
        }
    }

  /**
   * Update all the item's data that came from the key manager component.
   *
   * @param data
   */
  updateShortAnswers(data) {
    const itemIds = Object.keys(data);
    this.keyManagerIsLoading = true;

    for (const itemId of itemIds) {

      const itemData = this.itemFormArrays.value.find((i) => i.id == itemId);
      itemData.short_instruction = data[itemId].keys;
      itemData.removed_answers = data[itemId].removed;

      this.apiService.updateItemData(itemData)
        .subscribe((_: any) => {
          this.toast.success('Sikeres kulcsmentés!');
          this.refreshShortInstruction(itemId, itemData.short_instruction);

          this.keyManagerIsLoading = false;
        }, (_) => {
          this.toast.error('Sikertelen kulcsmentés!');

          this.keyManagerIsLoading = false;
        });
    }
  }

  /**
   * Refreshes the specific formGroup's short instruction property
   * @param itemId
   * @param shortInstruction
   */
  refreshShortInstruction(itemId: number | string, shortInstruction: string) {
    for (let control of this.itemFormArrays.controls) {
      if (control.value.id == itemId) {
        control.patchValue({short_instruction: shortInstruction});
      }
    }
  }

  /**
   * Gets all the data that is necessary for the key manager component.
   *
   * @param items
   */
  getKeyManagerInputData(items: any): void {
    for (const item of items) {
      this.apiService.getShortAnswers(item.id).subscribe((result: any) => {
        this.keyManagerInputData[item.id] = {
          keys: item.short_instruction,
          answers: result,
          removed: item.removed_answers ? item.removed_answers : []
        };
      });
    }
  }

  /**
   * Get data for single item.
   * @param itemId
   */
  getKeyManagerInputDataForItem(itemId: number) {

    //console.trace();
    const result: any = {};
    result[itemId] = this.keyManagerInputData[itemId];
    return result;
  }

}
