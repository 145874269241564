<div class="container">
    <table class="table table-hover">
        <thead>
        <tr>
            <th i18n>Azonosító</th>
            <th *ngIf="environmentService.get('examType') === 'mmk'">Témakör</th>
            <th *ngIf="environmentService.get('examType') === 'itolc'  && environmentService.get('adaptive_testing') === false" i18n>Szint</th>
            <th *ngIf="environmentService.get('examType') === 'itolc'" i18n>Nyelv</th>
            <th i18n *ngIf="environmentService.get('hide_places') === false">Helyszín</th>
            <th i18n>Időpont</th>
            <th i18n>Művelet</th>
        </tr>
        </thead>
        <tbody class="exam-table-body" >
        <ng-container *ngFor="let exam of pagedItems; let i = index">
            <tr>
                <td>
                    {{exam.id}}
                </td>
                <td *ngIf="environmentService.get('examType') === 'mmk'">
                    {{exam.level_name}}/{{exam.study_field_name}}
                </td>
                <td *ngIf="environmentService.get('examType') === 'itolc' && environmentService.get('adaptive_testing') === false">
                    {{exam.level}}
                </td>
                <td *ngIf="environmentService.get('examType') === 'itolc'">
                    {{exam.language}}
                </td>
                <td *ngIf="environmentService.get('hide_places') === false">
                    {{exam.place}}
                </td>
                <td>
                    {{exam.date}}
                </td>
                <td>
                    <span (click)="openExamCandidateDetails(i, exam.id)" class="course-pointer" i18n>Vizsgázók</span>
                </td>
            </tr>
            <tr class="sub-table" [class.hide]="!showCandidates[i]">
                <td colspan="5" class="sub-table-border">
                    <table class="table">
                        <thead>
                        <tr>
                            <th i18n>Azonosító</th>
                            <th i18n>Email</th>
                            <th i18n>Név</th>
                            <th i18n *ngIf="environmentService.get('examType') === 'mmk'">Százalék</th>
                            <th i18n>Eredmény</th>
                            <th i18n>Státusz</th>
                            <th style="text-align: center">
                                <button type="button" class="btn btn-success btn-sm" (click)="reloadExam(i, exam.id)" *ngIf="environmentService.get('adaptive_testing') !== true"><i class="fa fa-repeat red"></i></button>
                            </th>
                        </tr>

                        <!--IDENTITY CHECK-->
                        <ng-container *ngIf="isUserAdmin">
                            <tr *ngIf="environmentService.get('examType') === 'mmk'">
                                <th colspan="4"></th>
                                <th colspan="2">


                                    <app-examiner-select
                                        [exam_id]="exam.id"
                                        [isUserAdmin]="isUserAdmin"
                                        [exam_part]="'identity_check'"
                                        >
                                    </app-examiner-select>
                                </th>
                            </tr>
                        </ng-container>

                        </thead>
                        <tbody>
                        <ng-container *ngIf="showLoaders[i]">
                            <tr>
                                <td colspan="6">
                                    <div class="loading-div">
                                        <span id="loading"  class="loading"></span>
                                        <span class="loading-text" i18n>Adatok betöltése.. </span>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-container  *ngIf="!showLoaders[i]">
                            <ng-container *ngFor="let candidate of exam.candidates; let c = index">
                                <tr>
                                    <td>{{ candidate.user_id }}</td>
                                    <td>{{ candidate.email }}</td>
                                    <td>{{ candidate.last_name }} {{ candidate.first_name }}</td>
                                    <td *ngIf="environmentService.get('examType') === 'mmk'">
                                        <span *ngIf="candidate.exam_results !== null" class="span-success-{{ candidate.exam_results[exam.id].success }}">{{ candidate.exam_results[exam.id].percent }}%</span>
                                    </td>
                                    <td *ngIf="environmentService.get('examType') !== 'mmk'">
                                        &nbsp;
                                    </td>
                                    <td class="candidate-row" *ngIf="environmentService.get('examType') === 'mmk'">
                                        <span [ngSwitch]="candidate.candidate_exam_status[0]['status'] === 'closed'">
                                            <span *ngSwitchCase="false">
                                                <final-result *ngIf="candidate.exam_results !== null && candidate.exam_results[exam.id]" [candidate_id]="candidate.id" [(result)]="candidate.exam_results[exam.id]"></final-result>
                                            </span>
                                            <span *ngSwitchCase="true">Lezárva</span>
                                        </span>
                                    </td>
                                    <td class="candidate-row" *ngIf="environmentService.get('examType') === 'mmk'">
                                        <span *ngIf="candidate.exam_results[exam.id].status==null; else elseBlock">Vizsgára rögzített</span>
                                        <ng-template #elseBlock>
                                            <span *ngIf="candidate.exam_results[exam.id].status=='result' || candidate.exam_results[exam.id].status=='finished'; else defaultBlock">Vizsga lezárva</span>
                                        </ng-template>
                                        <ng-template #defaultBlock>Vizsga folyamatban</ng-template>
                                    </td>
                                    <td *ngIf="environmentService.get('examType') === 'mmk'">
                                        <span *ngIf="candidate.exam_results !== null && candidate.exam_results[exam.id].success !== null" (click)="openExamTaskDetails(i,c)" class="course-pointer" i18n>Feladatok</span>
                                        <a  href="/candidate-identity-page/{{ candidate.exam_results[exam.id].candidate_exam_id }}" target="_blank"
                                            *ngIf="environmentService.get('identityCheck', 'menu') !== false && candidate.exam_results !== null && candidate.exam_results[exam.id].success !== null && environmentService.get('examType') === 'mmk'"
                                            >Személyazonosság</a>
                                    </td>
                                    <td *ngIf="environmentService.get('examType') !== 'mmk'">
                                        <span *ngIf="candidate.exam_results && candidate.exam_results !== null" (click)="openExamTaskDetails(i,c)" class="course-pointer" i18n>Feladatok</span>
                                    </td>
                                </tr>
                                <tr class="sub-table-tasks tasks-tr"  id="candidate-tasks-{{ i }}-{{ c }}">
                                    <td colspan="8" class="sub-table-border">
                                        <table class="table" *ngIf="environmentService.get('examType') !== 'mmk'">
                                            <thead>
                                            <tr>
                                                <th i18n>Feladat ID</th>
                                                <th i18n>Fájlnév</th>
                                                <th i18n>Típus</th>
                                                <th i18n>Státusz</th>
                                                <th i18n>Eredmény</th>
                                                <th i18n *ngIf="environmentService.get('adaptive_testing') === true">CAT Theta</th>
                                                <th i18n *ngIf="environmentService.get('adaptive_testing') === true">CAT Std.Err.</th>
                                                <th i18n>Művelet</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <ng-container *ngFor="let examPart of candidate.exam_results | keyvalue">
                                                <tr>
                                                    <td [attr.colspan]="environmentService.get('adaptive_testing') === true ? '8' : '6'" class="text-center grey-bkg">{{examPart.key}}</td>
                                                </tr>
                                                <ng-container *ngFor="let examTask of examPart.value">
                                                    <tr>
                                                        <td>{{examTask.task.id}}</td>
                                                        <td>{{examTask.task.filename}}</td>
                                                        <td *ngIf="examTask.task.type === 'short_answer'" i18n>Rövid Válasz</td>
                                                        <td *ngIf="examTask.task.type === 'pair'" i18n>Párosítás</td>
                                                        <td *ngIf="examTask.task.type === 'select'" i18n>Feleletválasztás</td>
                                                        <td *ngIf="examTask.task.type === 'select_group'" i18n>Csoportosított Feleletválasztás</td>
                                                        <td *ngIf="examTask.task.type === 'extend'" i18n>Szöveg kiegészítés</td>
                                                        <td *ngIf="examTask.task.type === 'writing'" i18n>Szövegírás</td>
                                                        <td *ngIf="examTask.task.type === 'form'" i18n>Formanyomtatvány</td>
                                                        <td *ngIf="examPart.key === 'speaking'" i18n>Beszédkészség</td>
                                                        <td>{{examTask.result.auto_correct === 1 ? 'Automatically evaluated' : 'Manual verification'}}</td>
                                                        <td>{{examTask.result.score ? examTask.result.score : '?'}}/{{examTask.itemCount === 0 ? '1' : examTask.itemCount}}</td>
                                                        <td *ngIf="environmentService.get('adaptive_testing') === true">{{examTask.theta |  number:'1.2-2'}}</td>
                                                        <td *ngIf="environmentService.get('adaptive_testing') === true">{{examTask.standard_error |  number:'1.2-2'}}</td>
                                                        <td><a href="javascript:void(0);" (click)="navigateToTaskCorrector(examTask.task.id, candidate.candidate_exam_id)" i18n>Megtekint</a></td>
                                                    </tr>
                                                </ng-container>

                                            </ng-container>
                                            <tr *ngIf="environmentService.get('adaptive_testing') === true">
                                                <td [attr.colspan]="environmentService.get('adaptive_testing') === true ? '8' : '6'" class="text-center grey-bkg" i18n>CAT Result level: <strong>{{candidate.cat_level}}</strong></td>
                                            </tr>
                                            <tr>
                                                <td [attr.colspan]="environmentService.get('adaptive_testing') === true ? '8' : '6'" class="grey-bkg">&nbsp;</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <table class="table" *ngIf="environmentService.get('examType') === 'mmk'">
                                            <thead>
                                            <tr>
                                                <th>Feladat ID</th>
                                                <th>Sorszám</th>
                                                <th>Szakasz</th>
                                                <th>Típus</th>
                                                <th>Válasz</th>
                                                <th>-</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <ng-container *ngFor="let examTask of candidate.exam_tasks; let t = index">
                                                <tr>
                                                    <td>{{ examTask.task.id }}</td>
                                                    <td>{{ examTask.order + 1 }}. </td>
                                                    <td>{{ examTask.task.section }}</td>
                                                    <td>{{ examTask.task.format | taskTypeName }}</td>
                                                    <td>
                                                        <div  *ngIf="examTask.candidate_answer">
                                                            <span class="span-answer-success-{{ examTask.task_result }}">{{ examTask.task_result | answerResultName }}</span>
                                                            <span class="span-answer-result" title="Helyes válaszok/Bejelölt helyes válaszok" i18n-title>({{ examTask.number_of_correct_answers }}/{{ examTask.candidate_summary_right_answer }})</span>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <span (click)="navigateToTaskCorrector(examTask.task.id, candidate.exam_results[exam.id].candidate_exam_id)" class="course-pointer">Megnyitás</span>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr>
                                                <td colspan="5"></td>
                                                <td class="text-left">
                                                    <span (click)="navigateToAllTaskCorrector(exam.id, candidate.exam_results[exam.id].candidate_exam_id)" class="course-pointer">Összes megjelenítése</span>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        </tbody>
                    </table>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
    <mat-paginator [length]="matPagination['length']"
                  [pageSize]="matPagination['size']"
                  [pageSizeOptions]="[5, 10, 25, 100]"
                  (page)="matPaginate($event)"
                  *ngIf="tableData">
    </mat-paginator>
</div>

