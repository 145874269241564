import {Subject} from 'rxjs/internal/Subject';
import {ApiService} from './api.service';
import {FileUploadService} from './shared/upload.service';
import {ActivatedRoute} from '@angular/router';
import {EnvironmentService} from './environment.service';
import {Injectable} from '@angular/core';
import {WebsocketService} from './websocket.service';
import {Observable} from "rxjs";
import {WebcamService} from "./webcam.service";

@Injectable()
export class StreamService {

    // Peer connections are stored in this object
    private pc = {
        'candidate': [null] as RTCPeerConnection[],     // Connection for candidates
        'app': [null] as RTCPeerConnection[],           // Connection for mobile applications
        'inspector': [null] as RTCPeerConnection[],     // Connection for other inspectors
        'admin': [null] as RTCPeerConnection[],         // Connection for administrators
        'live': [null] as RTCPeerConnection[],          // Two way connection for candidate live exams
    };
    private receivingStream = new Subject<any>();
    private resetStream = new Subject<any>();
    private candidateQueue = {'app': [], 'candidate': [], 'inspector': [], 'admin': [], 'live': []};
    private listenersInitiated = false;

    constructor(
        private environmentService: EnvironmentService,
        private webcamService: WebcamService,
        private websocketService: WebsocketService,
    ) {
        this.pc.candidate = [];
        this.pc.app = [];
        this.pc.inspector = [];
        this.pc.live = [];
    }

    /**
     * Collect all streams that are running through the peerconnection
     * @param candidate_exam_id
     * @param user_type
     */
    getStream(candidate_exam_id, user_type = 'candidate') {
        const result = {'local': null, 'remote': null};

        if (!this.pc[user_type][candidate_exam_id] || typeof this.pc[user_type][candidate_exam_id] === 'undefined') {
            return result;
        }

        // getLocalStreams and getRemoteStreams are working in chrome, but looks like it does not implemented in typescript
        // ... they were not working properly, using getSenders ...
        const senders = this.pc[user_type][candidate_exam_id].getSenders();
        const localTracks = [];
        for (const key in senders) {
            if (senders[key].track === null) continue;
            localTracks.push(senders[key].track);
        }

        if (localTracks.length > 0) {
            result['local'] = new MediaStream();
            for (const key in localTracks) {
                if (localTracks[key] === null) continue;
                result['local'].addTrack(localTracks[key]);
            }
        }

        const receivers = this.pc[user_type][candidate_exam_id].getReceivers();
        const remoteTracks = [];
        for (const key in receivers) {
            if (receivers[key].track === null) continue;
            remoteTracks.push(receivers[key].track);
        }

        if (remoteTracks.length > 0) {
            result['remote'] = new MediaStream();
            for (const key in remoteTracks) {
                result['remote'].addTrack(remoteTracks[key]);
            }
        }

        return result;
    }

    /**
     * Add camera image to stream
     * @param type candidate or inspector
     * @param type_id candidate_exam_id or inspector_id
     * @param track
     * @param stream
     */
    addLocalVideoToStream(type, type_id, track, stream) {
        const pcArray = this.getPcArray(type);

        if (!pcArray[type_id] || typeof pcArray[type_id] === 'undefined') {
            console.log('No connection to add track', type, type_id);
            return false;
        }

        for (const track of stream.getTracks()) {
            pcArray[type_id].addTrack(track);
            console.log('Local Track added to connection', track);
        }
        return true;
    }

    /**
     * Clear local tracks from a peerconnection
     * @param type
     * @param type_id
     */
    clearLocalTracks(type, type_id) {
        const pcArray = this.getPcArray(type);
        const senders = pcArray[type_id].getSenders();
        const localTracks = [];
        for (const key in senders) {
            localTracks.push(senders[key]);
        }

        if (localTracks.length > 0) {
            for (const key in localTracks) {
                pcArray[type_id].removeTrack(localTracks[key]);
            }
        }

        return true;
    }

    getReceivingStreamSubject(): Observable<any> {
        return this.receivingStream.asObservable();
    }

    getResetStreamSubject(): Observable<any> {
        return this.resetStream.asObservable();
    }

    /**
     * Initialize and start candidate and app stream
     * @param candidate_exam_id
     * @param connectionType
     */
    async initStream(candidate_exam_id, connectionType = 'candidate') {
        if (connectionType === 'candidate') {
            await this.startStream('candidate', candidate_exam_id);
            await this.startStream('app', candidate_exam_id);
            this.triggerClientConnect(candidate_exam_id, connectionType);
        } else if (connectionType === 'live') {
            await this.startStream('live', candidate_exam_id);
        }
    }

    /**
     * More generic init function
     * @param type
     * @param id
     */
    async startStream(type, id) {
        // If this is our first stream to init, then we need to listen on websocket.
        if (!this.listenersInitiated) {
            this.listenersInitiated = true;
            this.initWebsocketListeners();
        }

        let pcArray = this.getPcArray(type);

        if (pcArray[id]) {
            await pcArray[id].close();
        }

        pcArray[id] = this.createPeerConnection(id, type);
    }

    /**
     * Close the connection
     * @param type
     * @param id
     */
    async endStream(type, id) {
        let pcArray = this.getPcArray(type);

        if (pcArray[id] && typeof pcArray[id] !== 'undefined') {
            await pcArray[id].close();
            pcArray[id] = null;
            delete pcArray[id];
        }
    }

    /**
     * Returns the peerconnection array object for the corresponding type
     * Useful for handling all once
     * @param type
     */
    getPcArray(type) {
        let pcArray = null;
        switch(type) {
            case 'candidate':
                pcArray = this.pc.candidate;
                break;
            case 'app':
                pcArray = this.pc.app;
                break;
            case 'inspector':
                pcArray = this.pc.inspector;
                break;
            case 'admin':
                pcArray = this.pc.admin;
                break;
            case 'live':
                pcArray = this.pc.live;
                break;
            default: pcArray = this.pc.candidate;
        }
        return pcArray;
    }

    initWebsocketListeners() {
        this.websocketService.addHandler('webrtc-description', this.processDescription.bind(this));
        this.websocketService.addHandler('webrtc-candidate', this.processCandidate.bind(this));
    }

    triggerClientConnect(candidate_exam_id, connectionType = 'candidate') {
        this.websocketService.sendMessage('rtc-initialized', {'candidate_exam_id': candidate_exam_id, 'connectionType': connectionType});
    }

    /**
     * Generically initialize the connection
     * @param candidate_exam_id
     * @param type
     */
    createPeerConnection (candidate_exam_id, type = 'candidate') {
        // https://www.html5rocks.com/en/tutorials/webrtc/basics/
        // @TODO: Move this to configuration
        const configuration = {
            iceServers: [
                {
                    urls: 'stun:online.itolc.hu:3478',
                    username: 'itolc',
                    credential: 'fT4hU8wE5vX7yV5m'
                },
                {
                    urls: 'turn:online.itolc.hu',
                    username: 'itolc',
                    credential: 'fT4hU8wE5vX7yV5m'
                }
            ]
        };

        let pc = new RTCPeerConnection(configuration);

        // if our channel is initialized, send any ice candidates to the other peer
        pc.onicecandidate = ({candidate}) => {
            // Send this candidate to the other peer.
            console.log('Sending our canidate answer', candidate_exam_id);
            this.websocketService.sendMessage('ice-candidate', {
                'candidate': candidate,
                'candidate_exam_id': candidate_exam_id, // candidate_exam_id can be also inspector_user_id
                'user_type': type === 'live' ? 'candidate' : type,
                'connectionType': type === 'live' ? 'live' : 'candidate',   // Could be nicer
            });
        };

        pc.onsignalingstatechange = (e) => { console.log('Signal State Change', type, candidate_exam_id, type, pc.signalingState, pc.connectionState); };
        pc.oniceconnectionstatechange = (e) => { console.log('Connection State Change', type, candidate_exam_id, type, pc.signalingState, pc.connectionState); };

        // let the "negotiationneeded" event trigger offer generation
        pc.onnegotiationneeded = async () => {
            if (pc.signalingState !== 'stable') {
                return;
            }
            try {
                await pc.setLocalDescription(await pc.createOffer());
                console.log('Negotiation needed: Sending local description to the other party.', type, candidate_exam_id);
                this.sendDescription(pc, type, candidate_exam_id, type === 'live' ? 'live' : 'candidate');
            } catch (err) {
                // @TODO: Error handling, I guess.
                console.error(err, type, candidate_exam_id);
            }
        };

        pc.ontrack = (event) => {
            console.log('We are winner', event);
            this.receivingStream.next({'candidate_exam_id': candidate_exam_id, 'user_type': type, 'event': event});   // Fire event
        };

        return pc;
    }

    /**
     * Close existing stream if already exists, create new one
     * @param candidate_exam_id
     * @param type
     * @param data
     */
    async restartStream(candidate_exam_id, type, data = null) {
        let pcArray = this.getPcArray(type);
        this.resetStream.next({'candidate_exam_id': candidate_exam_id, 'user_type': type});   // Fire event


        if (typeof pcArray[candidate_exam_id] !== 'undefined') {
            await pcArray[candidate_exam_id].close();
            pcArray[candidate_exam_id] = null;
        }

        pcArray[candidate_exam_id] = this.createPeerConnection(candidate_exam_id, type);

        if (data) {
            this.processDescription(data);
        }

        return true;
    }

    /**
     * We received webrtc candidate data on websocket
     * @param data
     */
    async processCandidate(data) {
        const type = data.user_type;
        const connectionType = data.connectionType && typeof data.connectionType !== 'undefined' ? data.connectionType : 'candidate';
        const pcArray = this.getPcArray(type === 'candidate' ? connectionType : type);  // If user is candidate, we might need live connection
        const user_id = ((type === 'inspector' || type === 'admin') ? data.inspector_id : data.candidate_exam_id);
        const pc = pcArray[user_id];

        // Maybe we are not event listening anymore.
        if (pc === null) {
            console.log('Could not found connection for data', data);
            return;
        }

        if (!data || !data.candidate || data.candidate === null) {
            //console.log('Received invalid candidate, ignoring.', data);
            return;
        }

        const candidate = data.candidate;
        console.log('Receiving WebRTC Candidate data', data);

        // 'RTCPeerConnection': Candidate missing values for both sdpMid and sdpMLineIndex
        if ((!data.candidate.sdpMid && !data.candidate.sdpMLineIndex) || (data.candidate.sdpMLineIndex === 0 && data.candidate.sdpMid === '0')) {
            //console.log('Missing sdpMid && sdpMLineIndex, continue?');
            //return; -- Does not work if we return here..?
        }

        // Queue candidates until we have a remote description
        // https://stackoverflow.com/questions/38198751/domexception-error-processing-ice-candidate
        if (!pc || !pc.remoteDescription || !pc.remoteDescription.type) {
            if (typeof this.candidateQueue[type === 'candidate' ? connectionType : type][user_id] === 'undefined') {
                this.candidateQueue[type === 'candidate' ? connectionType : type][user_id] = [];
            }
            console.log('Queue received candidate', user_id, data.candidate);
            this.candidateQueue[type === 'candidate' ? connectionType : type][user_id].push(data.candidate);
        } else {
            try {
                //console.log('Adding candidate', candidate);
                await pc.addIceCandidate(candidate);
            } catch(err) {
                console.error(err);
            }
        }
    }

    /**
     * We received description on websocket
     * @param data
     */
    async processDescription(data) {
        const type = data.user_type;
        const connectionType = data.connectionType && typeof data.connectionType !== 'undefined' ? data.connectionType : 'candidate';
        const pcArray = this.getPcArray(type === 'candidate' ? connectionType : type);  // If user is candidate, we might need live connection
        const user_id = ((type === 'inspector' || type === 'admin') ? data.inspector_id : data.candidate_exam_id);
        const pc = pcArray[user_id];

        // Maybe we are not event listening anymore.
        if (pc === null || typeof pc === 'undefined') {
            console.log('Could not found connection for data', data);
            return;
        }

        if (!data || !data.description) {
            //console.log('Received invalid description, ignoring.', data);
            return;
        }

        // We were already connected - The app probably was restarted, we should restart too.
        if ((pc.connectionState === 'connected' || pc.connectionState === 'closed' || pc.connectionState === 'disconnected' || pc.connectionState === 'failed') && type === 'app') {
            console.log('Received new description from app, when we already had a connection. Restart!', type, user_id, pc.connectionState);
            await this.restartStream(user_id, type, data);
            return false;
        }

        const description = data.description;
        console.log('Receiving WebRTC Description data', data);

        // Two way connection is necessary?
        if (connectionType === 'live' || type === 'inspector' || type === 'admin') {
            // Verify if this two way connection already contains our channel
            const streams = this.getStream(user_id, type);
            // Our channel is not added yet, do it now.
            if (streams.local === null) {
                await this.addCameraToConnection(connectionType === 'live' ? 'live' : type, user_id);
            }
        }

        await pc.setRemoteDescription(description);

        if (pc.signalingState !== 'stable') {
            console.log('Sending our description to the other peer', data.candidate_exam_id, pc.signalingState);
            await pc.setLocalDescription(await pc.createAnswer());
            this.sendDescription(pc, type, user_id, connectionType);
        }

        // Process waiting candidates
        setTimeout(() => {
            if (!this.candidateQueue[type === 'candidate' ? connectionType : type] ||
                typeof this.candidateQueue[type === 'candidate' ? connectionType : type] === 'undefined' ||
                !this.candidateQueue[type === 'candidate' ? connectionType : type][user_id] ||
                typeof this.candidateQueue[type === 'candidate' ? connectionType : type][user_id] === 'undefined'
            ) {
                console.log('No candidate queue for this user', type, user_id);
                return;
            }

            for (let key in this.candidateQueue[type === 'candidate' ? connectionType : type][user_id]) {
                if (this.candidateQueue[type === 'candidate' ? connectionType : type][user_id] &&
                    this.candidateQueue[type === 'candidate' ? connectionType : type][user_id].length > 0 &&
                    this.candidateQueue[type === 'candidate' ? connectionType : type][user_id][key] &&
                    this.candidateQueue[type === 'candidate' ? connectionType : type][user_id][key] !== [] &&
                    this.candidateQueue[type === 'candidate' ? connectionType : type][user_id][key] !== null) {
                    //console.log("Adding candidate",user_id, this.candidateQueue[type === 'candidate' ? connectionType : type][user_id][key]);
                    try {
                        pc.addIceCandidate(new RTCIceCandidate(this.candidateQueue[type === 'candidate' ? connectionType : type][user_id][key]));
                    } catch(err) {
                        console.log('Failed to add candidate', err, this.candidateQueue[type === 'candidate' ? connectionType : type][user_id][key]);
                    }
                }
            }
            this.candidateQueue[type === 'candidate' ? connectionType : type][user_id] = [];
        });
    }

    sendDescription(pc, type, user_id, connectionType = 'candidate') {
        const answer = {
            'description': pc.localDescription,
            'user_type': type,
            'connectionType': connectionType,
        };

        if (type === 'inspector' || type === 'admin') {
            answer['inspector_id'] = user_id;
        } else {
            answer['candidate_exam_id'] = user_id;
        }

        this.websocketService.sendMessage('ice-description', answer);
    }

    /**
     * Add camera picture to a connection (2 way)
     * @param connectionType
     * @param user_id
     */
    async addCameraToConnection(connectionType = 'candidate', user_id) {
        return new Promise((resolve, reject) => {
            this.webcamService.initWebcam('audiovideo', (stream, track) => {
                this.addLocalVideoToStream(connectionType, user_id, track, stream);
                resolve();
            });
        });
    }
}
