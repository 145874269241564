import {Component} from "@angular/core";

@Component({
    moduleId: module.id,
    host: {
        id: "landing"
    },
    selector: "app-corrector-list",
    templateUrl: "corrector-list.component.html",
    styleUrls: ["./corrector-list.component.css"]
})

export class CorrectorListComponent {
}
