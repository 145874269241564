import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from "@angular/core";
import {IInputFieldData, IKeyManagerInput, IKeyManagerOutput} from "../../interfaces/key_manager.interface";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: "key-manager",
  templateUrl: "./key-manager.component.html",
  styleUrls: ["./key-manager.component.css"]
})

export class KeyManagerComponent implements OnInit {
    @ViewChild("saveDialog") saveDialog: TemplateRef<any>;

    @Input() input: IKeyManagerInput;
    @Input() loading: boolean;
    @Output() changed = new EventEmitter<any>();

    opened: boolean = false;
    itemIds: number[] = [];
    inputFields: IInputFieldData = {};
    data: IKeyManagerInput = {};

    constructor(
        private dialog: MatDialog
    ) {}

    ngOnInit(): void {
      setTimeout(() => {
        this.data = this.input;
        this.itemIds = Object.keys(this.input).map((itemId) => Number(itemId));

        if (this.inputHasOrderProperty()) {
          this.sortItemIdsByItemOrder();
        }

        this.filterAlreadySeparatedAnswers();
      }, 1000);
    }

    /**
     * Sets the data to a new state from the
     * incoming input
     *
     * @param changes
     */
    //ngOnChanges(changes: SimpleChanges) {
//
    //}

    /**
     * Checks if the input has multiple items
     */
    inputHasOrderProperty() : boolean {
        return this.itemIds.map((itemId) => {
            if (!this.data || !this.data[itemId]) {
                return null;
            }
            return "order" in this.data[itemId];
        }).every(Boolean);
    }


    /**
     * Sorts the itemIds array depending
     * on the order of the items.
     */
    sortItemIdsByItemOrder(): void {
        this.itemIds.sort((firstItemId, secondItemId): number => {
            if (this.data[firstItemId].order < this.data[secondItemId].order) {
                return -1;
            }

            return 1;
        });
    }

    /**
     * Removes the answers that are already
     * in a separated section of the item (removed or keys)
     */
    filterAlreadySeparatedAnswers(): void {
        for (const itemId of this.itemIds) {
            if (!this.data || !this.data[itemId]) {
                return null;
            }
            this.data[itemId].answers = this.data[itemId].answers.filter((answer: string) => {
                return !this.data[itemId].keys.split("|").includes(answer) && !this.data[itemId].removed.includes(answer);
            });
        }
    }

    toggleView(): void {
        this.opened = !this.opened;
    }

    /**
     * Adds a new key from the input field
     * @param itemId
     */
    addNewKeyToItem(itemId: number): void {
        this.data[itemId].keys += this.data[itemId].keys === "" ? this.inputFields[itemId] : `|${this.inputFields[itemId]}`;
        this.inputFields[itemId] = "";
    }

    /**
     * Moves an answer to the keys section
     *
     * @param itemId
     * @param answer
     */
    addNewAnswerToItem(itemId: number, answer: string): void {
        this.data[itemId].keys += this.data[itemId].keys === "" ? answer : `|${answer}`;
        this.data[itemId].answers.splice(this.data[itemId].answers.indexOf(answer), 1);
    }

    /**
     * Removes, then moves the answer to the removed section
     *
     * @param itemId
     * @param badAnswer
     */
    removeAnswerFromItem(itemId: number, badAnswer: string): void {
        this.data[itemId].answers.splice(this.data[itemId].answers.indexOf(badAnswer), 1);
        this.data[itemId].removed.push(badAnswer);
    }


    /**
     * Removes, then moves the answer to the keys section
     *
     * @param itemId
     * @param answer
     */
    addRemovedAnswerToItem(itemId: number, answer: string): void {
        this.data[itemId].removed.splice(this.data[itemId].removed.indexOf(answer), 1);
        this.data[itemId].keys += this.data[itemId].keys === "" ? answer : `|${answer}`;
    }

    /**
     * Removes, then moves a key to the removed section
     *
     * @param itemId
     * @param removableKey
     */
    removeKeyFromItem(itemId: number, removableKey: string): void {
        let keys: string[] = this.data[itemId].keys.split("|");
        this.data[itemId].keys = keys.filter((key: string) => key !== removableKey).join("|");
        this.data[itemId].removed.push(removableKey);
    }

    /**
     * Opens an accept dialog.
     * After that the manipulated data
     * is emitted.
     */
    openSaveDialog(): void {
        const dialogRef = this.dialog.open(this.saveDialog);

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const output: IKeyManagerOutput = {};

                for (const itemId of this.itemIds) {
                    output[itemId] = {
                        keys: this.data[itemId].keys,
                        removed: this.data[itemId].removed
                    };
                }

                this.changed.emit(output);
            }
        });
    }
}
