<div class="short-answer-task-container">
    <div [innerHTML]="task.task_data.source_short_control"></div>
    <ul class="">
        <li *ngFor="let item of task.items; let i = index">
            <h5 [ngSwitch]="i">
                <span *ngSwitchCase="0">
                    0&nbsp;<strong>{{item.item_data.short_question}}</strong>
                </span>
                <span *ngSwitchDefault>
                    {{i}}/{{task.items.length - 1}}&nbsp;<strong>{{item.item_data.short_question}}</strong>
                </span>
            </h5>

            <div>
                <span>{{item.item_data.before_answer}}</span>

                <span *ngIf="i === 0"><strong class="example">{{item.item_data.short_instruction}}</strong></span>

                <span *ngIf="i > 0 && item.exam_answer && item.exam_answer.length > 0 && item.exam_answer[0].correct !== null
                             && item.exam_answer[0].correct === 1">
                    <strong class="correct">{{item.exam_answer[0].answer_data}}</strong>
                    <span><strong>({{item.item_data.short_instruction}})</strong></span>

                    <!--if is_autocorrect: 1 => automatic correction has done => red border (class: border)-->
                    <!--if is_autocorrect: 0 => overridden manual correction has done => black border (class: checked)-->
                    <button class="glyphicon glyphicon-ok correct-answ correct-btn active-correct-color"
                            [ngClass]="{'border': item.exam_answer[0].is_autocorrect === 1 && item.exam_answer[0].estimated_value === 1,
                             'checked': item.exam_answer[0].is_autocorrect === 0 && item.exam_answer[0].estimated_value === 1}"
                            (click)="onClickCorrectBtn($event, item.exam_answer[0].id, item.id)"></button>
                    <button class="glyphicon glyphicon-remove correct-answ incorrect-btn inactive-incorrect-color"
                            [ngClass]="{'border': item.exam_answer[0].is_autocorrect === 1 && item.exam_answer[0].estimated_value === 0,
                             'checked': item.exam_answer[0].is_autocorrect === 0 && item.exam_answer[0].estimated_value === 0}"
                            (click)="onClickIncorrectBtn($event, item.exam_answer[0].id, item.id)"></button>
                </span>


                <span *ngIf="i > 0 && item.exam_answer && item.exam_answer.length > 0 && item.exam_answer[0].correct !== null &&
                             item.exam_answer[0].correct === 0">
                    <strong class="incorrect"
                            *ngIf="item.exam_answer[0].answer_data !== null">{{item.exam_answer[0].answer_data}}</strong>

                    <span class="incorrect"
                          *ngIf="i > 0 && item.exam_answer[0].answer_data === null || item.exam_answer[0].answer_data === ''">
                        <strong>---</strong>
                    </span>

                    <span><strong>({{item.item_data.short_instruction}})</strong></span>

                    <!--if is_autocorrect: 1 => automatic correction has done => red border (class: border)-->
                    <!--if is_autocorrect: 0 => overridden manual correction has done => black border (class: checked)-->
                    <button class="glyphicon glyphicon-ok incorrect-answ correct-btn inactive-correct-color"
                            [ngClass]="{'border': item.exam_answer[0].is_autocorrect === 1 && item.exam_answer[0].estimated_value === 1,
                                   'checked': item.exam_answer[0].is_autocorrect === 0 && item.exam_answer[0].estimated_value === 1}"
                            (click)="onClickCorrectBtn($event, item.exam_answer[0].id, item.id)"></button>
                    <button class="glyphicon glyphicon-remove incorrect-answ incorrect-btn active-incorrect-color"
                            [ngClass]="{'border': item.exam_answer[0].is_autocorrect === 1 && item.exam_answer[0].estimated_value === 0,
                            'checked': item.exam_answer[0].is_autocorrect === 0 && item.exam_answer[0].estimated_value === 0}"
                            (click)="onClickIncorrectBtn($event, item.exam_answer[0].id, item.id)"></button>
                </span>

                <span *ngIf="i > 0 && item.exam_answer && item.exam_answer.length > 0 && item.exam_answer[0].correct === null &&
                             item.exam_answer[0].answer_data !== null">
                    <strong>{{item.exam_answer[0].answer_data}}</strong>
                </span>

                <span>{{item.item_data.after_answer}}</span>
            </div>
        </li>
    </ul>

    <div class="save-btn-container" *ngIf="!isAdmin">
        <button class="btn btn-warning" (click)="getDownloadFile()"><i class="fa fa-download"></i> Skála</button>
        <button class="btn btn-primary"
                (click)="saveManualCorrections()"
                [disabled]="correctionsByAnswerId.size !== task.items.length - 1
                            && !hasCorrectionDone && !hasTwoExample ||
                            correctionsByAnswerId.size !== task.items.length - 2
                            && !hasCorrectionDone && hasTwoExample">
            Mentés
        </button>
    </div>
</div>
