<div id="export-page">
    <div class="container">
        <div class="col-md-12">
            <h2>Személyazonosság ellenőrzése</h2>
            <div class="col-md-12" *ngIf="candidateExam">
                <p><b>Név:</b> {{ candidateExam.candidate.last_name }} {{ candidateExam.candidate.first_name }}</p>
                <p><b>Azonosító:</b> {{ candidateExam.candidate.user_id }}</p>
                <p><b>Email: </b> {{ candidateExam.candidate.email }}</p>
                <p><b>Igazolvány szám: </b> {{ candidateExam.candidate.identity_card_number }}</p>
                <p><b>Anyakönyvi szám: </b> {{ candidateExam.candidate.registry_number }}</p>
            </div>
        </div>

        <div class="col-md-12">
            <ng-container *ngIf="images">
                <h3>Képek</h3>
                <div class="col-md-3" *ngFor="let image of images">
                    <a href="{{ image }}" target="_blank">
                        <img src="{{ image }}" class="img-responsive" />
                    </a>
                </div>
            </ng-container>
        </div>

        <div class="col-md-12">
            <ng-container *ngIf="videos">
                <h3>Videók:</h3>
                <div class="col-md-12" *ngFor="let video of videos; let k = index;">
                    <video id="video_{{ k }}" width="500" height="340" controls>
                        <source src="{{ video }}" type="video/webm">
                    </video>
                    <br/>
                    <p>Lejátszási sebesség:
                        <span (click)="setSpeed('video_' + k, 1)">1x | </span>
                        <span (click)="setSpeed('video_' + k, 2)">2x | </span>
                        <span (click)="setSpeed('video_' + k, 4)">4x | </span>
                        <span (click)="setSpeed('video_' + k, 8)">8x | </span>
                        <span (click)="setSpeed('video_' + k, 16)">16x </span>
                    </p>
                </div>
            </ng-container>
        </div>

        <div class="col-md-12" *ngIf="candidateExam">
            <hr/>
            <ng-container *ngIf="!candidateExam.identity_check && isUserExaminer">
                <p>Értékelő neve</p>
                <input type="checkbox" [(ngModel)]="checkbox"/> Személyazonosság ellenőrizve <br/><br/>
                <button type="button" class="btn btn-success" (click)="sendIdentityCheck()">Mentés</button>
            </ng-container>
            <ng-container *ngIf="candidateExam.identity_check">
                <p><b>Személyazonosság ellenőrizve</b></p>
            </ng-container>
        </div>
    </div>
</div>

