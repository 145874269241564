
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";
import {Component, OnInit, DoCheck} from "@angular/core";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {FormControl} from "@angular/forms";
import {isNullOrUndefined} from "util";
import {HttpClient} from "@angular/common/http";
import {ExamService} from "../../services/exam_service/exam.service";

@Component({
    moduleId: module.id,
    selector: "exam-roster",
    host: {
        id: "exam-roster"
    },
    templateUrl: "exam-roster.component.html",
    styleUrls: ["exam-roster.component.css"]
})

export class ExamRosterComponent implements OnInit,DoCheck {
    private examHttpApi: ExamHttpApi;
    examData: any;
    areExamsShown: Array<boolean>;
    areCandidateExtraTime = [];
    loaderShow = true;
    loaderShowText = 'Névsor betöltés alatt...';
    isAdmin:boolean = false;
    extraTimeInput = new FormControl(null);
    openIdentityCardNumberForm = new FormControl(null);
    pagination: any = {
        current_page: 1,
        last_page: 0,
    };
    examTypes: any = {
        'live': $localize`:@@live:Éles`,
        'retake': $localize`:@@retake:Pótvizsga`,
        'pilot': $localize`:@@pilot:Pilot`,
        'online': $localize`:@@online:Online próbavizsga`,
        'local': $localize`:@@local:Helyi próbavizsga`,
        'test': $localize`:@@test:Teszt`,
    };

    constructor(private http: HttpClient,
                private authService: AuthService,
                private examService: ExamService) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();
        this.geList();
    }


    ngOnInit() {
        // console.log('OK');
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    geList(){
        this.loaderShow = true;
        this.examHttpApi.getExamRoster(this.pagination.current_page).subscribe((results) => {
            this.examData = results.data;
            this.pagination.current_page = results.current_page;
            this.pagination.last_page = results.last_page;
            this.areExamsShown = this.examData.map(() => false);
            this.loaderShow = false;

            this.areCandidateExtraTime = this.examData.map((exam) => {
                return exam.candidates.map((candidate) => {
                    if(candidate.pivot.extra_time === null){
                        return [{
                            writing: null,
                            writing_justification: null,
                            reading: null,
                            reading_justification: null,
                            listening: null,
                            listening_justification: null,
                            speaking: null,
                            speaking_justification: null,
                        }];
                    }
                    return JSON.parse(candidate.pivot.extra_time);
                });
            });
        });
    }

    private setPage(type: string){
        if(this.loaderShow){
            return;
        }

        this.pagination.current_page = type === 'pre' ? parseInt(this.pagination.current_page) - 1 : parseInt(this.pagination.current_page) + 1;
        this.pagination.current_page = parseInt(this.pagination.current_page) < 1 ? 1 : this.pagination.current_page;

        this.geList();
    }

    openCandidatesList(index: number){
        this.areExamsShown[index] = !this.areExamsShown[index];
    }


    suspensionOfExam(candidateExamId: number, type: string, status: string){
        this.examService.suspensionOfExam(candidateExamId,type, status);
    }

    passwordGenerate(candidateId){
        this.examService.passwordGenerate(candidateId);
    }

    openExtraTime(){
        this.extraTimeInput = new FormControl(null);
    }

    openIdentityCardForm(){
        this.openIdentityCardNumberForm = new FormControl(null);
    }

    reopeningExam(candidateExamId){
        if(confirm("Biztosan újranyitod a vizsgát?")) {
            this.examService.reopeningExam(candidateExamId);
        }
    }

    addIdentityCardNumberToCandidate(event:Event, candidateId:number, examIndex:number, candidateIndex:number){
        const selectedElement = <HTMLSelectElement> event.target;
        let value: any = selectedElement.value;

        console.log('value: ', candidateId, value);
        console.log(this.examData[examIndex].candidates[candidateIndex]);

        this.examData[examIndex].candidates[candidateIndex].identity_card_number = value;
        this.saveCandidateIdentityCardNumber(candidateId, value);
    }

    addExtraTimeToCandidate(event:Event, examIndex:number, candidateIndex:number, candidateId:number, part:string){
        const selectedElement = <HTMLSelectElement> event.target;
        let value: any = selectedElement.value;

        if(part === 'writing' ||
            part === 'reading' ||
            part === 'listening' ||
            part === 'speaking'){
            value = parseInt(selectedElement.value, 0);
        }

        this.areCandidateExtraTime[examIndex][candidateIndex][part] = value;
    }

    saveExtraTime(examIndex:number, candidateIndex:number, candidateId:number, examId: number, part:string){
        let partJustification= `${part}_justification`;

        if(isNullOrUndefined(this.areCandidateExtraTime[examIndex][candidateIndex][part]) ||
            isNaN(this.areCandidateExtraTime[examIndex][candidateIndex][part]) ||
            this.areCandidateExtraTime[examIndex][candidateIndex][part] === 0) {
            alert('Perc megadása kötelező');
            return false;
        }

        if(isNullOrUndefined(this.areCandidateExtraTime[examIndex][candidateIndex][partJustification]) ||
            this.areCandidateExtraTime[examIndex][candidateIndex][partJustification] === '') {
            alert('Indkolás megadása kötelező');
            return false;
        }

        let logDescription = 'CandidateExamID: ' + candidateId + ', Part: ' + part + ' Min: ' + this.areCandidateExtraTime[examIndex][candidateIndex][part] + ' Justification: ' + this.areCandidateExtraTime[examIndex][candidateIndex][partJustification];
        this.saveLog(examId, logDescription);

        this.examService.addExtraTimeToCandidate(part, candidateId, this.areCandidateExtraTime[examIndex][candidateIndex][part]);
    }

    saveLog(exam_id: number, description: string){
        let url = `${environment.apiPrefixUrl}diaries`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: exam_id,
            type: 'Időkorlát módosítása',
            description: description,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if (result) {
                console.log('Sikeres napló mentés');
            }
        })).subscribe();
    }

    saveCandidateIdentityCardNumber(candidateId: number, cardNumber: string){
        let url = `${environment.apiPrefixUrl}candidates/saveCandidateIdentityCardNumber`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_id: candidateId,
            card_number: cardNumber,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if (result) {
                alert('Sikeres mentés');
            }
        })).subscribe();
    }

    setOnTheSpot(candidateExamId: number, value){
        let url = `${environment.apiPrefixUrl}candidates/setOnTheSpot`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_exam_id: candidateExamId,
            value: value,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if (result) {
                alert('Sikeres mentés');
            }
        })).subscribe();
    }

    setDecision(candidateExamId: number, value){
        let url = `${environment.apiPrefixUrl}candidates/setDecision`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_exam_id: candidateExamId,
            value: value,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if (result) {
                alert('Sikeres mentés');
            }
        })).subscribe();
    }

    setIdentityCheck(candidateExamId: number, value){
        let url = `${environment.apiPrefixUrl}candidates/setIdentityCheck`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_exam_id: candidateExamId,
            value: value,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if (result) {
                alert('Sikeres mentés');
            }
        })).subscribe();
    }
}
