import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, FormControl, FormArray, Validators} from "@angular/forms";
import {Router, ActivatedRoute} from "@angular/router";
import {CandidateHttpApi} from "../../http_apis/candidate.http";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {isNullOrUndefined} from "util";
import {environment} from "../../../environments/environment";
import {PaginationService} from "../../services/pagination.service";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../services/api.service";

@Component({
    moduleId: module.id,
    host: {
        id: "candidate-list",
    },
    selector: "candidate-list",
    templateUrl: "candidate-list.component.html",
    styleUrls: ["candidate-list.component.css"]
})


export class CandidateListComponent implements OnInit {

    candidateList: any;
    examList: Array<any>;
    private candidateHttpApi: CandidateHttpApi;
    private examHttpApi: ExamHttpApi;
    loader: boolean = true;
    loader2: boolean = false;
    selectExamId: number;
    selectExamType: string;
    private candidatesAddArray = [];
    private elementsArray = [];
    private importFile: any;
    pageSize = 40;

    matPagination = {
        'page': 0,
        'size': 30,
        'length': 0,
    };

    // array of all items to be paged
    private allItems: Array<any>;
    // pager object
    pager: any = {};
    // paged items
    pagedItems: any[];

    filters: any = {
        last_name: {
            type: "string",
            value: null,
        },
        first_name: {
            type: "string",
            value: null,
        },
        email: {
            type: "string",
            value: null,
        },
        user_id: {
            type: "string",
            value: null,
        },
        created_at: {
            type: "string",
            value: null,
        },
        updated_at: {
            type: "string",
            value: null,
        },
        exams: {
            type: "array",
            value: null,
        }
    };

    constructor(private formBuilder: FormBuilder,
                private http: HttpClient,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private paginationService: PaginationService,
                private apiService: ApiService,
    ) {

        this.candidateHttpApi = new CandidateHttpApi(http, "data");
        this.candidateHttpApi.setDefaultUrl();

        this.examHttpApi = new ExamHttpApi(http, "data");
        this.examHttpApi.setDefaultUrl();


        this.examHttpApi.setExamsToSelectUrl();
        this.examHttpApi.read(undefined, undefined, true).subscribe(results => {
            this.examList = results;
            this.loader = false;
        });

        // this.candidatesAddArray.valueChanges.subscribe(() => {
        //     let value = parseInt(this.candidatesAddExamControl.value);
        // });

    }

    /**
     * Fetch candidates with pagination
     */
    getCandidates() {
        this.apiService.getCandidateList(this.filters, this.matPagination).subscribe((data: any) => {
            this.candidateList = data.data;
            this.allItems = data.data;
            this.matPagination['length'] = data.total;
            this.setPage(1);
        });
    }

    ngOnInit() {
       this.getCandidates();
    }

    getFilteredList() {
        this.getCandidates();
        return;

        // @TODO: Cleanup
        if (!this.candidateList) {
            return [];
        }

        let useUpdatedAt = false;
        let filteredList = this.candidateList.slice();
        Object.keys(this.filters).forEach((key) => {
            const filter = this.filters[key];

            if (!filter.value) {
                return [];
            }

            if (key === 'updated_at' && filter.value.length > 0) {
                useUpdatedAt = true;
            }

            filteredList = filteredList.filter(
                (item) => {
                    if (!item[key]) {
                        return false;
                    }
                    if (filter.type === "array") {
                        let searchID = parseInt(filter.value);
                        if (searchID === 0) {
                            return true;
                        }

                        return item[key].some((e) => e === searchID);
                    }

                    if (filter.type === "string" && !isNullOrUndefined(item[key])) {
                            return item[key].toLowerCase().includes(filter.value.toLowerCase());
                    }

                    return true;
                }
            );
        });

        this.allItems = filteredList;
        if(useUpdatedAt){
            this.reorderList();
        } else {
            this.reorderList(false);
        }

        this.setPage(1);
    }

    setPage(page: number) {
        // get pager object from service
        this.pager = this.paginationService.getPager(this.allItems.length, page, this.pageSize);

        // get current page of items
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);
    }

    reorderList(sortByName:boolean = true){
        if(sortByName){
            this.allItems = this.allItems.sort(function(a, b){
                if(a.last_name + a.first_name < b.last_name + b.first_name) { return -1; }
                if(a.last_name + a.first_name > b.last_name + b.first_name) { return 1; }
                return 0;
            });
        } else {
            this.allItems = this.allItems.sort(function(a, b){
                if(a.updated_at < b.updated_at) { return 1; }
                if(a.updated_at > b.updated_at) { return -1; }
                return 0;
            });
        }
    }

    openCandidate(id:number){
        this.router.navigateByUrl(`/candidates/edit/${id}`);
    }

    onChangeExam(value){
        this.selectExamId = parseInt(value);
    }

    onChangeExamType(value){
        this.selectExamType = value;
    }

    changeCheckbox(element: HTMLInputElement): void {
        // `Checkbox ${element.value} was ${element.checked ? '' : 'un'}checked\n`;
        let item = parseInt(element.value);

        if(element.checked){
            this.candidatesAddArray.push(item);
            this.elementsArray.push(element);
        } else {
            let index: number = this.candidatesAddArray.indexOf(item);
            if (index !== -1) {
                this.candidatesAddArray.splice(index, 1);
            }
        }
    }

    selectAllCheckbox(element: HTMLInputElement): void {
        // `Checkbox ${element.value} was ${element.checked ? '' : 'un'}checked\n`;
        if(element.checked){
            this.pagedItems.forEach((element) => {
                this.candidatesAddArray.push(element.id);

                let htmlElement = document.getElementById(element.id);
                this.elementsArray.push(htmlElement);

                this.setCheckedAll();
            });
        } else {
            this.uncheckAll();
        }
    }

    setCheckedAll(){
        this.elementsArray.forEach((element) => {
            element.checked = true;
        });
    }

    uncheckAll(){
        this.elementsArray.forEach((element) => {
            element.checked = false;
        });

        this.elementsArray = [];
        this.candidatesAddArray = [];
    }

    addExamToCandidate(){
        if(this.candidatesAddArray.length === 0){
            alert('Nincs vizsgázó kijelölve');
            return false;
        }

        this.candidateHttpApi.addCandidateArrayToExam(this.candidatesAddArray,this.selectExamId, this.selectExamType).subscribe((data) => {
            if(data.result){
                alert(data.msg);

                this.candidatesAddArray.forEach((item) => {
                    let id = parseInt(item);
                    const candidate = this.candidateList.find(c => c.id === id);

                    if(candidate){
                        let check = candidate.exams.some(e => e === this.selectExamId);
                        if(!check){
                            candidate.exams.push(this.selectExamId);
                        }
                    }
                });

                this.uncheckAll();
            } else {
                alert(data.msg);
            }
        });
    }

    onChangeFile(event: Event, name: string){
        let inputEl = <HTMLInputElement> event.target;
        this.importFile = inputEl.files[0];
    }

    candidateImport(){
        this.loader2 = true;

        if(environment.examType === 'mmk'){
            this.candidateHttpApi.candidateImportMMK(this.importFile).subscribe((data) => {
                if(data.response === true){
                    window.location.reload();
                }
            });
        } else {
            this.candidateHttpApi.candidateImport(this.importFile).subscribe((data) => {
                if(data.response === true){
                    window.location.reload();
                }
            });
        }
    }

    /**
     * Create and store excel file on the server
     * @param examId
     * @param part
     * @returns {Subscription}
     */
    createPostExcel() {
        if(this.candidatesAddArray.length === 0){
            alert('Nincs vizsgázó kijelölve');
            return false;
        }

        this.candidateHttpApi.candidateExport(this.candidatesAddArray).subscribe((data) => {
            this.getDownloadFile(data.file);
            this.uncheckAll();
        });
    }

    /**
     * Download xls file
     * @param file
     */
    private getDownloadFile(file) {
        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}export/download/MMK/${file}&token=${token}`;

        window.open(url);
    }

    matPaginate(event){
        this.matPagination['page'] = event.pageIndex;
        this.matPagination['size'] = event.pageSize;
        this.getCandidates();
    }
}
