import {Routes} from "@angular/router";
import {ListComponent} from "../../components/list/list.component";
import {CorrectorComponent} from "../../components/corrector/corrector.component";
import {ExaminerAuthGuardService} from "../../services/examiner-auth-guard.service";
import {CorrectorListComponent} from "../../components/corrector-list/corrector-list.component";
import {ExamResultMmkComponent} from "../../components/corrector/exam-result-mmk/exam-result-mmk.component";
import {ShowCandidateAllAnswersComponent} from "../../components/corrector/show-candidate-all-answers/show-candidate-all-answers.component";
import {ShowCandidateAnswersComponent} from "../../components/corrector/show-candidate-answer/show-candidate-answers.component";
export const CORRECTOR_ROUTES: Routes = [{
  path: "exam-answers",
  component: ListComponent,
  data: {
    type: "exam-answers",
    tableHeaders: [
      `Vizsga azonosító`,
      `Vizsga időpontja`,
      `Vizsga helye`,
      `Vizsga nyelve`,
      `Vizsga szintje`,
      `Vizsga típusa`,
      `Művelet`,
    ]
  }
}, {
  path: "edit-exam-answer/:id",
  component: CorrectorComponent
}, {
  path: "exam-answers-mmk",
  component: ExamResultMmkComponent,
},
{
    path: "show-exam-answer/:taskId/:candidateId",
    component: ShowCandidateAnswersComponent
}, {
    path: "show-candidate-all-answers/:examId/:candidateId",
    component: ShowCandidateAllAnswersComponent
}];
