import {Component} from "@angular/core";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {FileUploadService} from "../../services/shared/upload.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {isNullOrUndefined} from "util";

@Component({
    selector: "app-speaking-skills",
    templateUrl: "./speaking-skills.component.html",
    styleUrls: ["./speaking-skills.component.css"]
})
export class SpeakingSkillsComponent {

    mainForm: FormGroup;
    itemFormArrays: FormArray = new FormArray([]);
    taskId: number;
    prefixUrl: string;
    token: string;
    requieredRegSheetForm: boolean;
    isFormValid: boolean = true;
    isDuplicate: boolean = false;
    private sectionItems: Array<FormArray>;
    private items: Array<any>;
    submitButtonText:string = $localize`Mentés`;

    constructor(public examCreatorFormService: ExamCreatorFormService,
                private fileUploadService: FileUploadService,
                private activatedRoute: ActivatedRoute) {

        this.mainForm = this.examCreatorFormService.$examCreatorFrom;
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.fileUploadService.uploadedFile.subscribe(({url, type, index}) => {
            this.handleMediaUpload(url, type, index);
        });

        this.activatedRoute.params.subscribe(({id, duplicate}) => {
            if(!isNullOrUndefined(duplicate)){
                if(duplicate === 'duplicate'){
                    this.isDuplicate = true;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                } else {
                    this.isDuplicate = false;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                }
            }

            if (!id) {
                this.examCreatorFormService.backToMainForm();
            }

            this.taskId = id;
            if (this.taskId) {
                this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {
                    this.examCreatorFormService.setExamObject(examTask);
                    this.examCreatorFormService.setExistInstructionControl(examTask.instruction);

                    examTask.view_date = examTask.view_date.replace(/:[0-9]+$/, "");

                    this.mainForm.patchValue(examTask);

                    if (examTask.items) {
                        this.items = examTask.items;
                        this.setItemArraysWithExistData(this.items);
                    }
                });
            }
        });

        this.examCreatorFormService.requiredRegistryField.subscribe((data) => {
            if (isNullOrUndefined(data)) {
                this.requieredRegSheetForm = true;
            }

            this.requieredRegSheetForm = data;
        });

        this.mainForm.valueChanges.subscribe(() => {
            this.requieredRegSheetForm = this.mainForm.controls.instruction.value !== "" ||
                isNullOrUndefined(this.mainForm.controls.instruction.value);

            this.examCreatorFormService.registryFormSend.emit(this.mainForm);
        });

        if(this.isDuplicate){
            this.submitButtonText = $localize`Duplikálás mentése`;
        }
    }

    handleMediaUpload(url, type, index) {
        const formGroup = <FormGroup> this.itemFormArrays.at(index);

        if (typeof formGroup !== "undefined") {
            formGroup.patchValue({
                [type]: url
            });
        }
    }

    private setItemArraysWithExistData(items: Array<any>) {
        let itemArrays = items.map((item, index) => {
            let itemData = (typeof item.item_data === "string") ? JSON.parse(item.item_data) : item.item_data;
            itemData.id = item.id;
            let formGroupConfig = {};

            for (let dataItem in itemData) {
                if (itemData.hasOwnProperty(dataItem)) {
                    let value = itemData[dataItem];

                    if (dataItem !== "section_items") {
                        formGroupConfig[dataItem] = new FormControl(value);
                    } else {
                        formGroupConfig[dataItem] = this.sectionItems[index];
                    }
                }
            }

            if (!formGroupConfig["enlarge_file_url"]) {
                formGroupConfig["enlarge_file_url"] = new FormControl(null);
            }

            return new FormGroup(formGroupConfig);

        });

        this.itemFormArrays = new FormArray(itemArrays);
    }

    removeItemFile(index, type) {
        const formGroup = <FormGroup> this.itemFormArrays.at(index);

        formGroup.patchValue({
            [type]: null
        });
    }

    removedGroupItem(index: number) {
        if (this.itemFormArrays.value[index].id) {
            this.examCreatorFormService.itemHttpApi.del(this.itemFormArrays.value[index].id, true).subscribe(() => {
                console.log("Item removed!");
            });
        }

        this.itemFormArrays.removeAt(index);
        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }


    onClickNewOptionBtn(event: Event) {
        event.preventDefault();

        this.itemFormArrays.push(new FormGroup({
            speakingSourceControl: new FormControl(null, Validators.required),
            file_url: new FormControl(null),
            enlarge_file_url: new FormControl(null)
        }));

        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    private getItems() {
        this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {
            if (examTask.items) {
                this.items = examTask.items;
                this.setItemArraysWithExistData(this.items);
            }
        });
    }

    onSubmit(event: Event) {
        event.preventDefault();

        this.examCreatorFormService.setTaskDataControlValue("{}");

        let items: Array<FormGroup> = this.itemFormArrays.value.map((item, index) => {
            let id = item.id;
            delete item.id;
            return new FormGroup({
                item_data: new FormControl(JSON.stringify(item)),
                order: new FormControl(index),
                id: new FormControl(id)
            });
        });

        const formArray = new FormArray(items);

        this.mainForm.setControl("items", formArray);

        if (this.mainForm.valid) {
            // Disable save button.
            this.isFormValid = false;

            if (this.taskId) {
                if (this.isDuplicate) {
                    this.insertTask(formArray, true);
                } else {
                    this.updateTask(formArray);
                }
            } else {
                this.insertTask(formArray, false);
            }
        } else {
            this.examCreatorFormService.open.emit(true);
        }
    }

    async insertTask(formArray, isDuplicate) {
        this.taskId = await this.examCreatorFormService.insertTask(this.mainForm.value, formArray, isDuplicate);
        this.getItems();


        this.examCreatorFormService.createdTask.emit(this.taskId);
        this.isDuplicate = false;
        this.examCreatorFormService.$isDuplicate = false;

        this.submitted(true);
    }

    async updateTask(formArray) {
        await this.examCreatorFormService.updateTask(this.mainForm.value, formArray);
        this.getItems();
        this.submitted(false);
    }

    private submitted(isNew) {
        const submitBtnTextPrefix = isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`;
        const dateText = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
        });

        this.examCreatorFormService.submittedResult = `${submitBtnTextPrefix} ${dateText}-kor`;

        // Enable save button.
        this.isFormValid = true;
    }

    onPreview() {
        let items: Array<FormGroup> = this.itemFormArrays.value.map((item, index) => {
            return new FormGroup({
                item_data: new FormControl(JSON.stringify(item)),
                order: new FormControl(index),
                id: new FormControl(item.id),
                exam_answer: new FormControl(null)
            });
        });

        const formArray = new FormArray(items);
        this.examCreatorFormService.onPreviewTask(formArray.value);
    }

    countQuestions(){
        return this.itemFormArrays.value.length;
    }
}
