<form class="row" [formGroup]="parentFormGroup">
    <div class="col-xs-12">
        <div>
            <div class="form-group">
                <label i18n>A feladathoz tartozó instrukció:</label>
                <input type="text"
                       class="form-control"
                       formControlName="instruction"
                       i18n-placeholder
                       placeholder="Write [a letter VAGY an email] of about 80 words to your foreign [friend VAGY colleague] about the topic below.">
                <div *ngIf="formErrors.instruction" class="alert alert-danger">
                    {{ formErrors.instruction }}
                </div>
            </div>

        </div>
    </div>
</form>
