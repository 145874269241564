import { Component, OnInit, ViewChild } from "@angular/core";
import { RestConfig } from "../http_apis/rest_config.http";
import { AuthService } from "../services/auth.service";
import { Router } from "@angular/router";
import { AppHeader } from "./header/app.header";
import { AppLogin } from "./login/app.login";
import {WebsocketService} from "../services/websocket.service";
import {Title} from "@angular/platform-browser";
import {EnvironmentService} from "../services/environment.service";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html"
})

export class AppComponent implements OnInit {

    @ViewChild(AppHeader) appHeaderComponent: AppHeader;
    @ViewChild(AppLogin) appLoginComponent: AppLogin;

    constructor(private authService: AuthService,
                private websocketService: WebsocketService,
                private titleService: Title,
                private environmentService: EnvironmentService,
                private router: Router) {

        this.titleService.setTitle(this.environmentService.get('title'));

        let storageToken = window.localStorage.getItem("itolc_token");
        let requestOptions = RestConfig.getReqOpt();

        if (!requestOptions) {
            requestOptions = {'headers': {'Content-Type': "application/json"}};
        }

        if (storageToken !== null) {
            RestConfig.setReqOpt(requestOptions, storageToken);

            this.authService.token = storageToken;

            if (!this.authService.isTokenExpired()) {
                this.authService.refreshToken();
            } else {
                this.authService.token = undefined;
                this.router.navigateByUrl("/");
            }

        } else {
            RestConfig.setReqOpt(requestOptions);
        }
    }

    ngOnInit() {

        if (this.authService.token) {
            setTimeout(() => {
                this.appHeaderComponent.isLogged = true;
            });

            this.authService.getUserStatus().subscribe((data) => {
                //this.authService.userData = data; -- moved to local call
            });
        } else {
            this.router.navigateByUrl("/");
        }

        this.authService.loggedEvent.subscribe((result) => {
            setTimeout(() => {
                this.appHeaderComponent.isLogged = result;
            });
            this.router.navigateByUrl("/landing");
        });
    }
}
