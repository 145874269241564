
import {forkJoin as observableForkJoin, Observable} from 'rxjs';
//tslint:disable max-file-line-count
import {AfterViewChecked, Component, DoCheck, ElementRef, EventEmitter, Input, OnInit} from "@angular/core";
import {ITask} from "../../../interfaces/task.interface";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";

import {
    CORRECTION_OPTIONS_SPEAKING,
    CORRECTION_OPTIONS_SPEAKING_MONOLOG
} from "../../../services/shared/manual_correction_options";
import {isNullOrUndefined} from "util";
import {IFile} from "../../../interfaces/file.interface";
import {AuthService} from "../../../services/auth.service";

@Component({
    moduleId: module.id,
    selector: "speaking-task",
    templateUrl: "speaking-task.component.html",
    styleUrls: ["../corrector.component.css"]
})

export class SpeakingTaskComponent implements OnInit, DoCheck, AfterViewChecked {

    @Input("task") task: ITask;

    private examHttpApi: ExamHttpApi;
    private prefixUrl: string;
    private token: string;
    private startedPlayingAudioElementIndex: number;
    private audioElements: [HTMLAudioElement];
    private optionRateMap = new Map();
    private optionRateMap2 = new Map();
    private taskInitialize: EventEmitter<any> = new EventEmitter();
    loader = false;
    examAnswerId: number;
    currentTime: any;
    correctorComments: any;
    correctorCommentText: any;
    reviewByCorrectorForm: FormGroup;
    reviewByCorrector2Form: FormGroup;
    files: Array<IFile> = [];
    mp3Files: Array<boolean> = [];
    rating = ["*", 0, 1, 2, 3, 4]; // "*" equals 5
    correctionOptions:any = CORRECTION_OPTIONS_SPEAKING;
    isSavingLabelVisible = false;
    corrector1: string;
    corrector2: string;
    isAdmin: boolean;
    isAutoPlayChecked = true;
    disabledButton: boolean = false;

    constructor(private http: HttpClient,
                private formBuilder: FormBuilder,
                private authService: AuthService,
                private elementRef: ElementRef) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setSelectedTaskUrl();
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.reviewByCorrectorForm = this.formBuilder.group({});
        this.reviewByCorrector2Form = this.formBuilder.group({});

        this.taskInitialize.subscribe(() => {
            console.log('this.task: ', this.task.exam_order);
            this.correctionOptions = this.task.exam_order === 0 ? CORRECTION_OPTIONS_SPEAKING_MONOLOG : CORRECTION_OPTIONS_SPEAKING;
            const formControlKeys = Object.keys(this.correctionOptions);

            formControlKeys.forEach((key) => {
                this.reviewByCorrectorForm.registerControl(key, new FormControl(null, Validators.required)); // first corrector's review form
                this.reviewByCorrector2Form.registerControl(key, new FormControl(null, Validators.required)); // second corrector's review form

                let reviewArray = this.task.items.filter((reviews) => {
                    if (!isNullOrUndefined(reviews.exam_answer) && reviews.exam_answer[0] && !isNullOrUndefined(reviews.exam_answer[0].review)) {
                        return reviews.exam_answer[0].review;
                    }
                });

                let review = reviewArray[0].exam_answer[0].review;

                if (!isNullOrUndefined(review)) {
                    review.forEach((option, index) => {
                        if (option.rating === null) {
                            option.rating = "*";
                        }
                        if (this.task.is_admin) {
                            if (this.task.examiner1 === option.user_id) {
                                // these are the first corrector's reviews if admin logged in
                                // or the current corrector's reviews if the corrector logged in
                                this.optionRateMap.set(option.scale, option.rating);
                            } else {
                                // these are the second corrector's reviews if admin logged in
                                this.optionRateMap2.set(option.scale, option.rating);
                            }
                        } else {
                            this.optionRateMap.set(option.scale, option.rating);
                        }
                    });

                    this.reviewByCorrector2Form.controls[key].setValue(this.optionRateMap2.get(key));
                    this.reviewByCorrectorForm.controls[key].setValue(this.optionRateMap.get(key));
                }
            });
        });
    }

    ngOnInit() {
        this.taskInitialize.emit();
        this.ngLoadFile();

        this.correctorComments = !isNullOrUndefined(this.task.review_comments) ? this.task.review_comments : [];
        this.correctorCommentText = !isNullOrUndefined(this.task.review_comments) ? this.task.review_comments.comment : '';

        if (!isNullOrUndefined(this.task.items[0].exam_answer)) {
            this.examAnswerId = this.task.items[0].exam_answer[0].id;
        } else {
            this.task.items.map((item) => {
                if (!isNullOrUndefined(item.exam_answer)) {
                    this.examAnswerId = item.exam_answer[0].id;
                }
            });
        }

        if (this.isAdmin && !isNullOrUndefined(this.task.items[0].exam_answer)) {
            this.corrector2 = `${this.task.items[0].exam_answer[0].review[9].user.first_name} 
            ${this.task.items[0].exam_answer[0].review[9].user.last_name}`;
            this.corrector1 = `${this.task.items[0].exam_answer[0].review[0].user.first_name} 
            ${this.task.items[0].exam_answer[0].review[0].user.last_name}`;
        }
    }

    async ngLoadFile(){
        let getFileArray = [];

        await this.task["items"].forEach((item) => {
            item.item_data = (typeof item.item_data === "string") ?  JSON.parse(item.item_data) : item.item_data;

            if (item["exam_answer"] !== null && item["exam_answer"].length > 0) {
                let getFile = JSON.parse(item["exam_answer"][0].answer_data);
                getFileArray.push({
                    order: item.order,
                    item_id: item.id,
                    enlarge_file_url: item.item_data.enlarge_file_url,
                    question_url: item.item_data.file_url,
                    id: getFile["file_id"],
                    url: null,
                    speakingSourceControl: item.item_data.speakingSourceControl
                });

                item["exam_answer"][0].answer_data = (typeof item["exam_answer"][0].answer_data === "string") ? JSON.parse(item["exam_answer"][0].answer_data) : item["exam_answer"][0].answer_data;

                observableForkJoin(
                    this.examHttpApi.setAnswerFiles(item["exam_answer"][0]["candidate_exam_id"],item["exam_answer"][0]["item_id"])
                ).subscribe(answerFiles => {
                    if(answerFiles[0].length === 1){
                        if(answerFiles[0]){
                            let getData = answerFiles[0][0];
                            getFileArray.map((file, i) => {
                                // console.log('if1: ', file.item_id,getData.item_id);
                                if (file.item_id === getData.item_id){
                                    getFileArray[i].url = getData.url;
                                }
                            });
                        }
                    } else {
                        answerFiles[0].map((answer, j) => {
                            let getData = answer;
                            getFileArray.map((file, i) => {
                                // console.log('if2: ', file.item_id,getData.item_id);
                                if (file.item_id === getData.item_id){
                                    getFileArray[i].url = getData.url;
                                }
                            });
                        });
                    }

                    this.ngAfterViewChecked();
                }, err => console.log(err));
            } else {
                getFileArray.push({
                    order: item.order,
                    item_id: item.id,
                    enlarge_file_url: item.item_data.enlarge_file_url,
                    question_url: item.item_data.file_url,
                    id: null,
                    url: null,
                    speakingSourceControl: item.item_data.speakingSourceControl
                });
            }
        });

        // wait for files loader...
        await this.delay(3500);
        this.loader = true;

        getFileArray = getFileArray.sort((a: any, b: any) => {
            if (a.order < b.order) {
                return -1;
            } else if (a.order > b.order) {
                return 1;
            } else {
                return 0;
            }
        });

        this.files = getFileArray;
    }

    delay(ms: number) {
        return new Promise( resolve => setTimeout(resolve, ms) );
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    ngAfterViewChecked() {
        this.audioElements = this.elementRef.nativeElement.querySelectorAll("audio");
    }

    onSubmit(event) {
        event.preventDefault();
        this.disabledButton = true;

        const valueObj = this.reviewByCorrectorForm.value;
        let correctorOptionDatas = [];

        if(!this.reviewByCorrectorForm.valid){
            alert("Az értékelés nem teljes!");
            return false;
        }

        for (const item in valueObj) {
            let itemVal = valueObj[item];

            if (!isNullOrUndefined(itemVal)) {
                if (itemVal === "*") {
                    itemVal = null;
                }

                correctorOptionDatas.push({
                    exam_answer_id: this.examAnswerId,
                    scale: item,
                    rating: itemVal,
                    candidate_exam_id: this.task.candidate_exam_id,
                    exam_task_id: this.task.exam_task_id,
                    corrector_comment: this.correctorCommentText
                });
            }
        }

        if (correctorOptionDatas.length > 0) {
            this.examHttpApi.saveManualCorrectionOptions(correctorOptionDatas).subscribe((response:any) => {
                if (response) {
                    this.currentTime = new Date().toLocaleDateString("hu-HU", {
                        hour: "numeric",
                        hour12: false,
                        minute: "numeric"
                    });

                    this.isSavingLabelVisible = true;
                    this.disabledButton = false;

                    setTimeout(() => {
                        this.isSavingLabelVisible = false;
                    }, 5000);
                }
            });
        }
    }

    setDuration(i) {
        let id = "audio-" + i;
        let audio = <HTMLAudioElement> document.getElementById(id);

        if (audio.duration === Infinity) {
            audio.currentTime = 900;
        } else {
            audio.currentTime = 0;
        }


        let answer_id = "audio-answer-" + i;
        let audioAnswer = <HTMLAudioElement> document.getElementById(answer_id);

        if(!isNullOrUndefined(audioAnswer)) {
            if (audioAnswer.duration === Infinity) {
                audioAnswer.currentTime = 900;
            } else {
                audioAnswer.currentTime = 0;
            }
        }
    }

    // sets the auto play checkbox's value
    changeAutoPlay($event) {
        this.isAutoPlayChecked = $event.target.checked;
    }

    // emits if the audio file stopped playing
    audioEnded(type, i) {
        const next = i + 1;

        let id = "audio-" + next;
        if(type === 'question'){
            id = "audio-answer-" + i;
        }

        let audio = <HTMLAudioElement> document.getElementById(id);

        if(!isNullOrUndefined(audio)) {
            audio.play();
        }
    }

    playAudio(index: number) {
        this.startedPlayingAudioElementIndex = index;
    }

    /**
     * Download xls file
     * @param file
     */

    private getDownloadFile() {
        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}getDownloadReviewPDF/speaking_scale.pdf&token=${token}`;

        window.open(url);
    }
}
