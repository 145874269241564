import {ActivatedRoute} from "@angular/router";
import {Component, OnInit, DoCheck} from "@angular/core";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {ExamService} from "../../services/exam_service/exam.service";
import {DatePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {isNullOrUndefined} from "util";

@Component({
    moduleId: module.id,
    selector: "exam-sync",
    host: {
        id: "exam-sync"
    },
    templateUrl: "exam-sync.component.html",
    styleUrls: ["exam-sync.component.css"]
})

export class ExamSyncComponent implements OnInit {
    private examHttpApi: ExamHttpApi;
    exams = [];
    areExamsShown: Array<boolean>;
    isLoading = false;
    pagination: any = {
        current_page: 1,
        last_page: 0,
    };

    constructor(private http: HttpClient,
                private authService: AuthService,
                private examService: ExamService,
                private datePipe: DatePipe) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();

    }

    ngOnInit() {
        this.getExamList();
        // console.log('OK');
    }

    openExamPlaces(index: number){
        this.areExamsShown[index] = !this.areExamsShown[index];
    }

    private setPage(type: string){
        if(this.isLoading){
            return;
        }

        this.pagination.current_page = type === 'pre' ? this.pagination.current_page - 1 : this.pagination.current_page + 1;
        this.pagination.current_page = this.pagination.current_page < 1 ? 1 : this.pagination.current_page;

        this.getExamList();
    }

    private getExamList(){
        this.isLoading = true;
        this.examHttpApi.getExamSyncPage(this.pagination.current_page).subscribe((results) => {
            this.exams = results.data;
            this.pagination.current_page = results.current_page;
            this.pagination.last_page = results.last_page;

            this.areExamsShown = this.exams.map(() => false);

            this.isLoading = false;
        });
    }

    setReloadExam(examId: number){
        if (!window.confirm("Biztos, benne?")) {
            return;
        }

        let elem = <HTMLFormElement>document.getElementById('isLoading-' + examId);

        if(elem.style.display === ''){
            elem.style.display = 'block';

            this.examHttpApi.setExamPlaceStatus(examId).subscribe((response:any) => {
                console.log('response', response);

                if(!isNullOrUndefined(elem)){
                    elem.style.display = 'none';
                }

                if(response.success === 1){
                    const examIndex = this.exams.findIndex(item => item.id === examId);

                    if(response.success === 1){
                        const count = this.exams[examIndex].exam_places.length;

                        for(let placeIndex=0;placeIndex<count;placeIndex++){
                            if(this.exams[examIndex].exam_places[placeIndex].status === 1){
                                this.exams[examIndex].exam_places[placeIndex].status = 2;
                            }
                        }
                    }
                }
            },
        err => {
                console.log(err);
                if(!isNullOrUndefined(elem)){
                    elem.style.display = ''; // none
                }
            });
        }
    }

    checkPlaces(exam){
        const check = exam.exam_places.map((place) => {
            return place.status === 1;
        });

        return !check.some((item => item === false));
    }
}
