<div class="writing-task-container">
    <div [innerHTML]="taskData.task_content"></div>
    <div class="paragraph-title row">
        <div class="pull-left">
            <strong i18n>Vizsgázó válasza:</strong>
        </div>
        <div class="col-md-2 col-md-offset-5">
            <strong><span i18n>Szavak száma</span>: {{countWords(answerData.values.text)}}</strong>
            <strong><span i18n>Kiválasztva:</span>
                <ng-container [ngSwitch]="answerData.selected">
                    <ng-container *ngSwitchCase="true" i18n>
                        Igen
                    </ng-container>
                    <ng-container *ngSwitchCase="false" i18n>
                        Nem
                    </ng-container>
                </ng-container>
            </strong>
        </div>
    </div>

    <div style="width: 1060px;border: 2px solid #444444;padding: 5px;" readonly [innerHTML]="answerData.values.text"></div>

    <section class="rating-container">
        <h5><strong i18n>Értékelés:</strong></h5>

        <form novalidate [formGroup]="reviewByCorrectorForm" (submit)="onSubmit($event)">
            <section>
                <h5 *ngIf="isAdmin"><strong i18n>Értékelő 1: </strong>{{corrector1}}</h5>
                <div>
                    <label for="problem_solving2">{{correctionOptions.iTOLC_problem_solving2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating; let i = index;">
                            <input id="problem_solving2" type="radio" [value]="rate"
                                   formControlName="iTOLC_problem_solving2">
                            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
                            <div>
                                <span *ngIf="i === 0" title="értékelhetetlen" i18n-title>{{rate}}</span>
                                <span *ngIf="i > 0">{{rate}}</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>

            <section>
                <div>
                    <label for="structure2">{{correctionOptions.iTOLC_structure2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating;">
                            <input id="structure2" type="radio" [value]="rate" formControlName="iTOLC_structure2">
                            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
                            <div>{{rate}}</div>
                        </li>
                    </ul>
                </div>
            </section>

            <section>
                <div>
                    <label for="grammar2">{{correctionOptions.iTOLC_grammar2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating;">
                            <input id="grammar2" type="radio" [value]="rate" formControlName="iTOLC_grammar2">
                            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
                            <div>{{rate}}</div>
                        </li>
                    </ul>
                </div>
            </section>

            <section>
                <div>
                    <label for="vocabulary2">{{correctionOptions.iTOLC_vocabulary2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating;">
                            <input id="vocabulary2" type="radio" [value]="rate" formControlName="iTOLC_vocabulary2">
                            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
                            <div>{{rate}}</div>
                        </li>
                    </ul>
                </div>
            </section>

            <div>
                <ng-container *ngIf="!isAdmin">
                    <h4><b i18n>Értékelő jegyzetek:</b></h4>
                    <textarea class="textarea-comment" [ngModelOptions]="{standalone: true}" [(ngModel)]="correctorCommentText" (ngModelChange)="valueChange()"></textarea>
                    <div>Még {{minChar}} karakter</div>
                </ng-container>
            </div>

            <div class="bg-success text-success saved-label"
                 [class.hidden]="!isSavingLabelVisible" i18n>
                Mentve {{currentTime}}
            </div>

            <div class="save-btn-container" *ngIf="!isAdmin">
                <button class="btn btn-warning" (click)="getDownloadFile()"><i class="fa fa-download"></i> Skála</button>
                <button class="btn btn-primary" [disabled]="!(reviewByCorrectorForm.valid && minChar==0)">Mentés</button>
            </div>
        </form>

        <form [formGroup]="reviewByCorrector2Form" *ngIf="isAdmin">
            <section>
                <h5><strong i18n>Értékelő 2: </strong>{{corrector2}}</h5>
                <div>
                    <label for="problem_solving2_2">{{correctionOptions.iTOLC_problem_solving2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating;">
                            <input id="problem_solving2_2" type="radio" [value]="rate"
                                   formControlName="iTOLC_problem_solving2">
                            <div class="div-to-disable-input"></div>
                            <div>{{rate}}</div>
                        </li>
                    </ul>
                </div>
            </section>

            <section>
                <div>
                    <label for="structure2_2">{{correctionOptions.iTOLC_structure2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating;">
                            <input id="structure2_2" type="radio" [value]="rate" formControlName="iTOLC_structure2">
                            <div class="div-to-disable-input"></div>
                            <div>{{rate}}</div>
                        </li>
                    </ul>
                </div>
            </section>

            <section>
                <div>
                    <label for="grammar2_2">{{correctionOptions.iTOLC_grammar2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating;">
                            <input id="grammar2_2" type="radio" [value]="rate" formControlName="iTOLC_grammar2">
                            <div class="div-to-disable-input"></div>
                            <div>{{rate}}</div>
                        </li>
                    </ul>
                </div>
            </section>

            <section>
                <div>
                    <label for="vocabulary2_2">{{correctionOptions.iTOLC_vocabulary2}}</label>
                    <ul>
                        <li *ngFor="let rate of rating;">
                            <input id="vocabulary2_2" type="radio" [value]="rate" formControlName="iTOLC_vocabulary2">
                            <div class="div-to-disable-input"></div>
                            <div>{{rate}}</div>
                        </li>
                    </ul>
                </div>
            </section>
        </form>

    </section>
</div>
<div>
    <ng-container *ngIf="isAdmin">
        <h4><b i18n>Értékelő jegyzetek:</b></h4>
        <ng-container *ngFor="let comment of correctorComments; let i = index">
            <p><b>{{ comment.user_name }}:</b> {{ comment.comment }}</p>
        </ng-container>
    </ng-container>
</div>
