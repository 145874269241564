import {Directive, ElementRef, Input, OnChanges, Renderer2} from "@angular/core";

@Directive({selector: "[custom-checkbox]"})
export class CustomCheckboxDirective implements OnChanges {
    @Input("custom-checkbox") customCheckboxValue: string;

    constructor(private elementRef: ElementRef,
                private renderer: Renderer2) {
    }

    ngOnChanges() {
        if (this.customCheckboxValue !== null) {
            this.renderer.setAttribute(this.elementRef.nativeElement, "checked", this.customCheckboxValue);
        }
    }
}
