
import {throwError as observableThrowError, Observable, throwError} from 'rxjs';
import {ErrorHandler, Inject, Injectable, Injector, NgZone} from "@angular/core";
import "rxjs/Rx";
import {isNullOrUndefined} from "util";
import {ToastrService} from "ngx-toastr";
import {HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {catchError} from "rxjs/operators";

/**
 * MyErrorHandler
 */
@Injectable()
export class MyHttpErrorHandler implements HttpInterceptor {

    constructor(private toastr: ToastrService) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        return next.handle(req).pipe(
            catchError((error) => {
                if (error.status === 422) {
                    if (error.message === "Cannot remove tasks.") {
                        this.toastr.error($localize`Valószínűleg már válaszokat tartalmaz.`, $localize`A vizsga már nem módosítható!`, {onActivateTick: true});
                    } else if (error.error && error.error[0] && error.error[0] === "invalid_email_or_password") {
                        this.toastr.error($localize`Ellenőrízze az adatokat, és próbálja újra.`, $localize`Hibás email cím vagy jelszó`, {onActivateTick: true});
                    } else if (error.error && error.error.message) {
                        this.toastr.error($localize`A kiszolgáló az alábbi választ küldte: ` + error.error.message, $localize`Feldolgozási hiba`, {onActivateTick: true});
                    } else {
                        this.toastr.error($localize`A kiszolgáló az alábbi választ küldte: ` + error.message, $localize`Feldolgozási hiba`, {onActivateTick: true});
                    }
                } else  if (error.status === 400 || error.status === 404) {
                    let msg = !isNullOrUndefined(error.error.message) ? error.error.message : 'hiba';
                    this.toastr.error($localize`A kiszolgáló az alábbi választ küldte: ` + msg, $localize`Hibás lekérés`, {onActivateTick: true});
                } else  if (error.status === 401) {
                    let msg = !isNullOrUndefined(error.error.message) ? error.error.message : 'Ismeretlen azonosítási hiba';
                    this.toastr.error($localize`Sikertelen azonosítás: ` + msg + "\nPróbálj újra bejelentkezni!", $localize`Azonosítási hiba`, {onActivateTick: true});
                } else  if (error.status === 500) {
                    let msg = !isNullOrUndefined(error.error.message) ? error.error.message : 'Ismeretlen hiba';
                    this.toastr.error($localize`A kiszolgáló az alábbi hibát jelezte: ` + msg, $localize`Szerveroldali hiba történt`, {onActivateTick: true});
                } else if (!error.ok && error.text) {
                    const body = error ? error : "";
                    this.toastr.error($localize`A lekérés közben hiba történt: ` + `${error.status} - ${error.statusText || ""} ${body}`, $localize`Hiba az adatok elküldésekor`, {onActivateTick: true});
                } else if (error) {
                    this.toastr.error($localize`Az adatok elküldésekor hiba történt: ` + JSON.stringify(error), $localize`Nem azonosított hiba`, {onActivateTick: true});
                } else {
                    this.toastr.error($localize`Ismeretlen hiba történt az adatok elküldésekor.`, $localize`Nem azonosított hiba`, {onActivateTick: true});
                }

                console.error(error);
                return throwError(error);
            })
        );
    }
}
