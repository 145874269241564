<section id="results-section">
    <div class="col-md-12">
        <div class="col-md-12 form-group">
            <button class="btn btn-success" (click)="resultCalculate()" [disabled]="examResults.finalized">Újraszámol</button>
            <button class="btn btn-danger" (click)="finalizedFacet()"   [disabled]="examResults.finalized">Véglegesítés</button>
        </div>
    </div>
    <div class="col-md-12 row">
        <div class="col-md-3 form-group">
            <label for="uScaleCorrection">uScaleCorrection:</label>
            <input type="number" id="uScaleCorrection" class="form-control" step=".1" [(ngModel)]="examResults.uScaleCorrection" [disabled]="examResults.finalized">
        </div>
        <div class="col-md-3 form-group">
            <label for="uMeanCorrection">uMeanCorrection:</label>
            <input type="number" id="uMeanCorrection" class="form-control" step=".1" [(ngModel)]="examResults.uMeanCorrection" [disabled]="examResults.finalized">
        </div>
    </div>
    <div class="col-md-12 row">
        <div class="col-md-3 form-group">
            <label for="howManyLogitsToThePoint">howManyLogitsToThePoint:</label>
            <input type="number" id="howManyLogitsToThePoint" class="form-control" step=".1" [(ngModel)]="examResults.howManyLogitsToThePoint" [disabled]="examResults.finalized">
        </div>
        <div class="col-md-3 form-group">
            <label for="finalCorrection">finalCorrection:</label>
            <input type="number" id="finalCorrection" class="form-control" step=".1" [(ngModel)]="examResults.finalCorrection" [disabled]="examResults.finalized">
        </div>
    </div>
    <div class="col-md-12">
        <div class="col-md-12">
            <ul>
                <li><b>minMeasure:</b> {{examResults.minMeasure}}</li>
                <li><b>maxMeasure:</b> {{examResults.maxMeasure}}</li>
                <li><b>Range:</b> {{examResults.range}}</li>
                <li><b>uScale:</b> {{examResults.uScale}}</li>
                <li><b>uMean:</b> {{examResults.uMean}}</li>
            </ul>
        </div>
    </div>
    <div class="col-md-12">
        <div class="col-md-12">
            <table class="table results-table" id="result-table">
                <thead>
                <tr>
                    <th>Vizsgázó ID</th>
                    <th>Facet ID</th>
                    <th>Logit ability</th>
                    <th>FairMavge/logitError</th>
                    <th>Scaled abilities</th>
                    <th>Scaled half of error</th>
                </tr>
                </thead>
                <tbody *ngIf="examResults.candidates.length > 0">
                <tr *ngFor="let candidate of examResults.candidates">
                    <td>{{candidate.candidateId}}</td>
                    <td>{{candidate.facetId}}</td>
                    <td>{{candidate.logit_ability}}</td>
                    <td>{{candidate.logit_error}}</td>
                    <td>{{candidate.scaled_abilities}}</td>
                    <td>{{candidate.scaled_half_of_error}}</td>
                </tr>
                <tr>
                    <td class="td-avg" colspan="2"><b>Avg:</b></td>
                    <td class="td-avg"><b>{{examResults.measureAvg}}</b></td>
                    <td class="td-avg"><b>{{examResults.logitErrorAvg}}</b></td>
                    <td class="td-avg"><b>{{examResults.scaledAbilitiesAvg}}</b></td>
                    <td class="td-avg"><b>{{examResults.scaledHalfOfErrorAvg}}</b></td>
                </tr>
                </tbody>
            </table>

            <a class="btn btn-small btn-block btn-default"
               (click)="downloadScaledAbilitiesTable()">
                Táblázat letöltése
            </a>
        </div>
    </div>
</section>
