import {Component, Input, OnChanges} from "@angular/core";
import {FormGroup} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {isNullOrUndefined} from "util";

@Component({
    selector: "app-registry-sheet",
    templateUrl: "./registry-sheet.component.html",
    styleUrls: ["./registry-sheet.component.css"]
})

export class RegistrySheetComponent implements OnChanges {
    @Input("form-group") parentFormGroup: FormGroup;

    constructor(private examCreatorFormService: ExamCreatorFormService) {
        this.examCreatorFormService.registryFormSend.subscribe((data) => {
            this.onValueChanged(data);
        });
    }

    ngOnChanges() {
        this.onValueChanged(this.parentFormGroup);
    }

    onRequiredRegistry(data: any) {
        if (data.controls.instruction.value === "" || isNullOrUndefined(data.controls.instruction.value)) {
            this.examCreatorFormService.requiredRegistryField.emit(false);
        } else {
            this.examCreatorFormService.requiredRegistryField.emit(true);
        }
    }

    onValueChanged(data?: any) {
        this.onRequiredRegistry(data);

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = "";
            const control = data.controls[field];
            if (control && control.valid === false) {
                this.formErrors[field] += $localize`:@@please-fill-in:Kérjük, töltse ki a mezőt!`;
            }
        }
    }

    formErrors = {
        instruction: ""
    };
}
