import {Component, OnInit} from "@angular/core";
import {FormGroup, FormControl, } from "@angular/forms";
import {Router, ActivatedRoute} from "@angular/router";
import {ICandidate} from "../../interfaces/candidate.interface";
import {CandidateHttpApi} from "../../http_apis/candidate.http";
import {isNullOrUndefined} from "util";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {IExamAnswer} from "../../interfaces/exam_answer.interface";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../services/api.service";

@Component({
    moduleId: module.id,
    host: {
        id: "create-candidate",
    },
    selector: "create-candidate",
    templateUrl: "candidates.component.html",
    styleUrls: ["candidates.component.css"]
})


export class CandidatesComponent implements OnInit {

    candidateId: number;
    candidateForm: FormGroup;
    submitButtonText: string;
    pageTitle: string;
    submitCheck: boolean;
    loader: boolean = true;
    private candidateHttpApi: CandidateHttpApi;
    private examHttpApi: ExamHttpApi;
    selectExamId: number;
    selectExamType: string;
    selectExamIndex: number;
    examList: Array<any>;
    candidateExams: Array<any>;

    constructor(private http: HttpClient,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private apiService: ApiService,
    ) {

        this.candidateHttpApi = new CandidateHttpApi(http, "data");
        this.candidateHttpApi.setDefaultUrl();

        this.examHttpApi = new ExamHttpApi(http, "data");
        this.examHttpApi.setDefaultUrl();

        this.candidateForm = new FormGroup({
            first_name: new FormControl(),
            last_name: new FormControl(),
            user_id: new FormControl(),
            email: new FormControl(),
            password: new FormControl(),
            address: new FormControl(),
            mothers_name: new FormControl(),
            iir_id: new FormControl(),
            chamber_id: new FormControl(),
            date_of_birth: new FormControl(),
            place_of_birth: new FormControl()
        });

        this.submitCheck = false;

        this.activatedRoute.data.subscribe(({title, saveButtonText}) => {
            this.submitButtonText = saveButtonText;
            this.pageTitle = title;
        });

        this.activatedRoute.params.subscribe(({id}) => {
            this.candidateId = id;

            this.candidateHttpApi.read(this.candidateId, undefined, true).subscribe((candidate: ICandidate) => {

                this.candidateForm.patchValue({
                    first_name: candidate['first_name'],
                    last_name: candidate['last_name'],
                    user_id: candidate['user_id'],
                    email: candidate['email'],
                    password: candidate['password'],
                    address: candidate['address'],
                    mothers_name: candidate['mothers_name'],
                    iir_id: candidate['iir_id'],
                    chamber_id: candidate['chamber_id'],
                    date_of_birth: candidate['date_of_birth'],
                    place_of_birth: candidate['place_of_birth'],
                });

                this.candidateExams =  candidate['exams'];
            });
        });

        if(this.candidateId){
            this.examHttpApi.setExamsToSelectUrl();
            this.examHttpApi.read(undefined, undefined, true).subscribe((results: Array<IExamAnswer>) => {
                this.examList = results;
                this.loader = false;
            });
        }
    }

    ngOnInit() {
        //
    }

    onSubmit(event: Event, {value}) {
        event.preventDefault();

        if (!this.candidateId) {
            this.candidateHttpApi.insert(value, true).subscribe((candidate: ICandidate) => {
                if(isNullOrUndefined(candidate['id'])){
                    alert('Email már szerepel a rendszerben');
                    return false;
                }

                this.candidateId = candidate['id'];

                this.candidateForm.patchValue({
                    first_name: candidate['first_name'],
                    last_name: candidate['last_name'],
                    user_id: candidate['user_id'],
                    email: candidate['email'],
                    password: candidate['password'],
                    address: candidate['address'],
                    mothers_name: candidate['mothers_name'],
                    iir_id: candidate['iir_id'],
                    chamber_id: candidate['chamber_id'],
                    date_of_birth: candidate['date_of_birth'],
                    place_of_birth: candidate['place_of_birth'],
                });

                this.submitCheck = true;
                this.router.navigateByUrl(`/candidates/edit/` + this.candidateId);
            });
        } else {
            this.candidateHttpApi.update(value, this.candidateId, true).subscribe((result) => {
                if(!isNullOrUndefined(result['result'])){
                    alert(result['result']);
                } else {
                    alert('Done');
                }
            });
        }
    }

    onFinished(){
        this.router.navigateByUrl(`/candidates/edit/` + this.candidateId);
    }

    onResetPassword(){
        if (this.candidateId) {
            this.candidateHttpApi.resetPassword(this.candidateId).subscribe((data) => {
                this.candidateForm.patchValue({
                    password: data['password'],
                });
            });
        }
    }

    onRemoveCandidate(){
        if (window.confirm("Biztos benne?")) {
            if (this.candidateId) {
                this.candidateHttpApi.removeCandidate(this.candidateId).subscribe(() => {
                    this.router.navigateByUrl(`/candidates/list`);
                });
            }
        }
    }

    removeFromExam(examIndex: number,candidateExamId: number){
        console.log(examIndex,candidateExamId);
        if (window.confirm("Biztos benne?")) {
            this.apiService.removeCandidateFromExam(candidateExamId).subscribe((data) => {
                if(!data.status){
                    alert(data.msg);
                } else {
                    alert($localize`Sikeres törlés`);
                    this.candidateExams.splice(examIndex, 1);
                }
            });
        }
    }

    onChangeExam(value){
        this.selectExamId = value;
    }

    onChangeExamType(value){
        this.selectExamType = value;
    }

    addExamToCandidate(){
        this.candidateHttpApi.addCandidateToExam(this.candidateId,this.selectExamId, this.selectExamType).subscribe((data) => {
            if(data.result){
                this.candidateExams = data.candidate.exams;
            } else {
                alert('Már hozzá van rendelve ehez a vizsgához');
            }
        });
    }
}
