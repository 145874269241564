<div class="move-answers">
    <div class="move-answers__container">


        <!-- STEP 0 Selected user -->
        <div class="move-answers__step"
             *ngIf="stepActive === 'selectedUsers'">
            <h2 class="move-answers__title" matDialogTitle i18n>Vizsga válaszok áthelyezése</h2>
            <div *ngIf="!loadingMoveExam">
                <mat-dialog-content>
                    <div class="" *ngIf="enableMoveExam">
                        <div class="row">
                            <div class="col-xs-12">
                                <p i18n>A kiválasztott forrás vizsgázó válaszait a rendszer áthelyezi a cél vizsgázó válaszai helyére, majd törli a forrás vizsgázó válaszait (így a forrás vizsgázónak nem lesz értékelhető teljesítménye a vizsgarészhez). Amennyiben a rendszer ütközést érzékel, tehát a cél vizsgázónak már van leadott válasza valamelyik áthelyezendő forrás válasszal, akkor a következő képernyőn eldönthető, hogy melyik válasz legyen megtartva.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-xs-10">
                                <mat-form-field appearance="fill" class="full-width">
                                    <mat-label i18n>Forrás</mat-label>
                                    <mat-select [(ngModel)]="stepSelectedUsers.selected.source">
                                        <mat-option value="{{item.id}}"
                                                    *ngFor="let item of stepSelectedUsers.options.source; let i = index ">
                                            {{item.last_name + ' ' +item.first_name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 col-xs-10">
                                <mat-form-field appearance="fill" class="full-width">
                                    <mat-label i18n>Cél</mat-label>
                                    <mat-select class="full-width" [(ngModel)]="stepSelectedUsers.selected.destination">
                                        <mat-option value="{{item.id}}"
                                                    *ngFor="let item of stepSelectedUsers.options.destination; let i = index ">
                                            {{item.last_name + ' ' +item.first_name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                    <!-- No results -->
                    <div class="row" *ngIf="!enableMoveExam">
                        <div class="col-xs-12 text-danger" i18n>
                            Nincs áthelyezhető válasz
                        </div>
                    </div>
                </mat-dialog-content>
                <mat-dialog-actions align="end">
                    <button mat-button matDialogClose i18n>{{enableMoveExam ? 'Mégse' : 'Ok'}}</button>
                    <button mat-button
                            *ngIf="enableMoveExam"
                            (click)="save()"
                            color="primary"
                            cdkFocusInitial
                            i18n>Válaszok áthelyezése
                    </button>
                </mat-dialog-actions>
            </div>

            <!-- Spinner -->
            <div class="" *ngIf="loadingMoveExam">
                <i class="fa fa-cog fa-spin fa-2x fa-fw"></i>
            </div>
        </div>


        <!-- STEP 1 Crash -->
        <div class="move-answers__step"
             *ngIf="stepActive === 'crash'">
            <h2 class="move-answers__title" matDialogTitle i18n>{{stepCrash.title}}</h2>
            <h3 class="move-answers__title" matDialogTitle i18n [innerHTML]="stepCrash.subtitle"></h3>
            <mat-dialog-content>
                <div class="row">
                    <div class="col-xs-12">
                        <p i18n>{{stepCrash.description}}</p>
                    </div>
                </div>
                <div class="row" *ngIf="stepCrash.checkboxIDs !== undefined">
                    <div *ngFor="let item of stepCrash.checkboxIDs, let i = index"
                         class="col-md-6">
                        <mat-checkbox class="example-margin" [(ngModel)]="stepCrash.checkboxIDs[i].isChecked">
                            {{item.value}}
                        </mat-checkbox>
                    </div>
                </div>

                <div class="row"
                     *ngIf="stepCrash.checkboxRecordFileIDs !== undefined && stepCrash.checkboxRecordFileIDs.length > 0">
                    <div class="col-xs-12">
                        <hr>
                        <h3><strong>Felvételek</strong></h3>
                    </div>
                    <div *ngFor="let item of stepCrash.checkboxRecordFileIDs, let i = index"
                         class="col-md-6">
                        <mat-checkbox class="example-margin" [(ngModel)]="stepCrash.checkboxRecordFileIDs[i].isChecked">
                            {{item.value}}
                        </mat-checkbox>
                    </div>
                </div>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <button mat-button color="secondary" (click)="checkboxAllSelect(true)" i18n>Összes kijelölése</button>
                <button mat-button color="secondary" (click)="checkboxAllSelect(false)" i18n>Összes kijelölése törlése
                </button>
                <button mat-button matDialogClose i18n>Mégse</button>
                <button mat-button (click)="saveWithCrashes()" color="primary" cdkFocusInitial i18n>Válaszok
                    áthelyezése
                </button>
            </mat-dialog-actions>
        </div>
    </div>


    <!-- STEP 2 Success -->
    <div class="move-answers__step"
         *ngIf="stepActive === 'stepSuccess'">
        <h2 class="move-answers__title" matDialogTitle i18n>Sikeres</h2>
        <mat-dialog-content>
            <div class="row">
                <div class="col-xs-12">
                    <p i18n>Áthelyeztük a válaszokat!</p>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose i18n>Bezár</button>
        </mat-dialog-actions>
    </div>

    <!-- STEP 2 Failed -->
    <div class="move-answers__step"
         *ngIf="stepActive === 'stepFailed'">
        <h2 class="move-answers__title" matDialogTitle i18n>Sikertelen</h2>
        <mat-dialog-content>
            <div class="row">
                <div class="col-xs-12">
                    <p i18n>Valami hiba történt! Próbáld meg újra!</p>
                </div>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
            <button mat-button matDialogClose i18n>Bezár</button>
        </mat-dialog-actions>
    </div>
</div>
