<aside></aside>
<div class="container">
<h3>{{pageTitle}}</h3>
<form novalidate [formGroup]="createExamForm" (submit)="onSubmit($event, createExamForm)">
    <fieldset [disabled]="examIsReady">
    <header>
        <div>
            <label i18n>
                Vizsga nyelve:
                <select class="form-control" formControlName="language">
                    <option *ngFor="let lang of languages"
                    [value]="lang.value">{{lang.text}}</option>
                </select>
            </label>
        </div>
        <div>
            <label i18n>
                Vizsgaszint:
                <select class="form-control" formControlName="level" >
                    <option *ngFor="let level of levels"
                    [value]="level">{{level}}</option>
                </select>
            </label>
        </div>
        <div>
            <label>
                <span  i18n>Típus:</span>
                <select class="form-control" formControlName="type" >
                    <option value="live" i18n="live">Éles</option>
                    <option value="retake" i18n="retake">Pótvizsga</option>
                    <option value="pilot" i18n="pilot">Pilot</option>
                    <option value="online" i18n="online">Online próbavizsga</option>
                    <option value="local" i18n="local">Helyi próbavizsga</option>
                    <option value="test" i18n="test">Teszt</option>
                </select>
            </label>
        </div>
        <div>
            <label i18n>
                Helyszín:
                <input type="text" class="form-control"
                formControlName="place"/>
            </label>
        </div>
        <div id="date-time-container">
            <label i18n>
                Időpont:
                <input type="text" class="form-control"
                id="date" (focus)="onFocusInput($event)"
                formControlName="date"/>
            </label>
            <div class="date-and-time-picker" *ngIf="isDatePickerVisible">
                    <datepicker
                        id="datepicker"
                        (selectionDone)="dateChange($event)"
                        [startingDay]="1">
                    </datepicker>
                    <div id="timepicker">
                        <label i18n>
                            Óra:&nbsp;
                            <input class="form-control"
                            type="number" min="0" max="23"
                            [value]="hourValue" (change)="onChangeTimePicker($event, 'hour')"/>
                        </label>
                        <label i18n>
                            Perc:&nbsp;
                            <input class="form-control"
                            type="number" min="0" max="59"
                            [value]="minuteValue" (change)="onChangeTimePicker($event, 'minute')"/>
                        </label>
                    </div>
                    <button type="button" class="btn btn-large btn-block btn-primary"
                    (click)="onCloseClickBtn()" i18n>Bezárás</button>
                </div>
        </div>
    </header>
    <div>
        <label i18n>
            Megjegyzés:
            <textarea type="text" class="form-control"
                      formControlName="comment" rows="3" cols="88">
                </textarea>
        </label>
    </div>
    <label class="form-group" i18n>
      Szint szűrés kikapcsolása<input type="checkbox" formControlName="anchoring"/>
    </label>
    </fieldset>

    <fieldset [disabled]="examIsReady || isDuplicate">
        <legend class="import-legend"><span i18n>Vizsgázók</span>  <a href="assets/csv/example_candidates_import.csv" i18n-title title="any.szám;email;név;vizsgahelyszín"><i class="fa fa-download"></i> <span i18n>Minta import CSV</span></a><small i18n>(any.szám;email;név;vizsgahelyszín)</small></legend>
        <ng-container *ngIf="examId">
            <div  class="candidate-export">
                <div class="button-group">
                        <button type="button" id="submit-test-user-btn" class="btn btn-large btn-block btn-info" (click)="exportTestCandidates()" [disabled]="disableButtons || examIsReady" i18n>Teszt userek generálása</button>
                        <button type="button" id="submit-clear-test-data-btn" class="btn btn-large btn-block btn-info" (click)="resetTestAnswers()" [disabled]="disableButtons || examIsReady" i18n>Teszt adatok törlése</button>
                </div>

                <div class="button-group">
                    <form class="form-inline">
                        <button type="button" class="btn btn-warning" (click)="candidateImport()" [disabled]="disableButtons || examIsReady" i18n>Import</button>
                        <input type="file"
                               class="form-control"
                               (change)="onChangeFile($event, 'file')"
                               id="upload">
                        <br/>
                    </form>
                </div>
            </div>
        </ng-container>

        <div class="col-md-12 error-msg" *ngIf="this.importError != null">
            <div class="alert alert-danger">
                {{this.importError}}
            </div>
        </div>
    </fieldset>

    <fieldset [disabled]="examIsReady">
        <legend i18n>Írásbeli</legend>
        <fieldset class="inside">
            <legend i18n>Íráskészség</legend>
            <ul [formGroup]="tasksFromGroup" *ngIf="tasksFromGroup.get('writing')['controls'].length > 0">
                <writing-selection
                    [hasExistData]="hasExistWritingData"
                    [numOfWritingTasks]="tasksFromGroup.get('writing')['controls'].length"
                    [writingFormArray]="tasksFromGroup.get('writing')"
                ></writing-selection>
            </ul>
            <button type="button" class="btn btn-large btn-block btn-default" (click)="onClickAddTask($event, 'writing')" [disabled]="disableButtons || examIsReady" i18n>Új feladat hozzáadása</button>
        </fieldset>
        <fieldset class="inside">
            <legend i18n>Olvasott szöveg értése</legend>
            <ul [formGroup]="tasksFromGroup" *ngIf="tasksFromGroup.get('reading')['controls'].length > 0">
                <li *ngFor="let readingControl of tasksFromGroup.get('reading')['controls']; let i = index" [formGroup]="readingControl">
                    <label *ngIf="readingControl.controls.suborder.value === null">
                        <span i18n>Feladat</span>:
                        <select class="form-control" [formControl]="readingControl.controls.task_id" >
                            <option [value]="null" i18n>Kérem válasszon!</option>
                            <option *ngFor="let readingTask of taskGroups.reading"
                                    [value]="readingTask.id"
                                    [ngClass]="{'green': readingTask.available === true, 'red': readingTask.available === false}">{{readingTask.id}} / {{readingTask.filename}}&nbsp;({{readingTask.name}})</option>
                        </select>
                    </label>
                    <button type="button" class="btn btn-sm btn-default" (click)="onClickRemoveBtn('reading', i)" i18n>Törlés</button>
                </li>
            </ul>
            <button type="button" class="btn btn-large btn-block btn-default"
            (click)="onClickAddTask($event, 'reading')" [disabled]="disableButtons || examIsReady" i18n>Új feladat hozzáadása</button>
        </fieldset>
    </fieldset>
    <fieldset [disabled]="examIsReady">
        <legend i18n>Szóbeli</legend>
        <fieldset class="inside">
            <legend i18n>Beszédkészség</legend>
            <ul [formGroup]="tasksFromGroup" *ngIf="tasksFromGroup.get('speaking')['controls'].length > 0">
                <li *ngFor="let speakingControl of tasksFromGroup.get('speaking')['controls']; let i = index" [formGroup]="speakingControl">
                    <label *ngIf="speakingControl.controls.suborder.value === null">
                        <span i18n>Feladat:</span>
                        <select class="form-control" [formControl]="speakingControl.controls.task_id" >
                            <option [value]="null" i18n>Kérem válasszon!</option>
                            <option *ngFor="let speakingTask of taskGroups.speaking"
                                    [value]="speakingTask.id"
                                    [ngClass]="{'green': speakingTask.available === true, 'red': speakingTask.available === false}">{{speakingTask.id}} / {{speakingTask.filename}}&nbsp;({{speakingTask.name}})</option>
                        </select>
                    </label>
                    <button type="button" class="btn btn-sm btn-default" (click)="onClickRemoveBtn('speaking', i)" i18n>Törlés</button>
                </li>
            </ul>
            <button type="button" class="btn btn-large btn-block btn-default"
            (click)="onClickAddTask($event, 'speaking')" [disabled]="disableButtons || examIsReady" i18n>Új feladat hozzáadása</button>
        </fieldset>
        <fieldset class="inside">
            <legend i18n>Hallott szöveg értése</legend>
            <ul [formGroup]="tasksFromGroup" *ngIf="tasksFromGroup.get('listening')['controls'].length > 0">
                <li *ngFor="let listeningControl of tasksFromGroup.get('listening')['controls']; let i = index" [formGroup]="listeningControl">
                    <label *ngIf="listeningControl.controls.suborder.value === null">
                        <span i18n>Feladat:</span>
                        <select class="form-control" [formControl]="listeningControl.controls.task_id" >
                            <option [value]="null" i18n>Kérem válasszon!</option>
                            <option *ngFor="let listeningTask of taskGroups.listening"
                                    [value]="listeningTask.id"
                                    [ngClass]="{'green': listeningTask.available === true, 'red': listeningTask.available === false}">{{listeningTask.id}} / {{listeningTask.filename}}&nbsp;({{listeningTask.name}})</option>
                        </select>
                    </label>
                    <button type="button" class="btn btn-sm btn-default" (click)="onClickRemoveBtn('listening', i)" i18n>Törlés</button>
                </li>
            </ul>
            <button type="button" class="btn btn-large btn-block btn-default"
            (click)="onClickAddTask($event, 'listening')" [disabled]="disableButtons || examIsReady" i18n>Új feladat hozzáadása</button>
        </fieldset>
    </fieldset>
    <div class="row">
        <div class="col-md-6">
            <a class="btn btn-large btn-default" href="#" role="button" routerLink="/landing/exams" i18n>Vissza</a>
            <button type="submit" id="submit-btn" class="btn btn-large btn-primary" [disabled]="disableButtons || examIsReady">{{submitButtonText}}</button>

            <ng-container *ngIf="!isDuplicate && editForm">
                <button type="button" id="submit-duplicate-btn" class="btn btn-large btn-warning" (click)="duplicateExam()" [disabled]="disableButtons" i18n>Duplikálás</button>
            </ng-container>
        </div>
        <div class="col-md-6 text-right">
            <ng-container *ngIf="!isDuplicate && editForm && !examIsReady">
                <button type="button" id="submit-done-btn" class="btn btn-large btn-danger" (click)="examReady()" [disabled]="disableButtons || examIsReady" i18n>Véglegesítés</button>
            </ng-container>
        </div>
    </div>
    <div class="alert alert-success" *ngIf="this.examCreatorFormService.submittedResult != null && this.examId != null">
        {{this.examCreatorFormService.submittedResult}}
    </div>
</form>
</div>
