<h3 i18n>Szöveg kiegészítés</h3>
<form novalidate [formGroup]="completion" (submit)="onSubmit($event)">
    <app-registry-sheet [form-group]="mainForm"></app-registry-sheet>
    <div class="row">
        <div class="col-xs-12 form-group" *ngIf="hasUploadField">
            <label  i18n>
                Válassza ki a feladathoz szükséges forrásanyagot(videó, kép):
                <input type="file" class="form-control"
                       (change)="fileUploadService.onChangeInput($event, 'file');">
            </label>
            <div *ngIf="taskData && taskData.file_url != '' && taskData.file_url != null">
                <strong>{{taskData.file_url | filenameConverter}}</strong>
                <button class="btn btn-danger" (click)="removeTaskFile()" i18n>Fájl törlése</button>
                <a class="btn btn-danger" target="_blank" href="{{prefixUrl}}{{taskData.file_url}}?token={{token}}" i18n>Előnézet</a>
                <video-converter [filename]="taskData.file_url" (newVideoEvent)="updateVideoData($event)"></video-converter>
            </div>
        </div>
        <div class="col-xs-12"><label i18n="@@copy-source-text-here">Másolja be a feladathoz tartozó forrásszöveget ide:</label></div>
        <div class="col-xs-12 form-group">
            <app-tinymce class="app-tinymce" [formControl]="sourceControl" [required]="sourceRequired"></app-tinymce>
            <div *ngIf="formErrors.source_control" class="alert alert-danger">
                {{ formErrors.source_control }}
            </div>
        </div>
        <div class="col-xs-12"><label i18n>Feladathoz tartozó szöveg (kapcsos zárójelben a behelyettesítendő
            szavakkal):</label></div>
        <div class="col-xs-12 form-group">
            <app-tinymce class="app-tinymce" [formControl]="taskTextControl" [required]="isFormValid"></app-tinymce>
            <div *ngIf="formErrors.task_text_control" class="alert alert-danger">
                {{ formErrors.task_text_control }}
            </div>
        </div>

        <div class="col-xs-12 form-group">
            <key-manager
                *ngIf="taskId && !taskCheckbox.value"
                [input]="keyManagerInputData"
                [loading]="keyManagerIsLoading"
                (changed)="updateCompletionItems($event)"
            ></key-manager>
        </div>

        <div class="col-xs-12"><label i18n>Disztraktor szavak (megjelennek, ha a lehetőségek látszanak. Soronként egy
            darab.)</label></div>
        <div class="col-xs-12 form-group">
            <textarea class="form-control" [formControl]="taskWords"
                      (change)="watchTyping($event)"></textarea>
            <input type="hidden" [formControl]="taskWordsJSON">
        </div>
    </div>
    <div class="col-xs-12 form-group">
        <label>
            <input [checked]="taskCheckbox.value" type="checkbox" (change)="onChecked($event, 'taskCheckbox')" ><span i18n>Lehetőségek mutatása (megoldások behúzása drag-n-drop segítségével)</span>
        </label>
    </div>
    <ng-container [ngSwitch]="isDuplicate">
        <ng-container *ngSwitchCase="false">
            <button type="submit" class="btn btn-primary" [disabled]="!isFormValid || !isInputFieldRequired" i18n>Mentés</button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <button type="submit" class="btn btn-warning" [disabled]="!isFormValid || !isInputFieldRequired" i18n>Duplikálás mentése</button>
        </ng-container>
    </ng-container>
    <button type="button" class="btn btn-success" (click)="onPreview()" i18n>Előnézet</button>
    <div class="alert alert-success"
         *ngIf="this.examCreatorFormService.submittedResult != null && this.taskId != null">
        {{this.examCreatorFormService.submittedResult}}
    </div>
</form>
