<div id="export-page">
    <aside></aside>
    <div class="container">
        <h2>Exportok</h2>

        <form class="form-inline">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Dátum</label>
                        <div class="input-group">
                            <input type="text" class="form-control" name="startDate" [(ngModel)]="startDate" placeholder="2019-01-01">
                            <div class="input-group-addon">-tól</div>
                        </div>
                        <div class="input-group">
                            <input type="text" class="form-control" name="endDate" [(ngModel)]="endDate" placeholder="2019-05-05">
                            <div class="input-group-addon">-ig</div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="environmentService.get('examType') === 'itolc'"
                 class="row">
                <div class="col-md-12 bottom-group">
                    <div class="form-group">
                        <label>A vizsga dátumára vonatkozik a szűrés</label><br/>
                        <button class="btn btn-info" (click)="downloadExcel('candidatePoints')"><i *ngIf="isLoading['candidatePoints']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Vizsgázói pontok</button>
                    </div>
                </div>
                <div class="col-md-12 bottom-group">
                    <div class="form-group">
                        <label>Itt a hozzárendelés dátumára vonatkozik a szűrés</label><br/>
                        <button class="btn btn-info" (click)="downloadExcel('examinerPoints')"><i *ngIf="isLoading['examinerPoints']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Vizsgáztató mit javított</button>
                    </div>
                </div>
                <div class="col-md-12 bottom-group">
                    <div class="form-group">
                        <label>Itt a hozzárendelés dátumára vonatkozik a szűrés</label><br/>
                        <button class="btn btn-info" (click)="downloadExcel('productivePoints')"><i *ngIf="isLoading['productivePoints']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Vizsgáztató produktív pontszámok</button>
                    </div>
                </div>
                <div class="col-md-12 bottom-group">
                    <div class="form-group">
                        <label>Bejegyzés dátuma</label><br/>
                        <button class="btn btn-info" (click)="downloadExcel('diariesExport')"><i *ngIf="isLoading['diariesExport']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Naplók</button>
                    </div>
                </div>
                <div class="col-md-12 bottom-group">
                    <div class="form-group">
                        <label>Vizsga dátuma</label><br/>
                        <button class="btn btn-info" (click)="downloadExcel('examCommentExport')"><i *ngIf="isLoading['diariesExport']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Vizsga megjegyzések</button>
                    </div>
                </div>
                <div class="col-md-12 bottom-group">
                    <div class="form-group">
                        <label>Vizsga dátuma</label><br/>
                        <button class="btn btn-info" (click)="downloadExcel('examinerCommentExport')"><i *ngIf="isLoading['diariesExport']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Vizsgázatatói kommentek</button>
                    </div>
                </div>
            </div>

            <div *ngIf="environmentService.get('examType') === 'mmk'"
                 class="row">
                <div class="col-md-12 bottom-group">
                    <div class="form-group">
                        <label>Vizsgázó eredmények exportálása</label><br/>
                        <button class="btn btn-info" (click)="downloadExcel('mmkExamResultExport')"><i *ngIf="isLoading['mmkExamResultExport']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Letöltés</button>
                    </div>
                </div>
            </div>
        </form>

        <form class="form-inline">
            <div  *ngIf="environmentService.get('examType') === 'itolc'"
                  class="row">
                <h2>Vizsgákra vonatkozó exportok</h2>
                <div class="col-md-12">
                    <label>Viszga</label>
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="input-group">
                                <select [(ngModel)]="examID" name="exam" class="form-control">
                                    <option value="NULL" selected>-</option>
                                    <option *ngFor="let exam of exams" [value]="exam.id">
                                        &nbsp;{{exam.id}} {{exam.language }} {{exam.level}} - {{ exam.date }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 bottom-group">
                        <div class="form-group">
                            <button class="btn btn-info" (click)="downloadExcel('completeExamExport')"><i *ngIf="isLoading['completeExamExport']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Teljes Vizsga Export</button>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-info" (click)="downloadExcel('diariesExportOnlyExam')"><i *ngIf="isLoading['diariesExportOnlyExam']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Napló Export</button>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-info" (click)="downloadExcel('exportCandidateNotCorrection')"><i *ngIf="isLoading['exportCandidateNotCorrection']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Értékelésre nem jelöltek exportálása</button>
                        </div>
                        <div class="form-group">
                            <button class="btn btn-info" (click)="downloadDecisionList()"><i *ngIf="isLoading['decisionList']" class="fa fa-cog fa-spin fa-2x fa-fw"></i> Döntési lista (értékelésre küldés)</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

