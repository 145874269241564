<div>
    <div class="corrector-lg-container" [innerHTML]="task.task_data.sourceControl"></div>
    <div *ngFor="let itemData of task.task_data.itemData; let i = index">
        <div *ngIf="itemData.hasMediaFile" class="media-file-container">
            <audio *ngIf="itemData.isMp3" controls>
                <source src="{{prefixUrl}}{{itemData.file_url}}?token={{token}}" type="audio/mp3"/>
            </audio>
            <video *ngIf="!itemData.isMp3" controls>
                <source src="{{prefixUrl}}{{itemData.file_url}}?token={{token}}" type="video/mp4"/>
            </video>
        </div>
    </div>

    <ul *ngFor="let question of questions;">
        <div>
            <h4 *ngIf="question.multipleGroupName !== null">
                <strong>{{question.multipleGroupName}}</strong>
            </h4>
        </div>
        <li *ngFor="let item of question.multipleQuestions; let j = index">
            <ng-container *ngIf="areQuestionsAnArray">
                <div>
                    <!--example-->
                    <span *ngIf="item.number === 0 && !hasTwoExamples">0 item number: {{item.number}}</span>
                    <span *ngIf="item.number === 0 && hasTwoExamples">01 item number: {{item.number}}</span>
                    <span *ngIf="item.number === 1 && hasTwoExamples">02 item number: {{item.number}}</span>
                    <!---->
                    <span *ngIf="item.number > 0 && !hasTwoExamples">{{item.number}}/{{length - 1}}</span>
                    <span *ngIf="item.number > 1 && hasTwoExamples">{{(item.number) - 1}}/{{length - 2}}</span>
                    <strong>{{item.multipleQuestion}}</strong>
                </div>

                <div class="radio-btn-container">
                    <div class="radio"
                         *ngFor="let answer of item.answers; let j = index">

                        <label class="item"
                               [class.correct]="item.exam_answer !== null &&
                                    item.exam_answer.length > 0 &&
                                    item.exam_answer[0].correct === 1 &&
                                    correctAnswerIndexes[item.number] === j"
                               [class.incorrect]="item.exam_answer !== null &&
                                      item.exam_answer.length > 0 &&
                                      item.exam_answer[0].correct === 0 &&
                                      item.exam_answer[0].answer_data.answers[0] === j ||
                                      item.exam_answer === null && item.number !== 0 && !hasTwoExamples ||
                                      item.exam_answer === null && item.number !== 0 && item.number !== 1 && hasTwoExamples"
                               [class.example]="item.number === 0 && correctAnswerIndexes[item.number] === j && !hasTwoExamples ||
                                                item.number === 0 && correctAnswerIndexes[item.number] === j && hasTwoExamples ||
                                                item.number === 1 && correctAnswerIndexes[item.number] === j && hasTwoExamples"
                               [class.strong]="item.exam_answer !== null &&
                                   item.exam_answer.length > 0 &&
                                   correctAnswerIndexes[item.number] === j">

                            <!--examples-->
                            <input *ngIf="item.exam_answer === null"
                                   id="question-example-{{item.number}}"
                                   type="radio"
                                   name="question-example-{{item.number}}"
                                   [attr.checked]="answer.multipleAnswerRadio && item.number === 0 && hasTwoExamples ? true : null ||
                                                   answer.multipleAnswerRadio && item.number === 1 && hasTwoExamples ? true : null ||
                                                   answer.multipleAnswerRadio && item.number === 0 && !hasTwoExamples ? true : null"><label for="question-example-{{item.number}}"></label>
                            <!---->

                            <input *ngIf="item.exam_answer !== null && item.exam_answer.length > 0"
                                   id="question-{{item.number}}"
                                   type="radio"
                                   name="question-{{item.number}}"
                                   [attr.checked]="(item.exam_answer.length > 0 && item.exam_answer[0].answer_data && (item.exam_answer[0].answer_data.answers[0] === j)) ? true : null"><label for="question-{{item.number}}"></label>
                            {{answer.multipleAnswerText}}
                        </label>

                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!areQuestionsAnArray">
                <div>
                    <!--example-->
                    <span *ngIf="item.number === 0">0</span>
                    <!---->
                    <span *ngIf="item.number > 0">{{item.number}}/{{task.items.length - 1}}</span>
                    <strong>{{item.item_data.questions.multipleQuestion}}</strong>
                </div>

                <div class="radio-btn-container">
                    <div class="radio"
                         *ngFor="let answer of item.item_data.questions.answers; let j = index">

                        <label class="item"
                               [class.correct]="item.exam_answer !== null &&
                                    item.exam_answer.length > 0 &&
                                    item.exam_answer[0].correct === 1 &&
                                    correctAnswerIndexes[item.number] === j"
                               [class.incorrect]="item.exam_answer !== null &&
                                      item.exam_answer.length > 0 &&
                                      item.exam_answer[0].correct === 0 &&
                                      item.exam_answer[0].answer_data.answers[0] === j ||
                                      item.exam_answer === null && item.number !== 0"
                               [class.example]="item.number === 0 && correctAnswerIndexes[item.number] === j"
                               [class.strong]="item.exam_answer !== null &&
                                   item.exam_answer.length > 0 &&
                                   correctAnswerIndexes[item.number] === j">

                            <!--example-->
                            <input *ngIf="item.exam_answer === null"
                                   id="question-example"
                                   type="radio"
                                   name="question-example"
                                   [attr.checked]="answer.multipleAnswerRadio && item.number === 0 ? true : null"><label for="question-example"></label>
                            <!---->

                            <input *ngIf="item.exam_answer !== null && item.exam_answer.length > 0"
                                   id="question-{{item.number}}"
                                   type="radio"
                                   name="question-{{item.number}}"
                                   [attr.checked]="item.exam_answer[0].answer_data[0].answers[0] === j ? true : null"><label for="question-{{item.number}}"></label>
                            {{answer.multipleAnswerText}}
                        </label>

                    </div>
                </div>
            </ng-container>

        </li>
    </ul>

</div>
