import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "filenameConverter"
})

export class FilenameConverter implements PipeTransform {

  transform(value: string): any {
    if (value !== null) {
      value = value.split("/").pop();

      return `${value}`;
    } else {
      return value;
    }
    
  }

}
