<div class="session-list">
    <h2>Ütemezett szóbeli vizsgák</h2>
    <p *ngIf="sessions.length === 0">Még nincs ütemezve egy szóbeli vizsga sem ehhez a vizsgához.</p>
    <table *ngIf="sessions.length > 0" class="table">
        <thead>
            <tr>
                <th>Időpont</th>
                <th>Helyszín</th>
                <th>Felhasználó azonosító</th>
                <th>Keresztnév</th>
                <th>Vezetéknév</th>
                <th>Státusz</th>
                <th>Százalék</th>
                <th>Pontszám</th>
                <th>Eredmény</th>
                <th>Művelet</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let session of sessions; let i = index">
                <td>{{session.live}}</td>
                <td>{{session.live_exam}}</td>
                <td>{{session.candidate.user_id}}</td>
                <td>{{session.candidate.first_name}}</td>
                <td>{{session.candidate.last_name}}</td>
                <td>
                    <span *ngIf="!session.status || session.status === null" i18n>Ismeretlen</span>
                    <span *ngIf="session.status === 'waiting_live'" i18n>Tervezett</span>
                    <span *ngIf="session.status === 'live'" i18n>Folyamatban</span>
                    <span *ngIf="session.status === 'live_paused'" i18n>Szüneteltetve</span>
                    <span *ngIf="['result', 'finished', 'archived'].includes(session.status)" i18n>Lezárva</span>
                </td>
                <td>{{session.result_percent}}%</td>
                <td>{{session.result_point}}p</td>
                <td>
                    <final-result *ngIf="session" [candidate_id]="session.candidate.id" [(result)]="session.detailed_results"></final-result>
                </td>
                <td>
                    <span (click)="startLiveSession(session)" class="clickable" *ngIf="session.improved === 0 && (session.result_success === 1 || session.result_success === null)">
                        <b *ngIf="session.status !== 'live'">Szóbeli vizsga indítása</b>
                        <b *ngIf="session.status === 'live'">Csatlakozás a szóbeli vizsgához</b>
                    </span>
                    <span (click)="changeLiveSession(session)" class="clickable" *ngIf="session.improved === 0 && (session.result_success === 1 || session.result_success === null)"> | <b>Időpont módosítás</b></span>
                </td>
            </tr>
        </tbody>
    </table>

   <ng-template #liveSessionDateDialog>
      <h2 matDialogTitle>A szóbeli vizsga kezdési idejének módosítása</h2>
      <mat-dialog-content>
          <p>A {{sessionEdit.candidate.user_id}} felhasználóhoz tartozó vizsga időpont módosítása az alábbi mezővel lehetséges.
          <br>Kérjük ügyeljen a vizsgák ütközösének elkerülésére.</p>
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="picker" placeholder="A szóbeli vizsga kezdeti ideje" [(ngModel)]="sessionEdit.live">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
            </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary">Mégse</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Mentés</button>
      </mat-dialog-actions>
    </ng-template>
</div>
