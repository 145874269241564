export const CORRECTION_OPTIONS_WRITING = {
    iTOLC_problem_solving2: $localize`iTOLC Feladatmegoldás`,
    iTOLC_structure2: $localize`iTOLC Szerkezet`,
    iTOLC_grammar2: $localize`iTOLC Nyelvhelyesség`,
    iTOLC_vocabulary2: $localize`iTOLC Szókincs`,
};

export const CORRECTION_OPTIONS_SPEAKING_MONOLOG = {
    iTOLC_problem_solving2: $localize`iTOLC Feladatmegoldás`,
    iTOLC_structure2: $localize`iTOLC Szerkezet`,
    iTOLC_grammar2: $localize`iTOLC Nyelvhelyesség`,
    iTOLC_vocabulary2: $localize`iTOLC Szókincs`,
    iTOLC_speaking2: $localize`iTOLC Beszédmód`,
};

export const CORRECTION_OPTIONS_SPEAKING = {
    iTOLC_problem_solving2: $localize`iTOLC Feladatmegoldás`,
    iTOLC_grammar2: $localize`iTOLC Nyelvhelyesség`,
    iTOLC_vocabulary2: $localize`iTOLC Szókincs`,
    iTOLC_speaking2: $localize`iTOLC Beszédmód`,
};
