import {ActivatedRoute, Router} from "@angular/router";
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {FormControl, FormGroup} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {AuthService} from "../../services/auth.service";
import {MMK_TASK_TYPES, TASK_TYPES} from "../../services/shared/task_configs";
import {isNullOrUndefined} from "util";
import {TaskHttpApi} from "../../http_apis/task.http";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {EnvironmentService} from "../../services/environment.service";

export const LANGUAGES = [{
    value: "English",
    text: $localize`Angol`
}, {
    value: "German",
    text: $localize`Német`
}];

export const LEVELS = ["A2", "B1", "B2", "C1"];

const EXAM_TYPES = [{
    value: "writing",
    text: $localize`Íráskészség (Írásbeli)`
}, {
    value: "reading",
    text: $localize`Írott szöveg értése (Írásbeli)`
}, {
    value: "speaking",
    text: $localize`Beszédkészség (Szóbeli)`
}, {
    value: "listening",
    text: $localize`Hallott szöveg értése (Szóbeli)`
}];

const TEXT_TYPES = [{
    value: "original",
    text: $localize`Eredeti`
}, {
    value: "shortened",
    text: $localize`Rövidített`
}, {
    value: "extended",
    text: $localize`Kiegészített`
}];

const TEXT_BREED_TYPES = [{
    value: "functional",
    text: $localize`Funkcionális`
}, {
    value: "informative",
    text: $localize`Informatív`
}, {
    value: "narrative",
    text: $localize`Narratív`
}];

@Component({
    selector: "app-root",
    moduleId: module.id,
    templateUrl: "./app.form.html",
    styleUrls: ["app.form.css"]
})
export class FormComponent implements OnDestroy, OnInit {
    @ViewChild("fakeSubmit") fakeSubmit: ElementRef;

    private subscription;
    private isAdminStatus: boolean;
    private currentTaskUrl: string;
    examGroup: FormGroup;
    examCreatorForm: FormGroup;
    areFieldsRequired: boolean;
    levelOptions = LEVELS;
    textBreedTypeOptions = TEXT_BREED_TYPES;
    textFormatCodeOptions: Array<any>;
    textTextSourceOptions: Array<any>;
    textTypeOptions = TEXT_TYPES;
    examTypeOptions = EXAM_TYPES;
    taskTypeOptions: any;
    langOptions = LANGUAGES;
    taskNumberOptions: Array<any>;
    dateValue: string;
    comment: string;
    hourValue: number = 8;
    minuteValue: number = 0;
    showDateTimePicker: boolean = false;
    isDuplicate: boolean = false;
    taskHttpApi: TaskHttpApi;
    taskFormColumns: any;
    examSchemes: any;
    section: number;
    isFilenameExists:boolean;

    constructor(private router: Router,
                private activatedRoute: ActivatedRoute,
                private examCreatorFormService: ExamCreatorFormService,
                private http: HttpClient,
                public environmentService: EnvironmentService,
                private authService: AuthService) {

        this.taskTypeOptions = environment.examType === 'mmk' ? MMK_TASK_TYPES : TASK_TYPES;

        this.taskFormColumns = environment.taskFormColumns;
        this.taskHttpApi = new TaskHttpApi(http);
        this.taskHttpApi.setDefaultUrl();

        this.examCreatorForm = this.examCreatorFormService.$examCreatorFrom;
        this.examGroup = this.examCreatorFormService.$examGroup;
        this.isDuplicate = this.examCreatorFormService.$isDuplicate;

        this.examCreatorFormService.generatedTaskNumber.subscribe((options) => {
            // Do nothing if we would apply the same...
            if (this.taskNumberOptions && this.taskNumberOptions.length === options.length) {
                // This is necessary, as we would lose the frontend update by overwriting the same numbers.
                return;
            }
            this.taskNumberOptions = options;
        });


        this.taskHttpApi.getFormatCodes().subscribe((options) => {
            this.textFormatCodeOptions = options;
        });

        this.taskHttpApi.getTextSources().subscribe((options) => {
            this.textTextSourceOptions = options;
        });

        this.examCreatorFormService.selectedCurrentTaskForm.subscribe((routeUrl) => {
            console.log('examCreatorFormService.selectedCurrentTaskForm', routeUrl);
            this.navigateToCurrentTaskForm(routeUrl);
        });

        this.examCreatorFormService.createdTask.subscribe((id) => {
            this.examCreatorFormService.resetForm();

            this.currentTaskUrl = this.currentTaskUrl.replace(/\d+/g, id);
            this.currentTaskUrl = this.currentTaskUrl.replace('duplicate', '');
            this.examCreatorFormService.$isDuplicate = this.isDuplicate;

            this.router.navigate([this.currentTaskUrl, id], {
                relativeTo: this.activatedRoute
            });
        });

        this.router.events.subscribe((event) => {
            if (event.constructor.name === "NavigationEnd" && this.router.url === "/exam-task-creator") {
                this.examCreatorFormService.resetForm();
            }
        });

        this.subscription = this.examCreatorFormService.open.subscribe(() => {
            this.fakeSubmit.nativeElement.click();
        });

        this.examGroup.controls.part.valueChanges.subscribe(() => {
            this.areFieldsRequired = this.examGroup.controls.part.value !== "writing" || this.examGroup.controls.part.value !== "speaking";
        });

        this.taskHttpApi.getSimplifiedList().subscribe((results) => {
            this.examSchemes = results;
        });
    }

    private navigateToCurrentTaskForm(routeUrl) {
        this.currentTaskUrl = routeUrl;

        this.router.navigate([routeUrl], {
            relativeTo: this.activatedRoute
        });
    }

    ngOnInit() {
        this.dateChange(new Date());
        this.showDateTimePicker = false;

        setTimeout(() => {
            if(environment.examType === 'mmk'){
                this.examCreatorFormService.setPartControl('reading');
                console.log('examGroup.controls.part.value: ', this.examGroup.controls.part.value);
            }
        }, 1500);
    }

    ngOnDestroy() {
        this.examCreatorFormService.resetForm();
        this.examCreatorForm.removeControl("items");
        this.subscription.unsubscribe();
        this.examCreatorFormService.$isDuplicate = false;
    }

    dateChange(event: Date) {
        event = new Date(event.getTime() + 24 * 60 * 60 * 1000);
        this.dateValue = event.toISOString().split("T")[0];
        this.dateUpdate();
    }

    onChangeTimePicker(event: Event, type: string) {
        const inputEl = <HTMLInputElement> event.target;

        if (type === "hour") {
            this.hourValue = parseInt(inputEl.value);
        } else {
            this.minuteValue = parseInt(inputEl.value);
        }

        this.dateUpdate();
    }

    private dateUpdate() {
        const hourString = (this.hourValue < 10) ? `0${this.hourValue}` : this.hourValue;
        const minuteString = (this.minuteValue < 10) ? `0${this.minuteValue}` : this.minuteValue;
        const dateTimeStringValue = `${this.dateValue} ${hourString}:${minuteString}:00`;

        this.examCreatorForm.patchValue({
            view_date: dateTimeStringValue
        });
    }

    onCloseClickBtn() {
        this.showDateTimePicker = false;
    }

    onFocusInput(event: Event) {
        const inputEl = <HTMLInputElement> event.target;
        this.showDateTimePicker = inputEl.id === "check-date";
    }

    checkUniqueFilename (event: Event) {
        const inputEl = <HTMLInputElement> event.target;
        this.taskHttpApi.getFilenameExistCheck(inputEl.value,this.examCreatorFormService.taskId).subscribe((results) => {
            this.isFilenameExists = results;
            this.examCreatorForm.controls["filename"].setErrors((results ? {"incorrect": true} : null));
        });
    }
}
