import {Component} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {FileUploadService} from "../../services/shared/upload.service";
import {ActivatedRoute} from "@angular/router";
import {IExamTask} from "../../interfaces/exam_task.interface";
import {environment} from "../../../environments/environment";
import {isNullOrUndefined} from "util";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";

@Component({
    moduleId: module.id,
    selector: "app-copywriting",
    templateUrl: "./copywriting.component.html",
    styleUrls: ["./copywriting.component.css"]
})
export class CopywritingComponent {

    copyWriterForm: FormGroup;
    mainForm: FormGroup;
    taskControl: FormControl = new FormControl(null, Validators.required);
    requieredRegSheetForm: boolean;
    taskId: number;
    taskData: any;
    private prefixUrl: string;
    private token: string;
    private isFormValid: boolean = true;
    isDuplicate: boolean = false;

    constructor(private formBuilder: FormBuilder,
                public examCreatorFormService: ExamCreatorFormService,
                public fileUploadService: FileUploadService,
                private activatedRoute: ActivatedRoute) {

        this.mainForm = this.examCreatorFormService.$examCreatorFrom;
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.copyWriterForm = formBuilder.group({
            description: new FormControl(null),
            task_content: this.taskControl,
            file_url: new FormControl(null)
        });

        this.fileUploadService.uploadedFile.subscribe(({url}) => {
            this.copyWriterForm.patchValue({
                file_url: url
            });
        });

        this.activatedRoute.params.subscribe(({id, duplicate}) => {
            if(!isNullOrUndefined(duplicate)){
                if(duplicate === 'duplicate'){
                    this.isDuplicate = true;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                } else {
                    this.isDuplicate = false;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                }
            }

            this.taskId = id;

            let taskValues = this.examCreatorFormService.getTaskDataValue();

            if (taskValues !== null) {
                this.copyWriterForm.patchValue(taskValues);
            }

            if (this.taskId) {
                this.examCreatorFormService.taskHttpApi.read(id, "data", true).subscribe((examTask: IExamTask) => {

                    this.taskData = JSON.parse(examTask.task_data);
                    examCreatorFormService.previousNumber = examTask.number;

                    this.copyWriterForm.patchValue(this.taskData);

                    this.examCreatorFormService.setExamObject(examTask);
                    this.examCreatorFormService.setExistInstructionControl(examTask.instruction);

                    if(isNotNullOrUndefined(examTask.view_date)){
                        examTask.view_date = examTask.view_date.replace(/:[0-9]+$/, "");
                    }

                    this.mainForm.patchValue(examTask);
                    if (this.mainForm.controls["topic"].value === null) {
                        this.mainForm.patchValue({
                            topic: this.copyWriterForm.controls["description"].value
                        });
                    }
                });
            }

            this.mainForm.controls["topic"].valueChanges.subscribe(() => this.onValueChanged());

            if (!id) {
                this.examCreatorFormService.backToMainForm();
            }

            this.mainForm.patchValue({item_count: 1}, {emitEvent: false});
        });

        this.examCreatorFormService.requiredRegistryField.subscribe((data) => {
            if (isNullOrUndefined(data)) {
                this.requieredRegSheetForm = true;
            }
            this.requieredRegSheetForm = data;
        });

        this.mainForm.valueChanges.subscribe(() => {
            this.requieredRegSheetForm = this.mainForm.controls.instruction.value !== "" ||
                                         isNullOrUndefined(this.mainForm.controls.instruction.value);
            this.examCreatorFormService.registryFormSend.emit(this.mainForm);
        });

        this.copyWriterForm.controls["task_content"].valueChanges.subscribe(() => this.onValueChanged());
    }

    removeTaskFile() {
        this.taskData["file_url"] = null;
        this.copyWriterForm.patchValue(this.taskData);
    }

    onSubmit(event: Event) {
        event.preventDefault();

        console.log(this.copyWriterForm.valid, this.mainForm.valid, this.isDuplicate);

        if (this.copyWriterForm.valid) {
            this.examCreatorFormService.setTaskDataControlValue(JSON.stringify(this.copyWriterForm.value));

            if (this.mainForm.valid) {

                // Disable save button.
                this.isFormValid = false;

                if (this.taskId) {
                    if(this.isDuplicate){
                        this.insertTask();
                    } else {
                        this.examCreatorFormService.taskHttpApi.update(this.mainForm.value, this.taskId, true).subscribe((result) => {
                            if (result === true) {
                                this.submitted(false);
                            }
                        });
                    }
                } else {
                    this.insertTask();
                }

            } else {
                this.examCreatorFormService.open.emit(true);
            }

        }

        if (!this.mainForm.valid) {
            this.examCreatorFormService.open.emit(true);
        }
    }

    private insertTask(){
        this.examCreatorFormService.taskHttpApi.insert(this.mainForm.value, true).subscribe((result) => {
            console.log(result);
            if (result.id) {
                const item = {
                    item_data: null,
                    order: 0,
                    task_id: result.id
                };
                this.examCreatorFormService.itemHttpApi.insert(item, true).subscribe(() => {
                    this.examCreatorFormService.createdTask.emit(result.id);
                    this.submitted(true);
                });
            }
        });
    }

    private submitted(isNew) {
        const submitPrefixText = isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`;
        const dateText = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
        });

        this.examCreatorFormService.submittedResult = `${submitPrefixText} ${dateText}-kor`;

        // Enable save button.
        this.isFormValid = true;
    }

    onValueChanged() {
        if (this.copyWriterForm) {
            const form = this.copyWriterForm;
            for (const field in this.formErrors) {
                // clear previous error message (if any)
                this.formErrors[field] = "";
                const control = form.controls[field];

                if (control && !control.valid) {
                    this.formErrors[field] += $localize`:@@please-fill-in:Kérjük, töltse ki a mezőt!`;
                }
            }
        }

        if (this.mainForm.controls["topic"]) {
            const control = this.mainForm.controls["topic"];
            this.copyWriterForm.patchValue({
                description: control.value
            });
        }

    }

    formErrors = {
        task_content: ""
    };

    onPreview() {
        this.examCreatorFormService.onPreviewTask();
    }
}
