import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {EnvironmentService} from "../../services/environment.service";
import {MatDialog} from '@angular/material/dialog';
import {UserListComponent} from "./user-list/user-list.component";


@Component({
    moduleId: module.id,
    selector: "users",
    host: {
        id: "users"
    },
    templateUrl: "users.component.html",
    styleUrls: ["users.component.css"]
})

export class UsersComponent implements OnInit {
    @ViewChild('editUser') editUser: TemplateRef<any>;
    @ViewChild(UserListComponent) userList:UserListComponent;

    selectedUser = null;
    sendingNewUser = false;
    dialogRef = null;

    constructor(
        public environmentService: EnvironmentService,
        public dialog: MatDialog,
    ) {
    }

    ngOnInit() {
    }

    openEditDialog(user?) {
        this.selectedUser = user;
        console.log(this.selectedUser);
        this.dialogRef = this.dialog.open(this.editUser);
        this.dialogRef.afterClosed().subscribe(() => {
            this.selectedUser = null;
        });
    }

    updateUserList() {
        this.userList.getUsers();
        if (this.dialogRef !== null) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }
}
