//tslint:disable max-file-line-count
import {Component, DoCheck, EventEmitter, Input, OnInit} from "@angular/core";
import {ITask} from "../../../interfaces/task.interface";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {isNullOrUndefined} from "util";
import {CORRECTION_OPTIONS_SPEAKING} from "../../../services/shared/manual_correction_options";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {Sorting} from "../../../utils/sorting";
import {HttpClient} from "@angular/common/http";

@Component({
    moduleId: module.id,
    selector: "form-task",
    templateUrl: "form-task.component.html",
    styleUrls: ["../corrector.component.css"]
})

export class FormTaskComponent implements OnInit, DoCheck {

    @Input("task") task: ITask;

    private prefixUrl: string;
    private token: string;
    private examHttpApi: ExamHttpApi;
    private sectionId = 0;
    private optionRateMap = new Map();
    private optionRateMap2 = new Map();
    private taskInitialize: EventEmitter<any> = new EventEmitter();
    examAnswerId: number;
    corrector1: string;
    corrector2: string;
    isTaskAWebForm: boolean;
    isAdmin: boolean;
    currentTime: any;
    reviewByCorrectorForm: FormGroup;
    reviewByCorrector2Form: FormGroup;
    dropDownDatas: Array<string> = [];
    radioDatas: Array<string> = [];
    checkboxDatas: Array<string> = [];
    areSectionNamesVisible: boolean[] = [];
    rating = [0, 1, 2, 3, 4, "*"]; // "*" equals 5
    isSavingLabelVisible = false;
    correctionOptions: any = CORRECTION_OPTIONS_SPEAKING;

    constructor(private http: HttpClient,
                private formBuilder: FormBuilder,
                private authService: AuthService) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setSelectedTaskUrl();

        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.createFormGroups();
    }

    ngOnInit() {
        this.taskInitialize.emit();

        this.task.items = Sorting.sortTaskItemsByOrder(this.task.items);

        this.transformItemData();

        this.isTaskAWebForm = this.checkIfTaskAWebForm();

        if (this.isTaskAWebForm) {
            this.task.items.map((item) => {
                this.splitItemMulti(item.item_data.item_type, item.item_data.item_multi);
            });
        }

        this.examAnswerId = this.task.items[0].exam_answer[0].id;

        if (this.isAdmin) {
            this.setCorrectors();
        }

        console.log("this.task", this.task);
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    onSubmit(event) {
        event.preventDefault();

        const valueObj = this.reviewByCorrectorForm.value;
        let correctorOptionDatas = [];

        for (const item in valueObj) {
            if (valueObj.hasOwnProperty(item)) {
                let itemVal = valueObj[item];

                if (!isNullOrUndefined(itemVal)) {
                    if (itemVal === "*") {
                        itemVal = 5;
                    }

                    correctorOptionDatas.push({
                        exam_answer_id: this.examAnswerId,
                        scale: item,
                        rating: itemVal
                    });
                }
            }
        }

        if (correctorOptionDatas.length > 0) {
            this.examHttpApi.saveManualCorrectionOptions(correctorOptionDatas).subscribe((response:any) => {
                if (response) {
                    this.currentTime = new Date().toLocaleDateString("hu-HU", {
                        hour: "numeric",
                        hour12: false,
                        minute: "numeric"
                    });
                    this.isSavingLabelVisible = true;
                    setTimeout(() => {
                        this.isSavingLabelVisible = false;
                    }, 5000);
                }
            });
        }
    }

    private transformItemData() {
        this.task.items.forEach((item, index) => {
            item.item_data = (typeof item.item_data === "string") ? JSON.parse(item.item_data) : item.item_data;

            let itemData = item.item_data;
            let answerData = item.exam_answer[0].answer_data;

            if (!isNullOrUndefined(itemData.section_id)) {
                this.fillAreGroupNamesVisible(itemData, index);
            }

            if (!isNullOrUndefined(item.exam_answer)) {
                answerData = (typeof answerData === "string") ?
                    JSON.parse(answerData) : answerData;

                if (!isNullOrUndefined(itemData.section_items)) {
                    itemData.section_items.forEach((sectionItem) => {
                        for (let answer in answerData) {
                            if (answerData.hasOwnProperty(answer)) {
                                sectionItem.answer = answerData[answer];

                                if (sectionItem.item_type === "checkbox") {
                                    let areCheckboxesChecked = [];
                                    for (let element in sectionItem.answer) {
                                        if (sectionItem.answer.hasOwnProperty(element)) {
                                            areCheckboxesChecked.push(sectionItem.answer[element]);
                                        }
                                    }
                                    sectionItem.answer = areCheckboxesChecked;
                                }
                            }
                        }

                        this.splitItemMulti(sectionItem.item_type, sectionItem.item_multi);
                    });
                }

            }
        });
    }

    private setCorrectors() {
        this.corrector2 = `${this.task.items[0].exam_answer[0].review[9].user.first_name} ${this.task.items[0].exam_answer[0].review[9].user.last_name}`;
        this.corrector1 = `${this.task.items[0].exam_answer[0].review[0].user.first_name} ${this.task.items[0].exam_answer[0].review[0].user.last_name}`;
    }

    private splitItemMulti(itemType, itemMulti) {
        if (itemType === "dropdown") {
            this.dropDownDatas = itemMulti.split("\n");
        }
        if (itemType === "radio") {
            this.radioDatas = itemMulti.split("\n");
        }
        if (itemType === "checkbox") {
            this.checkboxDatas = itemMulti.split("\n");
        }
    }

    private checkIfTaskAWebForm(): boolean {
        return this.task.type !== "form";
    }

    private createFormGroups() {
        const formControlKeys = Object.keys(this.correctionOptions);
        this.reviewByCorrectorForm = this.formBuilder.group({}); // first corrector's form
        this.reviewByCorrector2Form = this.formBuilder.group({}); // second corrector's form

        formControlKeys.forEach((key) => {
            this.reviewByCorrectorForm.registerControl(key, new FormControl(null, Validators.required));
            this.reviewByCorrector2Form.registerControl(key, new FormControl(null, Validators.required));

            this.taskInitialize.subscribe(() => {
                if (!isNullOrUndefined(this.task.items[0].exam_answer[0].review)) {
                    this.task.items[0].exam_answer[0].review.forEach((option, index) => {
                        if (option.rating === 5) {
                            option.rating = "*";
                        }

                        if (index <= 8) {
                            // these are the first corrector's reviews if admin logged in
                            // or the current corrector's reviews if the corrector logged in
                            this.optionRateMap.set(option.scale, option.rating);
                        } else {
                            // these are the second corrector's reviews if admin logged in
                            this.optionRateMap2.set(option.scale, option.rating);
                        }
                    });
                    this.reviewByCorrector2Form.controls[key].setValue(this.optionRateMap2.get(key));
                    this.reviewByCorrectorForm.controls[key].setValue(this.optionRateMap.get(key));
                }
            });
        });
    }

    private fillAreGroupNamesVisible(itemData, index) {
        if (index === 0) { // if item is an example
            this.sectionId = itemData.section_id;
            this.areSectionNamesVisible.push(true);
        } else {
            if (itemData.section_id !== this.sectionId) {
                this.sectionId = itemData.section_id;
                this.areSectionNamesVisible.push(true);
            } else {
                this.areSectionNamesVisible.push(false);
            }
        }
    }


    /**
     * Download xls file
     * @param file
     */

    private getDownloadFile() {
        let fileName = 'speaking_scale.pdf';
        if(this.task.part === 'writing' || this.task.part === 'reading'){
            fileName = 'writing_scale.pdf';
        }

        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}getDownloadReviewPDF/${fileName}&token=${token}`;

        window.open(url);
    }
}
