<div id="login-bg">
</div>
<div class="container login-container">
    <div class="row login-row">
        <div class="login-wrapper col-xs-12">
            <div class="login-card col-xs-12 col-md-4">
                <div class="login-logo text-center" *ngIf="environmentService.get('logo') !== null">
                    <img [src]="environmentService.get('logo')">
                </div>
                <div class="login-title">
                    <span i18n="login">Belépés</span>
                </div>
                <div class="login-form">
                    <form novalidate [formGroup]="loginForm" (submit)="onSubmit($event)">
                        <div class="login-input-wrapper">
                            <label class="login-label" for="username">
                                <span i18n>Felhasználónév</span>
                            </label>
                            <input class="login-input" type="text" id="username"
                            formControlName="email"
                            placeholder="example@mail.com">
                        </div>
                        <div class="login-input-wrapper">
                            <label class="login-label" for="password">
                                <span i18n>Jelszó</span>
                            </label>
                            <input class="login-input" type="password"
                            id="password"
                            formControlName="password"
                            placeholder="password">
                        </div>
                        <div class="input-button-wrapper row">
                            <button type="submit" class="btn input-button col-xs-8">
                                <span i18n="login">Belépés</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
