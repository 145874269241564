import {BrowserModule} from "@angular/platform-browser";
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {LandingPageComponent} from "../components/landing-page/landing-page.component";
import {FormComponent} from "../components/form/app.form";
import {CopywritingComponent} from "../components/copywriting/copywriting.component";
import {AppLogin} from "../components/login/app.login";
import {RegistrySheetComponent} from "app/components/registry-sheet/registry-sheet.component";
import {VideoConverterComponent} from 'app/components/video-converter/video-converter.component';
import {ExamCreatorFormService} from "../services/shared/exam_creator_form.service";
import {FileUploadService} from "../services/shared/upload.service";
import {TinymceModule} from "angular2-tinymce";
import {FormCreatorComponent} from "../components/form-creator/form_creator.component";
import {FormCreatorItemComponent} from "../components/options/form_creator_items.component";
import {CompletionComponent} from "../components/completion/completion.component";
import {ShortAnswerComponent} from "../components/short-answer/short-answer.component";
import {PairingComponent} from "../components/pairing/pairing.component";
import {CreateExamComponent} from "../components/create-exam/create-exam.component";
import {MultipleChoiceComponent} from "../components/multiple-choice/multiple-choice.component";
import {MultipleChoiceGroupsComponent} from "../components/multiple-choice-groups/multiple-choice-groups.component";
import {ListComponent} from "../components/list/list.component";
import {SpeakingSkillsComponent} from "../components/speaking-skills/speaking-skills.component";
import {CorrectorListComponent} from "../components/corrector-list/corrector-list.component";
import {CorrectorComponent} from "../components/corrector/corrector.component";
import {AnalysisListComponent} from "../components/analysis-list/analysis-list.component";
import {AnalysisComponent} from "../components/analysis/analysis.component";
import {LANDING_ROUTES} from "./routes/landing_routes";
import {TASK_ROUTES} from "./routes/task_routes";
import {CORRECTOR_ROUTES} from "./routes/corrector_routes";
import {ANALYSIS_ROUTES} from "./routes/analysis_routes";
import {CustomCheckboxDirective} from "../directives/checkbox.directive";
import {ExtendTaskStringConverter} from "../pipes/string-converter.pipe";
import {FilenameConverter} from "../pipes/filename-converter.pipe";
import {ItemFilter} from "../pipes/item-filter.pipe";
import {environment} from "../../environments/environment";
import {MenuComponent} from "../components/menu/menu.component";
import {OptionCheckedDirective} from "../directives/option_checked.directive";
import {WritingSelectionComponent} from "../components/create-exam/writing-selection/writing-selection.component";
import {ExtendTaskComponent} from "../components/corrector/extend-task/extend-task.component";
import {FormTaskComponent} from "../components/corrector/form-task/form-task.component";
import {SpeakingTaskComponent} from "../components/corrector/speaking-task/speaking-task.component";
import {WritingTaskComponent} from "../components/corrector/writing-task/writing-task.component";
import {SelectTaskComponent} from "../components/corrector/select-task/select-task.component";
import {SelectGroupTaskComponent} from "../components/corrector/select-group-task/select-group-task.component";
import {PairTaskComponent} from "../components/corrector/pair-task/pair-task.component";
import {ShortAnswerTaskComponent} from "../components/corrector/short-answer-task/short-answer-task.component";
import {ResultsComponent} from "../components/analysis/results/results.component";
import {ExamRosterComponent} from "../components/exam-roster/exam-roster.component";
import {AdminAuthGuardService} from "../services/admin-auth-guard.service";
import {KeyManagerComponent} from "../components/key-manager/key-manager.component";
import {TaskCreatorAuthGuardService} from "../services/task-creator-auth-guard.service";
import {ExaminerAuthGuardService} from "../services/examiner-auth-guard.service";
import {InspectorAuthGuardService} from "../services/inspector-auth-guard.service";
import {DiariesComponent} from "../components/diaries/diaries.component";
import {ExportPageComponent} from "../components/export-page/export-page.component";
import {ExamSyncComponent} from "../components/exam-sync/exam-sync.component";
import {CandidateIdentityPageComponent} from "../components/candidate-identity-page/candidate-identity-page.component";
import {IdentityCheckingComponent} from "../components/identity-checking/identity-checking.component";
import {IdentityCardComponent} from "../components/identity-checking/identity-card/identity-card.component";
import {ChatComponent} from "../components/chat/chat.component";
import {UsersComponent} from "../components/users/users.component";
import {ApiService} from "../services/api.service";
import {MatSliderModule} from "@angular/material/slider";
import {MatPaginatorModule} from "@angular/material/paginator";
import {CreateMmkExamComponent} from "../components/create-exam/mmk-exam/create-mmk-exam.component";
import {TaskTypeNamePipe} from "../pipes/task-type-name.pipe";
import {CandidatesComponent} from "../components/candidates/candidates.component";
import {CandidateListComponent} from "../components/candidate-list/candidate-list.component";
import {ExamResultMmkComponent} from "../components/corrector/exam-result-mmk/exam-result-mmk.component";
import {MatDialogModule} from "@angular/material/dialog";
import {MatButtonModule} from '@angular/material/button';
import {DatepickerModule} from "ngx-bootstrap/datepicker";
import {TimepickerModule} from "ngx-bootstrap/timepicker";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import { MatChipsModule } from "@angular/material/chips";
import {
    NGX_MAT_DATE_FORMATS,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import {NgxMatMomentModule} from "@angular-material-components/moment-adapter";
import {LiveTestComponent} from "../components/live-test/live-test.component";
import {SessionListComponent} from 'app/components/live-test/session-list/session-list.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AnswerResultNamePipe} from "../pipes/answer-result-name.pipe";
import {ShowCandidateAllAnswersComponent} from "../components/corrector/show-candidate-all-answers/show-candidate-all-answers.component";
import {ShowCandidateAnswersComponent} from "../components/corrector/show-candidate-answer/show-candidate-answers.component";
import {ExaminerSelectComponent} from "../components/examiner-select/examiner-select.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule} from "@angular/material-moment-adapter";
import {MAT_DATE_FORMATS} from "@angular/material/core";
import {FinalResultComponent} from 'app/components/corrector/exam-result-mmk/final-result/final-result.component';
import {MatSelectModule} from "@angular/material/select";
import {SelectAutocompleteModule} from "../../../projects/select-autocomplete/src/lib/select-autocomplete.module";
import {AnalysisGroupComponent} from "../components/analysis-group/analysis-group.component";
import {EditUserComponent} from "../components/users/edit-user/edit-user.component";
import {UserListComponent} from "../components/users/user-list/user-list.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {CloseExamComponent} from "../components/identity-checking/identity-card/close-exam/close-exam.component";
import {MoveExamAnswersComponent} from "../components/move-exam-answers/move-exam-answers.component";
import {MatRadioModule} from "@angular/material/radio";


export const DATE_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD hh:mm:ss',
    },
    display: {
        dateInput: 'YYYY-MM-DD hh:mm:ss',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};

const routes: Routes = [
    {
        path: "",
        redirectTo: "/login",
        pathMatch: "full"
    },
    {
        path: "landing",
        component: LandingPageComponent,
        children: LANDING_ROUTES
    },
    {
        path: "exam-task-creator",
        component: FormComponent,
        canActivate: [TaskCreatorAuthGuardService],
        children: TASK_ROUTES
    },
    {
        path: "login",
        component: AppLogin
    }, {
        path: "create-exam",
        component: CreateExamComponent,
        canActivate: [AdminAuthGuardService],
        data: {
            title: `create-exam`,
            saveButtonText: "Létrehozás",
            edit: false
        }
    }, {
        path: "edit-exam/:id",
        component: CreateExamComponent,
        canActivate: [AdminAuthGuardService],
        data: {
            title: `edit-exam`,
            saveButtonText: "Mentés",
            edit: true
        }
    }, {
        path: "edit-exam/:id/:duplicate",
        component: CreateExamComponent,
        canActivate: [AdminAuthGuardService],
        data: {
            title: `edit-exam`,
            saveButtonText: "Mentés"
        }
    }, {
        path: "create-mmk-exam",
        component: CreateMmkExamComponent,
        canActivate: [AdminAuthGuardService],
        data: {
            title: `create-exam`,
            saveButtonText: "Létrehozás",
            edit: false
        }
    }, {
        path: "edit-mmk-exam/:id",
        component: CreateMmkExamComponent,
        canActivate: [AdminAuthGuardService],
        data: {
            title: `edit-exam`,
            saveButtonText: "Mentés",
            edit: true
        }
    }, {
        path: "edit-mmk-exam/:id/:duplicate",
        component: CreateMmkExamComponent,
        canActivate: [AdminAuthGuardService],
        data: {
            title: `edit-exam`,
            saveButtonText: "Mentés"
        }
    }, {
        path: "corrector",
        component: CorrectorListComponent,
        canActivate: [],    // @TODO: Proper guard, different per exam types
        children: CORRECTOR_ROUTES
    }, {
        path: "analysis",
        component: AnalysisListComponent,
        canActivate: [ExaminerAuthGuardService],
        children: ANALYSIS_ROUTES
    }, {
        path: "exam-roster",
        component: ExamRosterComponent,
        canActivate: [InspectorAuthGuardService]
    }, {
        path: "checking-identity",
        component: IdentityCheckingComponent,
        canActivate: [InspectorAuthGuardService]
    }, {
        path: "diaries",
        component: DiariesComponent,
        canActivate: [InspectorAuthGuardService]
    }, {
        path: "export-page",
        component: ExportPageComponent,
        canActivate: [AdminAuthGuardService]
    }, {
        path: "exam-sync",
        component: ExamSyncComponent,
        canActivate: [AdminAuthGuardService]
    }, {
        path: "users",
        component: UsersComponent,
        canActivate: [AdminAuthGuardService]
    }, {
        path: "candidate-identity-page/:candidateExamId",
        component: CandidateIdentityPageComponent,
        canActivate: [ExaminerAuthGuardService]
    }, {
        path: "candidates/new",
        component: CandidatesComponent,
        data: {
            title: `Vizsgázó felvétele`,
            saveButtonText: "Felvétel"
        }
    }, {
        path: "candidates/edit/:id",
        component: CandidatesComponent,
        data: {
            title: `edit-candidate`,
            saveButtonText: "Mentés"
        }
    }, {
        path: "candidates/list",
        component: CandidateListComponent
    }
];

let myMceConfig = {
    plugins: ["code", "image", "table", "template"],
    skin_url: "/assets/tinymce/skins/lightgray",
    automatic_uploads: true,
    entity_encoding : "raw",
    templates: [
    ],
    file_picker_callback,
    images_upload_handler
};

@NgModule({
    declarations: [
        LandingPageComponent,
        FormComponent,
        CopywritingComponent,
        FormCreatorComponent,
        AppLogin,
        RegistrySheetComponent,
        VideoConverterComponent,
        FormCreatorItemComponent,
        CompletionComponent,
        ShortAnswerComponent,
        PairingComponent,
        CreateExamComponent,
        CreateMmkExamComponent,
        ListComponent,
        SpeakingSkillsComponent,
        MultipleChoiceComponent,
        MultipleChoiceGroupsComponent,
        CorrectorListComponent,
        ExamRosterComponent,
        IdentityCheckingComponent,
        IdentityCardComponent,
        DiariesComponent,
        CorrectorComponent,
        AnalysisListComponent,
        AnalysisComponent,
        ExtendTaskStringConverter,
        FilenameConverter,
        AnswerResultNamePipe,
        ItemFilter,
        CustomCheckboxDirective,
        MenuComponent,
        OptionCheckedDirective,
        WritingSelectionComponent,
        ExtendTaskComponent,
        FormTaskComponent,
        SpeakingTaskComponent,
        WritingTaskComponent,
        SelectTaskComponent,
        SelectGroupTaskComponent,
        PairTaskComponent,
        ShortAnswerTaskComponent,
        ResultsComponent,
        ExportPageComponent,
        ExamSyncComponent,
        CandidateIdentityPageComponent,
        LiveTestComponent,
        SessionListComponent,
        UsersComponent,
        EditUserComponent,
        UserListComponent,
        CloseExamComponent,
        ChatComponent,
        KeyManagerComponent,
        TaskTypeNamePipe,
        CandidatesComponent,
        CandidateListComponent,
        ExamResultMmkComponent,
        ShowCandidateAllAnswersComponent,
        ShowCandidateAnswersComponent,
        FinalResultComponent,
        ExaminerSelectComponent,
        AnalysisGroupComponent,
        MoveExamAnswersComponent,
    ],
    imports: [
        DatepickerModule.forRoot(), // @TODO: Get rid of these by some mat element
        TimepickerModule.forRoot(),
        BrowserModule,
        FormsModule,
        MatPaginatorModule,
        MatInputModule,
        MatDialogModule,
        MatSelectModule,
        MatCheckboxModule,
        NgxMatTimepickerModule,
        NgxMatDatetimePickerModule,
        NgxMatMomentModule,
        MatChipsModule,
        MatIconModule,
        MatDatepickerModule,
        SelectAutocompleteModule,
        MatMomentDateModule,
        MatButtonModule,
        ReactiveFormsModule,
        TinymceModule.withConfig(myMceConfig),
        RouterModule.forRoot(routes),
        MatProgressSpinnerModule,
        MatRadioModule
    ],
    providers: [
        ExamCreatorFormService,
        FileUploadService,
        {
            provide: NGX_MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD HH:mm:ss',
                },
                display: {
                    dateInput: 'YYYY-MM-DD HH:mm',
                    monthYearLabel: 'YYYY',
                    dateA11yLabel: 'YYYY-MM-DD HH:mm:ss',
                    monthYearA11yLabel: 'YYYY',
                },
            },
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'YYYY-MM-DD',
                },
                display: {
                    dateInput: 'YYYY-MM-DD',
                    monthYearLabel: 'YYYY',
                    dateA11yLabel: 'YYYY-MM-DD',
                    monthYearA11yLabel: 'YYYY',
                },
            }}
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}

export function file_picker_callback(cb, value, meta) {
    var input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.onchange = function (e) {
        var elem = <HTMLInputElement> e.target;
        var files = elem.files;
        var file = files[0];
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            // Note: Now we need to register the blob in TinyMCEs image blob
            // registry. In the next release this part hopefully won"t be
            // necessary, as we are looking to handle it internally.
            var id = "blobid" + (new Date()).getTime();
            var blobCache = tinymce.activeEditor.editorUpload.blobCache;
            var blobInfo = blobCache.create(id, file, reader.result);
            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name
            cb(blobInfo.blobUri(), {title: file.name});
        };
    };
    input.click();
}

export function images_upload_handler(blobInfo, success, failure) {
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open("POST", environment.apiPrefixUrl + "files");
    xhr.setRequestHeader("authorization", "Bearer " + window.localStorage.getItem("itolc_token"));
    xhr.onload = function () {
        var json;

        if (xhr.status !== 200) {
            failure("HTTP Error: " + xhr.status);
            return;
        }
        json = JSON.parse(xhr.responseText);

        if (!json || typeof json.data.url !== "string") {
            failure("Invalid JSON: " + xhr.responseText);
            return;
        }
        success(environment.apiPrefixUrl + json.data.url + "?token=" + window.localStorage.getItem("itolc_token"));
    };

    formData = new FormData();

    formData.append("file", blobInfo.blob());

    xhr.send(formData);
}

export const routedComponents = [
    LandingPageComponent,
    ListComponent,
    FormComponent,
    CopywritingComponent,
    AppLogin,
    FormCreatorComponent,
    CompletionComponent,
    PairingComponent,
    CreateExamComponent,
    CreateMmkExamComponent,
    SpeakingSkillsComponent,
    MultipleChoiceComponent,
    MultipleChoiceGroupsComponent,
    CorrectorComponent,
    MenuComponent,
    CandidatesComponent,
    CandidateListComponent,
    ExamResultMmkComponent];
