
<div class="chat-container" *ngIf="visible" [style.z-index]="myzIndex" [style.right.px]="alignRight" [style.bottom.px]="alignBottom" (click)="noticed()">
    <div [className]="'panel ' + panelStyle">
        <div class="panel-heading" id="accordion">
            <span class="glyphicon glyphicon-comment"></span> {{candidate.last_name}} {{candidate.first_name}} | {{candidate.user_id}}
            <div class="btn-group pull-right">
                <a type="button" href="javascript:void(0);" class="btn btn-default btn-xs" (click)="hide()">
                    <span class="glyphicon glyphicon-remove"></span>
                </a>
            </div>
        </div>
    <div>
        <div class="panel-body" #chatContents>
            <ul class="chat">

                <li *ngFor="let message of messages; let i = index" [class]="message.sender === 'candidate' ? 'left clearfix' : 'right clearfix'">
                    <span [class]="message.sender === 'candidate' ? 'chat-img pull-left' : 'chat-img pull-right'">
                        <img *ngIf="message.sender === 'admin'" src="https://via.placeholder.com/50/44b348/fff&text=ME" alt="User Avatar" class="img-circle" />
                        <img *ngIf="message.sender === 'inspector'" src="https://via.placeholder.com/50/FA6F57/fff&text=ME" alt="User Avatar" class="img-circle" />
                        <img *ngIf="message.sender === 'candidate'" src="https://via.placeholder.com/50/55C1E7/fff&text=U" alt="User Avatar" class="img-circle" />
                    </span>
                    <div class="chat-body clearfix">
                        <div class="header">
                            <strong class="primary-font" *ngIf="message.sender === 'admin'">Vizsgaszervező</strong>
                            <strong class="primary-font" *ngIf="message.sender === 'inspector'">Vizsgafelügyelő</strong>
                            <strong class="primary-font" *ngIf="message.sender === 'candidate'">{{candidate.first_name}} (vizsgázó)</strong>
                            <small class="pull-right text-muted"><span class="glyphicon glyphicon-time"></span>{{message.time}}</small>
                        </div>
                        <p>
                            {{message.content}}
                        </p>
                    </div>
                </li>

            </ul>
        </div>
        <div class="panel-footer">
            <div class="input-group">
                <input id="btn-input" type="text" class="form-control input-sm" placeholder="Üzenet írása..." [(ngModel)]="draft" (keyup.enter)="send()" />
                <span class="input-group-btn">
                    <button class="btn btn-warning btn-sm" id="btn-chat" (click)="send();">
                        Küld</button>
                </span>
            </div>
        </div>
    </div>
    </div>
</div>
