import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {isNullOrUndefined} from "util";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {ToastrService} from "ngx-toastr";
import {EnvironmentService} from "../../services/environment.service";


@Component({
    moduleId: module.id,
    selector: "app-examiner-select",
    templateUrl: "./examiner-select.component.html",
    styleUrls: ["./examiner-select.component.css"]
})
export class ExaminerSelectComponent implements OnInit{
    @Input() exam_id: number;
    @Input() exam_part: string;
    @Input() isUserAdmin: boolean;

    candidateList: any = null; // Candidates for this part
    examiners: any = null;     // Available examiners
    examinerList:any = null;   // Assigned examiners
    identityData: any = null;  // Assigned inspectors
    inspectors: any = null;    // Available inspectors
    //@Output() examinerListChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    open_examiner = 0;
    private examHttpApi: ExamHttpApi;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        public environmentService: EnvironmentService,
    ) {
        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();
    }

    isLoaded() {
        if (this.examiners === null ||
            this.examinerList === null ||
            this.candidateList === null ||
            (this.exam_part === 'identity_check' && this.identityData === null) ||
            (this.exam_part === 'identity_check' && this.inspectors === null)
        ) {
            return false;
        }
        return true;
    }

    loadExaminers() {
        // Load details for this exam
        this.examHttpApi.getExamData(this.exam_id).subscribe((data) => {
            this.examinerList = data.examiners;
            this.candidateList = data.candidates_list;

            if ((!this.examinerList[this.exam_part] || typeof this.examinerList[this.exam_part] === 'undefined' || this.examinerList[this.exam_part].length < 1) &&
                this.environmentService.get('simple_identity_check') === true) {
                this.addNewExaminer(this.exam_id, this.exam_part, true);
            }
        });

        // Load available examiners
        this.examHttpApi.getExaminers().subscribe((data) => {
            this.examiners = data;
        });

        // Load inspectors if we are working with identity check mode
        if (this.exam_part === 'identity_check') {
            this.examHttpApi.getExamIdentityData(this.exam_id).subscribe((data) => {
                this.identityData = data;
            });
            this.examHttpApi.getInspectors().subscribe((data) => {
                this.inspectors = data;
            });
        }
    }

    getExaminers() {
        if (this.exam_part === 'identity_check') {
            return this.inspectors ? this.inspectors : [];
        } else {
            return this.examiners ? this.examiners : [];
        }
    }

    getCandidates() {
        if (this.exam_part === 'identity_check') {
            return this.identityData ? this.identityData : [];
        } else {
            return this.candidateList ? this.candidateList : [];
        }
    }

    setSelectDisabled(examID, part, candidateID){
        let selectedList = this.examinerList[part];

        return selectedList.some((item) => {
            return item.candidates.some((item => item === candidateID));
        });
    }

    saveExaminerRow(examId: number, part: string){
        let url = `${environment.apiPrefixUrl}exam/addExaminers`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: examId,
            part: part,
            examiners: this.examinerList[part],
        };

        if(isNullOrUndefined(this.examinerList[part])){
            return;
        }

        let correct = this.examinerList[part].map((item) => {
            isNullOrUndefined(item.examiner_1) || isNullOrUndefined(item.examiner_2);
        });

        if(correct.some(x => x === true)){
            this.toastr.error('Vizsgáztató hozzáadásához minden mező kitöltése kötelező!', 'Művelet sikertelen', {onActivateTick: true});
            return;
        }

        return this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let jsonData = response;
            if(jsonData.success){
                this.toastr.success('Vizsgáztatók hozzárendelve.', 'Művelet végrehajtva.', {onActivateTick: true});
            }
        })).subscribe();
    }

    addNewExaminer(examId: number, part: string, preselect = false){
        let index = !isNullOrUndefined(this.examinerList[part]) ? this.examinerList[part].length : 0;

        let candidates = [];

        // If we preselect, add all candidate ID to our list already
        if (preselect === true) {
            candidates = this.getCandidates().map((candidate) => {
                return this.exam_part === 'identity_check' ? candidate.candidate_id : candidate.id;
            });
        }

        // If we already have assigned inspectors
        if(index > 0){
            this.examinerList[part][index] = {
                model_id: null,
                part: part,
                examiner_1: null,
                examiner_2: null,
                examiner_3: null,
                candidates: candidates
            };
        } else {
            // If this is a new empty row
            this.examinerList[part] = [{
                model_id: null,
                part: part,
                examiner_1: null,
                examiner_2: null,
                examiner_3: null,
                candidates: candidates
            }];
        }

        //this.examinerListChange.emit(this.examinerList);
    }

    ngOnInit(): void {
        // Load only when opened
        //this.loadExaminers();
    }
}
