import {ActivatedRoute} from "@angular/router";
import {Component, OnInit, DoCheck} from "@angular/core";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {AuthService} from "../../services/auth.service";
import {ExamService} from "../../services/exam_service/exam.service";
import {DatePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";

@Component({
    moduleId: module.id,
    selector: "candidate-identity-page",
    host: {
        id: "candidate-identity-page"
    },
    templateUrl: "candidate-identity-page.component.html",
    styleUrls: ["candidate-identity-page.component.css"]
})

export class CandidateIdentityPageComponent implements OnInit,DoCheck {
    private examHttpApi: ExamHttpApi;
    isUserExaminer: boolean = false;
    checkbox: boolean = false;
    checked: boolean = false;
    candidateExam: any;
    images: any;
    videos: any;
    candidateExamId: number = 0;

    constructor(private http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private authService: AuthService) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();

        this.activatedRoute.params.subscribe(({candidateExamId}) => {
            this.candidateExamId = candidateExamId;
        });

        this.examHttpApi.getCandidateIdentityCheck(this.candidateExamId).subscribe((data) => {
            this.candidateExam = data;
            this.images = data.images;
            this.videos = data.videos;
        });
    }

    ngOnInit() {
        console.log(this.authService.$userStatus);
        this.isUserExaminer = this.authService.$userStatus === "examiner";
    }

    ngDoCheck() {
        //
    }

    sendIdentityCheck(){
        console.log(this.checkbox);

        if(this.checkbox){
            this.examHttpApi.sendIdentityCheck(this.candidateExamId, 1).subscribe((status) => {
                if(status){
                    this.candidateExam.identity_check = 1;
                }
            });
        }
    }

    setSpeed(id, speed: number){
        let video = document.getElementById(id) as HTMLVideoElement;
        console.log(id, video);
        // document.querySelector('video').playbackRate = speed;
        video.playbackRate = speed;
    }
}
