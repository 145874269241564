import {Component, DoCheck, OnInit} from "@angular/core";
import {TaskHttpApi} from "../../../http_apis/task.http";
import {IExamAnswer} from "../../../interfaces/exam_answer.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {FacetHttpApi} from "../../../http_apis/facet.http";
import {AuthService} from "../../../services/auth.service";
import {environment} from "../../../../environments/environment";
import {RestConfig} from "../../../http_apis/rest_config.http";
import {IExaminer} from "../../../interfaces/examiner.interface";
import {isNullOrUndefined} from "util";
import {PaginationService} from "../../../services/pagination.service";
import {HttpClient} from "@angular/common/http";
import {ToastrService} from "ngx-toastr";
import {EnvironmentService} from "../../../services/environment.service";
import {ApiService} from "../../../services/api.service";

@Component({
    moduleId: module.id,
    selector: "exam-result-mmk",
    templateUrl: "exam-result-mmk.component.html",
    styleUrls: ["./exam-result-mmk-component.css"]
})

export class ExamResultMmkComponent implements OnInit {

    private examHttpApi: ExamHttpApi;
    tableData: Array<any>;
    showCandidates: Array<boolean>;
    showLoaders: Array<boolean>;
    // 0: unsuccessful|1: speak|2: success
    tableSuccess: Array<any>;

    // array of all items to be paged
    private allItems: Array<any>;
    // pager object
    pager: any = {};
    // paged items
    pagedItems: any[];
    isUserAdmin: any;
    identityData: Array<any> = [];
    areExamsIdentityShown: Array<boolean>;
    inspectors: Array<IExaminer>;
    examinerList: Array<any> = [];

    matPagination = {
        'page': 0,
        'size': 10,
        'length': 0,
    };

    constructor(private http: HttpClient,
                private router: Router,
                private authService: AuthService,
                private toastr: ToastrService,
                public environmentService: EnvironmentService,
                private apiService: ApiService,
                private paginationService: PaginationService
    ) {

        this.examHttpApi = new ExamHttpApi(http, "data");
        this.examHttpApi.setDefaultUrl();



        this.examHttpApi.getInspectors().subscribe((data) => {
            this.inspectors = data;
            // console.log('this.inspectors: ', this.inspectors);
        });
    }

    private getExamList() {
        this.apiService.getExamList(this.matPagination).subscribe((results) => {
            this.tableData = results.data;
            this.allItems = results.data;
            this.pagedItems = this.tableData;

            this.allItems.forEach((exam) => {
                this.examinerList[exam.id] = {
                    examiner_1: exam.examiner_1,
                    examiner_2: exam.examiner_2,
                    examiner_3: exam.examiner_3
                };
            });

            this.matPagination['length'] = results.meta.pagination.total;
            this.showCandidates = this.pagedItems.map(() => false);
            this.showLoaders = this.pagedItems.map(() => false);
        });

    }

    ngOnInit() {
        this.getExamList();

        // console.log('ok');
        this.isUserAdmin = this.authService.$userStatus === "admin";
        this.authService.getuserDataLoadedSubject().subscribe((result) => {
            this.isUserAdmin = this.authService.$userStatus === "admin";
        });
    }

    openExamCandidateDetails(index: number, exam_id: number){
        this.showCandidates[index] = this.showCandidates[index] === true ? false : true;

        if(this.showCandidates[index]) {
            this.showLoaders[index] = true;

            this.examHttpApi.getExamAnswers(exam_id, (this.environmentService.get('adaptive_testing') ? true : null)).subscribe((results) => {
                this.pagedItems[index]['candidates'] = results;
                this.pagedItems[index]['open_identity'] = 0;
                this.showLoaders[index] = false;
            });
        }
    }

    openExamTaskDetails(examIndex: number,candidateIndex: number){
        console.log('openExamTaskDetails', examIndex,candidateIndex);
        let divToChange = document.getElementById('candidate-tasks-' + examIndex + '-' + candidateIndex);
        let display = divToChange.style.display;

        divToChange.style.display = display === 'none' || display === '' ? 'table-row' : 'none';
    }

    navigateToTaskCorrector(taskId: number, candidateExamId: number) {
        window.open(`corrector/show-exam-answer/${taskId}/${candidateExamId}`);
    }

    navigateToAllTaskCorrector(examId: number, candidateExamId: number) {
        window.open(`corrector/show-candidate-all-answers/${examId}/${candidateExamId}`);
    }

    /**
     * Update candidate_exam
     */
    improvedExam(event: Event, candidate_exam_id:number, i:number, c:number, exam_id:number, result:number){
        event.preventDefault();

        if(confirm('Biztos?')){
            // let value = this.pagedItems[i].candidates[c].exam_results[exam_id].improved;
            // let update = value === 0 ? 1 : 0; // true-false
            // let success = result === 1 ? 1 : 0; // class number to color

            this.apiService.updateExamImproved(candidate_exam_id, result);
            this.pagedItems[i].candidates[c].exam_results[exam_id].improved = result;
            this.pagedItems[i].candidates[c].exam_results[exam_id].success = 1;
        }
    }

    // @TODO: Depraceted, remove
    setPage(page: number) {
        // get pager object from service
        this.pager = this.paginationService.getPager(this.allItems.length, page);

        // get current page of items
        this.pagedItems = this.allItems.slice(this.pager.startIndex, this.pager.endIndex + 1);

        this.showCandidates = this.pagedItems.map(() => false);
        this.showLoaders = this.pagedItems.map(() => false);
    }

    reloadExam(index: number, exam_id: number) {
        this.showCandidates[index] = true;
        this.showLoaders[index] = true;
        this.pagedItems[index]['candidates'] = [];

        this.examHttpApi.getExamAnswers(exam_id).subscribe((results) => {
            this.pagedItems[index]['candidates'] = results;
            this.pagedItems[index]['open_identity'] = 0;
            this.showLoaders[index] = false;
        });
    }

    loadExamIdentityData(index: number, examId: number, open: boolean) {
        if(!this.areExamsIdentityShown[index]){
            this.getExamIdentityData(index, examId, open);
        } else {
            this.areExamsIdentityShown[index] = false;
        }
    }

    private getExamIdentityData(index: number, examId: number, open: boolean) {
        this.examHttpApi.getExamIdentityData(examId).subscribe((data) => {
            this.identityData[index] = data;
            if(open){
                this.toggleExamsIdentity(index);
            }
        });
    }

    private toggleExamsIdentity(index: number) {
        this.areExamsIdentityShown[index] = !this.areExamsIdentityShown[index];
    }

    /**
     * @Todo: merge to iTolc examiner function
     *
     * @param {number} index
     * @param {number} examId
     * @param {string} part
     */
    addNewExaminer(index: number, examId: number, part: string){
        console.log('addNewExaminer: ', examId, part);
        console.log(this.examinerList);

        this.examinerList[examId].push({
                examiner_1: null,
                examiner_2: null,
                examiner_3: null
        });
    }

    saveExaminerRow(examId: number, part: string){
        if(this.examinerList[examId].examiner_1 === null || this.examinerList[examId].examiner_1 === 0){
            this.toastr.warning('', 'Leggalább egy inspektor kiválasztása kötelező', {onActivateTick: true});
            return;
        }

        let body = {
            exam_id: examId,
            part: part,
            examiners: this.examinerList[examId],
        };

        console.log('body-save: ', body);

        this.examHttpApi.saveMmkExaminer(body).subscribe((data) => {
            if(data.result){
                this.toastr.success($localize`Az inspektor sikeresen mentve a vizsgához.`, $localize`Hozzárendelés sikeres`, {onActivateTick: true});
            }
        });
    }

    matPaginate(event){
        this.matPagination['page'] = event.pageIndex;
        this.matPagination['size'] = event.pageSize;
        this.getExamList();
    }
}
