import { IExamTask } from "../interfaces/exam_task.interface";
import {MMK_TASK_TYPES, TASK_CONFIGS} from "../services/shared/task_configs";
import {environment} from "../../environments/environment";
interface IExamTaskConfig {
    exam: IExamTask;
    examUrl: string;
}
export class ExamTaskModel {
    
    private taskConfigs = TASK_CONFIGS;
    private mmkTaskConfigs = MMK_TASK_TYPES;
    private taskUrl: string;

    constructor(private examObject: IExamTask) {
        this.setTaskUrl();
    }

    private setTaskUrl() {
        if(environment.examType === 'mmk'){
            let match = this.mmkTaskConfigs.filter((item) => item.value === this.examObject.format);
            if (match[0]) {
                this.taskUrl = match[0].url;
            }
        } else {
            let {part, level} = this.examObject;

            if (this.taskConfigs[`${part}_${level}`][this.examObject.number - 1]) {
                this.taskUrl = this.taskConfigs[`${part}_${level}`][this.examObject.number - 1].taskUrl;
            }
        }
    }

    private getTaskUrl() {
        return (this.taskUrl) ? this.taskUrl += `/${this.examObject.id}` : null;
    }

    getExamConfig(): IExamTaskConfig {
        return {
            exam: this.examObject,
            examUrl: this.getTaskUrl()
        };
    }
}