<button class="btn"
  (click)="showConvertPopup()"
  *ngIf="true || filename && filename.includes('/videos') && !filename.includes('_720p') && !filename.includes('_1080p')"
  [ngClass]="{
    'btn-danger': phase === 'unprocessed', 
    'btn-warning': phase === 'processing', 
    'btn-success': phase === 'processed'
  }"
  [disabled]="phase === 'processed' || phase === 'processing'"
>
  Konvertálás
</button>

<ng-template #convertDialog>
    <h2 matDialogTitle>Videó konvertálása</h2>
    <mat-dialog-content>
      <form #modalForm="ngForm">
        <p>Fájl: {{filename}}</p>
        <mat-form-field appearance="fill">

          <mat-label>Felbontás</mat-label>
          <select matNativeControl name="resolution" ngModel>
            <option value="720p" selected>720p (1200 x 720)</option>
            <option value="1080p">1080p (1920 x 1080)</option>
          </select>
        </mat-form-field>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button matDialogClose color="primary" [mat-dialog-close]="null">Mégse</button>
      <button mat-button matDialogClose cdkFocusInitial [mat-dialog-close]="modalForm.value">Mentés</button>
    </mat-dialog-actions>
</ng-template>