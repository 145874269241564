import {Component, Input, OnInit} from "@angular/core";
import {ITask} from "../../../interfaces/task.interface";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {isNullOrUndefined} from "util";
import {Sorting} from "../../../utils/sorting";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../../../services/environment.service";

@Component({
    moduleId: module.id,
    selector: "select-task",
    templateUrl: "select-task.component.html",
    styleUrls: ["../corrector.component.css"]
})

export class SelectTaskComponent implements OnInit {

    @Input("task") task: ITask;

    private prefixUrl: string;
    private token: string;
    private examHttpApi: ExamHttpApi;
    private groupId = 0;
    answerIndexes: number[] = [];
    areQuestionsAnArray: boolean;
    fileUrl: string;
    isMp3: boolean;
    hasMediaFile: boolean;
    areGroupNamesVisible: boolean[] = [];
    hasTwoExample: boolean = false;
    answerArray = [];

    constructor(private http: HttpClient,
                private environmentService: EnvironmentService) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setSelectedTaskUrl();
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;
    }

    ngOnInit() {
        this.task.items = Sorting.sortTaskItemsByOrder(this.task.items);

        this.task["items"].forEach((item:any) => {
            item.item_data = (typeof item.item_data === "string") ? JSON.parse(item.item_data) : item.item_data;


            if (this.environmentService.get('examType') !== 'mmk') {
                if (!isNullOrUndefined(item.exam_answer) && item.exam_answer.length > 0 && !isNullOrUndefined(item.exam_answer[0].answer_data)) {
                    item["exam_answer"][0].answer_data = (typeof item["exam_answer"][0].answer_data === "string") ?
                        JSON.parse(item["exam_answer"][0].answer_data) : item["exam_answer"][0].answer_data;
                }
            } else {

                let exam_answer = (typeof item.exam_answer === "string") ? JSON.parse(item.exam_answer) : item.exam_answer;

                exam_answer.map((answer) => {
                    if (!isNullOrUndefined(answer) && !isNullOrUndefined(answer.answer_data)) {
                        let setIndex = (typeof answer.answer_data === "string") ?
                            JSON.parse(answer.answer_data).answers[0] : answer.answer_data;

                        this.answerArray[setIndex] = true;
                    }
                });

                item["exam_answer"].answer_data = this.answerArray;
            }
        });

        this.hasTwoExample = !isNullOrUndefined(this.task.task_data.two_example_items) && this.task.task_data.two_example_items === true;

        this.task.items.forEach((item, index) => {
            const itemData = item.item_data;

            if (itemData.questions instanceof Array) {
                this.areQuestionsAnArray = true;

                if (!isNullOrUndefined(itemData.groupId)) {
                    this.fillAreGroupNamesVisible(itemData, index);
                }

                itemData.questions[0].answers.forEach((answer, innerIndex) => {
                    this.fillAnswerIndexes(answer, innerIndex);
                });
            } else {
                this.areQuestionsAnArray = false;

                if (!isNullOrUndefined(itemData.groupId)) {
                    this.fillAreGroupNamesVisible(itemData, index);
                }

                itemData.questions.answers.forEach((answer, innerIndex) => {
                    this.fillAnswerIndexes(answer, innerIndex);
                });
            }
        });

        if (!isNullOrUndefined(this.task.task_data) && !isNullOrUndefined(this.task.task_data.file_url)) {
            this.hasMediaFile = true;
            this.fileUrl = this.task.task_data.file_url;
            this.isMp3 = this.task.task_data.file_url.indexOf("mp3") > 0;
        }
    }

    private fillAnswerIndexes(answer, index) {
        if (answer.multipleAnswerRadio) {
            this.answerIndexes.push(index);
        }
    }

    private fillAreGroupNamesVisible(itemData, index) {
        if (index === 0) {
            this.groupId = itemData.groupId;
            this.areGroupNamesVisible.push(true);
        } else {
            if (itemData.groupId !== this.groupId) {
                this.groupId = itemData.groupId;
                this.areGroupNamesVisible.push(true);
            } else {
                this.areGroupNamesVisible.push(false);
            }
        }
    }

    private answerChecked(answers, k){
        // answer_data.answers[0]
        console.log('SOME:', answers, k);

        //item.exam_answer.length > 0 && item.exam_answer[0].answer_data && item.exam_answer[0].answer_data.answers[0] === j ? true : null
        return answers.some(elem => (elem === k));
    }
}
