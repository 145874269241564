import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from "@angular/core";
import {WebsocketService} from "../../services/websocket.service";
import {ToastrService} from "ngx-toastr";
import {AuthService} from "../../services/auth.service";

@Component({
    moduleId: module.id,
    selector: "chat",
    templateUrl: "chat.component.html",
    styleUrls: ["chat.component.css"]
})
export class ChatComponent implements OnInit {

    @Input("candidate") candidate: any;
    @ViewChild('chatContents') private chatContents: ElementRef;

    @HostListener('window:resize', ['$event'])
    onResize() {
        ChatComponent.rowSize = Math.floor(window.innerWidth / 403);
        this.show(true);
    }

    static zIndex = 100; // shared current zIndex
    static rowSize = 0;
    visible = false;
    myzIndex = 0;
    alignRight = 0;
    alignBottom = 0;
    panelStyle: String = 'panel-primary';
    messages = [];
    draft = "";
    unread = 0;

    constructor(private websocketService: WebsocketService,
                private toast: ToastrService,
                private auth: AuthService) {
    }

    show(resize?) {
        let previsible = this.visible;
        if (typeof resize === 'undefined') {
            this.visible = true;
        }

        ChatComponent.zIndex++;
        this.myzIndex = ChatComponent.zIndex;
        if (previsible !== this.visible) {
            this.alignRight = ((this.myzIndex - 101) % ChatComponent.rowSize) * 403;
        }
        setTimeout(() => {
            if (this.chatContents) {
                this.chatContents.nativeElement.scrollTop = this.chatContents.nativeElement.scrollHeight;
            }
        });
    }

    hide() {
        this.visible = false;
    }

    send() {
        const message = {
            'sender': this.auth.$userStatus,
            'candidate_exam_id': this.candidate.id,
            'time': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, 19).replace('T', ' '),
            'content': this.draft
        };

        this.messages.push(message);
        this.websocketService.sendChatMessage(message);

        this.draft = "";
        setTimeout(() => {
            this.chatContents.nativeElement.scrollTop = this.chatContents.nativeElement.scrollHeight;
        });
    }

    receive = (data) => {
        if (data.candidate_exam_id === this.candidate.id) {
            this.messages.push(data);
            this.panelStyle = 'panel-danger';
            this.show();
        }
    };

    ngOnInit(): void {
        ChatComponent.rowSize = Math.floor(window.innerWidth / 403);
        this.websocketService.subscribeForSocketEvent('chat', this.receive);

        if (this.myzIndex === 0 && this.candidate.messages && this.candidate.messages.length > 0) {
            this.unread = this.candidate.messages.length; //.emit(this.candidate.messages.length);
            this.panelStyle = 'panel-primary';
            this.messages = Object.keys(this.candidate.messages).map((k) => this.candidate.messages[k]);
        }
    }

    noticed() {
        this.panelStyle = 'panel-primary';
        this.unread = 0;
    }
}
