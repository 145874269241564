import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import { map, catchError  } from 'rxjs/operators';
import {EnvironmentService} from './environment.service';
import {RestConfig} from "../http_apis/rest_config.http";
import {Observable} from "rxjs";
import {environment} from "../../environments/environment";
import {IKeyManagerOutput} from "../interfaces/key_manager.interface";

/**
 * This service is used to communicate with the iTolc API
 */
@Injectable()
export class ApiService {

    constructor(
        private http: HttpClient,
        private environmentService: EnvironmentService,
    ) {

    }

    getUsers(paginationParams) {
        return this.http.get(this.environmentService.get('apiPrefixUrl') + 'users/get?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) + '&size=' + (paginationParams.size ? paginationParams.size : 10), RestConfig.getReqOpt());
    }

    /**
     * Get candidate list with pagination and filters
     * @param filters
     * @param paginationParams
     */
    getCandidateList(filters, paginationParams) {
        const filterString = this.convertFiltersToString(filters);
        return this.http.get(this.environmentService.get('apiPrefixUrl') +
            'candidates?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) +
            '&size=' + (paginationParams.size ? paginationParams.size : 10) +
            (filterString === '' ? '' : filterString)
            , RestConfig.getReqOpt());
    }

    getCheckingIdentityListForInspector(paginationParams): Observable<any> {
        return this.http.get(this.environmentService.get('apiPrefixUrl') + 'exam/getCheckingIdentityListForInspector?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) + '&size=' + (paginationParams.size ? paginationParams.size : 10), RestConfig.getReqOpt());
    }

    // @TODO: Slow ?
    getExamList(paginationParams): Observable<any> {
        return this.http.get(this.environmentService.get('apiPrefixUrl') + 'mmk/exam/getExamsList?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) + '&size=' + (paginationParams.size ? paginationParams.size : 10), RestConfig.getReqOpt());
    }

    // @TODO: Explain why do we need two functions for the same goal, see getExamList
    getExamPage(paginationParams): Observable<any> {
        return this.http.get(this.environmentService.get('apiPrefixUrl') + 'exam/getExamPage?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) + '&size=' + (paginationParams.size ? paginationParams.size : 10), RestConfig.getReqOpt());
    }

    newUser(user) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'users/manage', user, RestConfig.getReqOpt());
    }

    updateUser(user) {
        return this.http.patch(this.environmentService.get('apiPrefixUrl') + 'users/manage', user, RestConfig.getReqOpt());
    }

    /**
     * Returns all the unique answers that has been set for this task.
     * @param taskId
     * @returns string[]
     */
    getShortAnswers(itemId) {
        return this.http.get(this.environmentService.get('apiPrefixUrl') + `item/${itemId}/getShortAnswers`, RestConfig.getReqOpt());
    }

    /**
     *
     * @param itemIds
     */
    getCompletionAnswersForIds(itemIds: number[]) {
        return this.http.post(
            this.environmentService.get("apiPrefixUrl") + `items/getCompletionAnswersForIds`,
            {itemIds},
            RestConfig.getReqOpt()
        );
    }

    /**
     * Updates the specific items, and task data
     *
     * @param data
     */
    updateCompletionItemsAndTask(data: IKeyManagerOutput) {
        return this.http.post(
            this.environmentService.get("apiPrefixUrl") + `/items/updateCompletionItemsAndTask`,
            data,
            RestConfig.getReqOpt()
        );
    }

    /**
     * Updates the specific item's item_data column.
     * Short answer key manager component uses this endpoint.
     * @param itemData
     */
    updateItemData(itemData) {
        return this.http.post(this.environmentService.get(
            'apiPrefixUrl') + `items/${itemData.id}/updateItemData`,
            itemData,
            RestConfig.getReqOpt()
        );
    }

    /**
     * Converts the specific file to the given resolution
     * @param filename
     * @param resolution
     */
    convertVideo(filename, resolution) {
        console.log(filename, resolution);
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'video/convert', {
            'filename': filename,
            'resolution': resolution
        }, RestConfig.getReqOpt());
    }

    /**
     * Get default parameters for a new facets sd file
     * @param examId
     * @param part
     */
    getDefaultParams(examId, part) {

        return this.http.get(this.environmentService.get('apiPrefixUrl') + 'facets/getDefaultParams?exam_id=' + examId + '&part=' + part, RestConfig.getReqOpt());
    }

    /**
     * Create a new facets SD file based on the paramters
     * @param examId
     * @param part
     * @param params
     */
    createFacetsInputFile(examId, part, params, group_id = null) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'facets/generateInputFile', {
            exam_id: examId,
            module: part,
            params: params,
            group_id: group_id,
        }, RestConfig.getReqOpt());
    }

    saveAsNewInputFile(examId, part, fileData, fileName) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'facets', {
            exam_id: examId,
            module: part,
            file_data: fileData,
            file_name: fileName,
        }, RestConfig.getReqOpt());
    }


    /**
     * Change the date of the exam real time
     * @param examId
     * @param date
     */
    changeExamDate(examId, date) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'exam/setStartDate', {
            'exam_id': examId,
            'date': date,
        }, RestConfig.getReqOpt());
    }

    /**
     * Get live sessions for an exam
     * @param exam_id
     */
    getLiveSessions(exam_id: number) {
        return this.http.get(this.environmentService.get('apiPrefixUrl') + 'exam/getLiveSessions/' + exam_id, RestConfig.getReqOpt());
    }

    /**
     * Change date of a live session
     * @param candidate_exam_id
     * @param date
     */
    changeLiveSession(candidate_exam_id, date) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'exam/changeLiveSession', {
            'candidate_exam_id': candidate_exam_id,
            'live': date,
        }, RestConfig.getReqOpt());
    }

    /**
     * Update candidate's exam's status, eg live started.
     * @param candidate_exam_id
     * @param status
     */
    setCandidateExamStatus(candidate_exam_id, status) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'candidates/' + candidate_exam_id + '/setStatus', {
            'status': status,
        }, RestConfig.getReqOpt());
    }

    /**
     * Sets the final result for the exam
     * @param candidate_exam_id
     * @param success
     */
    setLiveResult(candidate_exam_id, success) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + 'exam/setLiveResult', {
            'candidate_exam_id': candidate_exam_id,
            'success': success,
        }, RestConfig.getReqOpt());
    }

    /**
     * Query all tasks
     * @param filters
     * @param paginationParams
     */
    getTaskList(filters, paginationParams) {
        const filterString = this.convertFiltersToString(filters);
        return this.http.get(this.environmentService.get('apiPrefixUrl')
            + 'tasks/get?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) + '&size=' + (paginationParams.size ? paginationParams.size : 10)
            + (filterString === '' ? '' : filterString)
            , RestConfig.getReqOpt());
    }

    /**
     *
     * @param filters
     * @param paginationParams
     */
    getExamsWithFacets(filters, paginationParams) {
        const filterString = this.convertFiltersToString(filters);
        return this.http.get(this.environmentService.get('apiPrefixUrl')
            + 'exam/getExamsWithFacets?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) + '&size=' + (paginationParams.size ? paginationParams.size : 10)
            + (filterString === '' ? '' : filterString)
            , RestConfig.getReqOpt());
    }


    /**
     * Convert filter object to an URL string
     * @param filters
     */
    private convertFiltersToString(filters) {
        let filterString = '';
        for (const key in filters) {
            if (filters[key].value === null) {
                continue;
            }
            filterString += '&' + key + '=' + filters[key].value;
        }
        return filterString;
    }

    /**
     * Update exam after oral test
     * @param candidate_exam_id
     * @param improved
     */
    updateExamImproved(candidate_exam_id: number, improved: number) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + `mmk/exam/updateImproved`, {
            candidate_exam_id: candidate_exam_id,
            improved: improved
        }, RestConfig.getReqOpt()).subscribe();
    }

    /**
     * Archive a task
     * @param data
     */
    postDestroyTask(data: Object) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + `tasks/destroy`, data, RestConfig.getReqOpt());
    }

    // Debug method.
    getRestConfig() {
        console.log(RestConfig.getReqOpt());
    }

    /**
     * Get simplified exam list, that contains just the ID
     */
    getExamsForDropDownList(): Observable<any> {
        return this.http.get(this.environmentService.get('apiPrefixUrl')
            + 'exam/getExamsForDropDownList'
            , RestConfig.getReqOpt());

    }

    /**
     * Create a new facet group
     * @param data
     */
    newFacetsGroup(data) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + `facets/newGroup`, data, RestConfig.getReqOpt());
    }

    /**
     * Clear test data
     * @param exam_id
     */
    resetTestAnswers(exam_id) {
        return this.http.post(this.environmentService.get('apiPrefixUrl') + `exam/resetTestAnswers/` + exam_id, {}, RestConfig.getReqOpt());
    }

    /**
     * QUery facet groups
     * @param paginationParams
     */
    getFacetsGroups(paginationParams) {
        return this.http.get(this.environmentService.get('apiPrefixUrl')
            + 'facets/getGroups?page=' + ((typeof paginationParams.page !== 'undefined' && paginationParams.page  !== null )? (paginationParams.page + 1) : 1) + '&size=' + (paginationParams.size ? paginationParams.size : 10)
            , RestConfig.getReqOpt());
    }

    /**
     * Get files for a facet group
     * @param groupId
     */
    getFacetsGroupFiles(groupId) {
        return this.http.get(this.environmentService.get('apiPrefixUrl')
            + 'facets/getFacetsGroupFiles/' + groupId
            , RestConfig.getReqOpt());
    }

    /**
     * Remove a candidate_exam entry (unsubscribe user from the exam)
     * @param id
     */
    removeCandidateFromExam(id){
        return this.http.delete(
            this.environmentService.get('apiPrefixUrl') + '/mmk/removeCandidateFromExam/' + id,
            RestConfig.getReqOpt())
            .map((response: any) => {
                return response.data;
            });
    }

    getDownloadRequestOptions () {
        let requestOptions = Object.assign({}, RestConfig.getReqOpt()); // Copy the object to not mess up other requests
        requestOptions['responseType'] = 'blob' as 'json';
        requestOptions['observe'] = 'response';
        return requestOptions;
    }

    /**
     * Export selected tasks
     * @param taskList
     * @param callback
     */
    downloadTasksExport(taskList, callback){
        return this.http.post(
            this.environmentService.get('apiPrefixUrl') + `export/tasksExport`,
            {tasks: taskList},
            this.getDownloadRequestOptions()
        ).subscribe(this.downloadFile.bind(this, callback));
    }

    /**
     * Download decision list for the exam
     * @param exam_id
     * @param callback
     */
    downloadDecisionList(exam_id, callback = () => {}){
        return this.http.post(
            this.environmentService.get('apiPrefixUrl') + `export/decisionList/` + exam_id ,
            {},
            this.getDownloadRequestOptions()
        ).subscribe(this.downloadFile.bind(this, callback));
    }

    /**
     * Download a response resource
     * @param callback
     * @param response
     */
    downloadFile(callback = null, response: HttpResponse<Blob>) {
        let filename: string = this.getFileName(response);
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType as any }));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();

        if (callback) {
            callback();
        }
    }

    /**
     * Parse filename from response
     * @param response
     */
    getFileName(response: HttpResponse<Blob>) {
        const contentDisposition: string = response.headers.get('Content-Disposition');

        if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
                return matches[1].replace(/['"]/g, '');
            }
        }

        return 'download';
    }

    /**
     * @param examIds
     * @param part
     */
    excelExport(examIds: number[], part: string) {
        let postUrl = `${environment.apiPrefixUrl}export/facet-group-excel-export`;
        let requestOptions = RestConfig.getReqOpt();
        let body: any = {
            exam_ids: examIds,
            part: part
        };

        return this.http.post(postUrl, body, requestOptions).pipe(map((response:any) => {
            let jsonData = response;

            let file = jsonData.file;
            let token = window.localStorage.getItem("itolc_token");
            let url = `${environment.apiPrefixUrl}export/getDownloadCSV/${file}&token=${token}`;
            window.open(url);

        })).subscribe();
    }
 }
