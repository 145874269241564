import {Component} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {FileUploadService} from "../../services/shared/upload.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {isNullOrUndefined} from "util";

const TYPES = [$localize`Szöveg - szöveg`, $localize`Kép - szöveg`, $localize`Kép - kép`, $localize`Fejezet - állítás`];

@Component({
    moduleId: module.id,
    selector: "app-pairing",
    templateUrl: "pairing.component.html",
    styleUrls: ["pairing.component.css"]
})
export class PairingComponent {

    pairing: FormGroup;
    mainForm: FormGroup;
    sourcePairingControl: FormControl = new FormControl(null);
    itemFormArrays: FormArray = new FormArray([]);
    textInput: FormControl = new FormControl(null, Validators.required);
    textInputDuplicated: FormControl = new FormControl(null);
    taskData: Object;
    reusableItem: FormControl = new FormControl();
    pickExistingFirst: FormControl = new FormControl();
    pickExistingSecond: FormControl = new FormControl();
    hasUploadField: boolean;
    sourceRequired: boolean = true;
    taskId: number;
    typeValue: number;
    prefixUrl: string;
    token: string;
    firstObjectValue: string = "";
    secondObjectValue: string = "";
    typeRadios: Array<string> = TYPES;
    private changeView: FormControl = new FormControl();
    private items: Array<any>;
    private sectionItems: Array<FormArray>;
    private typeControl: FormControl = new FormControl(null, Validators.required);
    private requieredRegSheetForm: boolean;
    private isFormValid: boolean = true;
    isDuplicate: boolean = false;


    constructor(private formBuilder: FormBuilder,
                public examCreatorFormService: ExamCreatorFormService,
                private fileUploadService: FileUploadService,
                private activatedRoute: ActivatedRoute) {

        this.mainForm = this.examCreatorFormService.$examCreatorFrom;
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.pairing = formBuilder.group({
            sourcePairingControl: this.sourcePairingControl,
            type: this.typeControl,
            reusable: this.reusableItem,
            reverse: new FormControl(),
            change_view: this.changeView
        });

        this.typeControl.valueChanges.subscribe((value) => {
            this.typeValue = value;
        });

        this.fileUploadService.uploadedFile.subscribe(({url, type, index}) => {
            if (type && typeof index === "number") {
                if (type === "first") {
                    if (this.itemFormArrays.at(index) !== null) {
                        this.itemFormArrays.at(index).patchValue({
                            textInput: url
                        });
                    } else {
                        this.itemFormArrays.push(new FormControl({
                            textInput: url
                        }));
                    }

                } else if (type === "second") {
                    if (this.itemFormArrays.at(index) !== null) {
                        this.itemFormArrays.at(index).patchValue({
                            textInputDuplicated: url
                        });
                    } else {
                        this.itemFormArrays.push(new FormControl({
                            textInputDuplicated: url
                        }));
                    }
                }
            } else {
                this.pairing.patchValue({
                    file_url: url
                });
            }
        });

        this.activatedRoute.data.subscribe(({type}) => {
            console.info(type);
            this.hasUploadField = type !== "reading";
            this.sourceRequired = type !== "reading";

            if (this.hasUploadField) {
                this.pairing.setControl("file_url", new FormControl());
            } else {
                this.pairing.removeControl("file_url");
            }
        });

        this.activatedRoute.params.subscribe(({id, duplicate}) => {
            if(!isNullOrUndefined(duplicate)){
                if(duplicate === 'duplicate'){
                    this.isDuplicate = true;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                } else {
                    this.isDuplicate = false;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                }
            }

            if (!id) {
                this.examCreatorFormService.backToMainForm();
            }

            this.taskId = id;

            let taskValues = this.examCreatorFormService.getTaskDataValue();

            if (taskValues !== null) {
                this.pairing.patchValue(taskValues);
            }

            if (this.taskId) {
                this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {

                    this.taskData = JSON.parse(examTask.task_data);
                    examCreatorFormService.previousNumber = examTask.number;

                    this.pairing.patchValue(this.taskData);

                    this.examCreatorFormService.setExamObject(examTask);
                    this.examCreatorFormService.setExistInstructionControl(examTask.instruction);

                    examTask.view_date = examTask.view_date.replace(/:[0-9]+$/, "");

                    this.mainForm.patchValue(examTask);

                    if (examTask.items) {
                        this.items = examTask.items;
                        this.sectionItems = [];
                        this.setItemArraysWithExistData(this.items);
                    }

                });
            }
        });

        this.examCreatorFormService.requiredRegistryField.subscribe((data) => {
            if (isNullOrUndefined(data)) {
                this.requieredRegSheetForm = true;
            }
            this.requieredRegSheetForm = data;
        });

        this.mainForm.valueChanges.subscribe(() => {
            this.requieredRegSheetForm = this.mainForm.controls.instruction.value !== "" ||
                isNullOrUndefined(this.mainForm.controls.instruction.value);
            this.examCreatorFormService.registryFormSend.emit(this.mainForm);
        });

    }

    changeViewChecked(event: Event) {
        let element = <HTMLInputElement> event.target;
        this.changeView.setValue(element.checked);
    }

    removeTaskFile() {
        this.taskData["file_url"] = null;
        this.pairing.patchValue(this.taskData);
    }

    removeItemFile(id, type) {
        this.items = this.items.map((item) => {
            item.item_data = (typeof item.item_data === "string") ?
                JSON.parse(item.item_data) : item.item_data;
            if (item.item_data.id === id) {
                item.item_data[type] = "";
            }
            return item;
        });
        this.setItemArraysWithExistData(this.items);
    }

    onClickNewOptionBtn(event: Event) {
        event.preventDefault();
        console.log("create new pairing module");
        let typeControl: FormControl = new FormControl(null, Validators.required);

        this.itemFormArrays.push(new FormGroup({
            type: typeControl,
            textInput: new FormControl(null, Validators.required),
            textInputDuplicated: new FormControl(null),
            toggle1: new FormControl(false),
            toggle1_id: new FormControl(null),
            toggle2: new FormControl(false),
            toggle2_id: new FormControl(null)
        }));

        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    removedItem(index: number) {
        console.log("Removed radio button item");
        if (this.itemFormArrays.value[index].id) {
            this.examCreatorFormService.itemHttpApi.del(this.itemFormArrays.value[index].id, true).subscribe(() => {
                console.log("Item removed!");
            });
        }
        this.itemFormArrays.removeAt(index);
        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    onChange($event, index, isFirst) {
        if (isFirst) {
            this.itemFormArrays.at(index).patchValue({
                textInput: $event.target.value
            });
        }
        if (!isFirst) {
            this.itemFormArrays.at(index).patchValue({
                textInputDuplicated: $event.target.value
            });
        }
        this.itemFormArrays.at(index).patchValue({
            type: "existing"
        });
    }

    private getItems() {
        this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {
            if (examTask.items) {
                this.items = examTask.items;
                this.sectionItems = [];
                this.setItemArraysWithExistData(this.items);
            }
        });
    }

    onCheck($event, index, num) {
        let toggle = num === 1 ? "toggle1" : "toggle2";
        let arr = num === 1 ? {toggle1: false} : {toggle2: false};

        if (!this.itemFormArrays.value[index][toggle] || this.itemFormArrays.value[index][toggle] === false) {
            arr = num === 1 ? {toggle1: true} : {toggle2: true};
        }

        this.itemFormArrays.at(index).patchValue(arr);
    }

    private setItemArraysWithExistData(items: Array<any>) {

        items.forEach((item, index) => {
            let itemData = (typeof item.item_data === "string") ? JSON.parse(item.item_data) : item.item_data;

            if (itemData.section_items) {
                this.sectionItems[index] = new FormArray([]);

                itemData.section_items.forEach((sectionItem, subIndex) => {
                    let sectionFormGroup = {};

                    for (let sItem in sectionItem) {
                        if (sectionItem.hasOwnProperty(sItem)) {
                            let sectionValue = sectionItem[sItem];
                            sectionFormGroup[sItem] = new FormControl(sectionValue);
                        }
                    }

                    let formGroup = new FormGroup(sectionFormGroup);

                    this.sectionItems[index].insert(subIndex, formGroup);
                });
            }
        });

        let itemArrays = items.map((item, index) => {
            let itemData = (typeof item.item_data === "string") ? JSON.parse(item.item_data) : item.item_data;
            itemData.id = item.id;
            let formGroupConfig = {};

            for (let dataItem in itemData) {
                if (itemData.hasOwnProperty(dataItem)) {
                    let value = itemData[dataItem];

                    if (dataItem !== "section_items") {
                        formGroupConfig[dataItem] = new FormControl(value);
                    } else {
                        formGroupConfig[dataItem] = this.sectionItems[index];
                    }
                }
            }

            if (!formGroupConfig["reverse"]) {
                formGroupConfig["reverse"] = new FormControl();
            }

            if (!formGroupConfig["changeView"]) {
                formGroupConfig["changeView"] = new FormControl();
            }

            return new FormGroup(formGroupConfig);
        });

        this.itemFormArrays = new FormArray(itemArrays);

    }

    private submitted(isNew) {
        const submitBtnTextPrefix = isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`;
        const dateText = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
        });

        if (!isNew) {
            this.examCreatorFormService.taskHttpApi.rescanExamAnswersByTaskId({task_id: this.taskId}).subscribe(() => {
                console.log("Rescan completed");
            });
        }

        this.examCreatorFormService.submittedResult = `${submitBtnTextPrefix} ${dateText}-kor`;

        // Enable save button.
        this.isFormValid = true;
    }

    onSubmit(event: Event) {
        event.preventDefault();

        console.log("Save all block");

        if (this.pairing.valid) {

            this.examCreatorFormService.setTaskDataControlValue(JSON.stringify(this.pairing.value));

            let items: Array<FormGroup> = this.itemFormArrays.value.map((item, index) => {
                return new FormGroup({
                    item_data: new FormControl(JSON.stringify(item)),
                    order: new FormControl(index),
                    id: new FormControl(item.id)
                });
            });

            const _formArray = new FormArray(items);

            this.mainForm.setControl("items", _formArray);

            if (this.mainForm.valid) {

                // Disable save button.
                this.isFormValid = false;

                if (this.taskId) {
                    if(this.isDuplicate){
                        this.insertTask(_formArray, true);
                    } else {
                        this.updateTask(_formArray);
                    }
                } else {
                    this.insertTask(_formArray, false);
                }
            } else {
                this.examCreatorFormService.open.emit(true);
            }

        }
        if (!this.mainForm.valid) {
            this.examCreatorFormService.open.emit(true);
        }
    }

    async insertTask(_formArray, isDuplicate) {
        this.taskId = await this.examCreatorFormService.insertTask(this.mainForm.value, _formArray, isDuplicate);
        this.getItems();
        this.examCreatorFormService.createdTask.emit(this.taskId);
        this.submitted(true);
    }

    async updateTask(_formArray){
        await this.examCreatorFormService.updateTask(this.mainForm.value, _formArray);
        this.getItems();
        this.submitted(false);
    }

    onPreview() {
        let items: Array<FormGroup> = this.itemFormArrays.value.map((item, index) => {
            return new FormGroup({
                item_data: new FormControl(JSON.stringify(item)),
                order: new FormControl(index),
                id: new FormControl(item.id),
                exam_answer: new FormControl(null)
            });
        });

        const _formArray = new FormArray(items);
        this.examCreatorFormService.onPreviewTask(_formArray.value);
    }

    countQuestions(){
        return this.itemFormArrays.value.length;
    }

    /**
     * The video data has changed (possibly by converter), store it in the form
     * @param filename
     */
    updateVideoData(filename: string) {
        this.taskData['file_url'] = `files/videos/${filename}`;
        this.pairing.patchValue(this.taskData);
    }
}
