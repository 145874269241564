import {Component, DoCheck, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {isNullOrUndefined} from "util";
import {EnvironmentService} from "../../services/environment.service";

@Component({
    moduleId: module.id,
    host: {
        id: "landing"
    },
    selector: "app-landing-page",
    templateUrl: "landing-page.component.html",
    styleUrls: ["./landing-page.component.css"]
})
export class LandingPageComponent implements OnInit, DoCheck {

    userStatus: string;

    constructor(private router: Router,
                private authService: AuthService,
                private environmentService: EnvironmentService
    ) {

        this.userStatus = this.authService.$userStatus;

        console.log('Landing page components ctr', this.userStatus);

        if(isNullOrUndefined(this.userStatus)) {
            this.userStatus = window.localStorage.getItem("itolc_status");
            this.authService.$userStatus = this.userStatus;
        }
    }

    ngOnInit() {
        console.log('Landing page init, user role', this.userStatus);
        if (!this.userStatus) {
            // Wait until roles are loaded?
        } else if (!this.router.isActive("landing/exams", false)) {
            this.router.navigateByUrl("/landing/tasks");
        }
    }

    ngDoCheck() {
        if (!isNullOrUndefined(this.userStatus) && this.userStatus === "tester") {
            this.router.navigateByUrl("/corrector/exam-answers");
        }

        if (!isNullOrUndefined(this.userStatus) && this.userStatus === "inspector") {
            if (this.environmentService.get('examType') === 'mmk') {
                this.router.navigateByUrl("/checking-identity");
            } else {
                this.router.navigateByUrl("/exam-roster");
            }

        }

        if (!isNullOrUndefined(this.userStatus) && this.userStatus === "examiner") {
            this.router.navigateByUrl("/corrector/exam-answers");
        }
    }
}
