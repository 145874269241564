<app-header></app-header>
<router-outlet></router-outlet>
<div id="additional-localizations-from-typescript-should-be-deleted-after-proper-solution" *ngIf="false">
    <p i18n>Azonosító</p>
    <p i18n>study_field_id</p>
    <p i18n>Témakör</p>
    <p i18n>Nyelv</p>
    <p i18n>Szint</p>
    <p i18n>Helyszín</p>
    <p i18n>Dátum</p>
    <p i18n>anchoring</p>
    <p i18n>structure</p>
    <p i18n>true_false_percent</p>
    <p i18n>multiple_choice_percent</p>
    <p i18n>writing_percent</p>
    <p i18n>Státusz</p>
    <p i18n>Típus</p>
    <p i18n>Megjegyzés</p>
    <p i18n>Állapot</p>
    <p i18n>deployed_at</p>
    <p i18n>Létrehozva</p>
    <p i18n>updated_at</p>
    <p i18n>Azonosító</p>
    <p i18n>Témakör</p>
    <p i18n>Szakasz</p>
    <p i18n>exam_part</p>
    <p i18n>user_id</p>
    <p i18n>Létrehozó neve</p>
    <p i18n>Fálj neve</p>
    <p i18n>level</p>
    <p i18n>Nyelv</p>
    <p i18n>part</p>
    <p i18n>number</p>
    <p i18n>Feladat neve</p>
    <p i18n>source</p>
    <p i18n>view_date</p>
    <p i18n>source_type</p>
    <p i18n>word_count</p>
    <p i18n>Itemszám</p>
    <p i18n>topic</p>
    <p i18n>text_breed</p>
    <p i18n>instruction</p>
    <p i18n>task_data</p>
    <p i18n>Típus</p>
    <p i18n>format_code_id</p>
    <p i18n>text_source_id</p>
    <p i18n>comment</p>
    <p i18n>archived_at</p>
    <p i18n>Létrehozás dátuma</p>
    <p i18n>Módosítás dátuma</p>
    <p i18n>Felhasználva (nap)</p>
    <p i18n>Formanyomtatvány</p>
    <p i18n>Szövegírás</p>
    <p i18n>Többszörös párosítás</p>
    <p i18n>Feleletválasztás / Alternatív választás</p>
    <p i18n>Rövid válasz</p>
    <p i18n>Szöveg kiegészítés</p>
    <p i18n>Feleletválasztás / Alternatív választás</p>
    <p i18n>Csoportosított feleletválasztás / Alternatív választás</p>
    <p i18n>Rövid válasz</p>
    <p i18n>Többszörös párosítás</p>
    <p i18n>Szöveg kiegészítés</p>
    <p i18n>Beszédkészség</p>
    <p i18n>Igaz-Hamis</p>
    <p i18n>Angol</p>
    <p i18n>Német</p>
    <p i18n>Íráskészség (Írásbeli)</p>
    <p i18n>Írott szöveg értése (Írásbeli)</p>
    <p i18n>Beszédkészség (Szóbeli)</p>
    <p i18n>Hallott szöveg értése (Szóbeli)</p>
    <p i18n>Eredeti</p>
    <p i18n>Rövidített</p>
    <p i18n>Kiegészített</p>
    <p i18n>Funkcionális</p>
    <p i18n>Informatív</p>
    <p i18n>Narratív</p>
    <p i18n="@@newTask">Vizsgafeladat létrehozása</p>
    <p i18n="@@taskList">Vizsgafeladatok</p>
    <p i18n="@@createExam">Új vizsga létrehozása</p>
    <p i18n="@@examList">Vizsgák</p>
    <p i18n="@@corrector">Vizsgák értékelése</p>
    <p i18n="@@identityCheck">Felügyelés</p>
    <p i18n="@@analysis">Elemzés</p>
    <p i18n="@@examRoster">Vizsga névsor</p>
    <p i18n="@@diaries">Naplóbejegyzések</p>
    <p i18n="@@exports">Exportok</p>
    <p i18n="@@examSync">Vizsgaletöltés státusz</p>
    <p i18n="@@userList">Felhasználók</p>
    <p i18n="@@createExam">Új vizsga létrehozása</p>
    <p i18n="@@newCandidate">Vizsgázó felvétele</p>
    <p i18n="@@newCandidate">Vizsgázó felvétele</p>
    <p i18n="@@candidateList">Vizsgázók</p>
    <p i18n="@@live">Éles</p>
    <p i18n="@@retake">Pótvizsga</p>
    <p i18n="@@pilot">Pilot</p>
    <p i18n="@@online">Online próbavizsga</p>
    <p i18n="@@local">Helyi próbavizsga</p>
    <p i18n="@@test">Teszt</p>
    <p i18n="@@7073918997194738201">Angol</p>
    <p i18n="@@5361249130152841730">Német</p>
    <p i18n="@@2815851643331238130">Íráskészség (Írásbeli)</p>
    <p i18n="@@1914662031636602813">Írott szöveg értése (Írásbeli)</p>
    <p i18n="@@3386961743846881885">Beszédkészség (Szóbeli)</p>
    <p i18n="@@425788222419836017">Hallott szöveg értése (Szóbeli)</p>
    <p i18n="@@6140397163707729538">Eredeti</p>
    <p i18n="@@9133502145212343531">Rövidített</p>
    <p i18n="@@1114035919486941239">Kiegészített</p>
    <p i18n="@@5525082202865304496">Funkcionális</p>
    <p i18n="@@7958122765894335522">Informatív</p>
    <p i18n="@@3542966491211751413">Narratív</p>
    <p i18n="@@6935245119761650651">Duplikálás mentése</p>
    <p i18n="@@6487864089140545507">Mentés</p>
    <p i18n="@@6935245119761650651">Duplikálás mentése</p>
    <p i18n="@@6486538196529270641">Azonosító</p>
    <p i18n="@@3149687387323931065">study_field_id</p>
    <p i18n="@@750054066406230448">Témakör</p>
    <p i18n="@@1991987637698634808">Nyelv</p>
    <p i18n="@@3673454325750174005">Szint</p>
    <p i18n="@@7191981616218172309">Helyszín</p>
    <p i18n="@@5188690858274389782">Dátum</p>
    <p i18n="@@6424118322955229393">anchoring</p>
    <p i18n="@@3099317483554120715">structure</p>
    <p i18n="@@4322986438659214726">true_false_percent</p>
    <p i18n="@@5238267854637314914">multiple_choice_percent</p>
    <p i18n="@@128576272023108411">writing_percent</p>
    <p i18n="@@5468571797573237515">Státusz</p>
    <p i18n="@@6326146529732945754">Típus</p>
    <p i18n="@@3197705249423563373">Megjegyzés</p>
    <p i18n="@@7080471221604517922">Állapot</p>
    <p i18n="@@679846511872514184">deployed_at</p>
    <p i18n="@@6111884206853053709">Létrehozva</p>
    <p i18n="@@234401016212218875">updated_at</p>
    <p i18n="@@6486538196529270641">Azonosító</p>
    <p i18n="@@750054066406230448">Témakör</p>
    <p i18n="@@1286693785282876299">Szakasz</p>
    <p i18n="@@4052382487222295824">exam_part</p>
    <p i18n="@@7352563887792862895">user_id</p>
    <p i18n="@@4490451156517891174">Létrehozó neve</p>
    <p i18n="@@6656163163442103288">Fálj neve</p>
    <p i18n="@@7328175834266423792">level</p>
    <p i18n="@@1991987637698634808">Nyelv</p>
    <p i18n="@@456158422862874991">part</p>
    <p i18n="@@8505625387835214066">number</p>
    <p i18n="@@4091156547968224568">Feladat neve</p>
    <p i18n="@@4499371134937174370">source</p>
    <p i18n="@@7271949217962262887">view_date</p>
    <p i18n="@@2495757549539904670">source_type</p>
    <p i18n="@@2963932526268634185">word_count</p>
    <p i18n="@@1774607334886679006">Itemszám</p>
    <p i18n="@@7718307525000161456">topic</p>
    <p i18n="@@2636903678672697270">text_breed</p>
    <p i18n="@@4216110970266128186">instruction</p>
    <p i18n="@@1779422200019676572">task_data</p>
    <p i18n="@@6326146529732945754">Típus</p>
    <p i18n="@@4860649521579560885">format_code_id</p>
    <p i18n="@@79619925283817745">text_source_id</p>
    <p i18n="@@8718048249801659622">comment</p>
    <p i18n="@@7615682107089240989">archived_at</p>
    <p i18n="@@8021096106943095730">Létrehozás dátuma</p>
    <p i18n="@@3422553996270054663">Módosítás dátuma</p>
    <p i18n="@@4040724584194522531">Felhasználva (nap)</p>
    <p i18n="@@452410258798760664">Formanyomtatvány</p>
    <p i18n="@@8901090853676344480">Szövegírás</p>
    <p i18n="@@428508676174026591">Többszörös párosítás</p>
    <p i18n="@@3902218584139149193">Feleletválasztás / Alternatív választás</p>
    <p i18n="@@3119572194095909109">Rövid válasz</p>
    <p i18n="@@2186190547956305670">Szöveg kiegészítés</p>
    <p i18n="@@3902218584139149193">Feleletválasztás / Alternatív választás</p>
    <p i18n="@@6539403880940887824">Csoportosított feleletválasztás / Alternatív választás</p>
    <p i18n="@@3119572194095909109">Rövid válasz</p>
    <p i18n="@@428508676174026591">Többszörös párosítás</p>
    <p i18n="@@2186190547956305670">Szöveg kiegészítés</p>
    <p i18n="@@4471282703052122496">Beszédkészség</p>
    <p i18n="@@3118896585962885739">Igaz-Hamis</p>
    <p i18n="@@4341797201760522666">Sikeres törlés</p>
    <p i18n="@@group">Csoport</p>
    <p i18n="@@8569259932403634104">Mentve</p>
    <p i18n="@@5955027786640543423">Frissítve</p>
    <p i18n="@@249277070510755459">A következő usereket nem sikerült felvenni hibás helyszín miatt: </p>
    <p i18n="@@7770879707487244045">Nincs fájl kiválasztva!</p>
    <p i18n="@@4595055543099841907">Ezzel véglegesíted a vizsgát! Ezután nem módosítható sem a FELADATSOR sem a VIZSGÁZÓK névsora! Biztos vagy benne?</p>
    <p i18n="@@5574004534307160235">A vizsgát nem sikerült lezárni!</p>
    <p i18n="@@8569259932403634104">Mentve</p>
    <p i18n="@@5955027786640543423">Frissítve</p>
    <p i18n="@@249277070510755459">A következő usereket nem sikerült felvenni hibás helyszín miatt: </p>
    <p i18n="@@7770879707487244045">Nincs fájl kiválasztva!</p>
    <p i18n="@@4595055543099841907">Ezzel véglegesíted a vizsgát! Ezután nem módosítható sem a FELADATSOR sem a VIZSGÁZÓK névsora! Biztos vagy benne?</p>
    <p i18n="@@5574004534307160235">A vizsgát nem sikerült lezárni!</p>
    <p i18n="@@please-fill-in">Kérjük, töltse ki a mezőt!</p>
    <p i18n="@@2297166990279574786">Autentikációs hiba történt. Kérjük jelentkezzen be újra.</p>
    <p i18n="@@1179211440472861326">Másodlagos videó újracsatlakoztatása nem sikerült.</p>
    <p i18n="@@8802773914905315014">Művelet sikertelen.</p>
    <p i18n="@@6253639126594186223">Elsődleges videó újracsatlakoztatása nem sikerült.</p>
    <p i18n="@@8802773914905315014">Művelet sikertelen.</p>
    <p i18n="@@260141497130782897">Videócsatornák csatlakoztatása...</p>
    <p i18n="@@8042480667651446710">Művelet végrehajtva.</p>
    <p i18n="@@2297166990279574786">Autentikációs hiba történt. Kérjük jelentkezzen be újra.</p>
    <p i18n="@@1179211440472861326">Másodlagos videó újracsatlakoztatása nem sikerült.</p>
    <p i18n="@@8802773914905315014">Művelet sikertelen.</p>
    <p i18n="@@6253639126594186223">Elsődleges videó újracsatlakoztatása nem sikerült.</p>
    <p i18n="@@8802773914905315014">Művelet sikertelen.</p>
    <p i18n="@@260141497130782897">Videócsatornák csatlakoztatása...</p>
    <p i18n="@@8042480667651446710">Művelet végrehajtva.</p>
    <p i18n="@@3195567689768162575">vizsgázó válasza:</p>
    <p i18n="@@6786984097735370965">Szöveg - szöveg</p>
    <p i18n="@@6595976945569456198">Kép - szöveg</p>
    <p i18n="@@9057012014280153264">Kép - kép</p>
    <p i18n="@@8313273612194771499">Fejezet - állítás</p>

<p i18n="@@1591923602636436417">iTOLC Feladatmegoldás</p>
<p i18n="@@8590522933643701215">iTOLC Szerkezet</p>
<p i18n="@@1434004452034905055">iTOLC Nyelvhelyesség</p>
<p i18n="@@4340321071603025554">iTOLC Szókincs</p>
<p i18n="@@1591923602636436417">iTOLC Feladatmegoldás</p>
<p i18n="@@8590522933643701215">iTOLC Szerkezet</p>
<p i18n="@@1434004452034905055">iTOLC Nyelvhelyesség</p>
<p i18n="@@4340321071603025554">iTOLC Szókincs</p>
<p i18n="@@1770191814809619250">iTOLC Beszédmód</p>
<p i18n="@@1591923602636436417">iTOLC Feladatmegoldás</p>
<p i18n="@@1434004452034905055">iTOLC Nyelvhelyesség</p>
<p i18n="@@4340321071603025554">iTOLC Szókincs</p>
<p i18n="@@1770191814809619250">iTOLC Beszédmód</p>
<p i18n="@@1591923602636436417">iTOLC Feladatmegoldás</p>
<p i18n="@@8590522933643701215">iTOLC Szerkezet</p>
<p i18n="@@1434004452034905055">iTOLC Nyelvhelyesség</p>
<p i18n="@@4340321071603025554">iTOLC Szókincs</p>
<p i18n="@@1591923602636436417">iTOLC Feladatmegoldás</p>
<p i18n="@@8590522933643701215">iTOLC Szerkezet</p>
<p i18n="@@1434004452034905055">iTOLC Nyelvhelyesség</p>
<p i18n="@@4340321071603025554">iTOLC Szókincs</p>
<p i18n="@@1770191814809619250">iTOLC Beszédmód</p>
<p i18n="@@1591923602636436417">iTOLC Feladatmegoldás</p>
<p i18n="@@1434004452034905055">iTOLC Nyelvhelyesség</p>
<p i18n="@@4340321071603025554">iTOLC Szókincs</p>
<p i18n="@@1770191814809619250">iTOLC Beszédmód</p>
<p i18n="@@1450195220140039102">Weboldal űrlap</p>
<p i18n="@@7774115802453299904"> Legalább egy itemnek szerepelnie kell a szövegben.</p>
<p i18n="@@7854446014999250847">Valószínűleg már válaszokat tartalmaz.</p>
<p i18n="@@7301061117798385654">A vizsga már nem módosítható!</p>
<p i18n="@@7490320208863685631">Ellenőrízze az adatokat, és próbálja újra.</p>
<p i18n="@@1360269434339397067">Hibás email cím vagy jelszó</p>
<p i18n="@@385865174051170037">A kiszolgáló az alábbi választ küldte: </p>
<p i18n="@@7222028832403992479">Feldolgozási hiba</p>
<p i18n="@@385865174051170037">A kiszolgáló az alábbi választ küldte: </p>
<p i18n="@@5508296271036738583">Hibás lekérés</p>
<p i18n="@@2190323237855656445">A lekérés közben hiba történt: </p>
<p i18n="@@1107765798879153457">Hiba az adatok elküldésekor</p>
<p i18n="@@7155034871068214024">Az adatok elküldésekor hiba történt: </p>
<p i18n="@@6699633402864413480">Ismeretlen hiba történt az adatok elküldésekor.</p>
<p i18n="@@1590678107620709511">Nem azonosított hiba</p>
<p i18n="@@6108789149150185981">Feladat azonosító:</p>

</div>