<div>
    <div class="corrector-lg-container" [innerHTML]="task.task_data.sourceControl"></div>
    <div *ngIf="hasMediaFile" class="media-file-container">
        <audio *ngIf="isMp3" controls>
            <source src="{{prefixUrl}}{{fileUrl}}?token={{token}}" type="audio/mp3"/>
        </audio>
        <video *ngIf="!isMp3" controls>
            <source src="{{prefixUrl}}{{fileUrl}}?token={{token}}" type="video/mp4"/>
        </video>
    </div>

    <ul>
        <li *ngFor="let item of task.items; let i = index">

            <ng-container *ngIf="areQuestionsAnArray">
                <h4 *ngIf="areGroupNamesVisible[i]" class="group-name">
                    <strong>{{item.item_data.multipleGroupName}}</strong>
                </h4>

                <div style="display: inline-flex;">
                    <!--examples-->
                    <span>{{ task.exam_order  + 1 }}.</span>
                    <!---->
                    <strong [innerHTML]="item.item_data.questions[0].multipleQuestion"></strong>
                </div>

                <div class="radio-btn-container">
                    <div class="radio"
                         *ngFor="let answer of item.item_data.questions[0].answers; let j = index">
                        <label class="item"
                            *ngIf="environmentService.get('examType') === 'mmk'"
                               style="display: inline-flex;"
                               [class.correct]="answer.multipleAnswerRadio"
                               [class.strong]="answer.multipleAnswerRadio">
                            <input *ngIf="item.exam_answer !== null && item.exam_answer.length > 0"
                                   id="answer-{{i}}"
                                   type="checkbox"
                                   name="answer-{{i}}"
                                   [attr.checked]="answerArray[j]"
                                   disabled><label for="answer-{{i}}"></label>
                            <div style="margin-left: 6px;" [innerHTML]="answer.multipleAnswerText"></div>
                        </label>
                        <label
                                *ngIf="environmentService.get('examType') !== 'mmk'"
                                class="item"
                               [class.correct]="item.exam_answer !== null &&
                                    item.exam_answer.length > 0 &&
                                    item.exam_answer[0].correct === 1 &&
                                    answerIndexes[i] === j"
                               [class.incorrect]="item.exam_answer !== null &&
                                      item.exam_answer.length > 0 &&
                                      item.exam_answer[0].correct === 0 &&
                                      item.exam_answer[0].answer_data.answers[0] === j ||
                                      item.exam_answer === null && i !== 0 && !hasTwoExample ||
                                      item.exam_answer === null && i !== 0 && i !== 1 && hasTwoExample"
                               [class.example]="i === 0 && answerIndexes[i] === j && !hasTwoExample ||
                                    i === 0 && answerIndexes[i] === j && hasTwoExample ||
                                    i === 1 && answerIndexes[i] === j && hasTwoExample"
                               [class.strong]="item.exam_answer !== null &&
                                   answerIndexes[i] === j">

                            <!--examples-->
                            <input *ngIf="item.exam_answer === null"
                                   [type]="environmentService.get('examType') === 'mmk' ? 'checkbox' : 'radio'"
                                   id="answer-{{i}}"
                                   name="answer-example-{{i}}"
                                   [attr.checked]="answer.multipleAnswerRadio && i === 0 && hasTwoExample ? true : null ||
                                     answer.multipleAnswerRadio && i === 1 && hasTwoExample ? true : null ||
                                     answer.multipleAnswerRadio && i === 0 && !hasTwoExample ? true : null"><label for="answer-{{i}}"></label>
                            <!---->
                            <input *ngIf="item.exam_answer !== null"
                                   [type]="environmentService.get('examType') === 'mmk' ? 'checkbox' : 'radio'"
                                   id="answer-{{i}}"
                                   name="answer-{{i}}"
                                   [attr.checked]="item.exam_answer.length > 0 && item.exam_answer[0].answer_data && answerChecked(item.exam_answer[0].answer_data.answers, j) ? true : null"><label for="answer-{{i}}"></label>
                            {{answer.multipleAnswerText}}
                        </label>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!areQuestionsAnArray">
                <h4 *ngIf="areGroupNamesVisible[i]" >{{item.item_data.multipleGroupName}}</h4>

                <div>
                    <!--examples-->
                    <span *ngIf="i === 0 && !hasTwoExample">0</span>
                    <span *ngIf="i === 0 && hasTwoExample">01</span>
                    <span *ngIf="i === 1 && hasTwoExample">02</span>
                    <!---->
                    <span *ngIf="i > 0 && !hasTwoExample">{{i}}/{{task.items.length - 1}}</span>
                    <span *ngIf="i > 1 && hasTwoExample">{{i - 1}}/{{task.items.length - 2}}</span>
                    <strong>{{item.item_data.questions.multipleQuestion}}</strong>
                </div>

                <div class="radio-btn-container">
                    <div class="radio"
                         *ngFor="let answer of item.item_data.questions.answers; let j = index">

                        <label class="item"
                               [class.correct]="item.exam_answer !== null &&
                                    item.exam_answer.length > 0 &&
                                    item.exam_answer[0].correct === 1 &&
                                    answerIndexes[i] === j"
                               [class.incorrect]="item.exam_answer !== null &&
                                      item.exam_answer.length > 0 &&
                                      item.exam_answer[0].correct === 0 &&
                                      item.exam_answer[0].answer_data[0] === j ||
                                      item.exam_answer === null && i !== 0 && !hasTwoExample ||
                                      item.exam_answer === null && i !== 0 && i !== 1 && hasTwoExample"
                               [class.example]="i === 0 && answerIndexes[i] === j && !hasTwoExample ||
                                    i === 0 && answerIndexes[i] === j && hasTwoExample ||
                                    i === 1 && answerIndexes[i] === j && hasTwoExample"
                               [class.strong]="item.exam_answer !== null &&
                                   item.exam_answer.length > 0 &&
                                   answerIndexes[i] === j">

                            <!--example-->
                            <input *ngIf="item.exam_answer === null || item.exam_answer.length < 1"
                                   id="answer-example-{{i}}"
                                   type="checkbox"
                                   name="answer-example-{{i}}"
                                   [attr.checked]="answer.multipleAnswerRadio && i === 0 && hasTwoExample ? true : null ||
                                     answer.multipleAnswerRadio && i === 1 && hasTwoExample ? true : null ||
                                     answer.multipleAnswerRadio && i === 0 && !hasTwoExample ? true : null"><label for="answer-example-{{i}}"></label>
                            <!---->
                            <input *ngIf="item.exam_answer !== null && item.exam_answer.length > 0"
                                   id="answer-{{i}}"
                                   type="checkbox"
                                   name="answer-{{i}}"
                                   [attr.checked]="item.exam_answer[0].answer_data[0] === j ? true : null"><label for="answer-{{i}}"></label>
                            {{answer.multipleAnswerText}}
                        </label>

                    </div>
                </div>
            </ng-container>

        </li>
    </ul>

</div>
