<div class="identity-card col-md-3 col-sm-3" [ngClass]="getCardClasses()">
    <div class="identity-card-contents">
        <div class="identity-card-header">
            <div class="candidate-name">
                <p>
                    {{ candidate.user_id }} <br>
                    {{ candidate.first_name }} {{ candidate.last_name }}
                </p>
            </div>
            <div class="candidate-exam-data">
                <ng-container [ngSwitch]="candidate.candidateExamStatus">
                    <span *ngSwitchCase="true" class="green">Online</span>
                    <span *ngSwitchCase="false">Offline</span>
                    <span *ngSwitchCase="">Offline</span><br>
                </ng-container>
                <ng-container [ngSwitch]="candidate.candidateAppStatus" *ngIf="environmentService.get('live_application')">
                    <span *ngSwitchCase="true" class="green">Online</span>
                    <span *ngSwitchCase="false">Offline</span>
                    <span *ngSwitchCase="">Offline</span><br>
                </ng-container>
                <ng-container [ngSwitch]="candidate.identity_check">
                    <span *ngSwitchCase="0" class="red">Ellenörzésre vár</span>
                    <span *ngSwitchCase="1">Személyazonosság ellenőrizve</span>
                </ng-container>
                <br>
                <span *ngIf="candidate.suspension_exam_time !== null" class="red">Felfüggesztve: {{ candidate.suspension_exam_time}}</span>
                <br>
                <p *ngIf="candidate.writing">Writing elkezdve: {{ candidate.writing }}</p>
                <p *ngIf="candidate.reading && environmentService.get('examType') !== 'mmk'">Reading elkezdve: {{ candidate.reading }}</p>
                <p *ngIf="candidate.reading && environmentService.get('examType') === 'mmk'">Írásbeli elkezdve: {{ candidate.reading }}</p>
                <p *ngIf="candidate.listening">Listening elkezdve: {{ candidate.listening }}</p>
                <p *ngIf="candidate.speaking">Speaking elkezdve: {{ candidate.speaking }}</p>
                <p *ngIf="candidate.is_finished && candidate.is_finished === 1">A vizsga véget ért.</p>
            </div>
        </div>
        <i *ngIf="loaderImages" class="fa fa-refresh fa-spin"></i>
        <div *ngIf="!loaderImages" class="col-md-12 candidate-images">
            <a *ngFor="let image of candidate.images" href="{{ image }}" target="_blank">
                <img src="{{ image }}" class="img-responsive" />
            </a>
        </div>

        <div class="inspector-actions">

            <div class="">
                <div class="btn-group" role="group">
                    <button type="button" class="btn btn-success" (click)=" focusOnVideo = !focusOnVideo" title="Kamerakép megtekintése" i18n-title><i class="fa fa-eye"></i></button>

                    <ng-container [ngSwitch]="candidate.identity_check === 0">
                        <button type="button" class="btn btn-success" *ngSwitchCase="true" (click)=" candidate.identity_check = 1; markAsVerified();" title="Ellenőrizve" i18n-title><i class="fa fa-check-circle"></i></button>
                        <button type="button" class="btn btn-danger" *ngSwitchCase="false" (click)="candidate.identity_check = 0; markAsVerified();" title="Visszavonás" i18n-title><i class="fa fa-close"></i></button>
                    </ng-container>
                    <button type="button" class="btn btn-primary" (click)="openChat();">Chat <span class="red-border" *ngIf="chat && chat.unread > 0">({{chat.unread}})</span></button>
                    <ng-container [ngSwitch]="candidate.is_finished === 1">
                        <ng-container *ngSwitchCase="false" [ngSwitch]="candidate.suspension_exam_time !== null">
                            <button type="button" class="btn btn-danger" *ngSwitchCase="false" (click)="suspensionOfExam('start', 'suspend');" title="Vizsga felfüggesztése" i18n-title i18n>Felfüggeszt</button>
                            <button type="button" class="btn btn-success" *ngSwitchCase="true"  (click)="suspensionOfExam('end', 'suspend');" title="Vizsga folytatása" i18n-title i18n>Folytatás</button>
                        </ng-container>
                        <ng-container *ngSwitchCase="true">
                            <button type="button" class="btn btn-danger" title="Vizsga lezárva" i18n-title i18n disabled>Lezárva</button>
                        </ng-container>
                    </ng-container>
                    <select class="form-control select-actions" (change)="onChangeTask($event)">
                        <option value="0" selected>Több</option>
                        <ng-container [ngSwitch]="candidate.is_finished === 1">
                            <option *ngSwitchCase="false" value="close-candidateExam">Vizsga lezárása</option>
                            <option *ngSwitchCase="true" value="closed-exam" disabled>Lezárva</option>
                        </ng-container>
                        <ng-template #closeExam>
                            <close-exam [closeCandidateExam]="candidate" (confirmClose)="closeCandidateExam()"></close-exam>
                        </ng-template>
                        <option value="generate-password">Jelszó generálás</option>
                        <option *ngIf="environmentService.get('hide_inspector_password_generate') === false" value="extend-time">Időkorlát bővítése</option>
                        <option *ngIf="environmentService.get('hide_inspector_extend_time') === false" value="reopen-exam">Vizsga újranyitása</option>
                        <option value="provide-id">Igazolványszám megadása</option>
                        <option value="reload-video">Videó újratöltése</option>
                        <option value="live-exam" *ngIf="liveOralExam">Szóbeli vizsga indítása</option>
                    </select>
                </div>
            </div>

            <!--Extra Time -->
            <ng-container *ngIf="candidate.extra_time_open" class="text-right">
                <div class="col-md-12">
                    <table class="extra-time-table">
                    <tr>
                        <td colspan="2" class="td-part">
                            <select class="form-control" [(ngModel)]="extraTimePart" (change)="onChangeExtraTimePart()">
                                <option value="null">Vizsgarész kiválasztása...</option>
                                <option value="writing">Íráskészség</option>
                                <option value="reading">Olvasásértés</option>
                                <option value="listening">Beszédértés</option>
                                <option value="speaking">Beszédkészség</option>
                            </select>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Extra perc hozzárendelése &nbsp;
                        </td>
                        <td>
                            <input type="number" min="0" class="form-control"
                                   [(ngModel)]="extraTimeMinute"
                                   style="width: 70px;">
                        </td>
                    </tr>
                    <tr>
                        <td>&nbsp;Indoklás:&nbsp;</td>
                        <td class="td-input">
                            <input type="text"
                                   class="form-control"
                                   [(ngModel)]="extraTimeJustification">
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2" class="td-button">
                            <div class="btn-group" role="group">
                                <button class="btn btn-primary" (click)="saveExtraTime()">Mentés</button>
                                <button class="btn btn-danger" (click)="ExtraTimeFormClose()">Bezár</button>
                            </div>
                        </td>
                    </tr>
                </table>
                </div>
            </ng-container>

            <!--Add identity card number-->
            <ng-container *ngIf="candidate.identity_card_number_form_open" class="text-right">
                <div class="col-md-12">
                    <table class="extra-time-table">
                    <tr>
                        <td style="padding-top: 10px;">
                            Igazolványszám:
                        </td>
                        <td style="padding-top: 10px;">
                            <input type="text" min="0" class="form-control"
                                   [(ngModel)]="candidate.identity_card_number"
                                   style="width: 120px;height: 30px;">
                        </td>
                        <td style="padding-top: 10px;padding-left: 5px;">
                            <div class="btn-group" role="group">
                                <button class="btn btn-primary" (click)="addIdentityCardNumberToCandidate()">Mentés</button>
                                <button class="btn btn-danger" (click)="identityCardNumberFormClose()">X</button>
                            </div>
                        </td>
                    </tr>
                </table>
                </div>
            </ng-container>

        </div>

    </div>
    <video [id]="'video-' + candidate.id" playsinline autoplay (click)="focusOnVideo = !focusOnVideo" controls></video>
    <video [id]="'app-video-' + candidate.id" playsinline autoplay (click)="focusOnVideo = !focusOnVideo" controls *ngIf="environmentService.get('live_application')"></video>
    <chat [candidate]="candidate"></chat>
</div>
