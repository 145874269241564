<!-- Completion task -->

<div class="extend-task-container">
    <div class="paragraph-title">
        <strong i18n>Vizsgázó válasza:</strong>
    </div>

    <div [innerHTML]="{task: task,
                    isTaskDragNDrop: isTaskDragNDrop,
                    taskData: task.task_data.task_text_control,
                    answers: task.words} | extendTaskStringConverter">
    </div>

    <div class="save-btn-container" *ngIf="!isAdmin && !isTaskDragNDrop">
        <button class="btn btn-warning" (click)="getDownloadFile()"><i class="fa fa-download"></i> Skála</button>
        <button class="btn btn-primary"
                (click)="saveManualCorrections()"
                [disabled]="correctionsByAnswerId.size !== task.items.length - 1
                            && !hasCorrectionDone && !hasTwoExample ||
                            correctionsByAnswerId.size !== task.items.length - 2
                            && !hasCorrectionDone && hasTwoExample">
            Mentés
        </button>
    </div>
</div>
