import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {IExam} from "../../interfaces/exam.interface";
import {FacetHttpApi} from "../../http_apis/facet.http";
import "rxjs/Rx";
import {isNullOrUndefined} from "util";
import {HttpClient} from "@angular/common/http";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {ApiService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";

interface IFiles {
    data: string;
    id: number;
    name: string;
    type: string;
    outputFiles: Array<IFile>;
}

interface IFile {
    data: string;
    id: number;
    name: string;
    type: string;
}

interface IFacet {
    created_at: string;
    exam_id: number;
    id: number;
    input_file: string;
    module: string;
    output_f1: string;
    output_f2: string;
    output_f3: string;
    output_out: string;
    updated_at: string;
    version: string;
}

@Component({
    moduleId: module.id,
    selector: "analysis",
    templateUrl: "./analysis.component.html",
    styleUrls: ["./analysis.component.css"]
})
export class AnalysisComponent implements OnInit {
    @ViewChild('newInputDialog') newInputDialog: TemplateRef<any>;

    newInputParams: {};
    loadingNewInputFile = false;

    private examId: number = null; // If we are analysing a single exam
    private groupId: number = null;// If we are analysing a group of exams
    private part: string;
    private facetHttpApi: FacetHttpApi;
    exam: IExam;
    hasInputFileNameModified = false;
    isEditorVisible: Array<boolean> = [];
    isAnalyzerEditorVisible: Array<boolean> = [];
    isOutputFilesVisible: Array<boolean> = [];
    outputFileData: Array<string> = [];
    inputFileNames: Array<string> = [];
    files: Array<IFiles>;
    textAreaColSpanSize = 8;
    btnBeforeColSpanSize = 7;
    inputFileTableColSpan = 7;
    outputFileTableColSpan = 8;
    outputFileNameColSpan = 7;
    innerOutputFileNameColSpan = 7;
    isLoading = false;

    constructor(private http: HttpClient,
                private router: Router,
                private dialog: MatDialog,
                private apiService: ApiService,
                private toastr: ToastrService,
                private activatedRoute: ActivatedRoute
    ) {

        this.facetHttpApi = new FacetHttpApi(http,  "data");

        this.activatedRoute.params.subscribe(({id}) => {
            // If we received a group ID
            if (id.split("-")[0] === 'group') {
                this.examId = null;
                this.groupId = id.split("-")[1];
                this.part = null;
            } else {
                this.examId = id.split("-")[0];
                this.part = id.split("-")[1];
            }

            this.loadFacets();
        });
    }

    private loadFacets() {
        if (this.examId !== null) {
            this.facetHttpApi.getModuleWithFacets(this.examId, this.part).subscribe(this.processFacets.bind(this));
        } else {
            this.apiService.getFacetsGroupFiles(this.groupId).subscribe(this.processFacets.bind(this));
        }
    }

    private processFacets(results) {
        this.exam = results;
        if (results.part && typeof results.part !== 'undefined' && results.part !== null) {
            this.part = results.part;
        }
        if (this.examId === null && results.exams && results.exams.length > 0) {
            this.examId = results.exams[0];
        }
        this.setFiles();
    }

    // set facet files data
    setFiles() {
        this.files = this.exam.facets.map((facet: IFacet) => {
            let outputFileName = facet.input_file.match(/output measures file = (.*?).out/)[1];
            let outputFileTypes = ["output_out", "output_f1", "output_f2", "output_f3"];
            let names = {
                input_file: facet.input_file.match(/scorefile=(.*?).f/)[1] + ".sd",   // TODO: replace this with real filename
                output_out: outputFileName + ".out",
                // output_out: facet.output_out.match(/scorefile=(.*?).f/)[1] + ".out" ,
                output_f1: outputFileName + "1.f",
                output_f2: outputFileName + "2.f",
                output_f3: outputFileName + "3.f",
            };
            let newFacet = {
                name: names.input_file,
                id: facet.id,
                data: facet.input_file,
                type: "input_file",
                outputFiles: []
            };

            outputFileTypes.forEach((type: string) => {
                Object.keys(facet).forEach((value) => {
                    if (value === type && facet[value]) {
                        let name = names[value];
                        newFacet.outputFiles.push({
                            data: facet[value],
                            id: facet.id,
                            type: value,
                            name: name
                        });
                    }
                });
            });

            return newFacet;
        });

        this.files.forEach(() => this.isEditorVisible.push(false));
        this.files.forEach(() => this.isAnalyzerEditorVisible.push(false));
        this.files.forEach(() => this.isOutputFilesVisible.push(false));
        this.files.forEach((file) => {
            if (file.outputFiles.length > 0) {
                this.outputFileData.push(file.outputFiles[0].data); // add the first output file data
            }
        });
        this.files.forEach((file) => this.inputFileNames.push(file.name));

        this.updateColSpanSizes();

        // console.log("exam: ", this.exam);
        // console.log("FILES: ", this.files);
    }

    selectOutputFile($event, inputFileIndex: number) {
        const selectedOutputFileIndex = $event.target.value;
        this.outputFileData[inputFileIndex] = this.files[inputFileIndex].outputFiles[selectedOutputFileIndex].data;
    }

    modifyInputFileName($event, inputFileIndex: number) {
        const newInputFileName = $event.target.value;
        this.hasInputFileNameModified = newInputFileName !== this.files[inputFileIndex].name;
    }

    toggleEditorVisibility(index: number) {
        this.isEditorVisible[index] = !this.isEditorVisible[index];

        if (this.isAnalyzerEditorVisible[index] && this.isEditorVisible[index]) {
            this.isAnalyzerEditorVisible[index] = false;
        }

        if (this.isEditorVisible[index]) {
            this.textAreaColSpanSize = 9;
        } else {
            this.textAreaColSpanSize = 5;
        }
    }

    toggleAnalyzerEditorVisibility(index: number) {
        this.isAnalyzerEditorVisible[index] = !this.isAnalyzerEditorVisible[index];

        if (this.isAnalyzerEditorVisible[index] && this.isEditorVisible[index]) {
            this.isEditorVisible[index] = false;
        }

        if (this.isAnalyzerEditorVisible[index]) {
            this.textAreaColSpanSize = 5;
        } else {
            this.textAreaColSpanSize = 9;
        }
    }

    toggleOutputFilesVisibility(index: number) {
        this.isOutputFilesVisible[index] = !this.isOutputFilesVisible[index];
    }

    updateColSpanSizes() {
        if (!isNullOrUndefined(this.files[0]) &&
            this.files[0].outputFiles.length > 0 &&
            this.textAreaColSpanSize !== 10) {

            this.btnBeforeColSpanSize = 8;
            this.inputFileTableColSpan = 8;
            this.outputFileTableColSpan = 9;
            this.outputFileNameColSpan = 8;
            this.innerOutputFileNameColSpan = 8;
        }
    }

    saveEditedInputFile(inputFileId: number, inputFileData: string, inputFileIndex: number) {
        this.facetHttpApi.setDefaultUrl();

        // @TODO: AVOID REDUNDANCY
        if (this.files[inputFileIndex].outputFiles.length > 0) {
            if (window.confirm("Ezzel a lépéssel törlődnek az output fájlok. Biztos benne?")) {
                this.facetHttpApi.update({input_file: inputFileData}, inputFileId, true).subscribe((result) => {
                    console.log("saveEditedInputFile result: ", result);
                    this.loadFacets();
                    this.toastr.success($localize`A fájl frissítésre került.`, $localize`Sikeres mentés`, {onActivateTick: true});
                });
            }
        } else {
            this.facetHttpApi.update({input_file: inputFileData}, inputFileId, true).subscribe((result) => {
                console.log("saveEditedInputFile result: ", result);
                this.loadFacets();
                this.toastr.success($localize`A fájl frissítésre került.`, $localize`Sikeres mentés`, {onActivateTick: true});
            });
        }
    }

    saveAsNewInputFile(inputFileData: string, inputFileName: string) {
        this.apiService.saveAsNewInputFile(this.examId, this.part, inputFileData, inputFileName).subscribe((result) => {
            console.log("saveEditedInputFile result: ", result);
            this.loadFacets();
            this.toastr.success($localize`A fájl új néven lett mentve.`, $localize`Sikeres mentés`, {onActivateTick: true});
        });
    }

    runFacet(inputFileId: number) {
        this.isLoading = true;
        this.facetHttpApi.runFacets({id: inputFileId}).subscribe((results) => {
            console.log(results);
            this.loadFacets();
            this.isLoading = false;
        });
    }

    downloadFile(id: number, type: string) {
        let file = null;

        if(type === 'output_out' || type === 'output_f1' || type === 'output_f2' || type === 'output_f3') {
            file = this.files[0].outputFiles.filter((file) => file.id === id && file.type === type);
        } else {
            file = this.files.filter((file) => file.id === id && file.type === type);
        }

        this.facetHttpApi.getFacetFile(file[0]).subscribe((results) => {
            console.log(results);
            // Get type and filename from headers.
            let type = results.headers.get("content-type").split(";")[0];
            //let filename = results.headers.get("content-disposition") ? results.headers.get("content-disposition").split("=")[1] : file[0];
            //filename = filename.substring(1, filename.length - 1);

            const filename = file[0].name;

            // Create blob from result.
            let blob = new Blob([results.body], {type: type});
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = filename;

            // Force download file.
            a.click();
        });
    }

    // Remove facet file.
    removeFile(id: number, index: number) {
        if (window.confirm("Biztos, hogy törlöd az összes input és output fájlt?")) {
            let file = this.exam.facets.filter((file) => file.id === id);

            this.facetHttpApi.removeFacetFile(file[0]);

            delete this.files[index];
        }
    }

    openResultsOnNewPage() {
        window.open(`analysis/results/${this.examId}-${this.part}`);
    }

    /**
     * Display dialog for changing default facets data, and call the generation endpont
     */
    showNewInputPopup() {
        this.loadingNewInputFile = true;

        // Fetch default params before showing the popup
        this.apiService.getDefaultParams(this.examId, this.part).subscribe((defaultParams:any) => {
            this.loadingNewInputFile = false;
            this.newInputParams = defaultParams.params;
            const dialogRef = this.dialog.open(this.newInputDialog);

            // When the dialog is submitted
            dialogRef.afterClosed().subscribe(result => {
                if (!result) {
                    return;
                }
                this.loadingNewInputFile = true;

                // Trigger generating a new sd file
                this.apiService.createFacetsInputFile(this.examId, this.part, this.newInputParams, this.groupId).subscribe((results:any) => {
                    // When it is ready...
                    this.loadingNewInputFile = false;

                    if(results.result === 'empty_examiner_nyat_number') {
                        this.toastr.warning($localize`Az egyik javítónak hiányzik a NYAT száma!`, $localize`Művelet sikertelen.`, {onActivateTick: true});
                    } else {
                        this.loadFacets();
                        this.toastr.success($localize`Az új bemeneti fájl sikeresen létrejött.`, $localize`Végrehajtva`, {onActivateTick: true});
                    }
                });
            });
        });

    }

    ngOnInit(): void {
        this.newInputParams = {
            'filename': '',
            'facet_headings': [],
            'block_values': {},
        };
    }
}
