<div class="exam-form-wrapper">
    <aside></aside>
    <div class="container">
        <h3>{{pageTitle}}</h3>
        <form novalidate [formGroup]="candidateForm"  (submit)="onSubmit($event, candidateForm)">
            <div class="row">
                <div class="col-md-4">
                    <label for="first_name">Keresztnév: </label>
                        <input name="first_name" id="first_name" type="text" class="form-control"
                               formControlName="first_name" required/>
                </div>
                <div class="col-md-4">
                    <label for="last_name">Vezetéknév: </label>
                        <input name="last_name" id="last_name" type="text" class="form-control"
                               formControlName="last_name" required/>
                </div>
                <div class="col-md-4">
                    <label for="email">Email: </label>
                    <input name="email" id="email" type="email" class="form-control"
                           formControlName="email"/>
                </div>
                <div class="col-md-4">
                    <label for="address">Cím: </label>
                    <input name="address" id="address" type="text" class="form-control"
                           formControlName="address"/>
                </div>
                <div class="col-md-4">
                    <label for="chamber_id">Kamarai azonosító: </label>
                    <input name="chamber_id" id="chamber_id" type="text" class="form-control"
                           formControlName="chamber_id"/>
                </div>
                <div class="col-md-4">
                    <label for="iir_id">IIR ID: </label>
                    <input name="iir_id" id="iir_id" type="text" class="form-control"
                           formControlName="iir_id" required/>
                </div>
                <div class="col-md-4">
                    <label for="date_of_birth">Születési dátum:  </label><i>(1982-01-01)</i>
                    <input name="date_of_birth" id="date_of_birth" type="text" class="form-control"
                           formControlName="date_of_birth"/>
                </div>
                <div class="col-md-4">
                    <label for="place_of_birth">Születési hely: </label>
                    <input name="place_of_birth" id="place_of_birth" type="text" class="form-control"
                           formControlName="place_of_birth"/>
                </div>
                <div class="col-md-4">
                    <label for="mothers_name">Anyja neve: </label>
                    <input name="mothers_name" id="mothers_name" type="text" class="form-control"
                           formControlName="mothers_name"/>
                </div>
                <div class="col-md-4 line-2">
                    <label for="user_id">User ID: </label>
                    <input name="user_id" id="user_id" type="text" class="form-control"
                           formControlName="user_id" readonly/>
                </div>
                <div class="col-md-4 line-2">
                    <label for="password">Password: </label>
                    <input name="password" id="password" type="text" class="form-control"
                           formControlName="password" readonly/>
                </div>
                <div class="col-md-12 button-container">
                    <div class="col-md-11 button-class-inline">
                        <div>
                            <button type="submit" id="submit-btn" class="btn btn-primary" *ngIf="!submitCheck">{{ submitButtonText }}</button>
                        </div>
                        <div *ngIf="submitCheck">
                            <button type="button" id="submit-btn-check" class="btn btn-success">Kész</button>
                            <br/><i>* A jelszó később nem megjeleníthető!</i>
                        </div>
                        <div *ngIf="!submitCheck && candidateId">
                            <button type="button" id="submit-btn-new-password" class="btn btn-warning" (click)="onResetPassword()">Új jelszó</button>
                            <br/><i>* A jelszó később nem megjeleníthető!</i>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <div *ngIf="!submitCheck && candidateId">
                            <button type="button" id="submit-btn-remove" class="btn btn-danger" (click)="onRemoveCandidate()" i18n>Törlés</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="row add-exam-container" *ngIf="!submitCheck && candidateId">
            <form class="form-inline">
                <div class="col-md-10">
                    <label for="addExam">Vizsgához rendelés:&nbsp;</label>
                    <select (change)="onChangeExam($event.target.value)"
                            class="form-control"
                            id="addExam"
                            [disabled]="loader">
                        <option value="NULL" selected>Vizsgák</option>
                        <option *ngFor="let exam of examList" [value]="exam.id">
                            {{exam.date}} - {{exam.study_field_name}} - {{exam.level_name}} - ({{exam.id}})
                        </option>
                    </select>
                    <select (change)="onChangeExamType($event.target.value)"
                            class="form-control"
                            id="addExamType"
                            [disabled]="loader">
                        <option value="NULL" selected>Típus</option>
                        <option value="offline">Offline</option>
                        <option value="online">Online</option>
                    </select>
                    <span id="loading"  class="loading" *ngIf="loader">&nbsp;</span>
                </div>
                <div class="col-md-2">
                    <button type="button" id="submit-btn-add-exam" class="btn btn-success"
                            (click)="addExamToCandidate()"
                            [disabled]="loader" >Hozzárendel</button>
                </div>
            </form>
        </div>

        <div class="row exam-list-container" *ngIf="!submitCheck && candidateId">
             <h3>Eddigi vizsgái</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Dátum</th>
                        <th>Témakör</th>
                        <th>Szakterület</th>
                        <th>Vizsga típusa</th>
                        <th>Tölés</th>
                    </tr>
                </thead>
                <tbody *ngIf="candidateExams">
                    <tr *ngFor="let exam of candidateExams; let i = index" >
                        <td>{{exam.id}}</td>
                        <td>{{exam.date}}</td>
                        <td>{{exam.level_name}}</td>
                        <td>{{exam.study_field_name}}</td>
                        <td>{{exam.pivot.place}}</td>
                        <td>

                            <button type="button" class="btn btn-danger"
                                    (click)="removeFromExam(i, exam.pivot.candidate_exam_id)"
                                    [disabled]="loader" ><i class="fa fa-trash-o"></i></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>