<h2 i18n>Csoportos elemzések</h2>

<a class="btn btn-large btn-default" href="javascript:void(0);" role="button" (click)="createGroup()" i18n>Új csoport létrehozása</a>

<table class="table table-hover">
    <thead>
        <tr>
            <th i18n>Csoport azonosító</th>
            <th i18n>Vizsgák</th>
            <th i18n>Vizsgarész</th>
            <th i18n>Nyelv</th>
            <th i18n>Szint</th>
            <th i18n>Művelet</th>
        </tr>
    </thead>
    <tbody class="exam-table-body analysis">
        <ng-container *ngFor="let facet of facets | keyvalue; let i = index">
            <tr>
                <td>
                    <span *ngIf="facet.value[0]">{{facet.value[0].group_id}}</span>
                </td>
                <td>
                    <span *ngFor="let groupItem of facet.value | keyvalue">{{groupItem.value.exam_id}}, </span>
                </td>
                <td>
                    <span *ngIf="facet.value[0]">{{facet.value[0].part}}</span>
                </td>
                <td>
                    <span *ngIf="facet.value[0]">{{facet.value[0].exam.language}}</span>
                </td>
                <td>
                    <span *ngIf="facet.value[0]">{{facet.value[0].exam.level}}</span>
                </td>
                <td>
                    <a
                       class="btn btn-small btn-default mr-3"
                       (click)="openGroupOnNewPage(facet.value[0].group_id)" role="button" i18n>
                        Elemzés
                    </a>
                    <ng-container *ngIf="facet.value[0]">
                        <button class="btn btn-small btn-default" (click)="excelExport(i)">
                            <span>SPSS export</span>
                        </button>
                    </ng-container>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

<mat-paginator [length]="matPagination['length']"
              [pageSize]="matPagination['size']"
              [pageSizeOptions]="[5, 10, 25, 100]"
              (page)="matPaginate($event)">
</mat-paginator>

<ng-template #newGroupDialog>
      <h2 matDialogTitle>Facets csoport létrehozása</h2>
      <mat-dialog-content>
          <p>Csoport létrehozása az alábbi vizsgákkal:</p>
          <mat-select-autocomplete class="grid-7-11"
              [placeholder]="'Vizsgák kiválasztása'"
              [options]="availableExams"
              [display]="'display'"
              appearance="fill"
              class="full-width"
              [multiple]='true'
              [labelCount]="5"
              (selectionChange)="newGroup.exams = $event">
            </mat-select-autocomplete>

            <p>* Fontos, hogy a vizsgában szereplő valamennyi feladat egyezzen, különben a program nem tudja megfelelően összeállítani a bemeneti fájlokat.</p>
            <mat-form-field appearance="fill">
              <mat-label>Vizsgarész</mat-label>
              <mat-select [(ngModel)]="newGroup.part">
                <mat-option value="reading">Reading</mat-option>
                <mat-option value="writing">Writing</mat-option>
                <mat-option value="speaking">Speaking</mat-option>
                <mat-option value="Listening">Listening</mat-option>
              </mat-select>
            </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary">Mégse</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Csoport létrehozása</button>
      </mat-dialog-actions>
</ng-template>
