
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";
import {Component, OnInit, DoCheck, Input, ViewChild, TemplateRef} from "@angular/core";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {AuthService} from "../../../services/auth.service";
import {environment} from "../../../../environments/environment";
import {RestConfig} from "../../../http_apis/rest_config.http";
import {MatDialog} from '@angular/material/dialog';
import {FormControl} from "@angular/forms";
import {isNullOrUndefined} from "../../../utils/iswhat";
import {ExamService} from "../../../services/exam_service/exam.service";
import {ITask} from "../../../interfaces/task.interface";
import {ChatComponent} from "../../chat/chat.component";
import {HttpClient} from "@angular/common/http";
import {StreamService} from "../../../services/stream.service";
import {ToastrService} from "ngx-toastr";
import {EnvironmentService} from "../../../services/environment.service";
import {WebsocketService} from "../../../services/websocket.service";

@Component({
    moduleId: module.id,
    selector: "identity-card",
    host: {
        id: "identity-card"
    },
    templateUrl: "identity-card.component.html",
    styleUrls: ["identity-card.component.css"]
})

export class IdentityCardComponent implements OnInit,DoCheck {
    @Input("candidate") candidate: any;
    @ViewChild(ChatComponent, { static: true }) chat: ChatComponent;
    @ViewChild("closeExam") confirmCloseCandidateExam: TemplateRef<any>;

    private examHttpApi: ExamHttpApi;
    private examData: any;
    liveOralExam: boolean = false;
    extraTimePart = null;
    extraTimeMinute = null;
    extraTimeJustification = null;
    areExamsShown: Array<boolean>;
    loaderImages = false;
    dialogRef = null;
    extraTimeInput = new FormControl(null);
    openIdentityCardNumberForm = new FormControl(null);
    inboundStream = {'app': null, 'candidate': null};

    focusOnVideo = false;

    constructor(private http: HttpClient,
                private authService: AuthService,
                private streamService: StreamService,
                private examService: ExamService,
                private toastr: ToastrService,
                private websocketService: WebsocketService,
                public environmentService: EnvironmentService,
                public dialog: MatDialog,
    ) {

        this.examHttpApi = new ExamHttpApi(http,"data");
        this.examHttpApi.setDefaultUrl();
        this.liveOralExam = this.environmentService.get('live_oral_exam');
    }

    ngOnInit() {
        this.imagesInit();

        this.examService.identityCardImagesReload.subscribe((candidate_exam_id) => {
            if(this.candidate.id === candidate_exam_id){
                this.reloadImages();
            }
        });

        this.examService.candidateStartedExamPart.subscribe((data) => {
            if(this.candidate.id === data.candidate_exam_id){
                this.reloadExamTimes();
            }
        });

        // Subscribe for status updates, and display online offline text based on them
        this.examService.candidateExamStatus.subscribe((data) => {
            console.log('candidateExamStatus event received', data);
            if(this.candidate.id === data.candidate_exam_id){
                let status = typeof data.status !== 'undefined' ? data.status : null;
                let appstatus = typeof data.appstatus !== 'undefined' ? data.appstatus : null;
                this.setCandidateExamStatus(status, appstatus);
            }
        });

        // Hook for reset stream, so we can clean start the new connection
        this.streamService.getResetStreamSubject().subscribe((data) => {
            if(this.candidate.id !== data.candidate_exam_id){
                return;
            }

            if (!this.inboundStream[data.user_type]) {
                return;
            }

            let video = null;
            if (data.user_type === 'app') {
                video = document.getElementById('app-video-' + data.candidate_exam_id) as HTMLVideoElement;
            } else {
                video = document.getElementById('video-' + data.candidate_exam_id) as HTMLVideoElement;
            }

            const tracks = this.inboundStream[data.user_type].getTracks();
            tracks.forEach(function(track) {
                track.stop();
            });

            video.srcObject = null;
            this.inboundStream[data.user_type] = null;
        });


        this.streamService.getReceivingStreamSubject().subscribe((data) => {
            // Do action only if the stream is for this candidate
            if(this.candidate.id !== data.candidate_exam_id){
                return;
            }

            let video = null;
            if (data.user_type === 'app') {
                video = document.getElementById('app-video-' + data.candidate_exam_id) as HTMLVideoElement;
            } else {
                video = document.getElementById('video-' + data.candidate_exam_id) as HTMLVideoElement;
            }

            // Apply remote video
            // We might receive directly video, or audio first
            // https://developer.mozilla.org/en-US/docs/Web/API/RTCPeerConnection/addTrack
            if (data.event.streams && data.event.streams[0]) {
                video.srcObject = data.event.streams[0];
            } else {
                if (!this.inboundStream[data.user_type]) {
                    this.inboundStream[data.user_type] = new MediaStream();
                    video.srcObject = this.inboundStream[data.user_type];
                }
                this.inboundStream[data.user_type].addTrack(data.event.track);
            }
        });

        // Listen websocket for status updates
        this.websocketService.readyCallback(() => {
            this.websocketService.addHandler('examStatus', (data) => {
                if (data.candidate_exam_id === this.candidate.id) {
                    this.reloadExamTimes();
                }
            });

            this.websocketService.addHandler('verified', (data) => {
                console.log('verified event received', data);

                // If we are displaying this candidate, update the data
                if (data.candidate_exam_id === this.candidate.id) {
                    console.log('Refreshing verified status for candidate_exam_id', this.candidate.id);
                    this.candidate.identity_check = data.identity_check;
                }
            });
        });
    }

    ngDoCheck() {
        //
    }

    imagesInit(){
        let sum = 5 - this.candidate.images.length;
        if(sum > 0){
            for(let i=0; i < sum; i++){
                this.candidate.images.push('/assets/images/profile_img.png');
            }
        }
    }

    onChangeTask($event){
        const value = $event.target.value;
        console.log('Candidate action triggered', value);

        if(value == "generate-password"){
            console.log('passwordGenerate');
            this.examService.passwordGenerate(this.candidate.candidate_id);
        } else if(value == "extend-time"){
            console.log('addExtraTimeToCandidate');
            this.extraTimeInput = new FormControl(null);
            this.candidate.extra_time_open = !this.candidate.extra_time_open;
            this.candidate.identity_card_number_form_open = false;
        } else if(value == "reopen-exam"){
            console.log('reopeningExam');
            this.reopeningExam();
        } else if(value == "provide-id"){
            console.log('openIdentityCardForm');
            this.openIdentityCardNumberForm = new FormControl(null);
            this.candidate.identity_card_number_form_open = !this.candidate.identity_card_number_form_open;
            this.candidate.extra_time_open = false;
        } else if(value == "reload-video"){
            this.reconnectStream();
        } else if (value == "live-exam") {
            this.examService.initLiveExam.emit({
                'candidate_exam_id': this.candidate.id,
                'user_id': this.candidate.user_id,
                'first_name': this.candidate.first_name,
                'last_name': this.candidate.last_name,
            });
        } else if(value == "close-candidateExam"){
            this.confirmCloseExam();
        }



        $event.target.value = '0';
    }

    /**
     * Re init WebRTC streams
     */
    reconnectStream(silent = false) {
        console.log('Trigger reconnect video stream');
        if (!this.streamService.restartStream(this.candidate.id, 'app')) {
            this.toastr.warning($localize`Másodlagos videó újracsatlakoztatása nem sikerült.`, $localize`Művelet sikertelen.`, {onActivateTick: true});
        }

        if (!this.streamService.restartStream(this.candidate.id, 'candidate')) {
            this.toastr.warning($localize`Elsődleges videó újracsatlakoztatása nem sikerült.`, $localize`Művelet sikertelen.`, {onActivateTick: true});
        }

        if (!silent) {
            this.toastr.success($localize`Videócsatornák csatlakoztatása...`, $localize`Művelet végrehajtva.`, {onActivateTick: true});
        }

        this.streamService.triggerClientConnect(this.candidate.id, 'candidate');
    }

    reopeningExam(){
        if(confirm("Biztosan újranyitod a vizsgát?")) {
            this.examService.reopeningExam(this.candidate.id);
        }
    }

    identityCardNumberFormClose(){
        this.candidate.identity_card_number_form_open = !this.candidate.identity_card_number_form_open;
    }

    ExtraTimeFormClose(){
        this.candidate.extra_time_open = !this.candidate.extra_time_open;
    }

    onChangeExtraTimePart(){
        this.extraTimeMinute = null;
        this.extraTimeJustification = null;
    }

    saveExtraTime(){
        if(isNullOrUndefined(this.extraTimePart)) {
            alert('Vizsgarész megadása kötelező');
            return false;
        }
        if(isNullOrUndefined(this.extraTimeMinute)) {
            alert('Perc megadása kötelező');
            return false;
        }

        if(isNullOrUndefined(this.extraTimeJustification)) {
            alert('Indkolás megadása kötelező');
            return false;
        }

        let logDescription = 'CandidateExamID: ' + this.candidate.id + ', Part: ' + this.extraTimePart + ' Min: ' + this.extraTimeMinute + ' Justification: ' + this.extraTimeJustification;
        this.saveLog(this.candidate.exam_id, logDescription);

         this.examService.addExtraTimeToCandidate(this.extraTimePart, this.candidate.id, this.extraTimeMinute);

        this.ExtraTimeFormClose();
        this.extraTimePart = null;
    }

    suspensionOfExam(type: string, status: string){
        console.log(this.candidate.is_finished);
        this.examService.suspensionOfExam(this.candidate.id, type, status).subscribe((date) => {
            this.candidate.suspension_exam_time = date;
            this.reloadExamTimes();
            // console.log('this.candidate.suspension_exam_time: ', date);
            // console.log('this.candidate.suspension_exam_time: ', this.candidate.suspension_exam_time);
        });
    }

    confirmCloseExam() {
        this.dialogRef = this.dialog.open(this.confirmCloseCandidateExam);
    }

    closeCandidateExam() {
        if (this.dialogRef !== null) {
            this.dialogRef.close();
            this.dialogRef = null;
        }

        this.examService.closeExam(this.candidate.id, 'close').subscribe((data) => {
            this.toastr.success($localize`Művelet végrehajtva`, $localize`A vizsga lezárásra került, és az értékelés megtörtént.`, {onActivateTick: true});
        });
        if (this.candidate.suspension_exam_time === null) {
            this.suspensionOfExam('start', 'close');
        }
    }

    addIdentityCardNumberToCandidate(){
        console.log(this.candidate.identity_card_number);

        this.saveCandidateIdentityCardNumber(this.candidate.candidate_id, this.candidate.identity_card_number);
    }

    saveCandidateIdentityCardNumber(candidateId: number, cardNumber: string){
        let url = `${environment.apiPrefixUrl}candidates/saveCandidateIdentityCardNumber`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_id: candidateId,
            card_number: cardNumber,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if (result) {
                alert('Sikeres mentés');
            }
        })).subscribe();
    }

    markAsVerified(){
        // this.examHttpApi.sendIdentityCheck(this.candidate.id, this.candidate.identity_check).subscribe();
        this.examService.markAsVerified(this.candidate.id, this.candidate.identity_check);
    }

    saveLog(exam_id: number, description: string){
        let url = `${environment.apiPrefixUrl}diaries`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: exam_id,
            type: 'Időkorlát módosítása',
            description: description,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if (result) {
                console.log('Sikeres napló mentés');
            }
        })).subscribe();
    }

    reloadImages(){
        this.loaderImages = true;
        this.getCandidateExamImages().subscribe((response:any) => {
            this.candidate.images = response;
            this.imagesInit();
            this.loaderImages = false;
            console.log('response: ', response);
        });
    }

    getCandidateExamImages(){
        let url = `${environment.apiPrefixUrl}exam/getCandidateExamImages/` + this.candidate.id;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return <Array<any>> response;
            }));
    }

    reloadExamTimes(){
        this.getStartExamModule().subscribe((response:any) => {
            this.candidate.writing = response['writing'];
            this.candidate.reading = response['reading'];
            this.candidate.listening = response['listening'];
            this.candidate.speaking = response['speaking'];
            this.candidate.is_finished = response['is_finished'];
        });
    }

    getStartExamModule(){
        let url = `${environment.apiPrefixUrl}candidates/getStartExamModule/` + this.candidate.id;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return <Array<any>> response;
            }));
    }

    setCandidateExamStatus(status, appstatus){
        console.log('setCandidateExamStatus: ', status, appstatus);

        const previousStatus = this.candidate.candidateExamStatus;

        if (status !== null) {
            this.candidate.candidateExamStatus = status === 'online';
        }

        if (appstatus !== null) {
            this.candidate.candidateAppStatus = appstatus === 'online';
        }

        console.log('Update status for user', this.candidate.id, previousStatus, this.candidate.candidateExamStatus);

        if (previousStatus === false && this.candidate.candidateExamStatus === true) {
            this.reconnectStream(true);
        }
    }

    mute() {
        const video = document.getElementById('video-' + this.candidate.id) as HTMLVideoElement;
        video.muted = true;
    }

    unMute() {
        const video = document.getElementById('video-' + this.candidate.id) as HTMLVideoElement;
        video.muted = false;
    }

    openChat() {
        this.chat.show();
    }

    getCardClasses() {
        let classes = [];
        if (this.candidate.live_exam) { //online
            classes.push("status-" + (this.candidate.candidateExamStatus === true ? "online" : "offline"));
            classes.push("identity-" + (this.candidate.identity_check === 0 ? "waiting" : "checked"));
        } else { // offline
            classes.push("offline-exam-candidate");
        }
        this.focusOnVideo ? classes.push("video-focus") : "";
        classes.push(this.environmentService.get("live_application") ? "double-video" : "single-video");

        return classes.join(" ");
    }
}
