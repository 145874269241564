<ng-container>
    <aside></aside>
</ng-container>

<div class="exam-roster col-md-12">
    <h2>Vizsgázók névsora</h2>

    <div class="col-md-12">
        <table class="table">
            <thead>
                <tr>
                    <th i18n>Azonosító</th>
                    <th i18n>Állapot</th>
                    <th i18n>Nyelv</th>
                    <th i18n>Szint</th>
                    <th i18n>Típus</th>
                    <th i18n>Időpont</th>
                    <th i18n>Művelet</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="examData">
                    <ng-container *ngFor="let exam of examData; let i = index">
                        <tr>
                            <td>{{ exam.id }}</td>
                            <td>{{ exam.status }}</td>
                            <td>{{ exam.language }}</td>
                            <td>{{ exam.level }}</td>
                            <td>{{ examTypes[exam.type] }}</td>
                            <td>{{ exam.date }}</td>
                            <td><span (click)="openCandidatesList(i)"><b>Névsor megnyitása</b></span></td>
                        </tr>
                        <tr [class.hide]="!areExamsShown[i]">
                            <td colspan="6">
                                <table class="table">
                                    <thead>
                                    <tr>
                                        <th>Vizsgázó azonosítója</th>
                                        <th>Vizsgázó neve</th>
                                        <th>Igazolványszám</th>
                                        <th>Megjelent</th>
                                        <th>Beadta értékelésre</th>
                                        <th>Személyazonosság</th>
                                        <th>Értékelendő</th>
                                        <th>Műveletek</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let candidate of exam.candidates; let k = index">
                                            <td>{{ candidate.user_id}}</td>
                                            <td>{{ candidate.last_name}} {{ candidate.first_name}}</td>
                                            <td>{{ candidate.identity_card_number}}</td>
                                            <td>
                                                <input (click)="setOnTheSpot(candidate.pivot.id, 1)" type="radio" [(ngModel)]="candidate.pivot.on_the_spot"  name="on_the_spot_{{ candidate.id }}" [value]="1"> Igen<br>
                                                <input (click)="setOnTheSpot(candidate.pivot.id, 0)" type="radio" [(ngModel)]="candidate.pivot.on_the_spot"  name="on_the_spot_{{ candidate.id }}" [value]="0"> Nem
                                            </td>
                                            <td>
                                                <input (click)="setDecision(candidate.pivot.id, 1)" type="radio" [(ngModel)]="candidate.pivot.decision"  name="decision_{{ candidate.id }}" [value]="1"> Igen<br>
                                                <input (click)="setDecision(candidate.pivot.id, 0)" type="radio" [(ngModel)]="candidate.pivot.decision"  name="decision_{{ candidate.id }}" [value]="0"> Nem
                                            </td>
                                            <td>
                                                <input (click)="setIdentityCheck(candidate.pivot.id, 1)" type="radio" [(ngModel)]="candidate.pivot.identity_check"  name="identity_check_{{ candidate.id }}" [value]="1"> Igen<br>
                                                <input (click)="setIdentityCheck(candidate.pivot.id, 0)" type="radio" [(ngModel)]="candidate.pivot.identity_check"  name="identity_check_{{ candidate.id }}" [value]="0"> Nem
                                            </td>
                                            <td>
                                                <span *ngIf="candidate.pivot.on_the_spot === 1 && candidate.pivot.identity_check === 1 && candidate.pivot.decision === 1">Igen</span>
                                                <span *ngIf="!(candidate.pivot.on_the_spot === 1 && candidate.pivot.identity_check === 1 && candidate.pivot.decision === 1)">Nem</span>
                                            </td>
                                            <td>
                                                <ng-container [ngSwitch]="candidate.pivot.suspension_exam_time !== null">
                                                    <span *ngSwitchCase="false" (click)="suspensionOfExam(candidate.pivot.id, 'start', 'suspend'); candidate.pivot.suspension_exam_time = '1'" >Felfüggesztés</span>
                                                    <span *ngSwitchCase="true"  (click)="suspensionOfExam(candidate.pivot.id, 'end', 'suspend'); candidate.pivot.suspension_exam_time = null" >Folytatás</span>
                                                </ng-container>
                                                <span (click)="passwordGenerate(candidate.id)">| Jelszó generálás</span>
                                                <span (click)="openExtraTime(); candidate.pivot.extra_time_open = !candidate.pivot.extra_time_open" >| Időkorlát bővítése</span>
                                                <span (click)="reopeningExam(candidate.pivot.id)">| Vizsga újranyitása</span>
                                                <span (click)="openIdentityCardForm(); candidate.pivot.identity_card_number_form_open = !candidate.pivot.identity_card_number_form_open" >| Igazolványszám megadása</span>

                                                <ng-container *ngIf="candidate.pivot.extra_time_open" class="text-right">
                                                    <table class="extra-time-table">
                                                        <tr>
                                                            <td colspan="2" class="td-part"><b>Íráskészség</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Extra perc hozzárendelése &nbsp;
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'writing')"
                                                                       value="{{ areCandidateExtraTime[i][k]['writing']}}"
                                                                       style="width: 70px;">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;Indoklás:&nbsp;</td>
                                                            <td class="td-input">
                                                                <input type="text"
                                                                       class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'writing_justification')"
                                                                       value="{{ areCandidateExtraTime[i][k]['writing_justification']}}">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" class="td-button">
                                                                <button class="btn btn-primary" (click)="saveExtraTime(i, k, candidate.pivot.id,  exam.id, 'writing')">Mentés</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2"><b>Olvasásértés</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Extra perc hozzárendelése &nbsp;
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'reading')"
                                                                       value="{{ areCandidateExtraTime[i][k]['reading']}}"
                                                                       style="width: 70px;">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;Indoklás:&nbsp;</td>
                                                            <td class="td-input">
                                                                <input type="text"
                                                                       class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'reading_justification')"
                                                                       value="{{ areCandidateExtraTime[i][k]['reading_justification']}}">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" class="td-button">
                                                                <button class="btn btn-primary" (click)="saveExtraTime(i, k, candidate.pivot.id,  exam.id, 'reading')">Mentés</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2"><b>Beszédértés</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Extra perc hozzárendelése &nbsp;
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'listening')"
                                                                       value="{{ areCandidateExtraTime[i][k]['listening']}}"
                                                                       style="width: 70px;">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;Indoklás:&nbsp;</td>
                                                            <td class="td-input">
                                                                <input type="text"
                                                                       class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'listening_justification')"
                                                                       value="{{ areCandidateExtraTime[i][k]['listening_justification']}}">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" class="td-button">
                                                                <button class="btn btn-primary" (click)="saveExtraTime(i, k, candidate.pivot.id,  exam.id, 'listening')">Mentés</button>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2"><b>Beszédkészség</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                Extra perc hozzárendelése &nbsp;
                                                            </td>
                                                            <td>
                                                                <input type="number" min="0" class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'speaking')"
                                                                       value="{{ areCandidateExtraTime[i][k]['speaking']}}"
                                                                       style="width: 70px;">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>&nbsp;Indoklás:&nbsp;</td>
                                                            <td class="td-input">
                                                                <input type="text"
                                                                       class="form-control"
                                                                       (change)="addExtraTimeToCandidate($event, i, k, candidate.pivot.id, 'speaking_justification')"
                                                                       value="{{ areCandidateExtraTime[i][k]['speaking_justification']}}">
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="2" class="td-button">
                                                                <button class="btn btn-primary" (click)="saveExtraTime(i, k, candidate.pivot.id,  exam.id, 'speaking')">Mentés</button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                                <ng-container *ngIf="candidate.pivot.identity_card_number_form_open" class="text-right">
                                                    <table class="extra-time-table">
                                                        <tr>
                                                            <td style="padding-top: 10px;">
                                                                Igazolványszám: &nbsp;
                                                                <input type="text" min="0" class="form-control"
                                                                       (change)="addIdentityCardNumberToCandidate($event, candidate.id, i, k)"
                                                                       value="{{ candidate.identity_card_number }}"
                                                                       style="width: 120px;">
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="pagination.current_page">
                    <ul class="pagination">
                        <i *ngIf="loaderShow" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                        <li *ngIf="pagination.current_page > 1"><button class="btn btn-primary" [disabled]="isLoading"  (click)="setPage('pre')">Előző</button></li>
                        <li><button class="btn btn-primary" [disabled]="isLoading">{{ pagination.current_page }}. oldal</button></li>
                        <li *ngIf="pagination.current_page < pagination.last_page"><button class="btn btn-primary" [disabled]="isLoading" (click)="setPage('next')">Következő</button></li>
                    </ul>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
