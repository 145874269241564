<ng-container>
    <aside></aside>
</ng-container>

<div class="exam-roster col-md-12">
    <h2>Személyazonosság ellenőrzése</h2>

    <div class="col-md-12">
        <table class="table">
            <thead>
                <tr>
                    <th i18n>Azonosító</th>
                    <th i18n>Állapot</th>
                    <th i18n *ngIf="environmentService.get('examType') !== 'mmk'">Nyelv</th>
                    <th i18n *ngIf="environmentService.get('examType') !== 'mmk'">Szint</th>
                    <th i18n *ngIf="environmentService.get('examType') === 'mmk'">Témakör</th>
                    <th i18n *ngIf="environmentService.get('examType') !== 'mmk'">Típus</th>
                    <th i18n>Időpont</th>
                    <th i18n>Művelet</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="examData">
                    <ng-container *ngFor="let exam of examData; let i = index">
                        <tr>
                            <td>{{ exam.id }}</td>
                            <td>{{ exam.status }}</td>
                            <td *ngIf="environmentService.get('examType') !== 'mmk'">{{ exam.language }}</td>
                            <td *ngIf="environmentService.get('examType') !== 'mmk'">{{ exam.level }}</td>
                            <td *ngIf="environmentService.get('examType') === 'mmk'">{{ exam.name }}/{{ exam.questions_subtypes_name}}</td>
                            <td *ngIf="environmentService.get('examType') !== 'mmk'">{{ examTypes[exam.type] }}</td>
                            <td>{{ exam.date }}</td>
                            <td>
                                <span (click)="openCandidatesList(i, exam.id)"><b>{{!this.areExamsShown[i] ? 'Felügyelés' : 'Elrejtés'}}</b></span>
                                | <span (click)="openStartChangeDialog(startTimeDialog, exam)"><b>Kezdési idő módosítás</b></span>
                                <!--<span *ngIf="this.areExamsShown[i]" (click)="goLarge()"> | <b>Fókusz</b></span>-->
                            </td>
                        </tr>
                        <tr [class.hide]="!areExamsShown[i]">
                            <td colspan="7">
                                <div class="row">
                                    <button mat-button color="primary" (click)="muteAll()">Összes némítása</button>
                                    <button mat-button color="primary" (click)="unMuteAll()">Összes hangosítása</button>
                                    <span *ngIf="exam.inspectors && environmentService.get('examType') === 'mmk'" style="color: green;">
                                        Aktív felügyelők:
                                        <span *ngFor="let inspector of exam.inspectors; let isLast=last">{{inspector}}{{isLast ? '' : ', '}}</span>
                                    </span>
                                </div>
                                <div class="row">
                                    <identity-card *ngFor="let candidate of exam.candidates; let k = index" [candidate]="candidate" #cards></identity-card>
                                </div>
                                <session-list *ngIf="environmentService.get('live_oral_exam') && areExamsShown[i]" [exam_id]="exam.id"></session-list>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
        <mat-paginator [length]="matPagination['length']"
                      [pageSize]="matPagination['size']"
                      [pageSizeOptions]="[5, 10, 25, 100]"
                      (page)="matPaginate($event)"
                      *ngIf="examData">
        </mat-paginator>
            </tbody>
        </table>
    </div>

   <ng-template #startTimeDialog>
      <h2 matDialogTitle>Vizsga kezdési idejének módosítása</h2>
      <mat-dialog-content>
          <p>A vizsga kezdeti ideje csak a jelenlegi környezetre hat. Az időponttal módosúl a belépési időintervallum is.</p>
            <mat-form-field>
              <input matInput [ngxMatDatetimePicker]="picker" placeholder="A vizsga kezdeti ideje" [(ngModel)]="examEdit.date">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <ngx-mat-datetime-picker #picker></ngx-mat-datetime-picker>
            </mat-form-field>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary">Mégse</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Mentés</button>
      </mat-dialog-actions>
    </ng-template>

    <live-test *ngIf="environmentService.get('live_oral_exam')" (openChat)="openChat($event)"></live-test>
</div>
