import {forkJoin as observableForkJoin,  Observable } from 'rxjs';
import {combineLatest, delay, defaultIfEmpty, catchError, map} from 'rxjs/operators';
import { RestConfig } from "./rest_config.http";
import {HttpClient} from "@angular/common/http";

export class RestHttp {
    protected modelName: string;
    protected defaultUrl: string;
    protected urlPrefix: string;
    protected delayInsertTime: number = 0;
    protected isFileUpload: boolean = false;

    constructor(protected http: HttpClient, modelName?: string) {
        if (modelName) {
            this.modelName = modelName;
        }
    }
    del(value, useDefaultUrl? : boolean): Observable<any> {
        let url = (useDefaultUrl) ? this.defaultUrl : RestConfig.getUrl();

        return this.http.delete(`${url}/${value}`, RestConfig.getReqOpt()).pipe(
            map(() => value));
    }

    delAll(deleteItems: Array<any>, useDefaultUrl?: boolean, deleteKey?: string): Observable<any> {

        let allObservables = deleteItems.filter((item) => item !== undefined).map((item) => {

            return (deleteKey) ? this.del(item[deleteKey], useDefaultUrl) : this.del(item, useDefaultUrl);
        });

        return observableForkJoin(allObservables).pipe(
                map((data) => { return defaultIfEmpty(false) })
        );
    }

    insert(body: any, useDefaultUrl?: boolean): Observable<any> {

        let url = (useDefaultUrl) ? this.defaultUrl : RestConfig.getUrl();
        let requestOptions = RestConfig.getReqOpt();

        if (requestOptions['observe']) {
            delete requestOptions['observe'];
        }

        if (this.isFileUpload) {
            delete requestOptions.headers['Content-Type'];
        } else {
            requestOptions.headers['Content-Type'] = 'application/json';
        }

        return this.http.post(url, body, requestOptions).pipe(
            delay(this.delayInsertTime),
            map((response:any) => {
                let jsonData = response;

                if (this.modelName && jsonData[this.modelName]) {
                    jsonData = jsonData[this.modelName];
                }

                return jsonData;
            }));
    }

    insertAll(insertItems: Array<any>, insertKey?: string, useDefaultUrl? : boolean): Observable<any> {

        let allObservables = insertItems.map((item) => {
            return (insertKey) ? this.insert(item[insertKey], useDefaultUrl) : this.insert(item, useDefaultUrl);
        });

        return observableForkJoin(allObservables).pipe(
            map((data) => { return defaultIfEmpty(false) })
        );
    }

    update(body: any, id?: number, useDefaultUrl?: boolean): Observable<any> {
        let url = (id) ? `${RestConfig.getUrl()}/${id}` : RestConfig.getUrl();

        if (useDefaultUrl) {
            url = (id) ? `${this.defaultUrl}/${id}` : this.defaultUrl;
        }

        return this.http.patch(url, body, RestConfig.getReqOpt()).pipe(
            map(() => true));
    }

    updateAll(updateDatas: Array<any>, useDefaultUrl?: boolean, updateKey?: string): Observable<any> {

        let allObservables = updateDatas.map((item) => {
            let {data, id} = item;

            return (updateKey) ? this.update(data[updateKey], id, useDefaultUrl) : this.update(data, id, useDefaultUrl);
        });

        return observableForkJoin(allObservables).pipe(
            map((data) => { return defaultIfEmpty(false) })
        );
    }

    read(id?: number, property?: any, useDefaultUrl?: boolean): Observable<any> {
        let url = (id) ? `${RestConfig.getUrl()}/${id}` : RestConfig.getUrl();

        if (useDefaultUrl) {
            url = (id) ? `${this.defaultUrl}/${id}` : this.defaultUrl;
        }

        return this.http.get(url, RestConfig.getReqOpt()).pipe(
            map((response:any) => {
                let jsonData = response;

                if (this.modelName && jsonData[this.modelName]) {
                    jsonData = jsonData[this.modelName];
                }

                if (typeof property === "string") {
                    jsonData = jsonData[property];
                }

                if (typeof property === "object") {
                    let properties = [];

                    property.fields.forEach((item) => {
                        properties.push(jsonData[item]);
                    });

                    if (property.isConcat) {
                        jsonData = properties.join(" ");
                    }

                }

                return jsonData;
            }));
    }

    readByIds(ids: Array<any>, property?: string, useDefaultUrl?: boolean): Observable<any> {
        let subItems = ids.filter((item) => typeof item === "object"), allObservables;

        if (subItems.length > 0)
        {
            allObservables = [];
            subItems.forEach((items: Array<any>, index) =>
            {
                items.forEach((item, itemIndex) =>
                {
                    allObservables.push(this.read(item, property, useDefaultUrl).pipe(combineLatest((result) => {
                        return {
                            index,
                            dataResult: result
                        };
                    })));
                });
            });
        } else {
            allObservables = ids.map((item) => {
                return this.read(item, property, useDefaultUrl);
            });
        }

        return observableForkJoin(allObservables);
    }

}
