//tslint:disable max-file-line-count

import {ActivatedRoute} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {FileUploadService} from "../../services/shared/upload.service";
import {FormCreatorItemComponent} from "../options/form_creator_items.component";
import {IPreviewGenerate} from "../options/form_creator_items.component";
import {isNullOrUndefined} from "../../utils/iswhat";
import {type} from "os";

const TYPES = [$localize`Weboldal űrlap`, $localize`Formanyomtatvány`];

@Component({
    moduleId: module.id,
    selector: "form-creator",
    host: {
        id: "form-creator"
    },
    entryComponents: [FormCreatorItemComponent],
    templateUrl: "form_creator.component.html"
})

export class FormCreatorComponent implements OnInit {

    formCreator: FormGroup;
    mainForm: FormGroup;
    itemFormArrays: FormArray = new FormArray([]);
    typeRadios: Array<string> = TYPES;
    sectionItems: Array<FormArray>;
    generatedPreviews: Array<any>;
    typeValue: number;
    taskId: number;
    isRegistryFieldRequired: boolean;
    isFormValid: boolean = true;
    taskData: Object;
    private typeControl: FormControl = new FormControl(null, Validators.required);
    private defaultItemNumber: number;

    constructor(private formBuilder: FormBuilder,
                public examCreatorFormService: ExamCreatorFormService,
                private fileUploadService: FileUploadService,
                private activatedRoute: ActivatedRoute) {

        this.mainForm = this.examCreatorFormService.$examCreatorFrom;

        this.formCreator = formBuilder.group({
            type: this.typeControl,
            logo_file_url: new FormControl(),
            form_title: new FormControl()
        });

        this.typeControl.valueChanges.subscribe((value) => {

            this.itemFormArrays = new FormArray([]);

            if (value === 0) {
                this.formCreator.removeControl("company_title");
                this.formCreator.removeControl("address");
                this.formCreator.removeControl("form_description");

                this.formCreator.setControl("site_title", new FormControl());
                this.formCreator.setControl("content_file_url", new FormControl());
                this.formCreator.setControl("background_color", new FormControl("#ffffff"));
                this.formCreator.setControl("color", new FormControl("#000000"));
            } else {

                this.sectionItems = [];

                this.formCreator.removeControl("site_title");
                this.formCreator.removeControl("content_file_url");

                this.formCreator.setControl("company_title", new FormControl());
                this.formCreator.setControl("address", new FormControl());
                this.formCreator.setControl("form_description", new FormControl());
            }

            this.typeValue = value;
        });

        this.fileUploadService.uploadedFile.subscribe(({url, type}) => {
            if (type === "logo") {
                this.formCreator.patchValue({
                    logo_file_url: url
                });
            } else {
                this.formCreator.patchValue({
                    content_file_url: url
                });
            }
        });

        this.activatedRoute.params.subscribe(({id}) => {
            this.taskId = id;

            let taskValues = this.examCreatorFormService.getTaskDataValue();
            let itemValues = this.examCreatorFormService.getItemsValues();

            if (taskValues !== null) {
                this.formCreator.patchValue(taskValues);
            }

            if (itemValues && itemValues.every((item) => item !== null)) {
                let itemArrayGroups = itemValues.map((itemObject) => {
                    let formConfigObject = {};

                    for (let item in itemObject) {
                        if (itemObject.hasOwnProperty(item)) {
                            let value = itemObject[item];
                            formConfigObject[item] = new FormControl(value);
                        }
                    }

                    return new FormGroup(formConfigObject);
                });

                this.itemFormArrays = new FormArray(itemArrayGroups);
            }

            // if (!this.mainForm.valid && !this.formCreator.valid && this.taskId) {
            if (this.taskId) {
                this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {

                    this.taskData = JSON.parse(examTask.task_data);
                    examCreatorFormService.previousNumber = examTask.number;

                    this.formCreator.patchValue(this.taskData);

                    // tslint:disable-next-line:variable-name
                    this.examCreatorFormService.setExamObject(examTask);
                    this.examCreatorFormService.setExistInstructionControl(examTask.instruction);

                    examTask.view_date = examTask.view_date.replace(/:[0-9]+$/, "");

                    this.mainForm.patchValue(examTask);

                    if (examTask.items) {
                        this.sectionItems = [];
                        this.setItemArraysWithExistData(examTask.items);
                    }
                });
            }

            if (!this.taskId) {
                this.examCreatorFormService.backToMainForm();
            }
        });

        this.examCreatorFormService.requiredRegistryField.subscribe((data) => {
            if (isNullOrUndefined(data)) {
                this.isRegistryFieldRequired = true;
            }
            this.isRegistryFieldRequired = data;
        });

        this.mainForm.valueChanges.subscribe(() => {
            this.isRegistryFieldRequired = this.mainForm.controls.instruction.value !== "" ||
                                           isNullOrUndefined(this.mainForm.controls.instruction.value);

            this.examCreatorFormService.registryFormSend.emit(this.mainForm);
        });

    }

    removeTaskFile(type) {
        if (type === "logo") {
            this.taskData["logo_file_url"] = "";
        } else {
            this.taskData["content_file_url"] = "";
        }
        this.formCreator.patchValue(this.taskData);
    }

    private setItemArraysWithExistData(items: Array<any>) {
        let _sectionId: number,
            _sectionName,
            itemArrays = [], sectionItems: Array<any> = [];
        const tempArray = items.map((item) => {
            const itemData = JSON.parse(item.item_data);
            itemData.id = item.id;
            return itemData;
        });
        const length = tempArray.length - 1;

        if (this.typeValue === 0) {
            items.forEach((item, index) => {
                let itemData = JSON.parse(item.item_data);

                if (itemData.section_items) {
                    this.sectionItems[index] = new FormArray([]);

                    itemData.section_items.forEach((sectionItem, subIndex) => {
                        let sectionFormGroup = {};

                        for (let sItem in sectionItem) {
                            if (sectionItem.hasOwnProperty(sItem)) {
                                let sectionValue = sectionItem[sItem];
                                sectionFormGroup[sItem] = new FormControl(sectionValue);
                            }
                        }

                        let formGroup = new FormGroup(sectionFormGroup);

                        this.sectionItems[index].insert(subIndex, formGroup);
                    });
                }
            });

            itemArrays = items.map((item, index) => {
                let itemData = JSON.parse(item.item_data);
                let formGroupConfig = {};

                for (let dataItem in itemData) {
                    if (itemData.hasOwnProperty(dataItem)) {
                        let value = itemData[dataItem];

                        if (dataItem !== "section_items") {
                            formGroupConfig[dataItem] = new FormControl(value);
                        } else {
                            formGroupConfig[dataItem] = this.sectionItems[index];
                        }
                    }
                }
                formGroupConfig["id"] = new FormControl(item.id);

                return new FormGroup(formGroupConfig);
            });
        } else {
            tempArray.forEach((tempItem, index) => {

                if (tempItem.section_items) {

                    if (typeof _sectionId === "number" && _sectionId !== tempItem.section_id) {

                        itemArrays.push({
                            section_id: _sectionId,
                            section_name: _sectionName,
                            section_items: sectionItems
                        });

                        sectionItems = [];
                    }

                    if (tempItem.section_items.length > 0) {
                        tempItem.section_items.forEach((sectionItem, subIndex) => {
                            tempItem.section_items[subIndex].id = tempItem.id;
                        });
                    } else {
                        tempItem.section_items.id = tempItem.id;
                    }

                    _sectionName = tempItem.section_name;
                    _sectionId = tempItem.section_id;
                    sectionItems = sectionItems.concat(tempItem.section_items);

                    if (index === length) {
                        itemArrays.push({
                            section_id: _sectionId,
                            section_name: _sectionName,
                            section_items: sectionItems
                        });
                    }
                }
            });

            itemArrays = itemArrays.map((item, index) => {
                let formGroupConfig = {};

                this.sectionItems[index] = new FormArray([]);
                item.section_items.forEach((sectionItem, subIndex) => {
                    let sectionFormGroup = {};

                    for (let sItem in sectionItem) {
                        if (sectionItem.hasOwnProperty(sItem)) {
                            let sectionValue = sectionItem[sItem];
                            sectionFormGroup[sItem] = new FormControl(sectionValue);
                        }
                    }

                    let formGroup = new FormGroup(sectionFormGroup);
                    this.sectionItems[index].insert(subIndex, formGroup);
                });

                for (let dataItem in item) {
                    if (item.hasOwnProperty(dataItem)) {
                        let value = item[dataItem];
                        formGroupConfig[dataItem] = new FormControl(value);
                    }
                }

                return new FormGroup(formGroupConfig);
            });
        }
        this.itemFormArrays = new FormArray(itemArrays);
    }

    ngOnInit() {
        //this.examCreatorFormService.setInstructionAndTypeControl("form", this.taskId);
        let itemCountControl = this.mainForm.get("item_count");

        this.defaultItemNumber = itemCountControl.value;

        itemCountControl.valueChanges.subscribe((value) => {
            this.defaultItemNumber = value;
        });
    }

    generatePreview(event: IPreviewGenerate, index?: number) {

        if (index !== undefined) {
            let sectionName = this.itemFormArrays.at(index).get("section_name").value;

            if (!this.generatedPreviews) {
                this.generatedPreviews = [{
                    section: sectionName,
                    previews: [event.preview]
                }];
            } else {

                if (this.generatedPreviews[index]) {
                    let previews = this.generatedPreviews[index].previews;
                    previews[event.index] = event.preview;

                    this.generatedPreviews[index] = {
                        section: sectionName,
                        previews
                    };
                } else {
                    this.generatedPreviews[index] = {
                        section: sectionName,
                        previews: [event.preview]
                    };
                }
            }

        } else {

            if (!this.generatedPreviews) {
                this.generatedPreviews = [event.preview];
            } else {
                this.generatedPreviews[event.index] = event.preview;
            }
        }
    }

    onClickNewOptionBtn(event: Event) {
        event.preventDefault();

        const length = this.itemFormArrays.length > 0 ? this.itemFormArrays.value[this.itemFormArrays.length - 1].section_id + 1 : 0;

        if (this.typeValue === 0) {
            this.itemFormArrays.push(new FormGroup({
                item_type: new FormControl(null, Validators.required),
                item_label: new FormControl(null, Validators.required)
            }));
        } else {
            this.sectionItems.push(new FormArray([]));

            this.itemFormArrays.push(new FormGroup({
                section_id: new FormControl(length),
                section_name: new FormControl(null, Validators.required)
            }));
        }

        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    onClickNewFieldBtn(event: Event, sectionGroup: FormGroup, index: number) {
        event.preventDefault();

        this.sectionItems[index].push(new FormGroup({
            item_type: new FormControl(null, Validators.required),
            item_label: new FormControl(null, Validators.required)
        }));

        sectionGroup.setControl("section_items", this.sectionItems[index]);
    }


    // When you delete a section
    onClickDeleteOptionBtn(sectionNumber: number, sectionGroup) {
        if (!isNullOrUndefined(sectionGroup.value.section_items) ) {
            // Remove all the fields from itemHttpApi
            let sectionGroupsLength = sectionGroup.value.section_items.length;

            for (let i = 0; i < sectionGroupsLength; ++i) {
                if (this.itemFormArrays.value[sectionNumber].section_items[i].id) {
                    this.examCreatorFormService.itemHttpApi
                        .del(this.itemFormArrays.value[sectionNumber].section_items[i].id, true)
                        .subscribe(() => {
                            console.log("Item removed!");
                        });
                }
            }

            // The section id, section items and section name will be empty
            this.itemFormArrays.value[sectionNumber] = [];

            // The fields of the current section will be empty
            this.sectionItems.splice(sectionNumber, 1);

            // The previews of the section will be empty
            this.generatedPreviews.splice(sectionNumber, 1);
        }

        // Remove the control of the section
        this.itemFormArrays.removeAt(sectionNumber);
    }

    removedItem(index: number, subIndex?: number) {
        if (subIndex !== undefined) {
            this.sectionItems[index].removeAt(subIndex);

            if (!isNullOrUndefined(this.itemFormArrays.value[index].section_items[subIndex])) {
                if (!isNullOrUndefined(this.itemFormArrays.value[index].section_items[subIndex].id)) {
                    this.examCreatorFormService.itemHttpApi
                        .del(this.itemFormArrays.value[index].section_items[subIndex].id, true)
                        .subscribe(() => {
                            console.log("Item removed!");
                    });
                }
            }

            this.itemFormArrays.value[index].section_items.splice(subIndex, 1);
        } else {
            if (this.itemFormArrays.value[index].id) {
                this.examCreatorFormService.itemHttpApi.del(this.itemFormArrays.value[index].id, true).subscribe(() => {
                    console.log("Item removed!");
                });
            }

            this.itemFormArrays.removeAt(index);
        }

        // When we have some fields we delete from the preview
        if (this.generatedPreviews && this.generatedPreviews.length > 0) {
            this.generatedPreviews.map((genPrevItem, genPrevIndex) => {
                if (genPrevIndex === index) {
                    if (isNullOrUndefined(subIndex)) {
                        genPrevItem.previews.splice(subIndex, 1);
                    } else {
                        genPrevItem.previews.splice(0, 1);
                    }
                }
            });
        }

        if (!this.generatedPreviews || this.generatedPreviews.length === 0) {
            delete this.generatedPreviews;
        }

        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    private getItems() {
        this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {
            if (examTask.items) {
                this.sectionItems = [];
                this.setItemArraysWithExistData(examTask.items);
            }
        });
    }

    onSubmit(event: Event) {
        event.preventDefault();

        if (this.formCreator.valid) {
            this.examCreatorFormService.setTaskDataControlValue(JSON.stringify(this.formCreator.value));

            let cnt = 0;
            let items: Array<FormGroup> = [];
            this.itemFormArrays.value.forEach((item, index) => {
                if (this.typeValue === 0) {
                    let itemArr = item,
                        id = itemArr.id;
                    delete itemArr.id;
                    items.push(new FormGroup({
                        item_data: new FormControl(JSON.stringify(itemArr)),
                        order: new FormControl(index),
                        id: new FormControl(id)
                    }));
                } else {
                    const {section_id, section_name} = item;
                    const itemData = this.sectionItems[index].value.map((sectionItem) => {
                        const {id, item_label, item_single, item_content, item_multi, item_type} = sectionItem;

                        return {
                            id, section_id, section_name,
                            section_items: [{
                                item_label, item_single, item_content, item_multi, item_type
                            }]
                        };
                    });
                    itemData.forEach((dataItem) => {
                        let itemArr = dataItem,
                            id = itemArr.id;

                        delete itemArr.id;

                        items.push(new FormGroup({
                            item_data: new FormControl(JSON.stringify(itemArr)),
                            order: new FormControl(cnt),
                            id: new FormControl(id)
                        }));

                        cnt++;
                    });
                }

            });

            const _formArray = new FormArray(items);
            this.mainForm.setControl("items", _formArray);

            if (this.mainForm.valid) {

                // Disable save button.
                this.isFormValid = false;

                if (this.taskId) {
                    this.examCreatorFormService.taskHttpApi.update(this.mainForm.value, this.taskId, true).subscribe((result) => {
                        console.info(result);
                        if (result === true) {
                            _formArray.value.forEach((item, index) => {
                                item.task_id = this.taskId;
                                if (item.id) {
                                    let id = item.id;
                                    delete item.id;
                                    this.examCreatorFormService.itemHttpApi.update(item, id, true).subscribe(() => {
                                        if (_formArray.value.length === index + 1) {
                                            this.getItems();
                                            this.submitted(false);
                                        }
                                    });
                                } else {
                                    this.examCreatorFormService.itemHttpApi.insert(item, true).subscribe(() => {
                                        if (_formArray.value.length === index + 1) {
                                            this.getItems();
                                            this.submitted(false);
                                        }
                                    });
                                }
                            });
                        }
                    });
                } else {
                    this.examCreatorFormService.taskHttpApi.insert(this.mainForm.value, true).subscribe((result) => {
                        if (result.id) {
                            _formArray.value.forEach((item, index) => {
                                item.task_id = result.id;
                                this.examCreatorFormService.itemHttpApi.insert(item, true).subscribe(() => {
                                    if (_formArray.value.length === index + 1) {
                                        this.examCreatorFormService.createdTask.emit(result.id);
                                        this.submitted(true);
                                    }
                                });
                            });
                        }
                    });
                }
            }
        }
        if (!this.mainForm.valid) {
            this.examCreatorFormService.open.emit(true);
        }
    }

    private submitted(isNew) {
        this.examCreatorFormService.submittedResult = (isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`) +
            " " + new Date().toLocaleDateString("hu-HU", {hour: "numeric", hour12: false, minute: "numeric"}) + "-kor";

        if (!isNew) {
            this.examCreatorFormService.taskHttpApi.rescanExamAnswersByTaskId({task_id: this.taskId}).subscribe(() => {
                console.log("Rescan completed");
            });
        }

        // Enable save button.
        this.isFormValid = true;
    }

    onPreview() {
        // make items array
        this.examCreatorFormService.setTaskDataControlValue(JSON.stringify(this.formCreator.value));
        console.log('Start generating preview');
        let cnt = 0;
        let items: Array<FormGroup> = [];
        this.itemFormArrays.value.forEach((item, index) => {
            if (this.typeValue === 0) {
                let itemArr = item,
                    id = itemArr.id;
                delete itemArr.id;
                items.push(new FormGroup({
                    item_data: new FormControl(JSON.stringify(itemArr)),
                    order: new FormControl(index),
                    id: new FormControl(id),
                    exam_answer: new FormControl(null)
                }));
            } else {
                const {section_id, section_name} = item;

                const itemData = this.sectionItems[index].value.map((sectionItem) => {
                    const {id, item_label, item_single, item_content, item_multi, item_type} = sectionItem;
                    return {
                        id, section_id, section_name,
                        section_items: [{
                            item_label, item_single, item_content, item_multi, item_type
                        }]
                    };
                });
                itemData.forEach((dataItem) => {
                    let itemArr = dataItem,
                        id = itemArr.id;
                    delete itemArr.id;
                    items.push(new FormGroup({
                        item_data: new FormControl(JSON.stringify(itemArr)),
                        order: new FormControl(cnt),
                        id: new FormControl(id),
                        exam_answer: new FormControl(null)
                    }));
                    cnt++;
                });
            }

        });
        // -- make items array -- end

        const _formArray = new FormArray(items);
        this.examCreatorFormService.onPreviewTask(_formArray.value);
    }

    countQuestions() {
        let sumQuestions = 0;
        this.itemFormArrays.value.forEach((item) => {
            const {questions} = item;
            if (!questions) {
                sumQuestions += item.length;
            }
        });

        return sumQuestions;
    }

}
