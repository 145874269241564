<div *ngIf="task != undefined">

  <div class="corrector-sm-container">
    <ul>
      <li>{{task.date}}</li>&nbsp;|&nbsp;
      <li>{{task.language}}</li>&nbsp;|&nbsp;
      <li>{{task.level}}</li>&nbsp;|&nbsp;
      <li>{{task.part}}</li>&nbsp;|&nbsp;
      <li *ngIf="this.isUserAdmin">{{task.last_name}}&nbsp;{{task.first_name}}</li>
      |
      <li>{{task.candidate_id}}</li>
    </ul>
  </div>

  <div class="corrector-container">
    <ul class="vertical-list">
      <li><strong i18n>Fájlnév</strong>: {{task.filename}}</li>
      <li><strong i18n>Típus</strong>: {{task.type}}</li>
      <li><strong i18n>Sorszám</strong>: {{task.number}}</li>
      <li><strong i18n>Feladat neve</strong>: {{task.name}}</li>
      <li><strong i18n>Forrás</strong>: {{task.source}}</li>
      <li><strong i18n>Megtekintés dátuma</strong>: {{task.view_date}}</li>
      <li><strong i18n>Szavak száma / perc, itemszám</strong>: {{task.item_count}}</li>
      <li><strong i18n>Témakör</strong>: {{task.topic}}</li>
      <li><strong i18n>Szövegfajta</strong>: {{task.text_breed}}</li>
      <li><strong i18n>Instrukció</strong>: {{task.instruction}}</li>
    </ul>
  </div>

  <div class="corrector-container" [ngSwitch]="task.type">

    <!-- Speaking task -->
    <ng-template ngSwitchCase="speaking">
      <speaking-task
        [task]="task"
      ></speaking-task>
    </ng-template>

    <!-- Extend task -->
    <ng-template ngSwitchCase="extend">
      <extend-task
        [task]="task"
      ></extend-task>
    </ng-template>

    <!-- Writing task -->
    <ng-template ngSwitchCase="writing">
      <writing-task
        [task]="task"
      ></writing-task>
    </ng-template>

    <!-- Form task -->
    <ng-template ngSwitchCase="form">
      <form-task
        [task]="task"
      ></form-task>
    </ng-template>

    <ng-template ngSwitchCase="select">
      <select-task
        [task]="task"
      ></select-task>
    </ng-template>

    <ng-template ngSwitchCase="select_group">
      <select-group-task
        [task]="task"
      ></select-group-task>
    </ng-template>

    <ng-template ngSwitchCase="pair">
      <pair-task
        [task]="task"
      ></pair-task>
    </ng-template>

    <ng-template ngSwitchCase="short_answer">
      <short-answer-task
        [task]="task"
      ></short-answer-task>
    </ng-template>

    <!-- Default: writing task -->
    <ng-template ngSwitchDefault>
      <writing-task
        [task]="task"
      ></writing-task>
    </ng-template>
  </div>

</div>
