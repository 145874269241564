import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {util} from "tinymce";
import JSON = util.JSON;

@Component({
    moduleId: module.id,
    host: {
        id: "show-candidate-answers",
    },
    selector: "show-candidate-answers",
    templateUrl: "show-candidate-answers.component.html",
    styleUrls: ["show-candidate-answers.component.css"]
})

export class ShowCandidateAnswersComponent {

    taskId: number;
    task: any;
    candidate: any;
    examId: number;
    candidateExamId: number;
    examHttpApi: ExamHttpApi;
    prefixUrl: string;
    token: string;

    constructor(private http: HttpClient,
                private router: Router,
                private activatedRoute: ActivatedRoute) {

        this.examHttpApi = new ExamHttpApi(http, "data");
        this.examHttpApi.setSelectedTaskUrl();
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.activatedRoute.params.subscribe(({taskId, candidateId}) => {
            this.taskId = taskId;
            this.examId = candidateId;
            this.loadTask();
        });

    }

    // Get task by task ID and candidate exam ID
    private loadTask() {
        this.examHttpApi.setTaskById(this.taskId, this.examId).subscribe((results) => {
            this.task = results;

            console.log('this.task: ', this.task);
            // if(results.candidate_is_finished !== 1){
            //     this.router.navigateByUrl(`/corrector/exam-answers`);
            // }

            this.task["task_data"] = (typeof this.task["task_data"] === "string") ?
                JSON.parse(this.task["task_data"]) : this.task["task_data"];
        });
    }
}
