import {Component, DoCheck, Input, OnInit} from "@angular/core";
import {ITask} from "../../../interfaces/task.interface";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {isNull, isNullOrUndefined} from "util";
import {Sorting} from "../../../utils/sorting";
import {AuthService} from "../../../services/auth.service";
import {HttpClient} from "@angular/common/http";

export interface ICorrection {
    correct: number;
}

@Component({
    moduleId: module.id,
    selector: "short-answer-task",
    templateUrl: "short-answer-task.component.html",
    styleUrls: ["../corrector.component.css"]
})

export class ShortAnswerTaskComponent implements OnInit, DoCheck {

    @Input("task") task: ITask;

    private prefixUrl: string;
    private token: string;
    private examHttpApi: ExamHttpApi;
    private correctionsByAnswerId: Map<number, ICorrection> = new Map();
    hasTwoExample = false;
    isAdmin = false;
    hasCorrectionDone = false;

    constructor(private http: HttpClient,
                private authService: AuthService) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setSelectedTaskUrl();
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;
    }

    ngOnInit() {
        this.task.items = Sorting.sortTaskItemsByOrder(this.task.items);

        this.task["items"].forEach((item) => {
            item.item_data = (typeof item.item_data === "string") ?
                JSON.parse(item.item_data) : item.item_data;
            if (!isNullOrUndefined(item["exam_answer"]) && item["exam_answer"].length > 0 && !isNullOrUndefined(item["exam_answer"][0].answer_data)) {
                item["exam_answer"][0].answer_data = item["exam_answer"][0].answer_data.replace(/{|}/g, "");
                this.correctionsByAnswerId.set(item["exam_answer"][0].id, {correct: item["exam_answer"][0].correct});
            }

            // The answer may be NULL
            if (!isNullOrUndefined(item["exam_answer"][0]) && isNull(item["exam_answer"][0].answer_data)) {
                this.correctionsByAnswerId.set(item["exam_answer"][0].id, {correct: 0});
            }
        });

        this.task.task_data = (typeof this.task.task_data === "string") ?
            JSON.parse(this.task.task_data) : this.task.task_data;

        this.hasTwoExample = !isNullOrUndefined(this.task.task_data.two_example_items)
            && this.task.task_data.task_data.two_example_items === true;

        this.task.items.forEach((item:any) => {
            item.item_data = (typeof item.item_data === "string") ? JSON.parse(item.item_data) : item.item_data;

            if (!isNullOrUndefined(item.exam_answer) &&
                item.exam_answer.lenght > 0 &&
                !isNullOrUndefined(item.exam_answer[0].is_autocorrect) &&
                item.exam_answer[0].is_autocorrect === 0) { // automatic correction has been overridden
                this.hasCorrectionDone = true;
                return;
            }
        });
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    // if the corrector marks an answer correct => update the buttons and the texts appearance
    onClickCorrectBtn(event, examAnswerId: number, taskItemId: number) {
        const button = event.target;
        const answerText = button.previousElementSibling.previousElementSibling;

        answerText.classList.remove("incorrect");
        answerText.classList.add("correct");

        button.classList.remove("inactive-correct-color");
        button.classList.add("active-correct-color");
        button.nextElementSibling.classList.remove("active-incorrect-color");
        button.nextElementSibling.classList.remove("inactive-incorrect-color");

        if (button.classList.value.indexOf("border") > -1 || button.classList.value.indexOf("checked") > -1) {
            button.classList.add("checked");
        } else {
            button.nextElementSibling.classList.add("checked");
        }

        // If a new word is added, it is added
        this.doNewWordShortInstruction("ADD_NEW_WORD", taskItemId);

        this.correctionsByAnswerId.set(examAnswerId, {correct: 1});
    }

    // if the corrector marks an answer incorrect => update the buttons and the texts appearance
    onClickIncorrectBtn(event, examAnswerId: number, taskItemId: number) {
        const button = event.target;
        const answerText = button.previousElementSibling.previousElementSibling.previousElementSibling;

        answerText.classList.remove("correct");
        answerText.classList.add("incorrect");

        button.classList.remove("inactive-incorrect-color");
        button.classList.add("active-incorrect-color");
        button.previousElementSibling.classList.remove("active-correct-color");
        button.previousElementSibling.classList.add("inactive-correct-color");

        if (button.classList.value.indexOf("border") > -1 || button.classList.value.indexOf("checked") > -1) {
            button.classList.add("checked");
        } else {
            button.previousElementSibling.classList.add("checked");
        }

        // Remove new word - For now, it's not doing anything
        this.doNewWordShortInstruction("REMOVE_NEW_WORD", taskItemId);

        this.correctionsByAnswerId.set(examAnswerId, {correct: 0});
    }

    saveManualCorrections() {
        this.correctionsByAnswerId.forEach(this.approveAutomaticCorrection.bind(this));
    }

    approveAutomaticCorrection(isCorrect: ICorrection, examAnswerId: number) { // correct: 1, not correct: 0
        this.examHttpApi.approveAutomaticCorrection(examAnswerId, isCorrect);
    }

    /**
     * Download xls file
     * @param file
     */

    private getDownloadFile() {
        let fileName = 'speaking_scale.pdf';
        if(this.task.part === 'writing' || this.task.part === 'reading'){
            fileName = 'writing_scale.pdf';
        }

        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}getDownloadReviewPDF/${fileName}&token=${token}`;

        window.open(url);
    }

    /**
     * Do New word short instruction
     * @param action
     * @param taskItemId
     */
    private doNewWordShortInstruction (action: string, taskItemId: number) {
        this.task.items.map((item) => {
            if (item.exam_answer === null || item.exam_answer.length < 1) {
                return false;
            }

            const answer_data = item.exam_answer[0].answer_data;
            if (item.id === taskItemId) {
                let newShortInstruction  = item.item_data.short_instruction
                    .split("|")
                    .map((item) => { return item.trim(); });

                switch (action) {
                    case "ADD_NEW_WORD":
                        if (!newShortInstruction.includes(answer_data)) {
                            newShortInstruction.push(answer_data);
                        }
                        break;
                    case "REMOVE_NEW_WORD":

                        break;
                    default: break;
                }

                item.item_data.short_instruction = newShortInstruction.join(" | ").trim();

                return true;
            }
        });
    }
}
