import {Component, EventEmitter, OnInit, Output} from "@angular/core";
import {ApiService} from "../../../services/api.service";
import {EnvironmentService} from "../../../services/environment.service";

@Component({
    moduleId: module.id,
    selector: "user-list",
    host: {
        id: "user-list"
    },
    templateUrl: "user-list.component.html",
    styleUrls: ["user-list.css"]
})

export class UserListComponent implements OnInit {
    @Output()
    userEdit = new EventEmitter<any>();
    pending = false;

    users;
    pagination = {
        'page': 0,
        'size': 10,
        'length': 0,
    };

    constructor(private apiService: ApiService, public environmentService: EnvironmentService) {}

    ngOnInit(): void {
        this.getUsers();
    }

    paginate(event) {
        this.pagination.page = event.pageIndex;
        this.pagination.size = event.pageSize;
        this.getUsers();
    }

    onUserEditClicked(user) {
        this.userEdit.emit(user);
    }

    getUsers() {
        this.pending = true;
        this.users = null;
        this.apiService.getUsers(this.pagination).subscribe((data: any) => {
            this.users = data.data;
            this.pagination.length = data.total;
            this.pending = false;
        });
    }
}