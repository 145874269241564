
import {catchError, map} from 'rxjs/operators';
import {RestHttp} from "./rest.http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {RestConfig} from "./rest_config.http";


export class FacetHttpApi extends RestHttp {

    jsonData = {result: "true"};

    setDefaultUrl() {
        this.defaultUrl = `${environment.apiPrefixUrl}facets`;
    }

    createInputFile(examId: number, part: string): Observable<any> {
        let url = `${environment.apiPrefixUrl}facets/generateInputFile`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: examId,
            module: part
        };

        return this.http.post(url, body, requestOptions).pipe(
            map((response:any) => {
                this.jsonData = response;
                return this.jsonData;
            }));
    }

    getModuleWithFacets(examId, part): Observable<any> {
        let url = `${environment.apiPrefixUrl}facet/getModuleWithFacets`;
        let requestOptions = Object.assign({}, RestConfig.getReqOpt()); // Copy the object to not mess up other requests

        requestOptions['params'] = {
            'id': examId.toString(),
            'part': part
        };

        return this.http.get(url, requestOptions).pipe(
            map((response: any) => {
                return <Array<any>> response;
            }));
    }

    getFacetFile(facet): Observable<any> {
        let url = `${environment.apiPrefixUrl}facet/getFacetFile`;
        let requestOptions = Object.assign({}, RestConfig.getReqOpt()); // Copy the object to not mess up other requests


        requestOptions['params'] = {
            "id": facet.id.toString(),
            "type": facet.type,
            "filename": facet.name,
        };

        requestOptions['responseType'] = 'blob';
        requestOptions['observe'] = 'response';

        return this.http.get(url, requestOptions).pipe(
            map((response: any) => {
                return response;
            }));
    }

    removeFacetFile(facet) {
        let url = `${environment.apiPrefixUrl}facets/${facet.id}`;
        let requestOptions = RestConfig.getReqOpt();

        this.http.delete(url, requestOptions).subscribe();
    }

    runFacets(data): Observable<any> {
        let url = `${environment.apiPrefixUrl}facets/runFacets`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.post(url, data, requestOptions).pipe(
            map((response:any) => {
                return response
            }));
    }

    postAnalysisResult(data): Observable<any> {
        let url = `${environment.apiPrefixUrl}facet/postAnalysisResult`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.post(url, data, requestOptions).pipe(
            map((response:any) => {
                return response
            }));
    }

    postFinalizedFacet(exam_id, part): Observable<any> {
        let url = `${environment.apiPrefixUrl}facet/postFinalizedFacet`;
        let requestOptions = RestConfig.getReqOpt();

        let data = {
            exam_id: exam_id,
            part: part
        };

        return this.http.post(url, data, requestOptions).pipe(
            map((response:any) => {
                return response
            }));
    }

    aggregatedResults(exam_id): Observable<any> {
        let url = `${environment.apiPrefixUrl}facet/aggregatedResults`;
        let requestOptions = RestConfig.getReqOpt();

        let data = {
            exam_id: exam_id
        };

        return this.http.post(url, data, requestOptions).pipe(
            map((response:any) => {
                return response
            }));
    }
}
