import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "taskTypeName"
})

export class TaskTypeNamePipe implements PipeTransform {

  transform(value: string): any {
    if (value === 'trueFalse') {
      return 'Igaz-Hamis';
    }

    if (value === 'multipleChoice') {
      return 'Feleletválasztós';
    }

      if (value === null) {
          return '-';
      }

    return 'Kifejtős';
  }

}
