<div class="pair-task-container">
    <div [innerHTML]="task.task_data.sourcePairingControl"></div>

    <h5>
        <strong i18n>Párosítás típusa: </strong>
        <span *ngIf="!isTaskPairingImageToText" i18n>Szöveg - szöveg</span>
        <span *ngIf="isTaskPairingImageToText" i18n>Kép - szöveg</span>
    </h5>
    <h5>
        <strong i18n>Többször felhasználható elemek: </strong>
        <span *ngIf="isItemsReusable" i18n>Igen</span>
        <span *ngIf="!isItemsReusable" i18n>Nem</span>
    </h5>

    <section *ngIf="isTaskPairingImageToText">
        <ul>
            <li *ngFor="let item of task.items; let i = index">
                <div *ngIf="item.item_data.textInputDuplicated">
                    <h5>{{i}} <strong>{{item.item_data.textInputDuplicated}}</strong></h5>
                    <div i18n> Helyes válasz:
                        <strong>{{item.item_data.letter}}</strong>
                    </div>
                    <div><span i18n>Vizsgázó válasza:</span>
                        <strong class="candidate-answ"
                                *ngIf="item.exam_answer != null && item.exam_answer.length > 0 && item.exam_answer[0].answer_data != null"
                                [class.correct]="item.exam_answer !== null && item.exam_answer.length > 0 && item.exam_answer[0].correct === 1"
                                [class.incorrect]="item.exam_answer !== null && item.exam_answer.length > 0 && item.exam_answer[0].correct === 0">
                            {{item.exam_answer[0].answer_data.letter}}
                        </strong>
                        <strong class="incorrect"
                                *ngIf="i > 0 && item.exam_answer == null || i > 0 && (item.exam_answer.answer_data == null || item.exam_answer.answer_data.length < 1)">---</strong>
                        <strong *ngIf="!item.exam_answer && i === 0" class="example" i18n>Példa</strong>
                    </div>
                </div>
            </li>
        </ul>
        <section>
            <div *ngFor="let item of task.items">
                <img src="{{prefixUrl}}{{item.item_data.textInput}}?token={{token}}">
                <h5><strong>{{item.item_data.letter}}</strong></h5>
            </div>
        </section>
    </section>

    <section *ngIf="!isTaskPairingImageToText">
        <ul class="text-to-text">
            <li *ngFor="let item of task.items; let i = index">
                <div *ngIf="item.item_data.textInputDuplicated">
                    <h5>{{i}} <strong>{{item.item_data.textInputDuplicated}}</strong></h5>
                    <div class="box-container">
                        <div><span i18n>Vizsgázó válasza: </span>
                            <div *ngIf="item.exam_answer != null && item.exam_answer.length > 0 && item.exam_answer[0].answer_data != null"
                                  [class.correct]="item.exam_answer !== null && item.exam_answer.length > 0 && item.exam_answer[0].correct === 1"
                                  [class.incorrect]="item.exam_answer !== null && item.exam_answer.length > 0 && item.exam_answer[0].correct === 0"
                                  [innerHTML]="item.exam_answer[0].answer_data.textInput">
                            </div>
                            <strong class="incorrect"
                                    *ngIf="i > 0 && item.exam_answer == null || i > 0 && (item.exam_answer.length  < 1 || item.exam_answer[0].answer_data == null)">---</strong>
                            <strong *ngIf="!item.exam_answer && i === 0" class="example" i18n>
                                Példa
                            </strong>
                        </div>
                        <div i18n>Helyes válasz:
                            <div [innerHTML]="item.item_data.textInput"></div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <!--<section class="text-container">-->
        <!--<div *ngFor="let item of task.items">-->
        <!--{{item.item_data.textInput}}-->
        <!--</div>-->
        <!--</section>-->
    </section>
</div>

