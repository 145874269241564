import {Pipe, PipeTransform} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {ITask} from "../interfaces/task.interface";
import {isNullOrUndefined} from "util";

//tslint:disable max-line-length
interface IConvertData {
    task: ITask;
    isTaskDragNDrop: boolean;
    taskData: String;
    answers: Array<any>;
}

@Pipe({
    name: "extendTaskStringConverter"
})

export class ExtendTaskStringConverter implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {
    }

    transform(value: IConvertData): any {
        let index = 0;
        let newValue: any;
        const task = value.task;
        const isTaskDragNDrop = value.isTaskDragNDrop;
        const match = value.taskData.match(/{(.*?)}/g);

        newValue = value.taskData.replace(/{(.*?)}/g, () => {
            const examAnswer = task.items[index].exam_answer[0];
            const exampleAnswer = task.items[0].item_data.text;

            if (!isTaskDragNDrop) { // if task is not drag n' drop (if you have to write the answers)

                if (!isNullOrUndefined(examAnswer)) {

                    if (!isNullOrUndefined(examAnswer.correct)) { // if correction added
                        index++;
                        const isAnswerCorrect = task.items[index - 1].exam_answer[0].correct === 1;
                        const isOverriddenCorrectionHasDone = task.items[index - 1].exam_answer[0].is_autocorrect === 0;
                        const isEstimatedValueCorrect = task.items[index - 1].exam_answer[0].estimated_value !== 0;
                        const correctAnswer = task.items[index - 1].item_data.text;

                        if (isAnswerCorrect) {

                            if (isOverriddenCorrectionHasDone) {

                                if (isEstimatedValueCorrect) {
                                    return `<strong class="correct">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok correct-answ correct-btn active-correct-color checked"></button>
                                                <button class="glyphicon glyphicon-remove correct-answ incorrect-btn inactive-incorrect-color"></button>
                                            </span>`;
                                } else {
                                    return `<strong class="correct">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok correct-answ correct-btn active-correct-color"></button>
                                                <button class="glyphicon glyphicon-remove correct-answ incorrect-btn inactive-incorrect-color checked"></button>
                                            </span>`;
                                }

                            } else {

                                if (isEstimatedValueCorrect) {
                                    return `<strong class="correct">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${ index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok correct-answ correct-btn active-correct-color border"></button>
                                                <button class="glyphicon glyphicon-remove correct-answ incorrect-btn inactive-incorrect-color"></button>
                                            </span>`;
                                } else {
                                    return `<strong class="correct">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok correct-answ correct-btn active-correct-color"></button>
                                                <button class="glyphicon glyphicon-remove correct-answ incorrect-btn inactive-incorrect-color border"></button>
                                            </span>`;
                                }

                            }

                        } else { // if the answer is incorrect

                            if (isOverriddenCorrectionHasDone) {

                                if (isEstimatedValueCorrect) {
                                    return `<strong class="incorrect">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok incorrect-answ correct-btn inactive-correct-color checked"></button>
                                                <button class="glyphicon glyphicon-remove incorrect-answ incorrect-btn active-incorrect-color"></button>
                                            </span>`;
                                } else {
                                    return `<strong class="incorrect">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok incorrect-answ correct-btn inactive-correct-color"></button>
                                                <button class="glyphicon glyphicon-remove incorrect-answ incorrect-btn active-incorrect-color checked"></button>
                                            </span>`;
                                }

                            } else {

                                if (isEstimatedValueCorrect) {
                                    return `<strong class="incorrect">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok incorrect-answ correct-btn inactive-correct-color border"></button>
                                                <button class="glyphicon glyphicon-remove incorrect-answ incorrect-btn inactive-incorrect-color"></button>
                                            </span>`;
                                } else {
                                    return `<strong class="incorrect">${value.answers[index - 1]}</strong>
                                            <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                            <span class="element-${index - 1}">
                                                <button class="glyphicon glyphicon-ok incorrect-answ correct-btn inactive-correct-color"></button>
                                                <button class="glyphicon glyphicon-remove incorrect-answ incorrect-btn inactive-incorrect-color border"></button>
                                            </span>`;
                                }
                            }
                        }
                    }

                } else {
                    index++;
                    if (index === 1) {  // example
                        return `<strong class="example">${value.answers[index - 1]}</strong>
                                <strong>(0/${match.length - 1})</strong>
                                <span></span>`;
                    }
                }
            } else { // if task is drag n' drop (if you don't have to write the answers)

                if (!isNullOrUndefined(examAnswer)) {

                    if (!isNullOrUndefined(examAnswer.correct)) { // if automatic correction has been done
                        index++;
                        const isAnswerCorrect = task.items[index - 1].exam_answer[0].correct === 1;
                        const correctAnswer = task.items[index - 1].item_data.text;

                        if (isAnswerCorrect) {
                            return `<strong class="correct">${value.answers[index - 1]}</strong>
                                    <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                    <span class="element-${index - 1}"></span>`;
                        } else {
                            return `<strong class="incorrect">${value.answers[index - 1]}</strong>
                                    <strong>(${correctAnswer} ${index - 1}/${match.length - 1})</strong>
                                    <span class="element-${index - 1}"></span>`;
                        }
                    } else {
                        index++;
                        if (value.answers[index - 1] !== "---") {
                            return `<strong>${value.answers[index - 1]}</strong>
                                    <strong>(${index - 1}/${ match.length - 1})</strong>
                                    <span></span>`;

                        } else {
                            return `<strong class="incorrect">---</strong>
                                    <strong>(${index - 1}/${ match.length - 1})</strong>
                                    <span></span>`;
                        }
                    }

                } else {
                    index++;
                    if (index === 1) { // example
                        return `<strong class="example">${exampleAnswer}</strong>
                                <strong>(0/${ match.length - 1})</strong>
                                <span></span>`;
                    }
                }
            }

        });

        return this.sanitizer.bypassSecurityTrustHtml(newValue);
    }
}
