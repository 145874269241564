
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute} from "@angular/router";
import {Component, OnInit, DoCheck} from "@angular/core";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {ExamService} from "../../services/exam_service/exam.service";
import {DatePipe} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../services/api.service";
import {EnvironmentService} from "../../services/environment.service";

@Component({
    moduleId: module.id,
    selector: "export-page",
    host: {
        id: "export-page"
    },
    templateUrl: "export-page.component.html",
    styleUrls: ["export-page.component.css"]
})

export class ExportPageComponent implements OnInit,DoCheck {
    private examHttpApi: ExamHttpApi;
    startDate: string = '2019-01-01';
    endDate: string = '';
    isAdmin:boolean = false;
    isLoading = {
        candidatePoints: false,
        examinerPoints: false,
        productivePoints: false,
        completeExamExport: false,
        diariesExportOnlyExam: false,
        diariesExport: false,
        exportCandidateNotCorrection: false
    };
    exams: Array<any> = [];
    examID: number = 0;

    constructor(private http: HttpClient,
                private authService: AuthService,
                private examService: ExamService,
                private datePipe: DatePipe,
                private apiService: ApiService,
                public environmentService: EnvironmentService,
    ) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();

        this.endDate = this.datePipe.transform(Date.now(), 'yyyy-MM-dd');

        this.examHttpApi.getExamsForDropDownList().subscribe((data) => {
            this.exams = data;
        });
    }


    ngOnInit() {
        // console.log('OK');
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    downloadExcel(type: string){
        this.isLoading[type] = true;

        let postUrl = `${environment.apiPrefixUrl}export/` + type;
        let requestOptions = RestConfig.getReqOpt();
        let body:any = null;

        if(type === 'completeExamExport' || type === 'diariesExportOnlyExam' || type === 'exportCandidateNotCorrection'){
            body = {
                exam_id: this.examID
            };
        } else {
            body = {
                start_date: this.startDate,
                end_date: this.endDate
            };
        }

        return this.http.post(postUrl, body, requestOptions).pipe(map((response:any) => {
            let jsonData = response;

            let file = jsonData.file;
            let token = window.localStorage.getItem("itolc_token");
            let url = `${environment.apiPrefixUrl}export/getDownloadCSV/${file}&token=${token}`;
            window.open(url);
            this.isLoading[type] = false;

        })).subscribe();
    }

    /**
     * Trigger downloading the decision list for the selected exam
     */
    downloadDecisionList() {
        this.isLoading['decisionList'] = true;
        this.apiService.downloadDecisionList(this.examID, () => {
            this.isLoading['decisionList'] = false;
        });
    }
}
