import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {isNullOrUndefined} from "util";

@Injectable()
export class InspectorAuthGuardService implements CanActivate {

    userStatus: string;

    constructor(private router: Router,
                private authService: AuthService) {

        this.userStatus = this.authService.$userStatus;

        console.log('Inspector auth guard, UserStatus', this.userStatus);

        if(isNullOrUndefined(this.userStatus)) {
            this.userStatus = window.localStorage.getItem("itolc_status");
            this.authService.$userStatus = this.userStatus;
        }
    }

    canActivate() {
        const status = this.userStatus === 'admin' || this.userStatus === 'inspector';

        console.log('Inspector auth guard, canActivate', this.userStatus);

        if(!status){
            console.log('InspectorAuthGuardService: Invalid user status, redirecting to home.');
            this.router.navigateByUrl("/");
        }

        return status;
    }
}
