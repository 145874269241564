import {Routes} from "@angular/router";
import {ListComponent} from "../../components/list/list.component";
import {AnalysisComponent} from "../../components/analysis/analysis.component";
import {ResultsComponent} from "../../components/analysis/results/results.component";
import {AnalysisGroupComponent} from "../../components/analysis-group/analysis-group.component";

export const ANALYSIS_ROUTES: Routes = [
    {
        path: "exam-modules",
        component: ListComponent,
        data: {
            type: "exam-modules",
            tableHeaders: [
                `Vizsga azonosító`,
                `Vizsga időpont`,
                `Vizsga helye`,
                `Vizsga szint`,
                `Vizsga típusa`,
                `Fájlok száma`,
                `Művelet`,
            ]
        }
    },
    {
        path: "groups",
        component: AnalysisGroupComponent
    },
    {
        path: "edit-analysis/:id",
        component: AnalysisComponent
    },
    {
        path: "results/:id",
        component: ResultsComponent
    }
];
