<fieldset [formGroup]="formGroup">
    <legend>Típusok:</legend>
    <div class="form-group">
        <label *ngFor="let type of typeRadios">
            <input type="radio" [value]="type.value" formControlName="item_type"/>{{type.text}}
        </label>
    </div>
    <div class="form-group">
        <label>Címke:
            <input type="text" formControlName="item_label"/>
        </label>
    </div>
    <div class="form-group custom-group" *ngIf="isMulti">
        <label>Értékek (soronként egy):
            <textarea formControlName="item_multi"></textarea>
        </label>
    </div>
    <div class="form-group custom-group" *ngIf="isContent">
        <label>Tartalom:
            <textarea formControlName="item_content"></textarea>
        </label>
    </div>
    <div class="form-group custom-group" *ngIf="isSingle">
        <label>Egysoros szöveg:
            <input type="text" formControlName="item_single">
        </label>
    </div>
    <button type="button" class="btn btn-large btn-block btn-danger" 
    (click)="removeItem()" i18n>Eltávolít</button>
</fieldset>