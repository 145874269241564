import { Pipe, PipeTransform } from "@angular/core";
import { FormArray, FormControl, FormGroup } from "@angular/forms";

@Pipe({
    name: "itemFilter",
    pure: false
})

export class ItemFilter implements PipeTransform {

    transform(items: any, type: string): any {
        
        // Remove the duplicate elements
        let textInputs = [];
        let uniqueArray = items.filter(function (item, index) {
            if (type === "textInput") {
                if (textInputs.indexOf(item.value.textInput) === -1) {
                    textInputs.push(item.value.textInput);
                    return item;
                }
            } else if (type === "textInputDuplicated") {
                if (textInputs.indexOf(item.value.textInputDuplicated) === -1) {
                    textInputs.push(item.value.textInputDuplicated);
                    return item;
                }
            }
        });
        return uniqueArray;
        
    }

}
