<h3 i18n>Beszédkészség</h3>
<form novalidate (submit)="onSubmit($event)">
    <app-registry-sheet [form-group]="mainForm"></app-registry-sheet>
    <div class="col-xs-12 form-group">
        <label i18n>Adjon hozzá hanganyagot a teszthez (egy vagy több)</label>
        <section *ngFor="let speakItem of itemFormArrays.controls; let i = index">
            <div class="col-xs-12 form-group speak-group">
                <div class="col-xs-12 form-group">
                    <label i18n>Válassza ki a feladathoz szükséges forrásanyagot(videó, kép):</label>
                    <input type="file" class="form-control"
                           (change)="fileUploadService.onChangeInput($event, 'file', 'file_url', i);">
                    <div class="video-loader"
                         *ngIf="!speakItem.controls.file_url.value && fileUploadService.isVideoLoading === 'file_url'">
                        <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                    </div>
                    <div *ngIf="speakItem.controls.file_url.value">
                        <strong>{{speakItem.controls.file_url.value | filenameConverter}}</strong>
                        <button class="btn btn-danger" (click)="removeItemFile(i, 'file_url')" i18n>
                            Fájl törlése
                        </button>
                        <a class="btn btn-danger" target="_blank"
                           href="{{prefixUrl}}{{speakItem.controls.file_url.value}}?token={{token}}" i18n>Előnézet</a>
                        <video-converter [filename]="speakItem.controls.file_url.value" (newVideoEvent)="handleMediaUpload($event, 'file_url', i);"></video-converter>
                    </div>
                </div>
                <div class="col-xs-12 form-group">
                    <label i18n>Válassza ki a feladathoz szükséges kiegészítő tartalmat(képet):</label>
                    <input type="file" class="form-control"
                           (change)="fileUploadService.onChangeInput($event, 'file', 'enlarge_file_url', i);">
                    <div class="video-loader"
                         *ngIf="!speakItem.controls.enlarge_file_url.value && fileUploadService.isVideoLoading === 'enlarge_file_url'">
                        <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                    </div>
                    <div *ngIf="speakItem.controls.enlarge_file_url.value">
                        <strong>{{speakItem.controls.enlarge_file_url.value | filenameConverter}}</strong>
                        <button class="btn btn-danger" (click)="removeItemFile(i, 'enlarge_file_url')" i18n>Fájl törlése
                        </button>
                        <a class="btn btn-danger" target="_blank"
                           href="{{prefixUrl}}{{speakItem.controls.enlarge_file_url.value}}?token={{token}}" i18n>Előnézet</a>
                    </div>
                </div>
                <div class="col-xs-12"><label i18n="@@copy-source-text-here" i18n>Másolja be a feladathoz tartozó forrásszöveget ide:</label></div>
                <div class="col-xs-12 form-group">
                    <app-tinymce class="app-tinymce"
                                 [formControl]="speakItem.controls.speakingSourceControl"></app-tinymce>
                    <div *ngIf="!speakItem.controls.speakingSourceControl.valid" class="alert alert-danger" i18n>Required
                        field.
                    </div>
                </div>
                <div class="col-xs-12 form-group">
                    <button type="button" class="btn btn-danger"
                            (click)="removedGroupItem(i)" i18n>Eltávolít
                    </button>
                </div>
            </div>
        </section>
        <div class="col-xs-12 form-group">
            <button type="button" class="btn btn-primary"
                    (click)="onClickNewOptionBtn($event)" i18n>Új opció hozzáadása
            </button>
        </div>
    </div>

    <ng-container [ngSwitch]="isDuplicate">
        <ng-container *ngSwitchCase="false">
            <button type="submit" class="btn btn-primary" [disabled]="!isFormValid || !requieredRegSheetForm" i18n>Mentés</button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <button type="submit" class="btn btn-warning" [disabled]="!isFormValid || !requieredRegSheetForm" i18n>Duplikálás mentése</button>
        </ng-container>
    </ng-container>

    <button type="button" class="btn btn-success" (click)="onPreview()" i18n>Előnézet</button>
    <div class="alert alert-success" *ngIf="this.examCreatorFormService.submittedResult != null && this.taskId != null">
        {{this.examCreatorFormService.submittedResult}}
    </div>
</form>
