import {Routes} from "@angular/router";
import {ListComponent} from "../../components/list/list.component";
import {TaskCreatorAuthGuardService} from "../../services/task-creator-auth-guard.service";
import {AdminAuthGuardService} from "../../services/admin-auth-guard.service";
import {environment} from "../../../environments/environment";

export const examTableHeaders = [];
export const taskTableHeaders = [];

if(environment.examListColumns['id']){ examTableHeaders.push($localize`Azonosító`); }
if(environment.examListColumns['study_field_id'])  { examTableHeaders.push($localize`study_field_id`); }
if(environment.examListColumns['study_field_name'])  { examTableHeaders.push($localize`Terület`); }
if(environment.examListColumns['level_name'])  { examTableHeaders.push($localize`Témakör`); }
if(environment.examListColumns['language'])  { examTableHeaders.push($localize`Nyelv`); }
if(environment.examListColumns['level'])  { examTableHeaders.push($localize`Szint`); }
if(environment.examListColumns['place'])  { examTableHeaders.push($localize`Helyszín`); }
if(environment.examListColumns['date'])  { examTableHeaders.push($localize`Dátum`); }
if(environment.examListColumns['anchoring'])  { examTableHeaders.push($localize`anchoring`); }
if(environment.examListColumns['structure'])  { examTableHeaders.push($localize`structure`); }
if(environment.examListColumns['true_false_percent'])  { examTableHeaders.push($localize`true_false_percent`); }
if(environment.examListColumns['multiple_choice_percent'])  { examTableHeaders.push($localize`multiple_choice_percent`); }
if(environment.examListColumns['writing_percent'])  { examTableHeaders.push($localize`writing_percent`); }
if(environment.examListColumns['status'])  { examTableHeaders.push($localize`Státusz`); }
if(environment.examListColumns['type'])  { examTableHeaders.push($localize`Típus`); }
if(environment.examListColumns['comment'])  { examTableHeaders.push($localize`Megjegyzés`); }
if(environment.examListColumns['ready'])  { examTableHeaders.push($localize`Állapot`); }
if(environment.examListColumns['deployed_at'])  { examTableHeaders.push($localize`deployed_at`); }
if(environment.examListColumns['created_at'])  { examTableHeaders.push($localize`Létrehozva`); }
if(environment.examListColumns['updated_at'])  { examTableHeaders.push($localize`updated_at`); }

if(environment.taskColumns.id)  { taskTableHeaders.push($localize`Azonosító`); }
if(environment.taskColumns.exam_scheme)  { taskTableHeaders.push($localize`Témakör`); }
if(environment.taskColumns.section)  { taskTableHeaders.push($localize`Szakasz`); }
if(environment.taskColumns.exam_part)  { taskTableHeaders.push($localize`exam_part`); }
if(environment.taskColumns.user_id)  { taskTableHeaders.push($localize`user_id`); }
if(environment.taskColumns.user_name)  { taskTableHeaders.push($localize`Létrehozó neve`); }
if(environment.taskColumns.filename)  { taskTableHeaders.push($localize`Fálj neve`); }
if(environment.taskColumns.level)  { taskTableHeaders.push($localize`level`); }
if(environment.taskColumns.language)  { taskTableHeaders.push($localize`Nyelv`); }
if(environment.taskColumns.part)  { taskTableHeaders.push($localize`part`); }
if(environment.taskColumns.number)  { taskTableHeaders.push($localize`number`); }
if(environment.taskColumns.name)  { taskTableHeaders.push($localize`Feladat neve`); }
if(environment.taskColumns.source)  { taskTableHeaders.push($localize`source`); }
if(environment.taskColumns.view_date)  { taskTableHeaders.push($localize`view_date`); }
if(environment.taskColumns.source_type)  { taskTableHeaders.push($localize`source_type`); }
if(environment.taskColumns.word_count)  { taskTableHeaders.push($localize`word_count`); }
if(environment.taskColumns.item_count)  { taskTableHeaders.push($localize`Itemszám`); }
if(environment.taskColumns.topic)  { taskTableHeaders.push($localize`topic`); }
if(environment.taskColumns.text_breed)  { taskTableHeaders.push($localize`text_breed`); }
if(environment.taskColumns.instruction)  { taskTableHeaders.push($localize`instruction`); }
if(environment.taskColumns.task_data)  { taskTableHeaders.push($localize`task_data`); }
if(environment.taskColumns.format)  { taskTableHeaders.push($localize`Típus`); }
if(environment.taskColumns.format_code_id)  { taskTableHeaders.push($localize`format_code_id`); }
if(environment.taskColumns.text_source_id)  { taskTableHeaders.push($localize`text_source_id`); }
if(environment.taskColumns.comment)  { taskTableHeaders.push($localize`comment`); }
if(environment.taskColumns.archived_at)  { taskTableHeaders.push($localize`archived_at`); }
if(environment.taskColumns.created_at)  { taskTableHeaders.push($localize`Létrehozás dátuma`); }
if(environment.taskColumns.updated_at)  { taskTableHeaders.push($localize`Módosítás dátuma`); }
if(environment.taskColumns.last_used_day)  { taskTableHeaders.push($localize`Felhasználva (nap)`); }

export const LANDING_ROUTES: Routes =
  [
    {
      path: "tasks",
      component: ListComponent,
      canActivate: [TaskCreatorAuthGuardService],
      data: {
        type: "tasks",
        tableHeaders: taskTableHeaders
      }
    },
    {
      path: "exams",
      component: ListComponent,
      canActivate: [AdminAuthGuardService],
      data: {
        type: "exams",
        tableHeaders: examTableHeaders
      }
    }
  ];
