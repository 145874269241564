<li *ngFor="let writingFormGroup of writingFormArray['controls']; let mainIndex = index">
    <label>
        <span i18n>Feladat:</span>
        <select class="form-control" (change)="selectTask($event, mainIndex)">
            <option [value]="null" i18n>Válasszon!</option>
            <option *ngFor="let option of mainSelectOptions; let i = index"
                    [value]="i" [selected]="hasSelected(mainIndex, option)"
                    [ngClass]="{'green': option.available === true, 'red': option.available === false}">{{option.id}} / {{option.taskText}}
            </option>
        </select>
    </label>
    <button type="button" class="btn btn-sm btn-default" (click)="deleteSelectedTaskDropdown(mainIndex)" i18n>Törlés</button>
    <div class="sub-selection-container"
        *ngIf="writingFormGroup.controls.groupArray">
        <span i18n>Opciók</span>
        <label *ngFor="let groupTask of writingFormGroup.controls.groupArray.controls">
            <select class="form-control options-select"
                    [formControl]="groupTask.controls.task_id">
                <option [value]="null" i18n>Válasszon!</option>
                <option *ngFor="let option of mainSelectOptions"
                        [value]="option.id"
                        [ngClass]="{'green': option.available === true, 'red': option.available === false}">{{option.id}} / {{option.taskText}}
                </option>
            </select>
        </label>
    </div>
</li>
