import {Component, OnInit} from "@angular/core";
import {LANGUAGES, LEVELS} from "../form/app.form";
import {FormBuilder, FormGroup, FormControl, FormArray, Validators} from "@angular/forms";
import {TaskHttpApi} from "../../http_apis/task.http";
import {ITaskGroup} from "../../interfaces/task_groups.interface";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {Router, ActivatedRoute} from "@angular/router";
import {IExam, ICurrentExamTask} from "../../interfaces/exam.interface";
import {WritingSelectionService} from "../../services/writing-selection.service";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {isNullOrUndefined} from "util";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {HttpClient} from "@angular/common/http";
import {ApiService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";

@Component({
    moduleId: module.id,
    host: {
        id: "create-exam",
    },
    selector: "create-exam",
    templateUrl: "create-exam.component.html",
    providers: [WritingSelectionService],
    styleUrls: ["create-exam.css"]
})

export class CreateExamComponent implements OnInit {

    languages = LANGUAGES;
    levels = LEVELS;
    createExamForm: FormGroup;
    taskGroups: ITaskGroup;
    tasksFromGroup: FormGroup;
    examIsReady: boolean = false;
    editForm: boolean = false;
    disableButtons: boolean;
    isDuplicate: boolean = false;
    hasExistWritingData: boolean;
    isDatePickerVisible: boolean = false;
    examId: number;
    hourValue: number = 8;
    minuteValue: number = 0;
    submitButtonText: string;
    pageTitle: string;
    importError: string = null;
    private levelControl = new FormControl();
    private langControl = new FormControl();
    private taskHttpApi: TaskHttpApi;
    private examHttpApi: ExamHttpApi;
    private currentExamTasks: Array<ICurrentExamTask>;
    private dateTimePickerOptions;
    private filterControl: FormControl = new FormControl(false);
    private dateValue: string;
    private importFile: any;

    constructor(private formBuilder: FormBuilder,
                private http: HttpClient,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private writingSelectionService: WritingSelectionService,
                public examCreatorFormService: ExamCreatorFormService,
                private apiService: ApiService,
                private toastr: ToastrService,
    ) {

        this.taskHttpApi = new TaskHttpApi(http,  "data");
        this.taskHttpApi.setDefaultUrl();

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();

        this.activatedRoute.params.subscribe(({id, duplicate}) => {
            this.examId = id;

            if(!isNullOrUndefined(duplicate)){
                this.isDuplicate = true;
                this.examIsReady = false;
            }

            this.tasksFromGroup = new FormGroup({
                writing: new FormArray([]),
                reading: new FormArray([]),
                listening: new FormArray([]),
                speaking: new FormArray([])
            });

            this.createExamForm = this.formBuilder.group({
                level: this.levelControl,
                place: new FormControl(null, Validators.required),
                comment: new FormControl(null),
                date: new FormControl(null, Validators.required),
                type: new FormControl(null, Validators.required),
                language: this.langControl,
                anchoring: this.filterControl,
                tasks: this.tasksFromGroup
            });

            if (this.examId) {
                this.examHttpApi.read(this.examId, undefined, true).subscribe((exam: IExam) => {
                    let {language, date, level, place, comment, type, tasks, anchoring} = exam;

                    if(!this.isDuplicate) {
                        this.examIsReady = exam.ready;
                    }

                    anchoring = anchoring === 1;
                    this.currentExamTasks = tasks;
                    date = date.replace(/:[0-9]+$/, "");
                    this.createExamForm.patchValue({language, date, level, place, comment, type, anchoring}, {emitEvent: false});
                    this.loadTasks();
                });
            } else {
                this.currentExamTasks = null;
            }
        });

        this.activatedRoute.data.subscribe(({title, saveButtonText, edit}) => {
            this.submitButtonText = $localize`:@@6487864089140545507:Mentés`;
            this.pageTitle = title;
            this.editForm = edit;
        });

        if(this.isDuplicate){
            this.submitButtonText = $localize`Duplikálás mentése`;
            this.examIsReady = false;
        }

        this.dateTimePickerOptions = {
            format: "YYYY-MM-DD HH:mm:ss"
        };

        this.filterControl.valueChanges.subscribe(() => {
            // Disable buttons when change anchoring.
            this.disableButtons = true;

            if (this.levelControl.value !== null) {
                this.tasksFromGroup = new FormGroup({
                    writing: new FormArray([]),
                    reading: new FormArray([]),
                    listening: new FormArray([]),
                    speaking: new FormArray([])
                });

                this.createExamForm.setControl("tasks", this.tasksFromGroup);
            }

            this.loadTasks();
        });

    }

    ngOnInit() {
        this.disableButtons = true;
        this.hasExistWritingData = false;
        this.isDatePickerVisible = false;
        this.dateChange(new Date());

        this.langControl.valueChanges.subscribe(() => {
            if (this.langControl.value !== null) {
                this.tasksFromGroup = new FormGroup({
                    writing: new FormArray([]),
                    reading: new FormArray([]),
                    listening: new FormArray([]),
                    speaking: new FormArray([])
                });

                this.createExamForm.setControl("tasks", this.tasksFromGroup);
            }

            // unnecessary
            // this.loadTasks();
        });

        this.levelControl.valueChanges.subscribe(() => {
            if (this.filterControl.value) {
                this.tasksFromGroup = new FormGroup({
                    writing: new FormArray([]),
                    reading: new FormArray([]),
                    listening: new FormArray([]),
                    speaking: new FormArray([])
                });

                this.createExamForm.setControl("tasks", this.tasksFromGroup);
            }

            this.loadTasks();
        });
    }

    private loadTasks() {
        let levelValue = this.levelControl.value;
        let langValue = this.langControl.value;

        if (this.filterControl.value) {
            levelValue = null;
        }

        if (langValue !== null) {
            // Get tasks depends on exam's level and language.
            this.taskHttpApi.getTaskWithGroups(levelValue, langValue).subscribe((results) => {

                this.taskGroups = results;

                // Important! Add saved tasks to lists if needed.
                if (this.currentExamTasks) {
                    this.currentExamTasks.forEach((item) => {
                        if (this.taskGroups[item.part].filter((task) => {
                            return (task && item.task) ? (task.id === item.task.id) : false;   // if there is no tasks provided, don't do anytihng
                        }).length === 0) {
                            this.taskGroups[item.part].push(item.task);
                        }
                    });
                }

                // Set writing list in service.
                this.writingSelectionService.$writings = this.taskGroups.writing;
                this.writingSelectionService.createDropdownElements();

                // If lists fully loaded, enable buttons.
                this.disableButtons = false;

                if (this.currentExamTasks !== null) {
                    this.setTasksFormGroupFromData();
                }
            });
        } else {
            this.disableButtons = true;
        }
    }

    private setTasksFormGroupFromData() {
        let writings = this.currentExamTasks.filter((item) => item.part === "writing");

        if (writings.length > 0) {
            this.hasExistWritingData = true;
            this.writingSelectionService
                .setPreviouslySelectedTasks(writings, <FormArray> this.tasksFromGroup.get("writing"));
        }

        // Set lists.
        this.currentExamTasks.forEach((item) => {
            if (item.part !== "writing") {
                this.generateFormGroupForCurrentTaskFormArray(item.part, item);
            }
        });
    }

    onClickAddTask(event: Event, type: string) {
        event.preventDefault();

        console.log('Click add task', type, event);

        this.hasExistWritingData = false;

        let taskGroupByType = <Array<any>> this.taskGroups[type];

        if (taskGroupByType.length > 0) {
            this.generateFormGroupForCurrentTaskFormArray(type);
        }
    }

    private generateFormGroupForCurrentTaskFormArray(type, existTask?: ICurrentExamTask) {
        let currentTaskFormArray: FormArray = <FormArray> this.tasksFromGroup.get(type);
        console.log(existTask, this.tasksFromGroup);
        if (!existTask) {
            let length = currentTaskFormArray.length;

            currentTaskFormArray.push(new FormGroup({
                task_id: new FormControl(),
                order: new FormControl(length),
                suborder: new FormControl(null)
            }));
        } else {
            currentTaskFormArray.push(new FormGroup({
                task_id: new FormControl(existTask.task_id),
                order: new FormControl(existTask.order),
                suborder: new FormControl(existTask.suborder)
            }));
            console.log(currentTaskFormArray);
        }
    }

    onSubmit(event: Event, {value, valid}) {
        event.preventDefault();

        if (valid) {
            this.disableButtons = true;
            const transformedValue = this.getTransformData(value);
            console.log('Saving', transformedValue);

            if(this.isDuplicate){
                this.examHttpApi.insert(transformedValue, true).subscribe((result) => {
                    this.submitted(true);
                    this.router.navigateByUrl(`/edit-exam/${result.id}`);
                });
            } else {
                if (!this.examId) {
                    this.examHttpApi.insert(transformedValue, true).subscribe((result) => {
                        this.submitted(true);
                        this.router.navigateByUrl(`/edit-exam/${result.id}`);
                    });
                } else {
                    this.examHttpApi.update(transformedValue, this.examId, true).subscribe(() => {
                        this.submitted(false);
                    });
                }
            }
        }
    }

    private submitted(isNew) {
        const submitPrefixText = isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`;
        const dateText = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
        });
        this.disableButtons = false;
        this.examCreatorFormService.submittedResult = `${submitPrefixText} ${dateText}-kor`;
    }

    private getTransformData(value: any) {
        value.anchoring = (value.anchoring === true) ? 1 : 0;

        for (let taskName in value.tasks) {
            let taskValues = <Array<any>> value.tasks[taskName];

            if (taskName === "writing") {
                const taskGroupValues = taskValues.filter((item) => item.groupArray);
                let nonTaskGroupValues = taskValues.filter((item) => !item.groupArray);
                let taskGroups = [];
                taskGroupValues.forEach((item) => {
                    const filtered = item.groupArray
                        .filter((groupItem) => groupItem.task_id !== null);
                    taskGroups = taskGroups.concat(filtered);
                });

                nonTaskGroupValues = nonTaskGroupValues.filter((item) => {
                    console.log(item);
                    if (item.task_id !== null) {
                        item.task_id = parseInt(item.task_id);
                        return item;
                    }
                });

                value.tasks[taskName] = taskGroups.concat(nonTaskGroupValues);

            } else {
                value.tasks[taskName] = taskValues.filter((item) => {
                    console.log(item);
                    if (item.task_id !== null) {
                        item.task_id = parseInt(item.task_id);
                        return item;
                    }
                });
            }
        }

        return value;
    }

    onClickRemoveBtn(type: string, currentIndex: number) {
        let currentFormArray = <FormArray> this.tasksFromGroup.get(type);
        currentFormArray.removeAt(currentIndex);
    }

    dateChange(event: Date) {
        event = new Date(event.getTime() + 24 * 60 * 60 * 1000);
        this.dateValue = event.toISOString().split("T")[0];
        this.dateUpdate();
    }

    onChangeTimePicker(event: Event, type: string) {
        const inputEl = <HTMLInputElement> event.target;

        if (type === "hour") {
            this.hourValue = parseInt(inputEl.value);
        } else {
            this.minuteValue = parseInt(inputEl.value);
        }

        this.dateUpdate();
    }

    private dateUpdate() {
        const hourString = (this.hourValue < 10) ? `0${this.hourValue}` : this.hourValue;
        const minuteString = (this.minuteValue < 10) ? `0${this.minuteValue}` : this.minuteValue;
        const dateTimeStringValue = `${this.dateValue} ${hourString}:${minuteString}:00`;

        this.createExamForm.patchValue({
            date: dateTimeStringValue
        });
    }

    onCloseClickBtn() {
        this.isDatePickerVisible = false;
    }

    onFocusInput(event: Event) {
        const inputEl = <HTMLInputElement> event.target;
        this.isDatePickerVisible = inputEl.id === "date";
    }

    duplicateExam(){
        if (this.examId) {
            this.router.navigateByUrl(`/edit-exam/${this.examId}/duplicate`);
        }
    }

    /**
     * Generate and export test candidates
     */
    exportTestCandidates(){
        if (this.examId) {
            this.examHttpApi.exportTestCandidates(this.examId).subscribe((response:any) => {
                this.getDownloadFile(response.file);
                this.importError = null;
            });
        }
    }

    resetTestAnswers(){
        if (this.examId) {
            this.apiService.resetTestAnswers(this.examId).subscribe((response:any) => {
                this.toastr.success(response.deleted + ' válasz és ' + response.updated + ' folyamat törölve.', 'Művelet végrehajtva', {onActivateTick: true});
            });
        }
    }

    /**
     * Download xls file
     * @param file
     */
    private getDownloadFile(file) {
        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}export/download/${file}&token=${token}`;

        window.open(url);
    }

    onChangeFile(event: Event, name: string){
        let inputEl = <HTMLInputElement> event.target;
        this.importFile = inputEl.files[0];
        this.importError = null;
    }

    /**
     * Import real candidates from CSV
     */
    candidateImport(){
        if(this.importFile && this.examId){
            this.examHttpApi.candidateImport(this.examId, this.importFile).subscribe((response:any) => {
                if(response.failed.length > 0){
                    this.importError = $localize`A következő usereket nem sikerült felvenni hibás helyszín miatt: ` + response.failed;
                } else {
                    this.importError = null;
                }
                this.getDownloadFile(response.file);
            });
        } else {
            this.importError = $localize`Nincs fájl kiválasztva!`;
        }
    }

    examReady(){
        if (window.confirm($localize`Ezzel véglegesíted a vizsgát!\nEzután nem módosítható sem a FELADATSOR sem a VIZSGÁZÓK névsora! Biztos vagy benne?`)) {
            this.examHttpApi.examReadyPost(this.examId).subscribe((response:any) => {
                if(response){
                    this.examIsReady = true;
                } else {
                    alert($localize`A vizsgát nem sikerült lezárni!`);
                }
            });
        }
    }
}
