import {Component, Input, OnInit} from "@angular/core";
import {ITask} from "../../../interfaces/task.interface";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {isNullOrUndefined} from "util";
import {Sorting} from "../../../utils/sorting";
import {HttpClient} from "@angular/common/http";

@Component({
    moduleId: module.id,
    selector: "select-group-task",
    templateUrl: "select-group-task.component.html",
    styleUrls: ["../corrector.component.css"]
})

export class SelectGroupTaskComponent implements OnInit {

    @Input("task") task: ITask;

    private prefixUrl: string;
    private token: string;
    private examHttpApi: ExamHttpApi;
    correctAnswerIndexes: number[] = [];
    areQuestionsAnArray: boolean = false;
    hasTwoExamples: boolean = false;
    questions: Array<any>;
    length: number;

    constructor(private http: HttpClient) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setSelectedTaskUrl();
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;
    }

    ngOnInit() {
        this.task.items = Sorting.sortTaskItemsByOrder(this.task.items);

        this.task["items"].forEach((item) => {
            item.item_data = (typeof item.item_data === "string") ?
                JSON.parse(item.item_data) : item.item_data;
        });

        this.task["items"].forEach((item) => {
            if (!isNullOrUndefined(item.exam_answer) && item.exam_answer.length > 0) {
                if (!isNullOrUndefined(item.exam_answer[0].answer_data)) {
                    item["exam_answer"][0].answer_data = (typeof item["exam_answer"][0].answer_data === "string") ?
                        JSON.parse(item["exam_answer"][0].answer_data) : item["exam_answer"][0].answer_data;
                }
            }
        });

        this.hasTwoExamples = !isNullOrUndefined(this.task.task_data.two_example_items) && this.task.task_data.two_example_items === true;

        this.task.items.map((item) => {
            if (item.item_data.questions instanceof Array) {
                this.areQuestionsAnArray = true;
                item.item_data.questions[0].answers.map((answer, innerIndex) => {
                    if (answer.multipleAnswerRadio) {
                        this.correctAnswerIndexes.push(innerIndex);
                    }
                });
            } else {
                item.item_data.questions.answers.map((answer, innerIndex) => {
                    if (answer.multipleAnswerRadio) {
                        this.correctAnswerIndexes.push(innerIndex);
                    }
                });
            }
        });

        let _groupId: number;
        this.questions = [];
        this.length = 0;
        let index = 0;
        this.task.items.forEach((item) => {
            this.length++;
            let groupId = item.item_data.groupId;
            let itemData = item.item_data;
            const {questions} = itemData;
            const _question = (Array.isArray(questions)) ? questions[0] : questions;

            if (groupId !== _groupId) {
                _groupId = groupId;

                if (this.questions.filter((question) => question.groupId === groupId).length === 0) {
                    this.questions.push({
                        groupId: itemData.groupId,
                        multipleGroupName: itemData.multipleGroupName,
                        multipleQuestions: [{
                            multipleQuestion: _question.multipleQuestion,
                            answers: _question.answers,
                            exam_answer: item.exam_answer,
                            number: index
                        }]
                    });
                } else {
                    this.questions.map((question) => {
                        if (question.groupId === groupId) {
                            question.multipleQuestions.push({
                                multipleQuestion: _question.multipleQuestion,
                                answers: _question.answers,
                                exam_answer: item.exam_answer,
                                number: index
                            });
                        }
                    });
                }
                index++;
            } else {
                this.questions.map((question) => {
                    if (question.groupId === _groupId) {
                        question.multipleQuestions.push({
                            multipleQuestion: _question.multipleQuestion,
                            answers: _question.answers,
                            exam_answer: item.exam_answer,
                            number: index
                        });
                    }
                });
                index++;
            }
        });

        this.task.task_data.itemData.map((item) => {
            item.hasMediaFile = item.file_url !== null;
            item.isMp3 = item.file_url.indexOf("mp3") > 0;
        });

        console.log("this.task", this.task);
        console.log("this.questions", this.questions);
    }
}
