//tslint:disable max-file-line-count
import {AfterViewInit, Component, DoCheck, Input, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {ITask} from "app/interfaces/task.interface";
import {isNullOrUndefined} from "util";
import {Sorting} from "../../../utils/sorting";
import {AuthService} from "../../../services/auth.service";
import {ICorrection} from "../short-answer-task/short-answer-task.component";
import {HttpClient} from "@angular/common/http";

@Component({
    moduleId: module.id,
    selector: "extend-task",
    templateUrl: "extend-task.component.html",
    styleUrls: ["../corrector.component.css"]
})

export class ExtendTaskComponent implements OnInit, AfterViewInit, DoCheck {

    @Input("task") task: ITask;

    private prefixUrl: string;
    private token: string;
    private examHttpApi: ExamHttpApi;
    private taskId: number;
    private examId: number;
    correctionsByAnswerId: Map<number, ICorrection> = new Map();
    isTaskDragNDrop: boolean;
    isAdmin = false;
    hasCorrectionDone = false;
    hasTwoExample = false;

    constructor(private http: HttpClient,
                private activatedRoute: ActivatedRoute,
                private authService: AuthService) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setSelectedTaskUrl();
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.activatedRoute.params.subscribe(({candidateId, taskId}) => {
            this.taskId = taskId;
            this.examId = candidateId;
        });
    }

    ngOnInit() {
        this.task.items = Sorting.sortTaskItemsByOrder(this.task.items);

        this.task["words"] = [];

        this.task.items.forEach((item:any) => {
            item.item_data = (typeof item.item_data === "string") ?
                JSON.parse(item.item_data) : item.item_data;

            if (!isNullOrUndefined(item.exam_answer) && item.exam_answer.length > 0) { // if item is not an example (example item's exam answer is null)

                if (!isNullOrUndefined(item.exam_answer[0].is_autocorrect) &&
                    item.exam_answer[0].is_autocorrect === 0) { // if is_autocorrect is 0 => automatic correction has been overridden
                    this.hasCorrectionDone = true;
                }

                if (!isNullOrUndefined(item.exam_answer[0].answer_data)) { // if item has answer
                    item.exam_answer[0].answer_data = (typeof item.exam_answer[0].answer_data === "string") ?
                        JSON.parse(item.exam_answer[0].answer_data) : item.exam_answer[0].answer_data;

                    if (item.exam_answer[0].answer_data.value) { // if answer has actual value
                        item.exam_answer[0].answer_data.value = this.removeStrongTags(item.exam_answer[0].answer_data.value);
                        this.task["words"].push(item.exam_answer[0].answer_data.value);
                        this.correctionsByAnswerId.set(item.exam_answer[0].id, {correct: item.exam_answer[0].correct});
                    } else {
                        this.task["words"].push("---");
                    }
                } else { // if there is no answer
                    this.task["words"].push("---");
                }
            } else { // if item is an example
                if (item.item_data.text) { // if example item has a value
                    this.task["words"].push(item.item_data.text);
                } else {
                    this.task["words"].push("");
                }
            }
        });

        this.task.task_data = (typeof this.task.task_data === "string") ?
            JSON.parse(this.task.task_data) : this.task.task_data;

        this.hasTwoExample = !isNullOrUndefined(this.task.task_data.two_example_items)
            && this.task.task_data.task_data.two_example_items === true;

        this.isTaskDragNDrop = this.task.task_data.task_checkbox;
    }

    // string-converter.pipe.ts converts the values into an appropriate appearance
    ngAfterViewInit() {
        const buttons = document.querySelectorAll(".correct-answ.correct-btn, .correct-answ.incorrect-btn," +
            ".incorrect-answ.correct-btn, .incorrect-answ.incorrect-btn");

        for (let button of <any> buttons) {
            button.addEventListener("click", this.onClickCorrectionBtn.bind(this));
        }
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    // if the corrector marks an answer correct or incorrect => update the buttons and the texts appearance
    onClickCorrectionBtn(event) {
        const button = event.target;
        const buttonClassNames = button.className.split(" ");
        const parentElementClassName = button.parentNode.className; // this element contains the current item's index
        const parentElementIndex = parseInt(parentElementClassName.substring(parentElementClassName.length - 1));
        const examAnswerId = this.task.items[parentElementIndex].exam_answer[0].id;
        const answerText = button.parentNode.previousElementSibling.previousElementSibling;

        if (this.isAnswerMarkedToCorrect(buttonClassNames)) { // if the answer is correct
            answerText.classList.remove("incorrect");
            answerText.classList.add("correct");

            button.classList.remove("inactive-correct-color");
            button.classList.add("active-correct-color");
            button.nextElementSibling.classList.remove("active-incorrect-color");
            button.nextElementSibling.classList.add("inactive-incorrect-color");

            if (button.classList.value.indexOf("border") > -1 || button.classList.value.indexOf("checked") > -1) {
                button.classList.add("checked");
            } else {
                button.nextElementSibling.classList.add("checked");
            }

            this.correctionsByAnswerId.set(examAnswerId, {correct: 1});
        } else { // if the answer is incorrect
            answerText.classList.remove("correct");
            answerText.classList.add("incorrect");

            button.classList.remove("inactive-incorrect-color");
            button.classList.add("active-incorrect-color");
            button.previousElementSibling.classList.remove("active-correct-color");
            button.previousElementSibling.classList.add("inactive-correct-color");

            if (button.classList.value.indexOf("border") > -1 || button.classList.value.indexOf("checked") > -1) {
                button.classList.add("checked");
            } else {
                button.previousElementSibling.classList.add("checked");
            }

            this.correctionsByAnswerId.set(examAnswerId, {correct: 0});
        }
    }

    saveManualCorrections() {
        this.correctionsByAnswerId.forEach(this.approveAutomaticCorrection.bind(this));
    }

    approveAutomaticCorrection(isCorrect: ICorrection, examAnswerId: number) { // correct: 1, not correct: 0
        this.examHttpApi.approveAutomaticCorrection(examAnswerId, isCorrect);
    }

    private removeStrongTags(answerHTML: string): string {
        return answerHTML.replace("<strong>", "").replace("</strong>", "");
    }

    private isAnswerMarkedToCorrect(classNames: string[]): boolean {
        const classNameToAnalyze = classNames[1];
        // className = glyphicon-ok => answer is marked to correct
        // className = glyphicon-remove => answer is marked to incorrect
        return classNameToAnalyze.indexOf("ok") > 0;
    }


    /**
     * Download xls file
     * @param file
     */

    private getDownloadFile() {
        let fileName = 'speaking_scale.pdf';
        if(this.task.part === 'writing' || this.task.part === 'reading'){
            fileName = 'writing_scale.pdf';
        }

        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}getDownloadReviewPDF/${fileName}&token=${token}`;

        window.open(url);
    }
}
