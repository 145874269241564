<aside></aside>
<div class="container">
    <h3>{{pageTitle}}</h3>
    <form novalidate [formGroup]="createExamForm" (submit)="onSubmit($event, createExamForm)">
        <div class="row">
            <div class="col-md-8">
                <div class="col-md-4">
                    <label>
                        Szakterületek:
                        <select class="form-control" formControlName="studyField">
                            <option *ngFor="let f of studyFields"
                                    [value]="f.id">{{f.name}}</option>
                        </select>
                    </label>
                </div>
                <div class="col-md-4">
                    <label>
                        Témakör:
                        <select class="form-control" formControlName="subtypes"
                                [attr.disabled]="!this.studyFieldControl.value ? true : null">
                            <option *ngFor="let s of subtypes"
                                    [value]="s.id">{{s.name}}</option>
                        </select>
                    </label>
                </div>
                <div class="col-md-4" id="date-time-container">
                    <label>
                        Időpont:
                        <input type="text" class="form-control"
                               id="date" (focus)="onFocusInput($event)"
                               formControlName="date"/>
                    </label>
                    <div class="date-and-time-picker" *ngIf="isDatePickerVisible">
                        <datepicker
                                id="datepicker"
                                (selectionDone)="dateChange($event)"
                                [startingDay]="1">
                        </datepicker>
                        <div id="timepicker">
                            <label>
                                Óra:&nbsp;
                                <input class="form-control"
                                       type="number" min="0" max="23"
                                       [value]="hourValue" (change)="onChangeTimePicker($event, 'hour')"/>
                            </label>
                            <label>
                                Perc:&nbsp;
                                <input class="form-control"
                                       type="number" min="0" max="59"
                                       [value]="minuteValue" (change)="onChangeTimePicker($event, 'minute')"/>
                            </label>
                        </div>
                        <button type="button" class="btn btn-large btn-block btn-primary"
                                (click)="onCloseClickBtn()">Bezárás</button>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="section-list col-md-12" formArrayName="structure">
                <h3>Szakaszok</h3>

                <div *ngFor="let sectionFormGroup of sections; let i = index" [formGroupName]="i">
                    <div class="col-md-12">
                        <div class="col-md-2">
                            <p class="section-number">{{ i + 1 }}. vizsga szakasz</p>
                        </div>
                        <div class="col-md-2">
                            <label>Kiválasztott szakasz:</label>
                            <!--<input type="number"  class="form-control section-input" formControlName="section" />-->
                            <select class="form-control" formControlName="section">
                                <option value="">   </option>
                                <option *ngFor="let task of getTaskOptionsForSection(i)"
                                        [value]="task.sections">{{task.sections}}</option>
                            </select>
                        </div>
                        <div class="col-md-2" *ngIf="subtypesControl.value == 4 && studyFieldControl.value == 4">
                            <label>Kiválasztott szakasz:</label>
                            <select class="form-control" formControlName="section2">
                                <option value="">   </option>
                                <option *ngFor="let task of getTaskOptionsForSection(i)"
                                        [value]="task.sections">{{task.sections}}</option>
                            </select>
                        </div>
                        <div class="col-md-2 group-section" *ngIf="environmentService.get('sections_as_percent') === true">
                            <label>Százalék:</label>
                            <input type="number" class="form-control section-input" formControlName="percent" />
                            <i *ngIf="taskCheckNumbers['structure']">Összesen: {{ taskCheckNumbers['structure'][i] }} db</i>
                        </div>
                        <div class="col-md-2 group-section" *ngIf="environmentService.get('sections_as_percent') === false">
                            <label>Darab:</label>
                            <input type="number" class="form-control section-input" formControlName="count" />
                            <i *ngIf="taskCheckNumbers['structure']">Összesen: {{ taskCheckNumbers['structure'][i] }} db</i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-9">
                <h3>Feladattípus elosztás</h3>
                <div class="col-md-3 group-section">
                    <label>Igaz-hamis(%):</label>
                    <input type="number" class="form-control section-input" formControlName="questionTypeTrueFalse" [attr.disabled]="!this.subtypesControl.value ? true : null"/>
                    <i *ngIf="taskCheckNumbers">Összesen: {{ taskCheckNumbers['trueFalse'] }} db</i>
                </div>
                <div class="col-md-3 group-section">
                    <label>Feleletválasztós(%):</label>
                    <input type="number" class="form-control section-input" formControlName="questionTypeMultipleChoice" [attr.disabled]="!this.subtypesControl.value ? true : null"/>
                    <i *ngIf="taskCheckNumbers">Összesen: {{ taskCheckNumbers['mChoice'] }} db</i>
                </div>
                <div class="col-md-3 group-section">
                    <label>Szöveges válaszadás(%):</label>
                    <input type="number" class="form-control section-input" formControlName="questionTypeWriting" [attr.disabled]="!this.subtypesControl.value ? true : null"/>
                    <i *ngIf="taskCheckNumbers">Összesen: {{ taskCheckNumbers['wiring'] }} db</i>
                </div>
                <div class="col-md-3">
                    <label>Összes kérdés(db): *</label>
                    <input type="number" class="form-control section-input" formControlName="sumQuestion"/>
                    <p class="small">* Az érték módosítása minden jövőbeli vizsgát érinteni fog ebben a témakörben!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="button-group">
                    <a class="btn btn-large btn-block btn-default" href="#" role="button" routerLink="/landing/exams">Vissza</a>
                    <button type="button"
                            id="btn-info-{{ loader }}"
                            class="btn btn-large btn-block btn-info"
                            (click)="onCheckExamTasks()"
                            [disabled]="disableButtons">
                        Ellenőrzés
                        <span id="loading" class="loading" *ngIf="loader">&nbsp;</span>
                    </button>
                    <button type="submit"
                            id="submit-btn-{{ loader }}"
                            class="btn btn-large btn-block btn-success"
                            [disabled]="disableButtons">
                        {{submitButtonText}}
                        <span id="loading2" class="loading" *ngIf="loader">&nbsp;</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="row margin-top" *ngIf="returnMsg">
            <div class="col-md-8">
                <div *ngIf="checkExamTasksMsg['success'] === true" class="alert alert-success" role="alert"><p [innerHTML]="checkExamTasksMsg['msg']"></p></div>
                <div *ngIf="checkExamTasksMsg['success'] === false" class="alert alert-danger" role="alert"><p [innerHTML]="checkExamTasksMsg['msg']"></p></div>
            </div>
        </div>
    </form>
</div>
