
import {catchError, map} from 'rxjs/operators';
import {RestHttp} from "./rest.http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {RestConfig} from "./rest_config.http";
import {ITaskGroup} from "../interfaces/task_groups.interface";

const DEVELOP_VERSION = "0002";
const EXAM_PART = {
    English: {
        listening: "LC",
        reading: "RC",
        speaking: "OP",
        writing: "WR"
    },
    German: {
        listening: "HV",
        reading: "LV",
        speaking: "MA",
        writing: "SA"
    }
};

export class TaskHttpApi extends RestHttp {

    setDefaultUrl() {
        this.defaultUrl = `${environment.apiPrefixUrl}tasks`;
    }

    rescanExamAnswersByTaskId(data: Object) {
        let url = `${environment.apiPrefixUrl}task/rescanTask`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.post(url, data, requestOptions).pipe(
            map((response:any) => {
                return response.status === 200;
            }));
    }

    generateTaskName(level, part, language, num): Observable<any> {
        let requestOptions = Object.assign({}, RestConfig.getReqOpt()); // Copy the object to not mess up other requests
        let partId = EXAM_PART[language][part];

        requestOptions['params'] = {
            "level": level,
            "part": part,
            "language": language,
        };

        requestOptions['observe'] = 'response';

        return this.http.get(this.defaultUrl + '?limit=1', requestOptions).pipe(
            map((response:any) => {
                let jsonData = <Array<any>> response.body.data;
                let endFileNameString;

                if (jsonData.length === 0) {
                    endFileNameString = "a";
                } else {
                    const newRegExp = new RegExp(`${level}-${partId}-${num}`);
                    let fileNames = jsonData.filter((item) => item.filename !== null && item.filename.match(newRegExp) !== null);

                    let reverse = fileNames;

                    if (reverse[0]) {
                        try {
                            let filename = <string> reverse[0].filename;
                            let match = filename.match(/([0-9]+)(\w)$/);
                            let currentNum = match[1];

                            if (currentNum >= num) {
                                if (currentNum === num) {
                                    num = currentNum;
                                }
                                let charCode = match[2].charCodeAt(0) + 1;
                                endFileNameString = String.fromCharCode(charCode);
                            } else {
                                endFileNameString = "a";
                            }
                        } catch (error) {
                            num = 0;
                            endFileNameString = "0";
                            console.log("No more free character");
                        }
                    } else {
                        endFileNameString = "a";
                    }

                }

                level = level === null ? "" : `-${level}`;
                partId = partId === null ? "" : `-${partId}`;
                num = num === null ? "" : `-${num}${endFileNameString}`;

                return `${DEVELOP_VERSION}` + level + partId + num;
            }));
    }

    getTaskWithGroups(level, langauge): Observable<ITaskGroup> {

        let requestOptions = Object.assign({}, RestConfig.getReqOpt()); // Copy the object to not mess up other requests

        requestOptions['params'] = {
            'language': langauge
        };

        if (level !== null) {
            requestOptions['params']['level'] = level;
        }

        return this.http.get(this.defaultUrl, requestOptions).pipe(
            map((response:any) => {
                let data = <Array<any>> response.data;

                let writing = data.filter((item) => item.part === "writing");
                let reading = data.filter((item) => item.part === "reading");
                let listening = data.filter((item) => item.part === "listening");
                let speaking = data.filter((item) => item.part === "speaking");

                return {writing, reading, listening, speaking};
            }));

    }

    getFormatCodes() {
        let requestOptions = RestConfig.getReqOpt();
        let url = `${environment.apiPrefixUrl}task/getFormatCodes`;

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return response.data;
            }));
    }

    getTextSources() {
        let requestOptions = RestConfig.getReqOpt();
        let url = `${environment.apiPrefixUrl}task/getTextSources`;

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return response.data;
            }));
    }

    getGroupBySectionTasks(): Observable<ITaskGroup> {
        let url = `${environment.apiPrefixUrl}mmk/getGroupBySectionTasks`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions)
            .map((response: any) => {
                return response.data;
            });
    }

    getStudyFields(): Observable<ITaskGroup> {
        let url = `${environment.apiPrefixUrl}mmk/getStudyFields`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions)
            .map((response: any) => {
                return response.data;
            });
    }

    getSimplifiedList(): Observable<ITaskGroup> {
        let url = `${environment.apiPrefixUrl}exam-schemes/getSimplifiedList`;
        let requestOptions= RestConfig.getReqOpt();

        return this.http.get(url, requestOptions)
            .map((response: any) => {
                return response.data;
            });
    }

    getFilenameExistCheck(filename, taskId) : Observable<any> {
        let requestOptions = RestConfig.getReqOpt();
        let url = `${environment.apiPrefixUrl}task/getFilenameExistsCheck`;
        requestOptions["params"] = {
            "filename": filename,
            "task_id": taskId
        };
        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return response.data;
            }));

    }
}
