<div>
    <div class="loading-layer" *ngIf="pending">
        <div class="loader-mat-spinner" id="loader-1"></div>
    </div>
    <table class="table table-hover" *ngIf="users != null">
        <thead>
        <tr>
            <th>ID</th>
            <th>Név</th>
            <th>Email</th>
            <th>Szerepkör</th>
            <th *ngIf="environmentService.get('allow_analysis_module') === true">NYAT szám</th>
            <th *ngIf="environmentService.get('allow_analysis_module') === true">Facets group</th>
            <th>Művelet</th>
        </tr>
        </thead>
        <tbody class="exam-table-body">
        <ng-container *ngFor="let user of users; let i = index">
            <tr>
                <td>{{ user.id }}</td>
                <td>{{ user.last_name }} {{ user.first_name }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.role }}</td>
                <td *ngIf="environmentService.get('allow_analysis_module') === true">{{ user.nyat_number }}</td>
                <td *ngIf="environmentService.get('allow_analysis_module') === true">{{ user.facets_group }}</td>
                <td>
                    <span class="btn btn-sm btn-primary" (click)="onUserEditClicked(user)"><i class="fa fa-pencil"></i></span>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>

    <mat-paginator [length]="pagination['length']"
                   [pageSize]="pagination['size']"
                   [pageSizeOptions]="[5, 10, 25, 100]"
                   (page)="paginate($event)">
    </mat-paginator>
</div>