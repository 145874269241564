import {Component} from "@angular/core";
import {Angular2Csv} from "angular2-csv/Angular2-csv";
import {FacetHttpApi} from "../../../http_apis/facet.http";
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";

interface IResults {
    finalized?: boolean;
    minMeasure?: number;
    maxMeasure?: number;
    measureAvg?: number;
    logitErrorAvg?: number;
    scaledAbilitiesAvg?: number;
    scaledHalfOfErrorAvg?: number;
    range?: number;
    uScale?: number;
    uScaleCorrection?: number;
    howManyLogitsToThePoint?: number;
    uMeanCorrection?: number;
    finalCorrection?: number;
    uMean?: number;
    scaledAbilities?: number;
    candidates?: IScaledAbilities[];
}

interface IScaledAbilities {
    candidateId: string;
    point: number;
}

@Component({
    selector: "results",
    templateUrl: "./results.component.html",
    styleUrls: ["./results.component.css"]
})
export class ResultsComponent {

    private facetHttpApi: FacetHttpApi;
    data: any;
    examId: number;
    part: string;
    examResults: IResults = {
        finalized: false,
        minMeasure: 0,
        maxMeasure: 0,
        measureAvg: 0,
        logitErrorAvg: 0,
        scaledAbilitiesAvg: 0,
        scaledHalfOfErrorAvg: 0,
        range: 0,
        uScale: 0,
        uScaleCorrection: null,
        howManyLogitsToThePoint: null,
        uMeanCorrection: null,
        finalCorrection: null,
        uMean: 0,
        scaledAbilities: null,
        candidates: []
    };

    constructor(private activatedRoute: ActivatedRoute,
                private http: HttpClient
    ) {

        this.facetHttpApi = new FacetHttpApi(http,  "data");
        this.activatedRoute.params.subscribe(({id}) => {
            this.examId = id.split("-")[0];
            this.part = id.split("-")[1];
        });

        this.resultCalculate();
    }

    downloadScaledAbilitiesTable() {
        // tslint:disable no-unused-expression
        new Angular2Csv(this.examResults.candidates, "scaled_abilities");
        console.log(this.examResults);
    }

    resultCalculate(){
        this.createData();
        this.facetHttpApi.postAnalysisResult(this.data).subscribe((results) => {
            if(results){
                this.examResults = results;
            }
        });
    }

    finalizedFacet(){
        this.facetHttpApi.postFinalizedFacet(this.examId,this.part).subscribe((results) => {
            if(results){
                this.examResults.finalized = true;
            }
        });
    }

    createData(){
        this.data = {
            'exam_id': this.examId,
            'part': this.part,
            'uScaleCorrection': this.examResults.uScaleCorrection,
            'howManyLogitsToThePoint': this.examResults.howManyLogitsToThePoint,
            'uMeanCorrection': this.examResults.uMeanCorrection,
            'finalCorrection': this.examResults.finalCorrection,
        };
    }
}
