import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";
import {isNullOrUndefined} from "util";
import {environment} from "../../../environments/environment";
import {EnvironmentService} from "../../services/environment.service";

@Component({
    moduleId: module.id,
    selector: "aside",
    template: `<ul class="nav nav-pills nav-stacked">
        <ng-container *ngFor="let menuItem of menuItems">
            <li *ngIf="menuItem.show">
                <a href="javascript:void(0);" routerLink="{{menuItem.route}}" routerLinkActive="active">{{menuItem.title}}</a>
            </li>
        </ng-container>
    </ul>`,
    styles: [
        '.nav .active { background: #cecece; }'
    ],
})

export class MenuComponent implements OnInit {
    userStatus: string;
    examType: string;
    candidateManagement: boolean;
    candidateList: boolean;
    menuItems = [];

    constructor(private router: Router,
                private environmentService: EnvironmentService,
                private authService: AuthService
    ) {


        this.refreshMenu();
        this.authService.getuserDataLoadedSubject().subscribe(this.refreshMenu.bind(this));
    }

    ngOnInit(): void {
        this.refreshMenu();
    }

    refreshMenu() {
        this.userStatus = this.authService.$userStatus;
        this.examType = this.environmentService.get('examType');
        this.candidateManagement = environment.menu.candidateManagement;
        this.candidateList = environment.menu.candidateList;
        this.menuItems = this.getMenuItems();
    }

    getMenuItems() {
        const menuArray = [
            {
                'route': '/exam-task-creator',
                'id': 'newTask',
                'show': (this.userStatus != 'tester' && this.userStatus != 'examiner' && this.userStatus != 'inspector'),
                'title': $localize`:@@newTask:Vizsgafeladat létrehozása`,
            },
            {
                'route': '/landing/tasks',
                'id': 'taskList',
                'show': (this.userStatus != 'tester' && this.userStatus != 'examiner' && this.userStatus != 'inspector'),
                'title': $localize`:@@taskList:Vizsgafeladatok`,
            },
            {
                'route': '/create-exam',
                'id': 'createExam',
                'show': (this.examType === 'itolc' && this.userStatus == 'admin'),
                'title': $localize`:@@createExam:Új vizsga létrehozása`,
            },
            {
                'route': '/landing/exams',
                'id': 'examList',
                'show': (this.userStatus == 'admin'),
                'title': $localize`:@@examList:Vizsgák`,
            },
            {
                'route': '/corrector/exam-answers',
                'id': 'corrector',
                'show': (this.examType === 'itolc' && this.userStatus != 'task_creator' && this.userStatus != 'inspector'),
                'title': $localize`:@@corrector:Vizsgák értékelése`,
            },
            {
                'route': '/checking-identity',
                'id': 'identityCheck',
                'show': (this.userStatus === 'inspector' || this.userStatus === 'admin'),
                'title': $localize`:@@identityCheck:Felügyelés`,
            },
            {
                'route': '/analysis/exam-modules',
                'id': 'analysis',
                'show': (this.examType === 'itolc' && this.userStatus == 'admin'),
                'title': $localize`:@@analysis:Elemzés`,
            },
            {
                'route': '/exam-roster',
                'id': 'examRoster',
                'show': (this.examType === 'itolc' && (this.userStatus == 'admin' || this.userStatus == 'inspector')),
                'title': $localize`:@@examRoster:Vizsga névsor`,
            },
            {
                'route': '/diaries',
                'id': 'diaries',
                'show': (this.examType === 'itolc' && (this.userStatus == 'admin' || this.userStatus == 'inspector')),
                'title': $localize`:@@diaries:Naplóbejegyzések`,
            },
            {
                'route': '/export-page',
                'id': 'exports',
                'show': ((this.examType === 'itolc' || this.examType === 'mmk') && this.userStatus == 'admin'),
                'title': $localize`:@@exports:Statisztikák`,
            },
            {
                'route': '/exam-sync',
                'id': 'examSync',
                'show': (this.examType === 'itolc' && this.userStatus == 'admin'),
                'title': $localize`:@@examSync:Vizsgaletöltés státusz`,
            },
            {
                'route': '/users',
                'id': 'userList',
                'show': (this.userStatus == 'admin'),
                'title': $localize`:@@userList:Felhasználók`,
            },
            {
                'route': '/create-mmk-exam',
                'id': 'createExamMMK',
                'show': (this.examType === 'mmk' && this.userStatus == 'admin'),
                'title': $localize`:@@createExam:Új vizsga létrehozása`,
            },
            {
                'route': '/candidates/new',
                'id': 'candidateManagement',
                'show': (this.candidateManagement && this.userStatus !== 'examiner' && this.userStatus !== 'inspector'),
                'title': $localize`:@@newCandidate:Vizsgázó felvétele`,
            },
            {
                'route': '/candidates/list',
                'id': 'candidateList',
                'show': (this.candidateList && this.userStatus !== 'examiner'  && this.userStatus !== 'inspector'),
                'title': $localize`:@@candidateList:Vizsgázók`,
            },
            {
                'route': '/corrector/exam-answers-mmk',
                'id': 'MMKExamAnswers',
                'show': (true),
                'title': $localize`:@@corrector:Vizsgák értékelése`,
            },
        ];

        // If disabled from env
        for (const key in menuArray) {
            if (menuArray[key].id === '') {
                continue;
            }
            if (this.environmentService.get(menuArray[key].id, 'menu') === false) {
                menuArray[key].show = false;
            }

        }

        return menuArray;
    }
}
