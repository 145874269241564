<div class="exam-form-wrapper">
    <aside></aside>
    <div class="container">
        <div class="row">
            <div class="form-group col-xs-12 col-md-12">
                <h3>Szűrés</h3>
                <div class="form-group col-xs-12 col-md-4">
                    <label for="last_name">Vezetéknév: </label><input class="form-control" type="text" id="last_name" [(ngModel)]="filters.last_name.value" (change)="getFilteredList()">
                </div>
                <div class="form-group col-xs-12 col-md-4">
                    <label for="first_name">Keresztnév: </label><input class="form-control" type="text" id="first_name" [(ngModel)]="filters.first_name.value" (change)="getFilteredList()">
                </div>
                <div class="form-group col-xs-12 col-md-4">
                    <label for="email">Email: </label><input class="form-control" type="text" id="email" [(ngModel)]="filters.email.value" (change)="getFilteredList()">
                </div>
                <div class="form-group col-xs-12 col-md-4">
                    <label for="created_at">Módosítás dátuma: </label><input class="form-control" type="text" id="created_at" [(ngModel)]="filters.updated_at.value" placeholder="2018-11-06" (change)="getFilteredList()">
                </div>
                <div class="form-group col-xs-12 col-md-4">
                    <label for="user_id">UserId: </label><input class="form-control" type="text" id="user_id" [(ngModel)]="filters.user_id.value" (change)="getFilteredList()">
                </div>
                <div class="form-group col-xs-12 col-md-4">
                    <label for="exams">Vizsgák:&nbsp;</label>
                    <select [(ngModel)]="filters.exams.value"
                            class="form-control"
                            id="exams"
                            [disabled]="loader"
                            (change)="getFilteredList()">
                        <option value="0" selected>Összes</option>
                        <option *ngFor="let exam of examList" [value]="exam.id">
                            {{exam.date}} - {{exam.level_name}} ({{exam.id}})
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-md-12">
                <h3>Vizsgázók importálása</h3>
                <form class="form-inline">
                    <div class="form-group col-xs-12 col-md-5 text-left">
                        <button type="button" class="btn btn-warning" (click)="candidateImport()">Import</button>
                        <input type="file"
                               class="form-control"
                               (change)="onChangeFile($event, 'file')"
                               id="upload">
                        <span id="loading2" class="loading" *ngIf="loader2">&nbsp;</span>
                    </div>
                </form>
            </div>
            <div class="col-md-12">
                <h3>Vizsga hozzárendelés</h3>
                <form class="form-inline">
                    <div class="col-md-12">
                        <label for="addExam">Vizsgához rendelés:&nbsp;</label>
                        <select (change)="onChangeExam($event.target.value)"
                                class="form-control"
                                id="addExam"
                                [disabled]="loader">
                            <option value="NULL" selected>Vizsgák</option>
                            <option *ngFor="let exam of examList" [value]="exam.id">
                                {{exam.date}} - {{exam.study_field_name}} / {{exam.level_name}} ({{exam.id}})
                            </option>
                        </select>
                        <select (change)="onChangeExamType($event.target.value)"
                                class="form-control"
                                id="addExamType"
                                [disabled]="loader">
                            <option value="NULL" selected>Típus</option>
                            <option value="offline">Offline</option>
                            <option value="online">Online</option>
                        </select>
                        <button type="button" id="submit-btn-add-exam" class="btn btn-success"
                                (click)="addExamToCandidate()"
                                [disabled]="loader" >Hozzárendel</button>
                        <span id="loading"  class="loading" *ngIf="loader">&nbsp;</span>
                    </div>
                </form>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h3>Vizsgázó lista</h3>
                <div class="col-md-12 text-right">
                    <button type="button" class="btn btn-danger" (click)="createPostExcel()">Export</button>
                </div>
                <table class="table table-hover">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Vezetéknév</th>
                            <th>Keresztnév</th>
                            <th>Email</th>
                            <th>User ID</th>
                            <th>Módosítás dátuma</th>
                            <th colspan="2">
                                <input type="checkbox" (change)="selectAllCheckbox(elem)" #elem> Összes kijelölése
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let candidate of pagedItems" [routerLink]="['/candidates/list']">
                            <td>{{candidate.id}}</td>
                            <td>{{candidate.last_name}}</td>
                            <td>{{candidate.first_name}}</td>
                            <td>{{candidate.email}}</td>
                            <td>{{candidate.user_id}}</td>
                            <td>{{candidate.updated_at}}</td>
                            <td><input type="checkbox" (change)="changeCheckbox(elem)" #elem [id]="candidate.id" value="{{candidate.id}}" class="user-checkbox"></td>
                            <td>
                                <button type="button" class="btn btn-primary" name="open" (click)="openCandidate(candidate.id)">
                                    <i class="glyphicon glyphicon-edit"></i>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <mat-paginator [length]="matPagination['length']"
                      [pageSize]="matPagination['size']"
                      [pageSizeOptions]="[5, 10, 25, 100]"
                      (page)="matPaginate($event)"
                      *ngIf="candidateList">
        </mat-paginator>
    </div>

</div>