import {RestHttp} from "./rest.http";
import {environment} from "../../environments/environment";
import { RestConfig } from "./rest_config.http";

export class CandidateHttpApi extends RestHttp {

    setDefaultUrl() {
        this.defaultUrl = `${environment.apiPrefixUrl}candidates`;
    }

    resetPassword(ID: number) {
        let url = `${environment.apiPrefixUrl}candidates/resetPassword`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            id: ID,
        };

        return this.http.post(url, body, requestOptions)
            .map((response: any) => {
                return response;
            });
    }

    getCandidateList() {
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(this.defaultUrl, requestOptions)
            .map((response: any) => {
                return response.data;
            });
    }

    removeCandidate(id){
        let url = this.defaultUrl + '/' + id;

        return this.http.delete(url, RestConfig.getReqOpt())
            .map(() => id);
    }

    addCandidateToExam(candidateId: number, examId: number, examType: string) {
        let url = `${environment.apiPrefixUrl}mmk/addCandidateToExam`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidateId: candidateId,
            examId: examId,
            examType: examType
        };

        return this.http.post(url, body, requestOptions)
            .map((response: any) => {
                return response;
            });
    }

    addCandidateArrayToExam(candidateIdArray: Array<number>, examId: number, examType: string) {
        let url = `${environment.apiPrefixUrl}mmk/addCandidateArrayToExam`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidateIdArray: candidateIdArray,
            examId: examId,
            examType: examType
        };

        return this.http.post(url, body, requestOptions)
            .map((response: any) => {
                return response;
            });
    }

    candidateImport(file){
        let url = `${environment.apiPrefixUrl}candidates/candidateImport`;
        let requestOptions = RestConfig.getReqOpt();
        //requestOptions.headers.delete("Content-type");
        delete requestOptions.headers['Content-Type'];

        // "enctype", "multipart/form-data"
        // requestOptions.headers.set("Content-type", "multipart/form-data");

        let formData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(url, formData, requestOptions)
            .map((response: any) => {
                return response;
            });
    }

    candidateImportMMK(file){
        let url = `${environment.apiPrefixUrl}mmk/candidateImport`;
        let requestOptions = RestConfig.getReqOpt();
        //requestOptions.headers.delete("Content-type");
        delete requestOptions.headers['Content-Type'];

        // "enctype", "multipart/form-data"
        // requestOptions.headers.set("Content-type", "multipart/form-data");

        let formData = new FormData();
        formData.append('file', file, file.name);

        return this.http.post(url, formData, requestOptions)
            .map((response: any) => {
                return response;
            });
    }

    candidateExport(candidates){
        let url = `${environment.apiPrefixUrl}export/candidateExport`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidates: candidates,
        };

        return this.http.post(url, body, requestOptions).map((response: any) => {
            return response;
        });
    }
}
