<div class="exam-form-wrapper">
    <aside></aside>
    <div class="container">
        <form ngNativeValidate class="row" [formGroup]="examCreatorForm">
            <div *ngIf="taskFormColumns.exam_scheme" class="form-group col-xs-12 col-md-4">
                <label for="examScheme" i18n>Témakör</label>
                <select class="form-control"
                        id="examScheme"
                        formControlName="exam_scheme_id" [required]="true">
                    <option *ngFor="let s of examSchemes"
                            [value]="s.id">{{s.study_fields_name}} / {{s.questions_subtype_name}}</option>
                </select>
            </div>
            <div *ngIf="taskFormColumns.section" class="form-group col-xs-12 col-md-4">
                <label for="section" i18n>Szakasz</label>
                <input type="number" class="form-control"
                       id="section"
                       min="1"
                       formControlName="section"
                       oninput="validity.valid||(value='');"
                       [required]="true">
            </div>
            <div *ngIf="taskFormColumns.filename" class="form-group col-xs-12 col-md-4">
                <label for="file-name" i18n="@@filename">A fájl neve</label>
                <input type="text" class="form-control" [ngClass]="{'filename-input-error':isFilenameExists}"
                       id="file-name" formControlName="filename" (blur)="checkUniqueFilename($event)">
                <div class="filename-error" *ngIf="isFilenameExists"> Már létező fájlnév!</div>
            </div>
            <div *ngIf="taskFormColumns.language" class="form-group col-xs-12 col-md-4">
                <label for="language" i18n>Nyelv</label>
                <select class="form-control"
                        id="language"
                        formControlName="language" [required]="true">
                    <option *ngFor="let lang of langOptions"
                            [value]="lang.value">
                        {{lang.text}}
                    </option>
                </select>
            </div>
            <div *ngIf="taskFormColumns.level" class="form-group col-xs-12 col-md-4" [formGroup]="examGroup">
                <label for="exam-level" i18n>Vizsgaszint</label>
                <select class="form-control"
                        id="exam-level"
                        formControlName="level" [required]="true">
                    <option *ngFor="let level of levelOptions"
                            [value]="level">
                        {{level}}
                    </option>
                </select>
            </div>
            <div *ngIf="taskFormColumns.exam_part" class="form-group col-xs-12 col-md-4" [formGroup]="examGroup">
                <label for="exam-type" i18n>Vizsgarész</label>
                <select class="form-control"
                        id="exam-type"
                        formControlName="part" [required]="true">
                    <option *ngFor="let examType of examTypeOptions"
                            [value]="examType.value">
                        {{examType.text}}
                    </option>
                </select>
            </div>
            <div *ngIf="taskFormColumns.number" class="form-group col-xs-12 col-md-4" [formGroup]="examGroup">
                <label for="task-number" i18n>Feladat sorszáma</label>
                <select class="form-control"
                        id="task-number" formControlName="number" [required]="true">
                    <option *ngFor="let num of taskNumberOptions" [value]="num.value">{{num.text}}</option>
                </select>
            </div>
            <div class="form-group col-xs-12 col-md-4">
                <label for="task-type" i18n>Feladat típusa</label>
                <select class="form-control" id="task-type" [formControlName]="environmentService.get('examType') === 'mmk' ? 'format' : 'type'"
                        [attr.disabled]="!taskFormColumns.exam_scheme && !this.examGroup.controls.number?.value ? true : null">
                    <ng-container *ngIf="taskFormColumns.exam_scheme">
                        <option *ngFor="let taskType of taskTypeOptions"
                                [value]="taskType.value">
                            {{taskType.text}}
                        </option>
                    </ng-container>
                    <ng-container *ngIf="!taskFormColumns.exam_scheme">
                        <option *ngFor="let taskType of taskTypeOptions[examGroup.controls.part.value]"
                                [value]="taskType.value">
                            {{taskType.text}}
                        </option>
                    </ng-container>
                </select>
            </div>
            <div *ngIf="taskFormColumns.name" class="form-group col-xs-12 col-md-4">
                <label for="task-name" i18n>A feladat neve</label>
                <input type="text" class="form-control"
                       id="task-name"
                       formControlName="name" [required]="true">
            </div>
            <div *ngIf="taskFormColumns.source" class="form-group col-xs-12 col-md-4">
                <label for="source-of-text" i18n>A szöveg forrása</label>
                <input type="url" class="form-control"
                       id="source-of-text"
                       formControlName="source" [required]="areFieldsRequired">
            </div>
            <div *ngIf="taskFormColumns.view_date" class="form-group col-xs-12 col-md-4">
                <label for="check-date" i18n>A megtekintés dátuma </label>
                <input id="check-date"
                       type="text"
                       class="form-control"
                       (focus)="onFocusInput($event)"
                       formControlName="view_date" [required]="true"/>
                <div class="date-and-time-picker" *ngIf="showDateTimePicker">
                    <datepicker
                        id="datepicker"
                        (selectionDone)="dateChange($event)"
                        [startingDay]="1">
                    </datepicker>
                    <div id="timepicker">
                        <label>
                            Óra:&nbsp;
                            <input class="form-control"
                                   type="number" min="0" max="23"
                                   [value]="hourValue" (change)="onChangeTimePicker($event, 'hour')"/>
                        </label>
                        <label>
                            Perc:&nbsp;
                            <input class="form-control"
                                   type="number" min="0" max="59"
                                   [value]="minuteValue" (change)="onChangeTimePicker($event, 'minute')"/>
                        </label>
                    </div>
                    <button type="button" class="btn btn-large btn-block btn-primary"
                            (click)="onCloseClickBtn()"><span i18n>Bezárás</span>
                    </button>
                </div>
            </div>
            <div *ngIf="taskFormColumns.source_type" class="form-group col-xs-12 col-md-4">
                <label for="used-text" i18n>Feladatban felhasznált szöveg</label>
                <select class="form-control"
                        id="used-text"
                        formControlName="source_type" [required]="areFieldsRequired">
                    <option *ngFor="let textType of textTypeOptions"
                            [value]="textType.value">
                        {{textType.text}}
                    </option>
                </select>
            </div>
            <div *ngIf="taskFormColumns.word_count" class="form-group col-xs-12 col-md-4">
                <label for="number-of-words" i18n>Szavak száma / perc</label>
                <input type="number" class="form-control"
                       id="number-of-words"
                       formControlName="word_count" [required]="areFieldsRequired">
            </div>
            <div *ngIf="taskFormColumns.item_count" class="form-group col-xs-12 col-md-4">
                <label for="item-number" i18n>Itemszám</label>
                <input type="number" class="form-control"
                       id="item-number"
                       max="10"
                       min="1"
                       formControlName="item_count" [required]="true">
            </div>
            <div *ngIf="taskFormColumns.topic" class="form-group col-xs-12 col-md-4">
                <label for="topic" i18n>Témakör</label>
                <input type="text" class="form-control" id="topic" formControlName="topic">
            </div>
            <div *ngIf="taskFormColumns.text_breed" class="form-group col-xs-12 col-md-4">
                <label for="text-breed" i18n>Szövegfajta</label>
                <select class="form-control"
                        id="text-breed"
                        formControlName="text_breed" [required]="areFieldsRequired">
                    <option *ngFor="let textBreedType of textBreedTypeOptions"
                            [value]="textBreedType.value">
                        {{textBreedType.text}}
                    </option>
                </select>
            </div>
            <div class="form-group col-xs-12 col-md-4" *ngIf="taskFormColumns.format_code_id && (examGroup.controls.part.value === 'listening' || examGroup.controls.part.value === 'reading')">
                <label for="text-breed" i18n>Format Code</label>
                <select class="form-control"
                        id="format_code_id"
                        formControlName="format_code_id" [required]="areFieldsRequired">
                    <option *ngFor="let item of textFormatCodeOptions"
                            [value]="item.id">
                        {{item.id}}. {{item.name}}
                    </option>
                </select>
            </div>
            <div class="form-group col-xs-12 col-md-4" *ngIf="taskFormColumns.text_source_id && examGroup.controls.part.value === 'listening'">
                <label for="text-breed" i18n>Text Source</label>
                <select class="form-control"
                        id="text_source_id"
                        formControlName="text_source_id" [required]="areFieldsRequired">
                    <option *ngFor="let item of textTextSourceOptions"
                            [value]="item.id">
                        {{item.name}}
                    </option>
                </select>
            </div>
            <div *ngIf="taskFormColumns.comment" class="form-group col-xs-12 col-md-12">
                <label for="text-breed" i18n>Megjegyzés</label><br/>
                <textarea formControlName="comment" rows="5" cols="104"></textarea>
            </div>
            <button type="submit" class="hidden" #fakeSubmit i18n>Mentés</button>
        </form>
        <router-outlet></router-outlet>
        <div>
            <a class="col-sx-12" routerLink="/landing" i18n>Vissza</a>
        </div>
    </div>
</div>
