import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from 'app/services/api.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'video-converter',
  templateUrl: './video-converter.component.html',
  styleUrls: ['./video-converter.component.css']
})

export class VideoConverterComponent implements OnInit {
  @ViewChild('convertDialog') convertDialog: TemplateRef<any>;

  @Input() filename: string;
  @Output() newVideoEvent = new EventEmitter<string>();
  
  phase: string = 'unprocessed';

  constructor(
    private dialog: MatDialog,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  showConvertPopup() {
    const dialogRef = this.dialog.open(this.convertDialog);

    dialogRef.afterClosed().subscribe(result => {
      
      if (result.resolution && this.filename) {
        this.phase = 'processing';
        
        this.apiService.convertVideo(this.filename.split('/').pop(), result.resolution).subscribe((result: any) => {
            console.log(result);
            this.phase = 'processed';
            this.newVideoEvent.emit(result.file);
            this.toastr.success('Az új fájl beállításra került a feladathoz: ' + result.file, 'Konvertálás befejezve', {onActivateTick: true});
        }, (error) => {
          this.phase = 'unprocessed';
        });
      }
    });
  }
}
