import {Component} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {FileUploadService} from "../../services/shared/upload.service";
import {ActivatedRoute} from "@angular/router";
import {environment} from "../../../environments/environment";
import {isNullOrUndefined} from "util";
import {ApiService} from "../../services/api.service";
import {IKeyManagerInput} from "../../interfaces/key_manager.interface";
import {ToastrService} from "ngx-toastr";

@Component({
    moduleId: module.id,
    selector: "app-completion",
    templateUrl: "./completion.component.html",
    styleUrls: ["./completion.component.css"]
})
export class CompletionComponent {
    completion: FormGroup;
    mainForm: FormGroup;
    sourceControl: FormControl = new FormControl(null);
    taskTextControl: FormControl = new FormControl(null, Validators.required);
    taskWords: FormControl = new FormControl(null);
    taskId: number;
    taskWordsJSON: FormControl = new FormControl(null);
    hasUploadField: boolean;
    taskCheckbox: FormControl = new FormControl();
    taskData: any;
    isInputFieldRequired: boolean;
    isFormValid: boolean = false;
    sourceRequired: boolean = true;
    prefixUrl: string;
    token: string;
    private itemIds: Array<any> = [];
    isDuplicate: boolean = false;
    keyManagerInputData: IKeyManagerInput = {};
    keyManagerIsLoading: boolean = false;

    constructor(private formBuilder: FormBuilder,
                public examCreatorFormService: ExamCreatorFormService,
                private fileUploadService: FileUploadService,
                private activatedRoute: ActivatedRoute,
                private apiService: ApiService,
                private toast: ToastrService) {

        this.mainForm = this.examCreatorFormService.$examCreatorFrom;
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.completion = formBuilder.group({
            source_control: this.sourceControl,
            task_text_control: this.taskTextControl,
            task_text_words: this.taskWords,
            task_text_array: this.taskWordsJSON,
            task_checkbox: this.taskCheckbox
        });

        this.fileUploadService.uploadedFile.subscribe(({url}) => {
            this.completion.registerControl("file_url", new FormControl());
            this.completion.controls["file_url"].setValue(url);
            this.taskData.file_url = url;
        });

        this.activatedRoute.data.subscribe(({type}) => {
            console.info("Type: ", type);
            this.hasUploadField = type !== "reading";
            this.sourceRequired = type !== "reading";
        });

        this.activatedRoute.params.subscribe(({id , duplicate}) => {
            console.log('duplicate, ', duplicate);

            if(!isNullOrUndefined(duplicate)){
                if(duplicate === 'duplicate'){
                    this.isDuplicate = true;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                } else {
                    this.isDuplicate = false;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                }
            }

            this.taskId = id;
            let taskValues = this.examCreatorFormService.getTaskDataValue();

            if (taskValues !== null) {
                this.completion.patchValue(taskValues);
            }

            if (!isNullOrUndefined(this.taskId)) {
                this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {
                    try {
                        examTask.items.forEach((item) => {
                            if (item.id && item.item_data) {
                                this.itemIds.push(item.id);
                            }
                        });

                        if (this.taskId && this.itemIds.length) {
                            this.getCompletionAnswersForIds();
                        }
                    } catch (error) {
                        console.log("examTask.items are undefined");
                    }


                    try {
                        examTask.view_date = examTask.view_date.replace(/:[0-9]+$/, "");
                    } catch (error) {
                        console.log("examTask.view_data is undefined");
                    }

                    this.taskData = JSON.parse(examTask.task_data);

                    examCreatorFormService.previousNumber = examTask.number;

                    this.completion.patchValue(this.taskData);

                    this.examCreatorFormService.setExamObject(examTask);
                    this.examCreatorFormService.setExistInstructionControl(examTask.instruction);

                    this.mainForm.patchValue(examTask);
                });
            }

            if (!id) {
                this.examCreatorFormService.backToMainForm();
            }
        });


        this.mainForm.patchValue({item_count: 1}, {emitEvent: false});

        this.examCreatorFormService.requiredRegistryField.subscribe((data) => {
            if (isNullOrUndefined(data)) {
                this.isInputFieldRequired = true;
            }

            this.isInputFieldRequired = data;
        });

        this.mainForm.valueChanges.subscribe(() => {
            this.isInputFieldRequired = this.mainForm.controls.instruction.value !== "" ||
                !isNullOrUndefined(this.mainForm.controls.instruction.value);
            this.examCreatorFormService.registryFormSend.emit(this.mainForm);
        });

        this.completion.valueChanges.subscribe(() => this.onValueChanged());
    }

    removeTaskFile() {
        this.taskData["file_url"] = null;
        this.completion.patchValue(this.taskData);
    }

    watchTyping(event: Event) {
        let element = <HTMLInputElement> event.target;
        this.taskWordsJSON.setValue(JSON.parse(JSON.stringify(element.value.split("\n"))));
    }

    onChecked(event: Event, type: any) {
        let element = <HTMLInputElement> event.target;

        if (type === "taskCheckbox") {
            this.taskCheckbox.setValue(element.checked);
        }
    }

    onRequiredForm() {
        setTimeout(() => {
            if (!isNullOrUndefined(this.completion.value.task_text_control)) {
                this.isFormValid = !isNullOrUndefined(this.completion.value.task_text_control.match(/({(.*?)})/g));
            }
        }, 1000);
    }

    onSubmit(event: Event) {
        event.preventDefault();

        if (this.completion.valid) {
            this.examCreatorFormService.setTaskDataControlValue(JSON.stringify(this.completion.value));

            let items: Array<FormGroup> = [];

            if (this.isFormValid) {

                // Disable save button.
                this.isFormValid = false;

                let words = this.completion.value.task_text_control.match(/({(.*?)})/g);
                words.forEach((word, index) => {
                    word = word.replace(/[{}]/g, "");
                    let itemData = {text: word};
                    items.push(new FormGroup({
                        item_data: new FormControl(JSON.stringify(itemData)),
                        order: new FormControl(index)
                    }));
                    if (this.itemIds[index]) {
                        items[index].controls.id = new FormControl(this.itemIds[index]);
                    }
                });
            }

            const _formArray = new FormArray(items);
            this.mainForm.setControl("items", _formArray);

            if (this.mainForm.valid) {

                if (this.taskId) {
                    if(this.isDuplicate){
                        this.insertTask(_formArray, true);
                    } else {
                        this.updateTask(_formArray);
                    }
                } else {
                    this.insertTask(_formArray, false);
                }
            } else {
                this.examCreatorFormService.open.emit(true);
            }

        }
        if (!this.mainForm.valid) {
            this.examCreatorFormService.open.emit(true);
        }
    }

    async insertTask(_formArray, isDuplicate) {
        this.taskId = await this.examCreatorFormService.insertTask(this.mainForm.value, _formArray, isDuplicate);
        this.submitted(true);
        this.examCreatorFormService.createdTask.emit(this.taskId);
    }

  /**
   * Gets a string of arrays
   */
  getCompletionAnswersForIds() : void {
    this.apiService.getCompletionAnswersForIds(this.itemIds).subscribe((response: any) => {
      console.log(response.result);

      this.keyManagerInputData = response.result;
    });
  }


  async updateTask(_formArray){
        await this.examCreatorFormService.updateTask(this.mainForm.value, _formArray);
        this.submitted(false);
    }

    private submitted(isNew) {
        const submitPrefixText = isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`;
        const dateText = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
        });

        if (!isNew) {
            this.examCreatorFormService.taskHttpApi.rescanExamAnswersByTaskId({task_id: this.taskId}).subscribe(() => {
                console.log("Rescan completed");
            });
        }

        this.examCreatorFormService.submittedResult = `${submitPrefixText} ${dateText}-kor`;

        // Enable save button.
        this.isFormValid = true;
    }

    onValueChanged() {
        this.onRequiredForm();
        if (!this.completion) {
            return;
        }

        const form = this.completion;

        for (const field in this.formErrors) {
            // clear previous error message (if any)
            this.formErrors[field] = "";
            const control = form.controls[field];

            if (control && control.valid === false) {
                this.formErrors[field] += $localize`:@@please-fill-in:Kérjük, töltse ki a mezőt!`;
            } else if (this.isFormValid === false && control.valid === true) {
                this.formErrors.task_text_control += $localize` Legalább egy itemnek szerepelnie kell a szövegben.`;
            }
        }

        this.countQuestions();
    }

    formErrors = {
        source_control: "",
        task_text_control: ""
    };

    onPreview() {
        let items: Array<FormGroup> = [];

        if (this.isFormValid) {
            let words = this.completion.value.task_text_control.match(/({(.*?)})/g);
            words.forEach((word, index) => {
                word = word.replace(/[{}]/g, "");
                let itemData = {text: word};
                items.push(new FormGroup({
                    item_data: new FormControl(JSON.stringify(itemData)),
                    order: new FormControl(index),
                    id: new FormControl(index),
                    exam_answer: new FormControl(null)
                }));
                if (this.itemIds[index]) {
                    items[index].controls.id = new FormControl(this.itemIds[index]);
                }
            });
        }

        const _formArray = new FormArray(items);
        this.examCreatorFormService.onPreviewTask(_formArray.value);
    }

    countQuestions(){
        let sum = 0;

        if(!isNullOrUndefined(this.completion.value.task_text_control)) {
            let words = this.completion.value.task_text_control.match(/({(.*?)})/g);

            if(!isNullOrUndefined(words)){
                sum += words.length;
            }
        }

        if(!isNullOrUndefined(this.completion.value.task_text_words)) {
            let distractorWords = this.completion.value.task_text_words.split("\n");

            distractorWords.forEach((word) => {
                if(word !== ''){
                    sum += 1;
                }
            });
        }

        this.mainForm.patchValue({item_count: sum}, {emitEvent: false});
    }

    /**
     * The video data has changed (possibly by converter), store it in the form
     * @param filename
     */
    updateVideoData(filename: string) {
        this.taskData['file_url'] = `files/videos/${filename}`;
        this.completion.patchValue(this.taskData);
    }

    /**
     *
     * @param data
     */
    updateCompletionItems(data) {
        this.keyManagerIsLoading = true;

        this.apiService.updateCompletionItemsAndTask(data).subscribe((response: any) => {
            this.completion.patchValue({
                task_text_control: response.result
            });

            this.toast.success("Sikeres kulcsmentés!");

            this.keyManagerIsLoading = false;
        }, (_) => {
            this.toast.error("Sikertelen kulcsmentés!");

            this.keyManagerIsLoading = false;
        });
    }
}
