<h3 i18n>Szövegírás</h3>
<form novalidate [formGroup]="copyWriterForm" (submit)="onSubmit($event)">
  <app-registry-sheet [form-group]="mainForm"></app-registry-sheet>
  <div class="row">
    <div class="col-xs-12 hidden">
        <div class="form-group">
          <label i18n>Témakör:</label>
          <input type="text"
                  class="form-control"
                  id="description"
                  type="hidden"
                  formControlName="description">
        </div>
    </div>
    <div class="col-xs-12"><label i18n>Feladat/Szöveg:</label></div>
    <div class="col-xs-12">
        <div class="form-group">
          <app-tinymce class="app-tinymce" [formControl]="taskControl" [required]="true"></app-tinymce>
          <div *ngIf="formErrors.task_content" class="alert alert-danger">
            {{ formErrors.task_content }}
          </div>
        </div>
    </div>
    <div class="col-xs-12">
        <div class="form-group">
          <label for="upload" i18n>Válassz ki a feladathoz kapcsolt képet (opcionális):</label>
          <input type="file"
                  class="form-control"
                  (change)="fileUploadService.onChangeInput($event, 'file')"
                  id="upload">
          <div *ngIf="taskData && taskData.file_url != '' && taskData.file_url != null">
            <strong>{{taskData.file_url | filenameConverter}}</strong>
            <button class="btn btn-danger" (click)="removeTaskFile()" i18n>Fájl törlése</button>
            <a class="btn btn-danger" target="_blank" href="{{prefixUrl}}{{taskData.file_url}}?token={{token}}" i18n>Előnézet</a>
          </div>
        </div>
    </div>
  </div>
    <ng-container [ngSwitch]="isDuplicate">
        <ng-container *ngSwitchCase="false">
            <button type="submit" class="btn btn-primary" [disabled]="!isFormValid || !requieredRegSheetForm" i18n>Mentés</button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <button type="submit" class="btn btn-warning" [disabled]="!isFormValid || !requieredRegSheetForm" i18n>Duplikálás mentése</button>
        </ng-container>
    </ng-container>

    <button type="button" class="btn btn-success" (click)="onPreview()" i18n>Előnézet</button>
    <div class="alert alert-success" *ngIf="this.examCreatorFormService.submittedResult != null && this.taskId != null">{{this.examCreatorFormService.submittedResult}}</div>
</form>
