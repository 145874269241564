import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import {AuthService} from "./auth.service";
import {Router} from "@angular/router";
import {isNullOrUndefined} from "util";

@Injectable()
export class ExaminerAuthGuardService implements CanActivate {

    userStatus: string;

    constructor(private router: Router,
                private authService: AuthService) {

        this.userStatus = this.authService.$userStatus;

        if(isNullOrUndefined(this.userStatus)) {
            this.userStatus = window.localStorage.getItem("itolc_status");
            this.authService.$userStatus = this.userStatus;
        }
    }

    canActivate() {
        const status = this.userStatus === 'admin' || this.userStatus === 'examiner';
        if(!status){
            console.log('ExaminerAuthGuardService: Invalid user status, redirecting to home.');
            this.router.navigateByUrl("/landing");
        }

        return status;
    }
}
