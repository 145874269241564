import {
    Component,
    EventEmitter,
    Input,
    OnInit, Output,
} from "@angular/core";
import {ApiService} from "../../../services/api.service";
import {FormBuilder, FormControl, ValidationErrors, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {EnvironmentService} from "../../../services/environment.service";


@Component({
    moduleId: module.id,
    selector: "edit-user",
    host: {
        id: "edit-user"
    },
    templateUrl: "edit-user.component.html",
    styleUrls: ["edit-user.component.css"]
})

export class EditUserComponent implements OnInit {
    pending = false;
    actionButtonText: String;

    validationErrorMessages = {
        last_name: {
            required: 'A vezetéknév megadása kötelező!',
            maxlength: 'A vezetéknév maximum 100 karaktert tartalmazhat'
        },

        first_name: {
            required: 'A keresztnév megadása kötelező!',
            maxlength: 'A keresztnév maximum 100 karaktert tartalmazhat'
        },

        email: {
            required: 'Az email cím megadása kötelező!',
            maxlength: 'Az email cím maximum 100 karaktert tartalmazhat'
        },

        password: {
            required: 'A jelszó megadása kötelező!',
            maxlength: 'A jelszó maximum 100 karaktert tartalmazhat'
        },

        nyat_number: {
            required: 'A NYAT azonosító megadása kötelező!',
            maxlength: 'A NYAT azonosító maximum 100 karaktert tartalmazhat'
        },

        facets_group: {
            required: 'A Facets azonosító megadása kötelező!',
            max: 'A Facets azonosító maximum 128 lehet'
        },
    };

    @Input()
    user;

    userForm;

    @Output()
    saveEvent = new EventEmitter<any>();

    defaultRole = 'examiner';

    constructor(
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private toastr: ToastrService,
        public environmentService: EnvironmentService,
    ) {
    }

    ngOnInit(): void {
        if (this.environmentService.get('allow_create_inspector') && !this.environmentService.get('allow_create_examiner')) {
            this.defaultRole = 'inspector';
        }

        this.isUserEmpty() ?
            this.actionButtonText = 'Új felhasználó létrehozása' :
            this.actionButtonText = 'Felhasználó szerkesztése';
        this.handleUserForm();
    }

    saveUser(userData) {
        this.pending = true;
        if(this.validateForm() === false){
            this.pending = false;
            return false;
        }

        if (this.isUserEmpty()) {
            this.apiService.newUser(userData).subscribe((data) => {
                    this.toastr.success('A felhasználó mentésre került.', 'Felhasználó létrehozva', {onActivateTick: true});
                    this.userForm.reset();
                    this.user = null;
                    this.userForm.patchValue({'role': this.defaultRole});
                    this.saveEvent.emit();
                },
                (error) => {
                    this.toastr.error(error.message, 'Felhasználó létrehozás sikertelen', {onActivateTick: true});
                    // Finally
                }).add(() => {
                this.pending = false;
            });
        } else {
            this.isUserEmpty() === false ? userData.id = this.user.id : null;
            this.apiService.updateUser(userData).subscribe((data) => {
                    this.toastr.success(this.user.first_name + ' ' + this.user.last_name + ' adatainak szerkesztése sikeres!', 'Adatok módosítva', {onActivateTick: true});
                    this.userForm.reset();
                    this.user = null;
                    this.userForm.patchValue({'role': this.defaultRole});
                    this.saveEvent.emit();
                },
                (error) => {
                    this.toastr.error(error.message, 'Felhasználó létrehozás sikertelen', {onActivateTick: true});
                    // Finally
                }).add(() => {
                this.actionButtonText = 'Új felhasználó létrehozása';
                this.pending = false;
            });
        }
    }

    validateForm() {
        let isValid = true;
        Object.keys(this.userForm.controls).forEach(key => {
            const controlErrors: ValidationErrors = this.userForm.get(key).errors;
            if (controlErrors != null) {
                isValid = false;
                Object.keys(controlErrors).forEach(keyError => {
                    this.toastr.warning(this.validationErrorMessages[key][keyError], 'Hibás adatok', {onActivateTick: true});
                });
            }
        });

        return isValid;
    }

    handleUserForm() {
        if (typeof this.user === 'undefined' || this.user === null) {
            this.userForm = this.formBuilder.group({
                last_name: ['', [Validators.required, Validators.maxLength(100)]],
                first_name: ['', [Validators.required, Validators.maxLength(100)]],
                email: ['', [Validators.required, Validators.maxLength(100)]],
                password: ['', [Validators.required, Validators.maxLength(100)]],
                nyat_number: this.environmentService.get('allow_analysis_module') ? ['', [Validators.required, Validators.maxLength(100)]] : null,
                facets_group: this.environmentService.get('allow_analysis_module') ? ['', Validators.max(128)] : null,
                role: this.defaultRole,
            });
        } else {
            this.userForm = this.formBuilder.group({
                last_name: [this.user.last_name, [Validators.required, Validators.maxLength(100)]],
                first_name: [this.user.first_name, [Validators.required, Validators.maxLength(100)]],
                email: [this.user.email, [Validators.required, Validators.maxLength(100)]],
                password: ['', [Validators.maxLength(100)]],
                nyat_number: this.environmentService.get('allow_analysis_module') ? [this.user.nyat_number, [Validators.required, Validators.maxLength(100)]] : null,
                facets_group: this.environmentService.get('allow_analysis_module') ? [this.user.facets_group, Validators.max(128)] : null,
                role: this.defaultRole,
            });
        }
    }

    isUserEmpty(): boolean {
        return typeof this.user === 'undefined' || this.user === null;
    }
}