//tslint:disable max-file-line-count

// Formanyomtatvány
export const FORM_CREATOR_TASK = {
    taskUrl: "form-creator-task",
    taskName: $localize`Formanyomtatvány`
};

// Szövegírás
export const COPY_WRITER_TASK = {
    taskUrl: "copy-writer-task",
    taskName: $localize`Szövegírás`
};

// Többszörös párosítás, olvasás
export const PAIR_READING_TASK = {
    taskUrl: "pair-reading-task",
    taskName: $localize`Többszörös párosítás`
};

// Feleletválasztás / Alternatív választás, olvasás
export const MULTIPLE_CHOICE_READING_TASK = {
    taskUrl: "multiple-choice-reading-task",
    taskName: $localize`Feleletválasztás / Alternatív választás`
};

// Rövid válasz, olvasás
export const SHORT_ANSWER_READING_TASK = {
    taskUrl: "short-answer-reading-task",
    taskName: $localize`Rövid válasz`
};

// Szöveg kiegészítés, olvasás
export const COMPLETION_READING_TASK = {
    taskUrl: "completion-reading-task",
    taskName: $localize`Szöveg kiegészítés`
};

// Feleletválasztás / Alternatív választás, audio
const MULTIPLE_CHOICE_LISTENING_TASK = {
    taskUrl: "multiple-choice-listening-task",
    taskName: $localize`Feleletválasztás / Alternatív választás`
};

// Csoportosított feleletválasztás / Alternatív választás, audio
const MULTIPLE_CHOICE_GROUPS_LISTENING_TASK = {
    taskUrl: "multiple-choice-groups-listening-task",
    taskName: $localize`Csoportosított feleletválasztás / Alternatív választás`
};

// Rövid válasz, audio
const SHORT_ANSWER_LISTENING_TASK = {
    taskUrl: "short-answer-listening-task",
    taskName: $localize`Rövid válasz`
};

// Többszörös párosítás, audio
const PAIR_LISTENING_TASK = {
    taskUrl: "pair-listening-task",
    taskName: $localize`Többszörös párosítás`
};

// Szöveg kiegészítés, audio
const COMPLETION_LISTENING_TASK = {
    taskUrl: "completion-listening-task",
    taskName: $localize`Szöveg kiegészítés`
};

export const SPEAKING_SKILLS_TASK = {
    taskUrl: "speaking-skills-task",
    taskName: $localize`Beszédkészség`
};

export const TASK_TYPES = {
    writing: [
        {
            value: "form",
            text: FORM_CREATOR_TASK.taskName,
            url: FORM_CREATOR_TASK.taskUrl
        },
        {
            value: "writing",
            text: COPY_WRITER_TASK.taskName,
            url: COPY_WRITER_TASK.taskUrl
        }
    ],
    reading: [
        {
            value: "select",
            text: MULTIPLE_CHOICE_READING_TASK.taskName,
            url: MULTIPLE_CHOICE_READING_TASK.taskUrl
        },
        {
            value: "pair",
            text: PAIR_READING_TASK.taskName,
            url: PAIR_READING_TASK.taskUrl
        },
        {
            value: "short_answer",
            text: SHORT_ANSWER_READING_TASK.taskName,
            url: SHORT_ANSWER_READING_TASK.taskUrl
        },
        {
            value: "extend",
            text: COMPLETION_READING_TASK.taskName,
            url: COMPLETION_READING_TASK.taskUrl
        }
    ],
    listening: [
        {
            value: "select",
            text: MULTIPLE_CHOICE_LISTENING_TASK.taskName,
            url: MULTIPLE_CHOICE_LISTENING_TASK.taskUrl
        },
        {
            value: "select_group",
            text: MULTIPLE_CHOICE_GROUPS_LISTENING_TASK.taskName,
            url: MULTIPLE_CHOICE_GROUPS_LISTENING_TASK.taskUrl
        },
        {
            value: "pair",
            text: PAIR_LISTENING_TASK.taskName,
            url: PAIR_LISTENING_TASK.taskUrl
        },
        {
            value: "short_answer",
            text: SHORT_ANSWER_LISTENING_TASK.taskName,
            url: SHORT_ANSWER_LISTENING_TASK.taskUrl
        },
        {
            value: "extend",
            text: COMPLETION_LISTENING_TASK.taskName,
            url: COMPLETION_LISTENING_TASK.taskUrl
        }
    ],
    speaking: [
        {
            value: "speaking",
            text: SPEAKING_SKILLS_TASK.taskName,
            url: SPEAKING_SKILLS_TASK.taskUrl
        }
    ]
};


export const TASK_CONFIGS = {
    // Writing
    writing_C1: [COPY_WRITER_TASK, COPY_WRITER_TASK],
    writing_A2: [COPY_WRITER_TASK, FORM_CREATOR_TASK],
    writing_B1: [COPY_WRITER_TASK, FORM_CREATOR_TASK],
    writing_B2: [COPY_WRITER_TASK, COPY_WRITER_TASK],

    // Reading
    reading_C1: [PAIR_READING_TASK,
                PAIR_READING_TASK,
                COMPLETION_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK],

    reading_B2: [PAIR_READING_TASK,
                SHORT_ANSWER_READING_TASK,
                PAIR_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK],

    reading_A2: [COMPLETION_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK,
                PAIR_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK],

    reading_B1: [COMPLETION_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK,
                PAIR_READING_TASK,
                MULTIPLE_CHOICE_READING_TASK,
                PAIR_READING_TASK],
    // Listening
    listening_C1: [MULTIPLE_CHOICE_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK,
        SHORT_ANSWER_LISTENING_TASK,
        SHORT_ANSWER_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK],

    listening_A2: [SHORT_ANSWER_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK],

    listening_B1: [MULTIPLE_CHOICE_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK,
        SHORT_ANSWER_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK],

    listening_B2: [MULTIPLE_CHOICE_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK,
        SHORT_ANSWER_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK,
        MULTIPLE_CHOICE_LISTENING_TASK],

    // Speaking
    speaking_C1: [SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK],

    speaking_A2: [SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK],

    speaking_B1: [SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK],

    speaking_B2: [SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK,
                SPEAKING_SKILLS_TASK]
};

// Igaz-Hamis ---
export const TRUE_FALSE_READING_TASK = {
    taskUrl: "multiple-choice-reading-task",
    taskName: $localize`Igaz-Hamis`
};

export const MMK_TASK_TYPES = [
    {
        value: "writing",
        text: COPY_WRITER_TASK.taskName,
        url: COPY_WRITER_TASK.taskUrl,
        main: 'writing',
        subType: 'writing'
    },
    {
        value: "multipleChoice",
        text: MULTIPLE_CHOICE_READING_TASK.taskName,
        url: MULTIPLE_CHOICE_READING_TASK.taskUrl,
        main: 'reading',
        subType: 'select'
    },
    {
        value: "trueFalse",
        text: TRUE_FALSE_READING_TASK.taskName,
        url: TRUE_FALSE_READING_TASK.taskUrl,
        main: 'reading',
        subType: 'select'
    },
    {
        value: "extend",
        text: COMPLETION_READING_TASK.taskName,
        url: COMPLETION_READING_TASK.taskUrl,
        main: 'reading',
        subType: 'extend'
    }
];