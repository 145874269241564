import {Component, Inject, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {AuthService} from "../../services/auth.service";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ApiService} from "../../services/api.service";
import {EnvironmentService} from "../../services/environment.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {map} from "rxjs/operators";
import {MoveExamAnswerService} from "./shared/move-exam-answer.service";
import {Observable} from "rxjs";

@Component({
    selector: "move-exam-answers",
    templateUrl: "./move-exam-answers.component.html",
    styleUrls: ["./move-exam-answers.component.css"]
})

export class MoveExamAnswersComponent implements OnInit {
    @ViewChild("confirm") confirm: TemplateRef<any>;
    private examHttpApi: ExamHttpApi;

    enableMoveExam: boolean;
    loadingMoveExam: boolean;
    stepSelectedUsers: {
        key: string,
        selected: {
            source: number | null,
            destination: number | null,
        },
        options: {
            source: Array<any>,
            destination: Array<any>,
        }
    };
    stepCrash: {
        key: string,
        title: string,
        description: string,
        subtitle: string,
        checkboxIDs: Array<any>,
        checkboxRecordFileIDs: Array<any>,
    };

    stepActive: string;

    constructor(private http: HttpClient,
                private moveExamAnswerService: MoveExamAnswerService,
                public environmentService: EnvironmentService,
                public dialogRef: MatDialogRef<MoveExamAnswersComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {

        this.examHttpApi = new ExamHttpApi(http, "data");
        this.examHttpApi.setDefaultUrl();
    }

    // Init
    ngOnInit() {
        this.initStepData();
        this.selectedUsers(this.data.examId);
    }

    /**
     * Init Step Data
     * Enter default data
     *
     */
    initStepData() {
        //Step Selected Users
        this.stepSelectedUsers = {
            key: "selectedUsers",
            selected: {
                source: null,
                destination: null,
            },
            options: {
                source: [],
                destination: [],
            }
        };

        //Step Crash
        this.stepCrash = {
            key: "crash",
            title: "Ütközés",
            description: "",
            subtitle: "",
            checkboxIDs: [],
            checkboxRecordFileIDs: [],
        };
    }

    /**
     * Set active step
     *
     * @param key
     */
    setActiveStep(key: string) {
        this.stepActive = key;
    }

    /**
     * Close
     *
     * Close dialog
     */
    close() {
        this.dialogRef.close();
    }

    /**
     * Selected Users
     * Load current answers
     *
     * @param examId
     */
    selectedUsers(examId: number) {
        this.setActiveStep("selectedUsers");

        //Before load users data
        this.enableMoveExam = false;
        this.loadingMoveExam = true;

        // Load users
        this.examHttpApi.getExamData(examId).subscribe((results: Array<any>) => {
            const candidatesList = results["candidates_list"].filter(item => {
                const partCandidates = [];
                results[this.data.part].forEach(partItem => {
                    partCandidates.push(...partItem.candidates.map(candidateItem => candidateItem.user_id));
                });

                if (partCandidates.indexOf(item.user_id) !== -1) {
                    return item;
                }
            });

            this.stepSelectedUsers.options.source = candidatesList;
            this.stepSelectedUsers.options.destination = candidatesList;

            this.enableMoveExam = (this.stepSelectedUsers.options.source.length > 0 && this.stepSelectedUsers.options.destination.length > 0);
            this.loadingMoveExam = false;
        });
    }

    /**
     * Checkbox All Select
     *
     * Select all fields in the crash form
     *
     * @param state
     */
    checkboxAllSelect(state: boolean) {
        this.stepCrash.checkboxIDs = this.stepCrash.checkboxIDs.map(item => {
            item.isChecked = state;
            return item;
        });

        this.stepCrash.checkboxRecordFileIDs = this.stepCrash.checkboxRecordFileIDs.map(item => {
            item.isChecked = state;
            return item;
        });
    }

    saveWithCrashes(): boolean {
        const examId = this.data.examId;
        const selectedSource = this.stepSelectedUsers.selected.source;
        const selectedDestination = this.stepSelectedUsers.selected.destination;

        // If you are incomplete in your selection, you will not do either
        if (selectedSource === null || selectedDestination === null) {
            return false;
        }

        const usersData = {
            examID: examId,
            sourceCandidateID: selectedSource,
            destCandidateID: selectedDestination,
            part: this.data.part,
            checkboxIDs: this.stepCrash.checkboxIDs,
            checkboxRecordFileIDs: this.stepCrash.checkboxRecordFileIDs,
        };
        this.updateAnswers(usersData).subscribe((response: any) => {
            if (response.status == 200) {
                this.setActiveStep("stepSuccess");

                return false;
            } else {
                this.setActiveStep("stepFailed");
            }
        });

        return false;
    }

    /**
     * Save
     *
     * @param override
     */
    save(override: boolean = false): boolean {
        const examId = this.data.examId;
        const selectedSource = this.stepSelectedUsers.selected.source;
        const selectedDestination = this.stepSelectedUsers.selected.destination;

        // If you are incomplete in your selection, you will not do either
        if (selectedSource === null || selectedDestination === null) {
            return false;
        }

        if (selectedSource === selectedDestination) {
            alert("A forrás és a cél személy nem lehet ugyanaz!");

            this.stepSelectedUsers.selected.source = null;
            this.stepSelectedUsers.selected.destination = null;

            return;
        }

        const usersData = {
            examID: examId,
            sourceCandidateID: selectedSource,
            destCandidateID: selectedDestination,
            part: this.data.part,
        };
        this.checkAnswerCrash(usersData).subscribe((response: any) => {
            const data = response.data;
            if (data.response.result === "warning") {
                const form: Array<number>[] = response.data.form;
                const formRecordFile: Array<number>[] = response.data.formRecordFile;

                // In the event of a crash
                this.stepCrash.checkboxIDs = Object.values(form).map(item => {
                    return {value: item, isChecked: false};
                });

                // In the event of a crash during recording
                this.stepCrash.checkboxRecordFileIDs = Object.values(formRecordFile).map(item => {
                    return {value: item, isChecked: false};
                });

                this.stepCrash.description = data.response.message;
                this.stepCrash.subtitle = data.response.subtitle;

                this.setActiveStep("crash");
            } else if (data.response.result === "success") {
                this.updateAnswers(usersData).subscribe((response: any) => {
                    if (response.status == 200) {
                        this.setActiveStep("stepSuccess");

                        return false;
                    } else {
                        this.setActiveStep("stepFailed");
                    }
                });

                return false;
            } else if ("error") {
                this.setActiveStep("stepFailed");
            }
        });

        return true;
    }

    checkAnswerCrash(data): Observable<ArrayBuffer> {
        return this.moveExamAnswerService.checkAnswerCrash(data);
    }

    updateAnswers(data): Observable<ArrayBuffer> {
        return this.moveExamAnswerService.updateAnswers(data);
    }
}
