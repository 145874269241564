import {HttpClient} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {RestConfig} from "../../../http_apis/rest_config.http";

@Injectable({
    providedIn: "root"
})
export class MoveExamAnswerService {
    requestOptions;

    constructor(
        private http: HttpClient,
    ) {
        this.requestOptions = RestConfig.getReqOpt();
    }

    public checkAnswerCrash(data): Observable<any> {
        const urlBuilder = `${environment.apiPrefixUrl}task/checkAnswerCrash`;
        return this.http.post<any>(urlBuilder, data, this.requestOptions);
    }

    public updateAnswers(data): Observable<any> {
        const urlBuilder = `${environment.apiPrefixUrl}task/updateAnswers`;
        return this.http.post<any>(urlBuilder, data, {...this.requestOptions, ...{observe: "response"}});
    }
}
