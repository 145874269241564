
import {catchError, map} from 'rxjs/operators';
import { EventEmitter, Injectable } from '@angular/core';
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import { ToastrService } from 'ngx-toastr';
import {HttpClient} from "@angular/common/http";
import {isNullOrUndefined} from "util";
import {Observable} from "../../../../node_modules/rxjs";

@Injectable()
export class ExamService {

    identityCardImagesReload: EventEmitter<any> = new EventEmitter();
    candidateStartedExamPart: EventEmitter<any> = new EventEmitter();
    candidateExamStatus: EventEmitter<any> = new EventEmitter();
    candidateExamStatusArray: EventEmitter<any> = new EventEmitter();
    identityCheckDataLoaded: EventEmitter<any> = new EventEmitter();
    initLiveExam: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient,
                private toastr: ToastrService
    ) {
    }

    reopeningExam(candidateExamId){
        let url = `${environment.apiPrefixUrl}exam/reopeningExam`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_exam_id: candidateExamId,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            alert('Sikeresen újranyitás');
        })).subscribe();
    }

    addExtraTimeToCandidate(part:string, candidateExamId:number, timeValue:number){
        let url = `${environment.apiPrefixUrl}exam/addExtraTimeToCandidate`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            id: candidateExamId,
            extra_time: timeValue,
            part: part,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            this.toastr.success('Extra idő hozzáadva!', 'Művelet végrehajtva.', {onActivateTick: true});
        })).subscribe();
    }

    passwordGenerate(candidateId){
        let url = `${environment.apiPrefixUrl}passwordGenerate`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            id: candidateId,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let password = response.password;
            alert('Az új jelszó: ' + password);
        })).subscribe();
    }

    /**
     * Mark the user as verified, so it can begin the exam.
     * Also send websocket message to start exam!
     * @param candidateExamId
     * @param identityCheck
     */
    markAsVerified(candidateExamId, identityCheck){
        let url = `${environment.apiPrefixUrl}markAsVerified`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            candidate_exam_id: candidateExamId,
            identity_check: identityCheck,
        };

        this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let result = response.result;
            if(result){
                this.toastr.success('A vizsgázó megjelölve azonosítottnak, a vizsga megkezdődik.', 'Művelet végrehajtva.', {onActivateTick: true});
            } else {
                this.toastr.info('A vizsgázó azonosításának visszavonása', 'Művelet végrehajtva.', {onActivateTick: true});
            }
        })).subscribe();
    }

    suspensionOfExam(candidateExamId: number, type: string, closedStatus: string): Observable<any> {
        let url = `${environment.apiPrefixUrl}exam/postSuspensionCandidateExam`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            id: candidateExamId,
            type: type,
            status: closedStatus
        };

        return this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let date = response.date;
            let statusIsClose = response.status;

            if(date !== null){
                if(statusIsClose) {
                    this.toastr.success('A vizsgázó lezárva', 'Művelet végrehajtva.', {onActivateTick: true});
                } else {
                    this.toastr.success('A vizsgázó felfüggesztve', 'Művelet végrehajtva.', {onActivateTick: true});
                }
            } else {
                this.toastr.info('Vizsgázó folytathatja a vizsgát', 'Művelet végrehajtva.', {onActivateTick: true});
            }

            return date;
        }));
    }

    closeExam(candidateId, status): Observable<any>{
        let url = `${environment.apiPrefixUrl}candidates/postCloseCandidateExam`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            id: candidateId,
            status: status
        };
        return this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            return response.data;
        }));
    }
}
