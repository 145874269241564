import {Component, Input, OnChanges} from "@angular/core";
import {WritingSelectionService} from "../../../services/writing-selection.service";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {IExam} from "../../../interfaces/exam.interface";
import {isNullOrUndefined} from "util";

@Component({
    moduleId: module.id,
    selector: "writing-selection",
    templateUrl: "writing-selection.component.html",
    styleUrls: ["writing-selection.css"]
})

export class WritingSelectionComponent implements OnChanges {

    @Input("writingFormArray") writingFormArray: FormArray;
    @Input("numOfWritingTasks") numOfWritingTasks: number;
    @Input("hasExistData") hasExistData: boolean;

    mainSelectOptions: Array<IExam>; // contains the tasks which can be selected

    constructor(private writingSelectionService: WritingSelectionService) {
        this.mainSelectOptions = [];
    }

    ngOnChanges(changes) {
        this.mainSelectOptions = this.writingSelectionService.getDropdownElements();
    }

    private static generateGroupFormArray(mainIndex: number): FormArray {
        const formArray = new FormArray([]);

        // have to add three formGroup to the formArray, because in groups only three tasks can be selected
        for (let i = 0; i < 3; i++) {
            formArray.insert(i, new FormGroup({
                order: new FormControl(mainIndex),
                suborder: new FormControl(i),
                task_id: new FormControl()
            }));
        }

        return formArray;
    }

    selectTask(event: Event, mainIndex: number) {
        const selectedElement = <HTMLSelectElement> event.target;
        const selectedElementIndex = parseInt(selectedElement.value, 0);
        const selectedOption = this.mainSelectOptions[selectedElementIndex];
        const selectedOptionFormGroup = <FormGroup> this.writingFormArray.at(mainIndex);

        if (selectedOption.isGroup) { // if selected task is grouped (it has more than one item)
            if (!selectedOptionFormGroup.get("groupArray")) { // if the previously selected task wasn't a grouped task
                selectedOptionFormGroup.addControl("groupArray", WritingSelectionComponent.generateGroupFormArray(mainIndex));
            }

            this.writingFormArray[mainIndex] = <FormArray> selectedOptionFormGroup.get("groupArray");

            this.writingFormArray[mainIndex].controls.forEach((control) => {
                control.get("task_id").setValue(null);
            });
        } else {
            if (selectedOptionFormGroup.get("groupArray")) { // if the previously selected task was a grouped task
                delete this.writingFormArray[selectedElementIndex];
                selectedOptionFormGroup.removeControl("groupArray");
            }

            selectedOptionFormGroup.patchValue({
                order: mainIndex,
                suborder: null,
                task_id: selectedOption.id
            });
        }
    }

    deleteSelectedTaskDropdown(mainIndex: number) {
        this.hasExistData = false;
        this.writingFormArray.removeAt(mainIndex);

        this.writingFormArray.controls.forEach((formGroup, index) => {
            // formGroup(s) after the deleted item in the order
            if (index >= mainIndex) {
                const currentOrder: number = formGroup.get("order").value;

                // Because if an item removed, have to update (decrease with 1) the other's order too
                formGroup.patchValue({order: currentOrder - 1});
            }
        });
    }

    hasSelected(mainIndex: number, option: any): boolean {
        let isThisTaskSelected = false;
        const groupArray = this.writingFormArray.at(mainIndex).get("groupArray"); // if has groupArray => its a group task

        if (option.isGroup && !isNullOrUndefined(groupArray)) { // if selected task is grouped (it has more than one item)
            isThisTaskSelected = true;
        } else {
            let selectedTaskId;

            if (!isNullOrUndefined(this.writingFormArray.at(mainIndex).get("task_id"))) {
                selectedTaskId = this.writingFormArray.at(mainIndex).get("task_id").value;
            }

            isThisTaskSelected = option.id === selectedTaskId;
        }

        return isThisTaskSelected;
    }
}
