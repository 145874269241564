<div>
    <input type="checkbox" id="auto-play-cb" [ngModel]="isAutoPlayChecked" (change)="changeAutoPlay($event)">
    <label for="auto-play-cb" i18n>Automatikus lejátsztás</label>
</div>
<span *ngIf="!loader"><b i18n>Válaszok betöltés alatt.....</b></span>

<div *ngFor="let file of files; let i = index" class="speaking-answer">
    <img *ngIf="i === 0 && file && file.enlarge_file_url" src="{{prefixUrl}}{{file.enlarge_file_url}}?token={{token}}">
    <h3 class="title" i18n>{{file.order+1}}. feladat</h3>
    <div *ngIf="task.number === 1" class="audio-container answer-audio-container">
        <span class="title" i18n>Forrásszöveg:</span>
        <div class="audio-source">
            <div [innerHTML]="file.speakingSourceControl"></div>
        </div>
    </div>
    <div *ngIf="file.question_url !== null" class="audio-container question-audio-container">
        <span class="title" i18n>forrás fájl:</span>
        <div class="audio-source">
            <audio id="audio-{{file.order}}" controls
                   (durationchange)="setDuration(file.order)"
                   (ended)="audioEnded('question', i)"
                   (play)="playAudio(file.order)">
                <source src="{{prefixUrl}}{{file.question_url}}?token={{token}}" type="audio/mp3"/>
            </audio>
        </div>
        <button>
            <a href="{{prefixUrl}}{{file.question_url}}?token={{token}}" download i18n>Fájl letöltése</a>
        </button>
        <span class="hidden"><i>{{file.item_id}}</i></span>
    </div>
    <div *ngIf="file.url !== null" class="audio-container answer-audio-container">
        <span class="title" i18n>vizsgázó válasza:</span>
        <div class="audio-source">
            <audio id="audio-answer-{{file.order}}" controls
                   (durationchange)="setDuration(file.order)"
                   (ended)="audioEnded('answer', i)"
                   (play)="playAudio(file.order)">
              <source src="{{prefixUrl}}{{file.url}}?token={{token}}" type="audio/mp3"/>
            </audio>
        </div>
        <button>
            <a href="{{prefixUrl}}{{file.url}}?token={{token}}" download i18n>Fájl letöltése</a>
        </button>
        <span class="hidden"><i>{{file.url}}</i></span>
    </div>
    <div *ngIf="file.url === null" class="audio-container answer-audio-container">
        <span class="title" i18n>vizsgázó válasza:</span>
        <div class="audio-source audio-container-not-file"><b i18n>Nincs válasz</b></div>
    </div>
</div>

<section class="rating-container">
  <h5><strong i18n>Értékelés:</strong></h5>

  <form novalidate [formGroup]="reviewByCorrectorForm" (submit)="onSubmit($event)">
    <section>
      <h5 *ngIf="isAdmin"><strong i18n>Értékelő 1: </strong>{{corrector1}}</h5>
      <div>
        <label for="problem_solving2">{{correctionOptions.iTOLC_problem_solving2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="problem_solving2" type="radio" [value]="rate" formControlName="iTOLC_problem_solving2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

      <section *ngIf="task.exam_order === 0">
          <div>
              <label for="structure2">{{correctionOptions.iTOLC_structure2}}</label>
              <ul>
                  <li *ngFor="let rate of rating;">
                      <input id="structure2" type="radio" [value]="rate" formControlName="iTOLC_structure2">
                      <div *ngIf="isAdmin" class="div-to-disable-input"></div>
                      <div>{{rate}}</div>
                  </li>
              </ul>
          </div>
      </section>

    <section>
      <div>
        <label for="grammar2">{{correctionOptions.iTOLC_grammar2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="grammar2" type="radio" [value]="rate" formControlName="iTOLC_grammar2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="vocabulary2">{{correctionOptions.iTOLC_vocabulary2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="vocabulary2" type="radio" [value]="rate" formControlName="iTOLC_vocabulary2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

      <section>
      <div>
        <label for="speaking2">{{correctionOptions.iTOLC_speaking2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="speaking2" type="radio" [value]="rate" formControlName="iTOLC_speaking2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>


      <div>
          <ng-container *ngIf="!isAdmin">
              <h4><b>Értékelő jegyzetek:</b></h4>
              <textarea #textarea_comment class="textarea-comment"(change)="correctorCommentText = textarea_comment.value"  [innerHTML]="correctorCommentText"></textarea>
          </ng-container>
      </div>

    <div class="bg-success text-success saved-label"
         [class.hidden]="!isSavingLabelVisible">
      Mentve {{currentTime}}
    </div>

    <div class="save-btn-container" *ngIf="!isAdmin">
        <button class="btn btn-warning" (click)="getDownloadFile()"><i class="fa fa-download"></i> Skála</button>
      <button class="btn btn-primary" [disabled]="disabledButton">Mentés</button>
    </div>
  </form>

  <form [formGroup]="reviewByCorrector2Form" *ngIf="isAdmin">
    <section>
      <h5><strong i18n>Értékelő 2: </strong>{{corrector2}}</h5>
      <div>
        <label for="problem_solving2_2">{{correctionOptions.iTOLC_problem_solving2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="problem_solving2_2" type="radio" [value]="rate" formControlName="iTOLC_problem_solving2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

      <section *ngIf="task.exam_order === 0">
          <div>
              <label for="structure2_2">{{correctionOptions.iTOLC_structure2}}</label>
              <ul>
                  <li *ngFor="let rate of rating;">
                      <input id="structure2_2" type="radio" [value]="rate" formControlName="iTOLC_structure2">
                      <div class="div-to-disable-input"></div>
                      <div>{{rate}}</div>
                  </li>
              </ul>
          </div>
      </section>

    <section>
      <div>
        <label for="grammar2_2">{{correctionOptions.iTOLC_grammar2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="grammar2_2" type="radio" [value]="rate" formControlName="iTOLC_grammar2">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="vocabulary2_2">{{correctionOptions.iTOLC_vocabulary2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="vocabulary2_2" type="radio" [value]="rate" formControlName="iTOLC_vocabulary2">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="speaking2_2">{{correctionOptions.iTOLC_speaking2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="speaking2_2" type="radio" [value]="rate" formControlName="iTOLC_speaking2">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

  </form>

</section>
<div>
    <ng-container *ngIf="isAdmin">
        <h4><b i18n>Értékelő jegyzetek:</b></h4>
        <ng-container *ngFor="let comment of correctorComments; let i = index">
            <p><b>{{ comment.user_name }}:</b> {{ comment.comment }}</p>
        </ng-container>
    </ng-container>
</div>
