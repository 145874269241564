import {Component, EventEmitter, Input, Output} from "@angular/core";
import {ITask} from "../../../../interfaces/task.interface";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";
import {AuthService} from "../../../../services/auth.service";
import {ToastrService} from "ngx-toastr";
import {EnvironmentService} from "../../../../services/environment.service";
import {ApiService} from "../../../../services/api.service";

@Component({
    moduleId: module.id,
    selector: "final-result",
    template: `
        <span *ngIf="result.success === 0"> eredménytelen </span>
        <span *ngIf="result.success === 1 && result.improved === 0" class="span-success-{{ result.success }}">
            <!--<input type="checkbox" name="improved" id="improved" value="1" [checked]="result.improved" (click)="improvedExam($event, result.candidate_exam_id,i,c,exam_id)">-->

            <span *ngIf="result.exam_subtype !== 24">Szóbeli javítás <br></span>

            <!--
            <input *ngIf="improvedExam !== null" type="radio" value="1" name="improved-{{ candidate_id }}" [ngModel]="result.improved" (click)="improvedExam($event, result.candidate_exam_id, 1)">


            <span *ngIf="result.exam_subtype === 24">Szóbelin megfelelt <br></span>
            <span *ngIf="result.exam_subtype !== 24">Sikeres</span>

            <input *ngIf="improvedExam !== null" type="radio" value="2" name="improved-{{ candidate_id }}" [ngModel]="result.improved" (click)="improvedExam($event, result.candidate_exam_id, 2)">


            <span *ngIf="result.exam_subtype === 24">Szóbelin nem felelt meg <br></span>
            <span *ngIf="result.exam_subtype !== 24">Sikertelen</span>
            -->
        </span>
        <span *ngIf="result.success === 1 && result.improved === 1" class="span-success-2">
            megfelel (szóbelin)
        </span>
        <span *ngIf="result.success === 1 && result.improved === 2">eredménytelen (szóbeli) </span>
        <span *ngIf="result.success === 2"  class="span-success-{{ result.success }}"> megfelel </span>
        <span *ngIf="result.success === null" > vizsga kitöltése folyamatban </span>
        <span *ngIf="result.success === 3" class="span-success-3">nem jelent meg </span>

        <!-- result details infobox:  only Energetikai auditor / auditor megújjító / Klímavédelem -->
        <ng-container *ngIf="result.sections.length > 0 && displayResultInfobox(result.exam_subtype)">
            <div class="exam-info-box">
                <i class="fa fa-info infobox-icon"></i>
                <div class="exam-info-box-body">
                    <ng-container *ngFor="let resultSection of result.sections; let r = index">
                        <p *ngIf="true || r < 3">
                            <b>{{ resultSection.number_of_section }}. szakasz:</b> {{ resultSection.percent }} %
                        </p>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    `
})
export class FinalResultComponent {
    @Input("result") result: any;
    @Output() resultChange = new EventEmitter<any>();


    @Input("candidate_id") candidate_id: any;

    // @TODO: WTF are these, why are they named like this

    //@Input("improvedExam") improvedExam: (args: any) => void;

    constructor(
        private apiService: ApiService
    ) {

    }

    /**
     * Update exam results on the backend
     * @param event
     * @param candidate_exam_id
     * @param result
     */
    improvedExam(event: Event, candidate_exam_id:number, result:number){
        event.preventDefault();

        if(confirm('Biztos?')){
            // let value = this.pagedItems[i].candidates[c].exam_results[exam_id].improved;
            // let update = value === 0 ? 1 : 0; // true-false
            // let success = result === 1 ? 1 : 0; // class number to color

            this.apiService.updateExamImproved(candidate_exam_id, result);
            this.result.improved = result;
            console.log('Setting improved to ', result, candidate_exam_id);
            this.result.success = 1;
            this.resultChange.emit(this.result);
        }
    }

    /**
     * Display result details infobox
     *
     * ExamTypes:
     * 4, Energetikai auditori
     * 25, Klímavédelem
     * 26, Energetikai auditori megújító
     * 44, FLR
     *
     * @param id
     */
    displayResultInfobox(id): boolean {
        return [4, 25, 26, 44].some((element) => element === id);
    }
}
