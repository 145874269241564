
import {catchError, map} from 'rxjs/operators';
import {ActivatedRoute, Router} from "@angular/router";
import {Component, OnInit, DoCheck} from "@angular/core";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {FormControl} from "@angular/forms";
import {HttpClient} from "@angular/common/http";

export interface Pagination {
    currentPage: number;
    totalPage: number;
    totalPageArray: Array<number>;
}

@Component({
    moduleId: module.id,
    selector: "diaries",
    host: {
        id: "diaries"
    },
    templateUrl: "diaries.component.html",
    styleUrls: ["diaries.component.css"]
})

export class DiariesComponent implements OnInit,DoCheck {
    private examHttpApi: ExamHttpApi;
    private list: any;
    private diaryList: any;
    pagination: Pagination = {
        currentPage: 1,
        totalPage: 1,
        totalPageArray: [1]
    };

    private exams: any;
    areExamsShown: Array<boolean>;
    loaderShow = true;
    loaderShowText = 'Lista betöltés alatt...';
    isAdmin:boolean = false;
    formShow:boolean = false;

    diaryTypes = ['Késés', 'Felfüggesztés', 'Berendezések ellenőrzése', 'Technikai probléma', 'Időkorlát módosítása', 'Fókuszvesztés', 'Egyéb'];
    formType = new FormControl(null);
    formExamId = new FormControl(null);
    formDescription = new FormControl(null);
    examTypes: any = {
        'live': $localize`:@@live:Éles`,
        'retake': $localize`:@@retake:Pótvizsga`,
        'pilot': $localize`:@@pilot:Pilot`,
        'online': $localize`:@@online:Online próbavizsga`,
        'local': $localize`:@@local:Helyi próbavizsga`,
        'test': $localize`:@@test:Teszt`,
    };

    constructor(private http: HttpClient,
                private authService: AuthService,
                private router: Router) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();

        this.getExamList(this.pagination.currentPage);

        this.examHttpApi.getExamListOnDiary().subscribe((results) => {
            this.exams = results;
        });
    }

    private getExamList(page: number){
        this.loaderShow = true;
        this.examHttpApi.getExamListForPAginate(page).subscribe((results) => {
            this.list = results.data;
            this.pagination.totalPage = results.total;
            this.pagination.totalPageArray = Array.from({length: results.total}, (v, k) => k + 1);

            this.areExamsShown = this.list.map(() => false);
            this.diaryList = this.list.map(() => []);

            this.loaderShow = false;
        });
    }

    getDiariesList(index:number, id: number){
        this.examHttpApi.getDiariesList(id).subscribe((results) => {
            this.diaryList[index] = results.data;
        });
    }

    ngOnInit() {
        // console.log('OK');
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    openExam(index: number, examId: number){
        this.getDiariesList(index, examId);
        this.areExamsShown[index] = !this.areExamsShown[index];
    }

    openDetailsList(index: number){
        this.areExamsShown[index] = !this.areExamsShown[index];
    }

    openForm(){
        this.formType = new FormControl(null);
        this.formExamId = new FormControl(null);
        this.formDescription = new FormControl(null);

        this.formShow = true;
    }

    onSubmit(event: Event){
        event.preventDefault();

        let url = `${environment.apiPrefixUrl}diaries`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: this.formExamId.value,
            type: this.formType.value,
            description: this.formDescription.value,
        };

        if(this.formExamId.valid && this.formType.valid && this.formDescription.valid) {
            this.http.post(url, body, requestOptions).pipe(map((response:any) => {
                let result = response.result;

                if (result) {
                    alert('Sikeres mentés');
                    window.location.reload();
                }
            })).subscribe();
        } else {
            alert('Minden mező kitöltése kötelező!');
        }
    }

    setPage(page:number){
        if(this.loaderShow === false && page >= 1 && page <= this.pagination.totalPage){
            this.loaderShow = true;

            this.pagination.currentPage = page;
            this.getExamList(this.pagination.currentPage);
        }
    }
}
