import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "answerResultName"
})

export class AnswerResultNamePipe implements PipeTransform {

  transform(value: number): any {
    if (value === 1) {
      return 'Helyes';
    }

    return 'Hibás';
  }

}
