//tslint:disable max-file-line-count
import {Component, DoCheck, EventEmitter, Input, OnInit} from "@angular/core";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {isNullOrUndefined} from "../../../utils/iswhat";
import {ITask, ITaskData} from "app/interfaces/task.interface";
import {IAnswerData} from "../../../interfaces/answer.interface";
import {CORRECTION_OPTIONS_WRITING} from "../../../services/shared/manual_correction_options";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../../services/auth.service";
import {HttpClient} from "@angular/common/http";

@Component({
  moduleId: module.id,
  selector: "writing-task",
  templateUrl: "writing-task.component.html",
  styleUrls: ["../corrector.component.css"]
})

export class WritingTaskComponent implements OnInit, DoCheck {

  @Input("task") task: ITask;

  private examHttpApi: ExamHttpApi;
  private prefixUrl: string;
  private token: string;
  private optionRateMap = new Map();
  private optionRateMap2 = new Map();
  private taskInitialize: EventEmitter<any> = new EventEmitter();
  private wordCounter = 0;
  examAnswerId: number;
  currentTime: any;
  correctorComments: any;
  correctorCommentText: any;
  answerData: IAnswerData;
  taskData: ITaskData;
  reviewByCorrectorForm: FormGroup;
  reviewByCorrector2Form: FormGroup;
  rating = ["*", 0, 1, 2, 3, 4]; // "*" equals 5
  correctionOptions: any = CORRECTION_OPTIONS_WRITING;
  isSavingLabelVisible = false;
  corrector1: string;
  corrector2: string;
  isAdmin: boolean;
  disabledButton: boolean = false;
  minChar: number = 300;

  constructor(private http: HttpClient,
              private formBuilder: FormBuilder,
              private authService: AuthService) {

    this.examHttpApi = new ExamHttpApi(http,  "data");
    this.examHttpApi.setSelectedTaskUrl();
    this.token = window.localStorage.getItem("itolc_token");
    this.prefixUrl = environment.apiPrefixUrl;

    const formControlKeys = Object.keys(this.correctionOptions);
    this.reviewByCorrectorForm = this.formBuilder.group({}); // first corrector's form
    this.reviewByCorrector2Form = this.formBuilder.group({}); // second corrector's form

    formControlKeys.forEach((key) => {
      this.reviewByCorrectorForm.registerControl(key, new FormControl(null, Validators.required));
      this.reviewByCorrector2Form.registerControl(key, new FormControl(null, Validators.required));

      this.taskInitialize.subscribe(() => {
        if (!isNullOrUndefined(this.task.items[0].exam_answer[0].review)) {
          this.task.items[0].exam_answer[0].review.forEach((option, index) => {
            if (option.rating === null) {
              option.rating = "*";
            }

              if (this.task.is_admin) {
                  if (this.task.examiner1 === option.user_id) {
                      // these are the first corrector's reviews if admin logged in
                      // or the current corrector's reviews if the corrector logged in
                      this.optionRateMap.set(option.scale, option.rating);
                  } else {
                      // these are the second corrector's reviews if admin logged in
                      this.optionRateMap2.set(option.scale, option.rating);
                  }
              } else {
                  this.optionRateMap.set(option.scale, option.rating);
              }
          });
          this.reviewByCorrector2Form.controls[key].setValue(this.optionRateMap2.get(key));
          this.reviewByCorrectorForm.controls[key].setValue(this.optionRateMap.get(key));
        }
      });

    });

  }

  onSubmit(event) {
    event.preventDefault();
    this.disabledButton = true;

    const valueObj = this.reviewByCorrectorForm.value;
    const correctorOptionDatas = [];

    /*if(!this.reviewByCorrectorForm.valid){
        alert("Az értékelés nem teljes!");
        return false;
    }*/

    for (const item in valueObj) {
      if (valueObj.hasOwnProperty(item)) {
        let itemVal = valueObj[item];

        if (!isNullOrUndefined(itemVal)) {
          if (itemVal === "*") {
            itemVal = null;
          }

          correctorOptionDatas.push({
            candidate_exam_id: this.task.candidate_exam_id,
            exam_task_id: this.task.exam_task_id,
            corrector_comment: this.correctorCommentText,
            exam_answer_id: this.examAnswerId,
            scale: item,
            rating: itemVal
          });
        }
      }
    }

    if (correctorOptionDatas.length > 0) {
      this.examHttpApi.saveManualCorrectionOptions(correctorOptionDatas).subscribe((response:any) => {
        if (response) {
          this.currentTime = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
          });
          this.isSavingLabelVisible = true;
          this.disabledButton = false;
          setTimeout(() => {
            this.isSavingLabelVisible = false;
          }, 5000);
        }
      });
    }
  }

  ngOnInit() {
    this.taskInitialize.emit();

    this.taskData = this.task.task_data;
    this.correctorComments = !isNullOrUndefined(this.task.review_comments) ? this.task.review_comments : [];
    this.correctorCommentText = !isNullOrUndefined(this.task.review_comments) ? this.task.review_comments.comment : '';

    this.task["items"].forEach((item) => {
      item.item_data = (typeof item.item_data === "string") ?
        JSON.parse(item.item_data) : item.item_data;

      if (!isNullOrUndefined(item["exam_answer"]) && item["exam_answer"].length > 0) {
        console.log(item);
        item["exam_answer"][0].answer_data = (typeof item["exam_answer"][0].answer_data === "string") ?
          this.answerData = JSON.parse(item["exam_answer"][0].answer_data) :
          this.answerData = item["exam_answer"][0].answer_data;

          if (!isNullOrUndefined(this.answerData.values.text)) {
              this.answerData.values.text = this.answerData.values.text.replace(/\n/g, '<br/>');
          }
      }
    });

    this.examAnswerId = this.task.items[0].exam_answer[0].id;

    if (this.isAdmin) {
      this.corrector2 = `${this.task.items[0].exam_answer[0].review[9].user.first_name} ${this.task.items[0].exam_answer[0].review[9].user.last_name}`;
      this.corrector1 = `${this.task.items[0].exam_answer[0].review[0].user.first_name} ${this.task.items[0].exam_answer[0].review[0].user.last_name}`;
    }
  }

  countWords(value) {
        if(isNullOrUndefined(value)){
            return 0;
        }

      value = value.replace(/(^\s*)|(\s*$)/gi, ' '); // exclude start and end white-space
      value = value.replace(/[ ]{2,}/gi, ' '); // 2 or more space to 1
      value = value.replace(/\n /, ' '); // exclude newline with a start spacing
      value = value.replace(/<[^>]*>/g, ' ');
      value = value.replace('<br />', ' ');
      value = value.replace("<[^>]*>", " ");

      let words =  value.match(/\S+/g);
      let currentCounterNumber = isNullOrUndefined(words) ? 0 : words.length;

      return currentCounterNumber;
    }

  ngDoCheck() {
    this.isAdmin = this.authService.$userStatus === "admin";
  }

    /**
     * Download xls file
     * @param file
     */

    private getDownloadFile() {
        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}getDownloadReviewPDF/writing_scale.pdf&token=${token}`;

        window.open(url);
    }

    valueChange() {
        this.minChar = this.correctorCommentText.length < 300 ? 300 - this.correctorCommentText.length : 0;
    }
}
