import {Component, Input, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ITask} from "../../../interfaces/task.interface";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {environment} from "../../../../environments/environment";
import {isNullOrUndefined} from "util";
import {Sorting} from "../../../utils/sorting";
import {HttpClient} from "@angular/common/http";

@Component({
    moduleId: module.id,
    selector: "pair-task",
    templateUrl: "pair-task.component.html",
    styleUrls: ["../corrector.component.css"]
})

export class PairTaskComponent implements OnInit {

    @Input("task") task: ITask;

    private prefixUrl: string;
    private token: string;
    private examHttpApi: ExamHttpApi;
    private taskId: number;
    private examId: number;
    private imageLetterMap = new Map();
    private letters = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "O", "P"];
    isTaskPairingImageToText: boolean;
    isCandidateAnswersCorrect = [];
    isItemsReusable: boolean;

    constructor(private http: HttpClient,
                private activatedRoute: ActivatedRoute) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setSelectedTaskUrl();
        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.activatedRoute.params.subscribe(({candidateId, taskId}) => {
            this.taskId = taskId;
            this.examId = candidateId;
        });
    }

    ngOnInit() {
        this.task.items = Sorting.sortTaskItemsByOrder(this.task.items);

        this.isItemsReusable = this.checkIfItemsReusable();

        this.task.items.forEach((item, index) => {
            item.item_data = (typeof item.item_data === "string") ?
                JSON.parse(item.item_data) : item.item_data;

            this.isTaskPairingImageToText = this.checkIfTaskPairingIsImageToText();

            if (this.isTaskPairingImageToText) {
                this.imageLetterMap.set(item.item_data.textInput, this.letters[index]);
                item.item_data.letter = this.imageLetterMap.get(item.item_data.textInput);
            }
        });

        this.task.items.forEach((item:any) => {
            if (!isNullOrUndefined(item.exam_answer) && item.exam_answer.length > 0) {
                item.exam_answer[0].answer_data = (typeof item.exam_answer[0].answer_data === "string") ?
                    JSON.parse(item.exam_answer[0].answer_data) : item.exam_answer[0].answer_data;

                this.task["items"].forEach((i) => {
                    i.item_data = (typeof i.item_data === "string") ? JSON.parse(i.item_data) : i.item_data;

                    if (item["exam_answer"].length > 0 && !isNullOrUndefined(item["exam_answer"][0].answer_data) && i.id === item["exam_answer"][0].answer_data.answer_item_id) {
                        item["exam_answer"][0].answer_data.textInput = i.item_data.textInput;

                        if (this.isTaskPairingImageToText) {
                            item["exam_answer"][0].answer_data.letter = this.imageLetterMap.get(item["exam_answer"][0].answer_data.textInput);
                        }
                    }
                });
            }
        });

        this.isCandidateAnswersCorrect = this.task.items.map((item:any) => {
            if (!this.isTaskPairingImageToText) {
                if (!isNullOrUndefined(item.exam_answer)) { // if has answer
                    const rightAnswerLetter = item.item_data.textInput.charAt(0); // get the correct answer's first letter

                    if (item.exam_answer.length > 0 && !isNullOrUndefined(item.exam_answer[0].answer_data)) { // if has actual answer data
                        if (!isNullOrUndefined(item.exam_answer[0].answer_data.textInput)) {
                            const candidateAnswerLetter = item.exam_answer[0].answer_data.textInput.charAt(0);
                            return rightAnswerLetter === candidateAnswerLetter;
                        } else {
                            return false;
                        }
                    }
                } else { // if has no answer
                    return null;
                }
            } else {
                if (!isNullOrUndefined(item.exam_answer)) { // if has answer
                    const rightAnswerLetter = item.item_data.letter;


                    if (!isNullOrUndefined(item.exam_answer.answer_data) && item.exam_answer.answer_data.length > 0) { // if has actual answer data
                        if (!isNullOrUndefined(item.exam_answer[0].answer_data.letter)) {
                            const candidateAnswerLetter = item.exam_answer[0].answer_data.letter;
                            return rightAnswerLetter === candidateAnswerLetter;
                        } else {
                            return false;
                        }
                    }
                } else {
                    return null;
                }
            }
        });

        console.log("this.task", this.task);
    }

    private checkIfTaskPairingIsImageToText() {
        // if this.task.task_data.type - 0 => text-text, 1 => image-text, 2 => image-image, 3 => chapter-statement
        return this.task.task_data.type === 1;
    }

    private checkIfItemsReusable() {
        return !isNullOrUndefined(this.task.task_data.reusable);
    }

}
