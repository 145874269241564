<ng-container>
    <aside></aside>
</ng-container>

<div class="exam-roster col-md-12">
    <ng-container *ngIf="formShow">
        <div class="col-md-offset-3 col-md-6 text-left mb-10 mt-10 new-form-div">
            <h2 class="text-center">Új bejegyzés</h2>
            <form  name="newForm" (submit)="onSubmit($event)">
            <div class="col-md-6 mb-5 mt-5">
                <label>
                    Típus:
                    <select class="form-control" [formControl]="formType" required>
                        <option *ngFor="let type of diaryTypes"
                                [value]="type">{{ type }}</option>
                    </select>
                </label>
            </div>
            <div class="col-md-6 mb-5 mt-5">
                <label>
                    Vizsga:
                    <select class="form-control" [formControl]="formExamId" required>
                        <option *ngFor="let exam of exams"
                                [value]="exam.id">{{ exam.id }} <span class="exam-info"> {{ exam.level }}-{{ exam.language }} {{ exam.date }}</span></option>
                    </select>
                </label>
            </div>
            <div class="col-md-12 mb-5 mt-5">
                <label>
                    Részletek:
                    <textarea type="text" class="form-control"
                              [formControl]="formDescription" rows="3" cols="90" required>
                    </textarea>
                </label>
            </div>
            <div class="col-md-12 mb-7 mt-7">
                <button type="submit" class="btn btn-success">Mentés</button>
                <button type="button" class="btn btn-warning" (click)="formShow = false;">Mégse</button>
            </div>
        </form>
        </div>
    </ng-container>

    <ng-container *ngIf="!formShow">
        <h2>Naplóbejegyzések</h2>
        <div class="col-md-12 text-right mb-5">
            <button class="btn btn-success" (click)="openForm()">Új bejegyzés</button>
        </div>
    </ng-container>

    <div class="col-md-12 mt-7">
        <table class="table">
            <thead>
                <tr>
                    <th>Vizsga azonosító</th>
                    <th>Időpont</th>
                    <th>Hely</th>
                    <th>Típus</th>
                    <th>Nyelv</th>
                    <th>Színt</th>
                    <th>Művelet</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="!loaderShow">
                    <ng-container *ngFor="let exam of list; let i = index">
                        <tr>
                            <td>{{ exam.id }}</td>
                            <td>{{ exam.date }}</td>
                            <td><span *ngIf="exam.places"> {{ exam.places }}</span></td>
                            <td>{{ examTypes[exam.type] }}</td>
                            <td>{{ exam.language }}</td>
                            <td>{{ exam.level }}</td>
                            <td><span (click)="openExam(i, exam.id)"><b>Megtekint</b></span></td>
                        </tr>
                        <tr [class.hide]="!areExamsShown[i]">
                            <td colspan="5">
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Időpont</th>
                                            <th>Hely</th>
                                            <th>Típus</th>
                                            <th>Művelet</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="diaryList[i]">
                                        <ng-container *ngFor="let diary of diaryList[i]; let i = index">
                                            <tr>
                                                <td>{{ diary.created_at }}</td>
                                                <td><span *ngIf="diary.place"> {{ diary.place.name }}</span></td>
                                                <td>{{ diary.type }}</td>
                                                <td>
                                                    <ng-container [ngSwitch]="diaryList[i].open === 1">
                                                        <span *ngSwitchCase="true" (click)="diaryList[i].open = 0" >Bezár</span>
                                                        <span *ngSwitchCase="false" (click)="diaryList[i].open = 1">Megtekint</span>
                                                    </ng-container>
                                                </td>
                                            </tr>
                                            <ng-container *ngIf="diaryList[i].open === 1">
                                                <tr>
                                                    <td colspan="2">
                                                        <table class="table table-bordered">
                                                            <tbody>
                                                            <tr>
                                                                <td *ngIf="diary.user"><b>Felhasználó: </b>{{ diary.user.last_name }} {{ diary.user.first_name }}</td>
                                                                <td *ngIf="!diary.user"><b>Rendszer</b></td>
                                                                <td><b>Vizsga azonosító: </b>{{ diary.exam_id}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td><b>Időpont: </b>{{ diary.created_at}}</td>
                                                                <td><b>Helyszín: </b><span *ngIf="diary.place"> {{ diary.place.name }}</span></td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2"><b>Típus: </b>{{ diary.type}}</td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="2"><b>Részeltes leírás: </b><span style="white-space: pre-line;">{{ diary.description}}</span></td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </ng-container>
                                        </ng-container>
                                    </tbody>
                                </table>
                            </td>
                            <td colspan="3"></td>
                        </tr>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="loaderShow">
                    <tr>
                        <td colspan="5">{{ loaderShowText }}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>

    <div class="col-md-12 mt-7">
        <ul class="pagination">
            <i *ngIf="loaderShow" class="fa fa-cog fa-spin fa-3x fa-fw"></i>

            <li class="page-item">
                <a class="page-link"
                   (click)="setPage(pagination.currentPage - 1)">
                    Previous
                </a>
            </li>
            <li *ngFor="let page of pagination.totalPageArray;" class="page-item" [class.active]="page === pagination.currentPage">
                <a *ngIf="page <= pagination.currentPage + 5" class="page-link" (click)="setPage(page)" >
                    {{ page }}
                </a>
            </li>
            <li class="page-item">
                <a class="page-link"
                   (click)="setPage(pagination.currentPage + 1)">
                    Next
                </a>
            </li>
        </ul>
    </div>
</div>
