import {Component, OnInit, DoCheck, Input, ViewChild, TemplateRef, Output, EventEmitter} from "@angular/core";
import {WebsocketService} from "../../services/websocket.service";
import {StreamService} from "../../services/stream.service";
import {ExamService} from "../../services/exam_service/exam.service";
import {EnvironmentService} from "../../services/environment.service";
import {WebcamService} from "../../services/webcam.service";
import {AuthService} from "../../services/auth.service";
import {MatDialog} from "@angular/material/dialog";
import {ToastrService} from "ngx-toastr";
import {ApiService} from "../../services/api.service";

@Component({
    moduleId: module.id,
    selector: "live-test",
    host: {
        id: "live-test"
    },
    templateUrl: "live-test.component.html",
    styleUrls: ["live-test.component.css"]
})

export class LiveTestComponent implements OnInit {

    status = 'offline';
    candidate = null;
    candidateInboundStream = null;
    inspectors = {};

    @ViewChild('liveSessionResultDialog') liveSessionResultDialog: TemplateRef<any>;
    success = true; // End result of the exam
    setResult = "successful"; // default

    @Output() openChat = new EventEmitter<number>();

    constructor(
                private streamService: StreamService,
                private examService: ExamService,
                private websocketService: WebsocketService,
                private webcamService: WebcamService,
                private authService: AuthService,
                public dialog: MatDialog,
                private toastr: ToastrService,
                private apiService: ApiService,
                environmentService: EnvironmentService,
    ) {

    }

    /**
     * Start a session.
     */
    init() {
        this.inspectors = {};
        console.log('Initializing live exam for', this.candidate.candidate_exam_id);
        this.apiService.setCandidateExamStatus(this.candidate.candidate_exam_id, 'live').subscribe(() => {
            this.initCandidate();
        });
    }

    /**
     * Initialize 2way stream connection to the candidate
     */
    async initCandidate(resume = false) {
        await this.streamService.initStream(this.candidate.candidate_exam_id, 'live');

        // We are joining, let the world know
        this.websocketService.sendMessage('start-live', {
            'candidate_exam_id': this.candidate.candidate_exam_id,
            'resume': resume,
            'name': this.authService.userData.last_name + ' ' + this.authService.userData.first_name
        });

        // Apply candidate stream on the live exam video player
        //this.candidateInboundStream = null;
        //const streams = this.streamService.getStream(this.candidate.candidate_exam_id);
        //this.applyStream('candidate', streams.remote);

        // Start our webcam, and add it to the peerconnection.
        this.webcamService.initWebcam('audiovideo', (stream, track) => {
            //    this.streamService.addLocalVideoToStream('candidate', this.candidate.candidate_exam_id, track, stream);// Moved to stream service
            this.applyStream('my', stream, true);
            this.status = 'online';
        });
    }

    /**
     * Get stream data from event
     * @param data
     */
    collectVideoStream(data) {
        if (data.event.streams && data.event.streams[0]) {
            return data.event.streams[0];
        } else {
            if ((data.user_type === 'inspector' || data.user_type === 'admin') && (!this.inspectors[data.candidate_exam_id].inboundStream || this.inspectors[data.candidate_exam_id].inboundStream === null)) {
                this.inspectors[data.candidate_exam_id].inboundStream = new MediaStream();
            } else if ((data.user_type === 'candidate' || data.user_type === 'live') && this.candidateInboundStream === null) {
                this.candidateInboundStream = new MediaStream();
            }

            if ((data.user_type === 'inspector' || data.user_type === 'admin')) {
                this.inspectors[data.candidate_exam_id].inboundStream.addTrack(data.event.track);
                return this.inspectors[data.candidate_exam_id].inboundStream;
            } else if ((data.user_type === 'candidate' || data.user_type === 'live')) {
                this.candidateInboundStream.addTrack(data.event.track);
                return this.candidateInboundStream;
            }

            return null;
        }
    }

    /**
     * Called when we receive websocket data about the inspector
     * Registers inspector, and start peerconnectiong and video stream
     * @param type
     * @param data
     */
    async initInspectorConnection(type, data) {
        if (!this.candidate || data.candidate_exam_id !== this.candidate.candidate_exam_id) {
            console.log('Ignore inspector connection', data);
            return;
        }
        console.log('Waiting for connection from new inspector', data);
        this.addInspector(data.inspector_id, data.name);

        // A new inspector joined, start the stream towards it, and also attach our camera stream
        await this.streamService.startStream(data.privileges, data.inspector_id);
        if (type === 'existing') {
            this.streamService.addCameraToConnection(data.privileges, data.inspector_id);   // admin or inspector
        }
    }

    /**
     * Store inspector with name, which will trigger new video element too
     * @param id
     * @param name
     */
    addInspector(id, name) {
        if (typeof this.inspectors[id] !== "undefined" && this.inspectors[id] !== null) {
            this.inspectors[id].inboundStream = null;
            return;
        }

        this.inspectors[id] = {'name': name, 'inboundStream': null};
    }

    /**
     * Applying video stream that we received
     * @param type
     * @param stream
     * @param mute
     * @param inspector_id
     */
    applyStream(type, stream, mute = true, inspector_id = null) {
        let selector = null;

        if (type === 'candidate' || type === 'live') {
            selector = 'live-exam-candidate-video';
        } else if (type === 'my') {
            selector = 'live-exam-my-video';
        } else if (type === 'inspector' || type === 'admin') {
            selector = 'live-exam-inspector-video-' + inspector_id;
        }

        const video = document.getElementById(selector) as HTMLVideoElement;
        video.srcObject = stream;

        console.log('Apply remote stream to element', video);

        if (mute) {
            video.muted = true;
        }
    }

    /**
     * Add listeners to websocket service
     */
    initWebsocketListeners() {
        // We have just joined, and there were already other inspectors on the channel
        this.websocketService.addHandler('live-inspector-exists', this.initInspectorConnection.bind(this, 'existing'));

        // We were already joined for a while, but some other inspector just joined
        this.websocketService.addHandler('live-inspector-joined', this.initInspectorConnection.bind(this, 'joined'));

        // Somebody is waiting for a live exam
        this.websocketService.addHandler('live-wait', (data) => {
            console.log('live-wait event received', data, this.candidate);

            // If we already have a live connection to this very user, then something might went wrong.
            if (this.status === 'online' && data.candidate_exam_id === this.candidate.candidate_exam_id) {
                console.log('Previously interrupted session detected, try to recover');
                this.initCandidate();
            }
        });

        // If someone else pressed pause
        this.websocketService.addHandler('live-status', (data) => {
            console.log('live-status event received', data, this.candidate);

            // If not for us...
            if (!this.candidate || data.candidate_exam_id !== this.candidate.candidate_exam_id) {
                return;
            }

            // Do the right thing
            switch (data.status) {
                case 'paused':
                    this.pause(true);
                    break;
                case 'online':
                    this.resume(true);
                    break;
                case 'end':
                    this.close(true);
                    break;
            }
        });
    }

    ngOnInit() {
        this.websocketService.readyCallback(this.initWebsocketListeners.bind(this));

        // When we receive stream data, display it
        this.streamService.getReceivingStreamSubject().subscribe((data) => {
            // Do action only if the stream is for this candidate
            if (!this.candidate ||
                data.user_type === 'app' || // We do not need to display the application
                (data.user_type === 'candidate' && this.candidate.candidate_exam_id !== data.candidate_exam_id) ||    // If this is a candidate, but not for this session
                ((data.user_type === 'inspector' || data.user_type === 'admin') && (typeof this.inspectors[data.candidate_exam_id] === 'undefined' || this.inspectors[data.candidate_exam_id] === null))    // If this is an inspector, but not in the current session
            ) {
                return;
            }

            console.log('Received video data', data);

            // Apply stream to video element. If inspector, we have to send the inspector id (stored in the candidate_exam_id...)
            this.applyStream(data.user_type, this.collectVideoStream(data), null, ((data.user_type === 'inspector' || data.user_type === 'admin') ? data.candidate_exam_id : null));
        });

        // When the user clicks on start video stream, this event gets fired
        this.examService.initLiveExam.subscribe((candidate) => {
            console.log('Connecting to', candidate);
            this.candidate = candidate;
            this.status = 'connecting';
            this.init();
        });
    }

    /**
     * Pause live session, that will disconnect the candidate but keep the inspectors in the channel
     * @param automated if it was called by handler, we do not need the loop
     */
    pause(automated = false) {
        console.log('Pause triggered', automated);
        this.status = 'paused';
        //this.streamService.clearLocalTracks('candidate', this.candidate.candidate_exam_id);
        this.streamService.endStream('live', this.candidate.candidate_exam_id);
        this.applyStream('candidate', null);        // Hide the video
        this.candidateInboundStream = null;

        if (!automated) {
            this.apiService.setCandidateExamStatus(this.candidate.candidate_exam_id, 'live_paused').subscribe();

            // Let others know
            this.websocketService.sendMessage('live-status', {'candidate_exam_id': this.candidate.candidate_exam_id, 'status': 'paused'});
        }
    }

    /**
     * Resume live session, see @pause
     * @param automated
     */
    async resume(automated = false) {
        console.log('Resume triggered', automated);
        this.status = 'online';
        //await this.streamService.restartStream(this.candidate.candidate_exam_id, 'live');
        this.initCandidate(true);

        if (!automated) {
            this.apiService.setCandidateExamStatus(this.candidate.candidate_exam_id, 'live').subscribe();
            this.websocketService.sendMessage('live-status', {'candidate_exam_id': this.candidate.candidate_exam_id, 'status': 'online'});
        }
    }

    reconnect() {
        // Re register on websocket, and then reconnect video streams
        this.websocketService.registerUser(async () => {
            this.candidateInboundStream = null;
            await this.streamService.restartStream(this.candidate.candidate_exam_id, 'live');
            this.initCandidate();
            this.websocketService.sendMessage('start-live', {
                'candidate_exam_id': this.candidate.candidate_exam_id,
                'name': this.authService.userData.last_name + ' ' + this.authService.userData.first_name
            });
        });
    }

    setSuccess() {
        this.success = this.setResult === "successful" ? true : false;
    }

    close(automated = false) {
        this.status = 'end';

        try {
            this.streamService.endStream('live', this.candidate.candidate_exam_id);
            for(const key in this.inspectors) {
                this.streamService.endStream('inspector', key);
                this.streamService.endStream('admin', key);
            }
            this.applyStream('candidate', null);
        } catch(err) {
            console.error('Could not close stream properly', err);
        }

        // @TODO: Completely close all connections

        if (!automated) {
            // Notify others
            this.websocketService.sendMessage('live-status', {'candidate_exam_id': this.candidate.candidate_exam_id, 'status': 'end'});

            // Ask for the results
            const dialogRef = this.dialog.open(this.liveSessionResultDialog);

            // sets the new value
            this.setSuccess();

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    // @TODO: Store result stored in the this.success
                    console.log(this.success);
                    this.apiService.setLiveResult(this.candidate.candidate_exam_id, this.success).subscribe(() => {
                        this.toastr.success(this.candidate.user_id + ' felhasználó szóbeli vizsgájának eredménye: ' + (this.success ? 'Megfelelt' : 'Nem felelt meg'), 'Művelet végrehajtva.', {onActivateTick: true});
                        this.success = null;
                    });
                }
            });
        }
    }

    triggerOpenChat() {
        this.openChat.emit(this.candidate.candidate_exam_id);
    }
}
