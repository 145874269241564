<!--<aside></aside>-->
<div class="container">
    <div *ngIf="candidate">
        <p>
            <b>Azonosító:</b> {{ candidate.user_id }}<br>
            <b>Név:</b> {{ candidate.last_name }}  {{ candidate.first_name }}
        </p>
    </div>
    <hr>

    <ng-container *ngIf="tasks">
        <div *ngFor="let task of tasks; let i = index">
            <h5 style="display: inline-flex;">
                {{ task.order + 1 }}.&nbsp;<strong [innerHTML]="task.item.item_data.questions[0].multipleQuestion"></strong> <i>({{ task.task_id }})</i>
            </h5>

            <div class="answers-div">
                <div *ngFor="let answer of task.item.item_data.questions[0].answers; let k = index" >
                    <div class="form-check"  [ngClass]="{correct_answer:answer.multipleAnswerRadio}">
                        <input type="checkbox" class="form-check-input" id="exampleCheck - {{ k }}" [checked]="answerChecked(task.item.answer,k)" disabled>
                        <label class="form-check-label" for="exampleCheck - {{ k }}" [innerHTML]="answer.multipleAnswerText"></label>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!tasks">
        <p>Betöltés...</p>
    </ng-container>
</div>
