import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {ExamHttpApi} from "../../../http_apis/exam.http";
import {HttpClient} from "@angular/common/http";

@Component({
    moduleId: module.id,
    host: {
        id: "show-candidate-all-answers",
    },
    selector: "show-candidate-all-answers",
    templateUrl: "show-candidate-all-answers.component.html",
    styleUrls: ["show-candidate-all-answers.component.css"]
})


export class ShowCandidateAllAnswersComponent implements OnInit {

    tasks: any;
    candidate: any;
    examId: number;
    candidateExamId: number;
    examHttpApi: ExamHttpApi;

    constructor(
        private http: HttpClient,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.examHttpApi = new ExamHttpApi(http, "data");
        this.examHttpApi.setSelectedTaskUrl();

        this.activatedRoute.params.subscribe(({examId, candidateId}) => {
            this.examId = examId;
            this.candidateExamId = candidateId;
            this.loadTask();
        });
    }

    ngOnInit() {
        //
    }

    private loadTask() {
        this.examHttpApi.setCandidateAllAnswers(this.candidateExamId, this.examId).subscribe((results) => {
            this.tasks = results.tasks;
            this.candidate = results.candidate;

            console.log('results: ', results);

            console.log('this.candidate: ', this.candidate);
            console.log('this.tasks: ', this.tasks);


            if(results.candidateExam.is_finished !== 1){
                // @TODO: Do we need to handle something?
                //this.router.navigateByUrl(`/corrector/exam-answers`);
            }
        });
    }

    private answerChecked(answers, k){
        // answer_data.answers[0]
        return answers.some(elem => elem.answer_data.answers[0] === k);
    }
}
