
import {catchError, map} from 'rxjs/operators';
//tslint:disable max-file-line-count
import {Component, DoCheck, OnInit} from "@angular/core";
import {TaskHttpApi} from "../../http_apis/task.http";
import {ExamTaskModel} from "../../models/exam.model";
import {IExamTask} from "../../interfaces/exam_task.interface";
import {IExamAnswer} from "../../interfaces/exam_answer.interface";
import {ActivatedRoute, Router} from "@angular/router";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {FacetHttpApi} from "../../http_apis/facet.http";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {IExaminer} from "../../interfaces/examiner.interface";
import {isNullOrUndefined} from "util";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {ApiService} from "../../services/api.service";
import {EnvironmentService} from "../../services/environment.service";
import {MoveExamAnswersComponent} from "../move-exam-answers/move-exam-answers.component";
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";

@Component({
    moduleId: module.id,
    selector: "list",
    templateUrl: "list.component.html",
    styleUrls: ["list.component.css"]
})

export class ListComponent implements OnInit, DoCheck {
    private taskHttpApi: TaskHttpApi;
    private examHttpApi: ExamHttpApi;
    private facetHttpApi: FacetHttpApi;
    typeList: string;
    private isTesterStatus: boolean;
    list: Array<any>;
    examinerList: Array<any> = [];

    identityData: Array<any> = [];

    candidateList: Array<any> = []; // open exam tab

    tableHeaders: Array<string>;
    examListColumns: any;
    taskListColumns: any;
    examiners: Array<IExaminer>;
    inspectors: Array<IExaminer>;
    areExamsShown: Array<boolean>;
    areExamsIdentityShown: Array<boolean>;
    areModulesShown: Array<any>;
    areTasksShown: Array<any>;
    disabledCandidate: Array<any>;
    examData: Array<any>;
    isUserAdmin: boolean;
    isLoading: boolean = true;
    waitingForSdFile: boolean = false;

    fileCounter = [];
    taskAddArray = [];  // Checkboxes
    taskElementsArray = []; // Checkboxes

    pagination: any = {
        current_page: 1,
        last_page: 0,
    };
    matPagination = {
        'page': 0,
        'size': 10,
        'length': 0,
    };
    examTypes: any = {
        'live': $localize`:@@live:Éles`,
        'retake': $localize`:@@retake:Pótvizsga`,
        'pilot': $localize`:@@pilot:Pilot`,
        'online': $localize`:@@online:Online próbavizsga`,
        'local': $localize`:@@local:Helyi próbavizsga`,
        'test': $localize`:@@test:Teszt`,
    };
    taskTypes = {
        'short_answer': $localize`:@@short_answer:Rövid Válasz`,
        'pair': $localize`:@@pair:Párosítás`,
        'select': $localize`:@@select:Feleletválasztás`,
        'select_group': $localize`:@@select_group:Csoportosított Feleletválasztás`,
        'extend': $localize`:@@extend:Szöveg kiegészítés`,
        'writing': $localize`:@@writing:Szövegírás`,
        'form': $localize`:@@form:Formanyomtatvány`,
        'speaking': $localize`:@@speaking:Beszédkészség`,
    };
    filters: any = {
        id: {
            type: "int",
            value: null,
        },
        filename: {
            type: "string",
            value: null,
        },
        name: {
            type: "string",
            value: null,
        },
        created_at: {
            type: "string",
            value: null,
        },
        updated_at: {
            type: "string",
            value: null,
        },
        item_count: {
            type: "int",
            value: null,
        },
        language: {
            type: "string",
            value: null,
        },
        level: {
            type: "string",
            value: null,
        },
        exam_scheme_id: {
            type: "int",
            value: null,
        },
        format: {
            type: "string",
            value: null,
        },
        section: {
            type: "int",
            value: null,
        },
        question_text: {
            type: "string",
            value: null,
        }
    };
    examSchemes: any;

    constructor(private http: HttpClient,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                private apiService: ApiService,
                public environmentService: EnvironmentService,
                private authService: AuthService,
                private dialog: MatDialog) {

        this.taskHttpApi = new TaskHttpApi(http,  "data");
        this.taskHttpApi.setDefaultUrl();

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();

        this.facetHttpApi = new FacetHttpApi(http,  "data");
        this.facetHttpApi.setDefaultUrl();

        this.examListColumns = environment.examListColumns;
        this.taskListColumns = environment.taskColumns;
        this.activatedRoute.data.subscribe(({type, tableHeaders}) => {
            this.typeList = type;
            this.tableHeaders = tableHeaders;

            this.loadListData();
        });

        this.taskHttpApi.getSimplifiedList().subscribe((results) => {
            this.examSchemes = results;
        });
    }

    ngOnInit() {
        this.isUserAdmin = this.authService.$userStatus === "admin";
    }

    ngDoCheck() {
        this.isUserAdmin = this.authService.$userStatus === "admin";
    }

    getFilteredList() {
        if (!this.list) {
            return [];
        }

        return this.list;

        let filteredList = this.list.slice();

        Object.keys(this.filters).forEach((key) => {
            const filter = this.filters[key];

            if (filter.value === null || filter.value === 'null') {
                return [];
            }

            filteredList = filteredList.filter(
                (item) => {
                    if(key === 'question_text'){
                        if(!isNullOrUndefined(item.exam.items)){
                            if(item.exam.items.length > 0){
                                if(!isNullOrUndefined(item.exam.items[0].item_data)){
                                    return item.exam.items[0].item_data.toLowerCase().includes(filter.value.toLowerCase());
                                }
                            }
                        }
                    } else {
                        if (filter.type === "int") {
                            console.log('fi: ', key, item.exam[key], filter.value, item.exam[key] === filter.value);
                            return parseInt(item.exam[key]) === parseInt(filter.value);
                        }

                        if (filter.type === "string") {
                            return item.exam[key].toLowerCase().includes(filter.value.toLowerCase());
                        }

                        return true;
                    }
                }
            );
        });

        return filteredList;
    }

    checkModuleExaminers(examinerArray, part:string) {
        let re = this.authService.$userStatus === "admin";
        let userId = this.authService.$userId;

        let examiner = examinerArray.find((item => item.part === part));
        let examiner1 = !isNullOrUndefined(examiner) ? examiner.examiner_1 : null;
        let examiner2 = !isNullOrUndefined(examiner) ? examiner.examiner_2 : null;
        let examiner3 = !isNullOrUndefined(examiner) ? examiner.examiner_3 : null;

        if (!re) {
            if (examiner1 === userId || examiner2 === userId || examiner3 === userId) {
                re = true;
            }
        }

        return re;
    }

    private loadListData() {
        if (this.typeList === "tasks") {
            this.apiService.getTaskList(this.filters, this.matPagination).subscribe((data: any) => {
                data = data instanceof HttpResponse ? data.body : data; // @TODO Why is this necessary? Sometimes this sometimes that, eg after archive button
                const results = data.data;
                this.matPagination['length'] = data.meta.pagination.total;
                this.list = results.map((item) => {
                    let examModel = new ExamTaskModel(item);
                    return examModel.getExamConfig();
                });

            });
        } else if (this.typeList === "exams") {
            this.getExamList();
        } else if (this.typeList === "exam-answers") {
            this.examHttpApi.setDefaultUrl();

            this.getExamList();

            this.examHttpApi.getExaminers().subscribe((data) => {
                this.examiners = data;
            });

            this.examHttpApi.getInspectors().subscribe((data) => {
                this.inspectors = data;
            });

        } else if (this.typeList === "exam-modules") {
            this.apiService.getExamsWithFacets(this.filters, this.matPagination).subscribe((data:any) => {
                data = data instanceof HttpResponse ? data.body : data; // @TODO Why is this necessary? Sometimes this sometimes that, eg after archive button
                this.list = data.data;
                this.matPagination['length'] = data.total;

                this.areExamsShown = this.list.map(() => false);
                this.areExamsIdentityShown = this.list.map(() => false);
                this.examData = this.list.map(() => { return {}; });
                this.isTesterStatus = this.authService.$userStatus === "tester";
            });
        } else {
            // this.examHttpApi.read(undefined, undefined, true).subscribe((results: Array<any>) => {
            //     this.list = results;
            // });
            this.getExamList();
        }
    }

    private setPage(type: string){
        if(this.isLoading){
            return;
        }

        this.pagination.current_page = type === 'pre' ? this.pagination.current_page - 1 : this.pagination.current_page + 1;
        this.pagination.current_page = this.pagination.current_page < 1 ? 1 : this.pagination.current_page;

        this.getExamList();
    }

    private getExamList(){
        this.isLoading = true;
        this.apiService.getExamPage(this.matPagination).subscribe((data:any) => {
            data = data instanceof HttpResponse ? data.body : data; // @TODO Why is this necessary? Sometimes this sometimes that, eg after archive button
            const results = data.data;
            this.matPagination['length'] = data.meta.pagination.total;

            this.isTesterStatus = this.authService.$userStatus === "tester"; // @TODO: Why do this here??
            this.list = results;

            this.areExamsShown = this.list.map(() => false);
            this.areExamsIdentityShown = this.list.map(() => false);

            this.examData = this.list.map(() => {
                return {};
            });
            this.isLoading = false;
        });
    }

    private getExamData(index: number, examId: number) {
        this.examHttpApi.getExamData(examId).subscribe((data) => {
            this.examData[index] = data;

            this.list.forEach((exam) => {
                this.examinerList[exam.id] = data.examiners;
            });

            this.candidateList[examId] = data.candidates_list;

            console.log(this.candidateList);

            this.fillAreTaskShownArray();
        });
    }

    private fillAreTaskShownArray() {
        this.areTasksShown = this.examData.map((exam) => {
            let elementContainer = {
                writing: [],
                speaking: [],
                reading: [],
                listening: []
            };

            if (!isNullOrUndefined(exam.writing)) {
                exam.writing.map(() => {
                    elementContainer.writing.push(false);
                });
            }
            if (!isNullOrUndefined(exam.speaking)) {
                exam.speaking.map(() => {
                    elementContainer.speaking.push(false);
                });
            }
            if (!isNullOrUndefined(exam.reading)) {
                exam.reading.map(() => {
                    elementContainer.reading.push(false);
                });
            }
            if (!isNullOrUndefined(exam.listening)) {
                exam.listening.map(() => {
                    elementContainer.listening.push(false);
                });
            }

            return elementContainer;
        });
    }

    private toggleExams(index: number) {
        this.areExamsShown[index] = !this.areExamsShown[index];
    }

    private toggleExamsIdentity(index: number) {
        this.areExamsIdentityShown[index] = !this.areExamsIdentityShown[index];
    }

    openFilesOnNewPage(value: any, part?: string, duplicate?: boolean) {
        console.log('open: ', value, part, duplicate);
        let examUrl = environment.examType === 'mmk' ? `edit-mmk-exam/${value}` : `edit-exam/${value}`;

        if (value !== null && typeof value === "string") {
            if(duplicate){
                this.router.navigateByUrl(`exam-task-creator/${value}/duplicate`);
            } else {
                this.router.navigateByUrl(`exam-task-creator/${value}`);
            }
        } else if (typeof value === "number") {
            if (part) {
                window.open(`analysis/edit-analysis/${value}-${part}`);
            } else {
                let examUrl = environment.examType === 'mmk' ? `edit-mmk-exam/${value}` : `edit-exam/${value}`;
                this.router.navigateByUrl(examUrl);
            }
        }
    }

    openResultsOnNewPage(value: number, part: string) {
        if (!isNullOrUndefined(value) && !isNullOrUndefined(part)) {
            window.open(`analysis/results/${value}-${part}`);
        }
    }

    onClickGenerateSdFile(examId: number, part: string, partNum: number, examIndex: number) {
        this.waitingForSdFile= true;
        this.facetHttpApi.createInputFile(examId, part).subscribe((results) => {
            console.log(results);
            if(results.result === 'empty_examiner_nyat_number'){
                alert('Az egyik javítónak hiányzik a NYAT száma!');
            } else {
                this.list[examIndex].files++;
                this.increaseFileCounter(examId, partNum);
                this.openFilesOnNewPage(examId, part);
            }

            this.waitingForSdFile = false;
        });
    }

    increaseFileCounter(examId: number, partNum: number) {
        this.fileCounter[examId][partNum].files++;
    }

    navigateToTaskCorrector(taskId: number, candidateExamId: number) {
        window.open(`corrector/edit-exam-answer/${taskId}-${candidateExamId}`);
    }

    loadExamData(index: number, examId: number) {
        this.toggleExams(index);

        const currentExam = this.examData[index];

        if (Object.keys(currentExam).length === 0) {
            this.getExamData(index, examId);
        }

        this.areModulesShown = this.examData.map(() => {
            return {
                writing: false,
                speaking: false,
                reading: false,
                listening: false
            };
        });
    }

    loadExamIdentityData(index: number, examId: number, open: boolean) {
        if(!this.areExamsIdentityShown[index]){
            this.getExamIdentityData(index, examId, open);
        } else {
            this.areExamsIdentityShown[index] = false;
        }
    }

    private getExamIdentityData(index: number, examId: number, open: boolean) {
        this.examHttpApi.getExamIdentityData(examId).subscribe((data) => {
            this.identityData[index] = data;
            if(open){
                this.toggleExamsIdentity(index);
            }
        });
    }

    toggleModules(index: number, type: string) {
        this.areModulesShown[index][type] = !this.areModulesShown[index][type];
    }

    toggleTasks(index: number, innerIndex: number, type: string) {
        this.areTasksShown[index][type][innerIndex] = !this.areTasksShown[index][type][innerIndex];
    }

    subsToGetNumOfGeneratedFiles(examId: number) {
        this.getNumOfGeneratedFiles(examId).subscribe();
    }

    getNumOfGeneratedFiles(examId: number) {
        let url = `${environment.apiPrefixUrl}exam/getCountFiles/${examId}`;
        let requestOptions = RestConfig.getReqOpt();

        return this.http.get(url, requestOptions).pipe(
            map((response:any) => {
                return this.fileCounter[examId] = response;
            }));
    }


    /**
     * Create and store excel file on the server
     * @param examId
     * @param part
     * @returns {Subscription}
     */
    createPostExcel(examId: number, part: string) {
        let url = `${environment.apiPrefixUrl}export/insteadOfFacet`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: examId,
            part: part
        };

        return this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let jsonData = response;
            this.getDownloadFile(jsonData.file);
        })).subscribe();
    }

    /**
     * Download xls file
     * @param file
     */
    private getDownloadFile(file) {
        let token = window.localStorage.getItem("itolc_token");
        let url = `${environment.apiPrefixUrl}export/download/${file}&token=${token}`;

        window.open(url);
    }

    saveExaminerRow(examId: number, part: string){
        let url = `${environment.apiPrefixUrl}exam/addExaminers`;
        let requestOptions = RestConfig.getReqOpt();
        let body = {
            exam_id: examId,
            part: part,
            examiners: this.examinerList[examId][part],
        };

        if(isNullOrUndefined(this.examinerList[examId][part])){
            return;
        }

        let correct = this.examinerList[examId][part].map((item) => {
            isNullOrUndefined(item.examiner_1) || isNullOrUndefined(item.examiner_2);
        });

        if(correct.some(x => x === true)){
            alert('Vizsgáztató hozzáadásához minden mező kitöltése kötelező!');
            return;
        }

        return this.http.post(url, body, requestOptions).pipe(map((response:any) => {
            let jsonData = response;
            if(jsonData.success){
                alert('Sikeres hozzárendelés!');
            }
        })).subscribe();
    }

    addNewExaminer(examId: number, part: string){
        let index = !isNullOrUndefined(this.examinerList[examId][part]) ? this.examinerList[examId][part].length : 0;

        if(index > 0){
            this.examinerList[examId][part][index] = {
                model_id: null,
                part: part,
                examiner_1: null,
                examiner_2: null,
                examiner_3: null,
                candidates: []
            };
        } else {
            this.examinerList[examId][part] = [{
                model_id: null,
                part: part,
                examiner_1: null,
                examiner_2: null,
                examiner_3: null,
                candidates: []
            }];
        }
    }

    setSelectDisabled(examID, part, candidateID){
        let selectedList = this.examinerList[examID][part];

        return selectedList.some((item) => {
            return item.candidates.some((item => item === candidateID));
        });
    }

    aggregatedResults(examID){
        this.facetHttpApi.aggregatedResults(examID).subscribe((results) => {
            console.log(results);

            if(results){
                let token = window.localStorage.getItem("itolc_token");
                let url = `${environment.apiPrefixUrl}getDownloadFacetsXLS/${results.file}&token=${token}`;

                window.open(url);
            }
        });
    }

    changeCheckbox(element: HTMLInputElement): void {
        // `Checkbox ${element.value} was ${element.checked ? '' : 'un'}checked\n`;

        if(element.checked){
            this.taskAddArray.push(element.value);
            this.taskElementsArray.push(element);
        } else {
            let index: number = this.taskAddArray.indexOf(element.value);
            if (index !== -1) {
                this.taskAddArray.splice(index, 1);
            }
        }
    }

    selectAllCheckbox(element: HTMLInputElement): void {
        if(element.checked){
            this.list.forEach((element) => {

                this.taskAddArray.push(element.exam.id);

                let htmlElement = document.getElementById(element.exam.id);
                this.taskElementsArray.push(htmlElement);

                this.setCheckedAll();
            });
        } else {
            this.uncheckAll();
        }
    }

    setCheckedAll(){
        this.taskElementsArray.forEach((element) => {
            element.checked = true;
        });
    }

    uncheckAll(){
        this.taskElementsArray.forEach((element) => {
            element.checked = false;
        });

        this.taskElementsArray = [];
        this.taskAddArray = [];
    }

    /**
     * Export selected tasks
     */
    exportTask(){
        this.apiService.downloadTasksExport(this.taskAddArray, this.uncheckAll.bind(this));
    }

    matPaginate(event){
        this.matPagination['page'] = event.pageIndex;
        this.matPagination['size'] = event.pageSize;
        this.loadListData();
    }


    /**
     * Open move exam answers popup
     * @param examId
     * @param part
     * @returns {Subscription}
     */
    openMoveExamAnswersPopup(examId: number, part: string) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
            examId: examId,
            part: part
        };

        this.dialog.open(MoveExamAnswersComponent, dialogConfig);
    }

    /**
     * @param id
     * @param part
     */
    excelExport(id: number, part: string) {
        console.log('excelExport', id, part);
        this.apiService.excelExport([id], part);
    }
}
