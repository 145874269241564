import { Routes } from "@angular/router";
import { CopywritingComponent } from "../../components/copywriting/copywriting.component";
import { FormCreatorComponent } from "../../components/form-creator/form_creator.component";
import { CompletionComponent } from "../../components/completion/completion.component";
import { ShortAnswerComponent } from "../../components/short-answer/short-answer.component";
import { PairingComponent } from "../../components/pairing/pairing.component";
import { SpeakingSkillsComponent } from "../../components/speaking-skills/speaking-skills.component";
import { MultipleChoiceComponent } from "../../components/multiple-choice/multiple-choice.component";
import { MultipleChoiceGroupsComponent } from "../../components/multiple-choice-groups/multiple-choice-groups.component";
export const TASK_ROUTES: Routes = [{
    path: "copy-writer-task",
    component: CopywritingComponent
},{
    path: "copy-writer-task/:id",
    component: CopywritingComponent
},{
    path: "copy-writer-task/:id/:duplicate",
    component: CopywritingComponent
},{
    path: "form-creator-task",
    component: FormCreatorComponent
},{
    path: "form-creator-task/:id",
    component: FormCreatorComponent
},{
    path: "form-creator-task/:id/:duplicate",
    component: FormCreatorComponent
},{
    path: "completion-reading-task",
    component: CompletionComponent,
    data: {
        type: "reading"
    }
},{
    path: "completion-reading-task/:id",
    component: CompletionComponent,
    data: {
        type: "reading"
    }
},{
    path: "completion-reading-task/:id/:duplicate",
    component: CompletionComponent,
    data: {
        type: "reading"
    }
},{
    path: "completion-listening-task",
    component: CompletionComponent,
    data: {
        type: "listening"
    }
},{
    path: "completion-listening-task/:id",
    component: CompletionComponent,
    data: {
        type: "listening"
    }
},{
    path: "completion-listening-task/:id/:duplicate",
    component: CompletionComponent,
    data: {
        type: "listening"
    }
},{
    path: "short-answer-reading-task",
    component: ShortAnswerComponent,
    data: {
        type: "reading"
    }
},{
    path: "short-answer-reading-task/:id",
    component: ShortAnswerComponent,
    data: {
        type: "reading"
    }
},{
    path: "short-answer-reading-task/:id/:duplicate",
    component: ShortAnswerComponent,
    data: {
        type: "reading"
    }
},{
    path: "short-answer-listening-task",
    component: ShortAnswerComponent,
    data: {
        type: "listening"
    }
},{
    path: "short-answer-listening-task/:id",
    component: ShortAnswerComponent,
    data: {
        type: "listening"
    }
},{
    path: "short-answer-listening-task/:id/:duplicate",
    component: ShortAnswerComponent,
    data: {
        type: "listening"
    }
},{
    path: "pair-reading-task",
    component: PairingComponent,
    data: {
        type: "reading"
    }
},{
    path: "pair-reading-task/:id",
    component: PairingComponent,
    data: {
        type: "reading"
    }
},{
    path: "pair-reading-task/:id/:duplicate",
    component: PairingComponent,
    data: {
        type: "reading"
    }
},{
    path: "pair-listening-task",
    component: PairingComponent,
    data: {
        type: "listening"
    }
},{
    path: "pair-listening-task/:id",
    component: PairingComponent,
    data: {
        type: "listening"
    }
},{
    path: "pair-listening-task/:id/:duplicate",
    component: PairingComponent,
    data: {
        type: "listening"
    }
},{
    path: "speaking-skills-task",
    component: SpeakingSkillsComponent
},{
    path: "speaking-skills-task/:id/:duplicate",
    component: SpeakingSkillsComponent
},{
    path: "speaking-skills-task/:id",
    component: SpeakingSkillsComponent
},{
    path: "multiple-choice-reading-task",
    component: MultipleChoiceComponent,
    data: {
        type: "reading"
    }
},{
    path: "multiple-choice-reading-task/:id",
    component: MultipleChoiceComponent,
    data: {
        type: "reading"
    }
},{
    path: "multiple-choice-reading-task/:id/:duplicate",
    component: MultipleChoiceComponent,
    data: {
        type: "reading"
    }
},{
    path: "multiple-choice-listening-task",
    component: MultipleChoiceComponent,
    data: {
        type: "listening"
    }
},{
    path: "multiple-choice-listening-task/:id",
    component: MultipleChoiceComponent,
    data: {
        type: "listening"
    }
},{
    path: "multiple-choice-listening-task/:id/:duplicate",
    component: MultipleChoiceComponent,
    data: {
        type: "listening"
    }
},{
    path: "multiple-choice-groups-listening-task",
    component: MultipleChoiceGroupsComponent,
    data: {
        type: "listening"
    }
},{
    path: "multiple-choice-groups-listening-task/:id",
    component: MultipleChoiceGroupsComponent,
    data: {
        type: "listening"
    }
},{
    path: "multiple-choice-groups-listening-task/:id/:duplicate",
    component: MultipleChoiceGroupsComponent,
    data: {
        type: "listening"
    }
}];
