<div class="live-test">
    <h2>Szóbeli vizsgáztatás</h2>

    <div class="live-session">
        <p>Status: {{status}}</p>

        <div class="offline" [hidden]="status !== 'offline'">
            <p>A szóbeli vizsga megkezdéséhez kattintson a 'Szóbeli vizsga indítása' gombra valamely ütemezett szóbeli vizsgánál.</p>
        </div>

        <div class="connecting" [hidden]="status !== 'connecting'">
            <p>Az élő kapcsolat kiépítése folyamatban van...</p>
        </div>

        <div class="online" [hidden]="status !== 'online' && status !== 'paused'">
            <p>Csatlakozva szóbeli vizsgához.</p>
            <a class="btn btn-small btn-default"
               (click)="reconnect()" role="button">
                Újracsatlakozás
            </a>
            <a class="btn btn-small btn-default"
                *ngIf="status === 'online'"
               (click)="pause()" role="button"
               title="A vizsgázó átmenetileg le lesz csatlakoztatva, így a felügyelőknek lehetőségük van egyeztetni álláspontjaikat." i18n-title>
                Vizsgázó várakoztatása
            </a>
            <a class="btn btn-small btn-default"
                *ngIf="status === 'paused'"
               (click)="resume()" role="button">
                Vizsgázó visszacsatlakoztatása
            </a>

            <a class="btn btn-small btn-default"
               (click)="close()" role="button">
                Vizsga lezárása és eredmény megadása
            </a>

            <a class="btn btn-small btn-default"
               (click)="triggerOpenChat()" role="button">
                Chat
            </a>

            <table class="table webcam-table">
                <thead>
                    <tr>
                        <th>Vizsgázó<span *ngIf="candidate !== null">: {{candidate.first_name + ' ' + candidate.last_name}} ({{candidate.user_id}})</span></th>
                        <th>Saját webkamera</th>
                        <th *ngFor="let inspector of inspectors | keyvalue">{{inspector.value.name}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><video [id]="'live-exam-candidate-video'" playsinline autoplay controls></video></td>
                        <td><video [id]="'live-exam-my-video'" playsinline autoplay controls muted></video></td>
                        <td *ngFor="let inspector of inspectors | keyvalue" #inspectorVideo>
                            <video [id]="'live-exam-inspector-video-' + inspector.key" playsinline autoplay controls muted></video>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

   <ng-template #liveSessionResultDialog>
      <h2 matDialogTitle>A szóbeli vizsga eredménye</h2>
      <mat-dialog-content>
          <mat-radio-group required [(ngModel)]="setResult" (ngModelChange)="setSuccess()" [ngModelOptions]="{standalone: true}" arial-label="A {{candidate.user_id}} felhasználóhoz tartozó szóbeli vizsga eredmény megadása:">
              <mat-radio-button name="setLiveResult" value="successful">Sikeres vizsga</mat-radio-button><br />
              <mat-radio-button name="setLiveResult" value="failed">Sikertelen vizsga</mat-radio-button>
          </mat-radio-group>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose color="primary">Bezárás eredmény megadás nélkül</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Mentés</button>
      </mat-dialog-actions>
    </ng-template>
</div>
