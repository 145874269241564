import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";

@Component({
    moduleId: module.id,
    selector: "analysis-group",
    templateUrl: "./analysis-group.component.html",
    styleUrls: ["analysis-group.component.css"],
})
/**
 * Groupped exam analysis for analysing multiple exams at once
 */
export class AnalysisGroupComponent implements OnInit {

    @ViewChild('newGroupDialog') newGroupDialog: TemplateRef<any>;

    facets = [];
    availableExams = [];

    newGroup = {
        'exams': [],
        'part': null,
    };

    matPagination = {
        'page': 0,
        'size': 10,
        'length': 0,
    };

    constructor(
        private dialog: MatDialog,
        private apiService: ApiService,
        private toastr: ToastrService,
    ) {

    }

    ngOnInit(): void {
        // Fetch available exams
        this.apiService.getExamsForDropDownList().subscribe((data) => {
            this.availableExams = data.data.slice(0, 100).map((exam) => {   // All exams would kill our UI
                return {'value': exam.id, 'display': exam.id + ' (' + exam.level + ', ' + exam.language + ', ' + exam.date + ')'};
            });
        });

        this.loadFacetGroups();
    }

    /**
     * Load list
     */
    loadFacetGroups() {
        this.apiService.getFacetsGroups(this.matPagination).subscribe((data:any) => {
            this.facets = data.data;
            this.matPagination['length'] = data.total;
        });
    }

    openGroupOnNewPage(id:number) {
        window.open(`analysis/edit-analysis/group-` + id);
    }

    /**
     * Navigate between pages by mat pagination
     * @param event
     */
    matPaginate(event){
        this.matPagination['page'] = event.pageIndex;
        this.matPagination['size'] = event.pageSize;
        this.loadFacetGroups();
    }

    /**
     * Display new group dialog, and send it to api
     */
    createGroup() {
        const dialogRef = this.dialog.open(this.newGroupDialog);

        dialogRef.afterClosed().subscribe(result => {
            if (!result) {
                return;
            }
            this.apiService.newFacetsGroup(this.newGroup).subscribe(() => {
                this.toastr.success('Az új Facets group létrehozásra került.', 'Művelet végrehajtva.', {onActivateTick: true});
            });
        });
    }


    excelExport(key: number) {
        if (this.facets[key] === undefined) {
            this.toastr.warning('Hibás exam id', 'Művelet sikertelen.', {onActivateTick: true});
            return;
        }

        const examIds = this.facets[key].map((exam) => {
            return exam.exam_id;
        });

        const part = this.facets[key][0].part;
        this.apiService.excelExport(examIds, part);
    }
}
