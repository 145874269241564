
import {finalize, map} from 'rxjs/operators';
import { EventEmitter, Injectable, Output, Directive } from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {TaskHttpApi} from "../../http_apis/task.http";
import {ItemHttpApi} from "../../http_apis/item.http";
import {MMK_TASK_TYPES, TASK_CONFIGS, TASK_TYPES} from "./task_configs";
import {RestConfig} from "../../http_apis/rest_config.http";
import {isNullOrUndefined} from "util";
import {HttpClient} from "@angular/common/http";
import {EnvironmentService} from "../environment.service";

@Directive()
@Injectable()
export class ExamCreatorFormService {

    taskId: number;
    previousNumber: number;
    getFileName: string;
    submittedResult: string = null;
    isDuplicate: boolean = false;
    examCreatorForm: FormGroup;
    examGroup: FormGroup;
    taskHttpApi: TaskHttpApi;
    itemHttpApi: ItemHttpApi;
    createdTask: EventEmitter<number> = new EventEmitter();
    generatedTaskNumber: EventEmitter<Array<any>> = new EventEmitter();
    selectedCurrentTaskForm: EventEmitter<any> = new EventEmitter();
    registryFormSend: EventEmitter<any> = new EventEmitter();
    requiredRegistryField: EventEmitter<boolean> = new EventEmitter();
    private fileName: FormControl = new FormControl();
    private examLevel: FormControl = new FormControl(null, Validators.required);
    private examType: FormControl = new FormControl(null, Validators.required);
    private langControl: FormControl = new FormControl(null, Validators.required);
    private taskNumber: FormControl = new FormControl({value: null, disabled: true});
    private taskDataControl: FormControl = new FormControl();
    taskRouteUrl: string = null;

    @Output() open: EventEmitter<any> = new EventEmitter();

    constructor(private formBuilder: FormBuilder,
                private environmentService: EnvironmentService,
                private http: HttpClient
    ) {

        this.taskHttpApi = new TaskHttpApi(http);
        this.taskHttpApi.setDefaultUrl();
        this.itemHttpApi = new ItemHttpApi(http);
        this.itemHttpApi.setDefaultUrl();

        this.examGroup = new FormGroup({
            level: this.examLevel,
            part: this.examType,
            number: this.taskNumber
        });

        this.examCreatorForm = formBuilder.group({
            examGroup: this.examGroup
        });

        this.registerControls();

        this.subscribeValueChanges();

        this.isDuplicate = false;
    }

    get $examCreatorFrom(): FormGroup {
        return this.examCreatorForm;
    }

    get $examGroup(): FormGroup {
        return this.examGroup;
    }

    get $isDuplicate(): boolean {
        return this.isDuplicate;
    }

    set $isDuplicate(value: boolean) {
        this.isDuplicate = value;
    }

    private registerControls() {
        let itemsGroup: FormGroup = new FormGroup({
            id: new FormControl(null),
            item_data: new FormControl(null),
            order: new FormControl(1),
            exam_answer: new FormControl(null),
        });

        this.examCreatorForm.registerControl("filename", this.fileName);
        this.examCreatorForm.registerControl("view_date", new FormControl(null));
        this.examCreatorForm.registerControl("name", new FormControl(null, Validators.required));
        this.examCreatorForm.registerControl("source", new FormControl(null));
        this.examCreatorForm.registerControl("comment", new FormControl(null));
        this.examCreatorForm.registerControl("word_count", new FormControl(null));
        this.examCreatorForm.registerControl("source_type", new FormControl(null));
        this.examCreatorForm.registerControl("item_count", new FormControl(null, Validators.required));
        this.examCreatorForm.registerControl("language", this.langControl);
        this.examCreatorForm.registerControl("task_data", this.taskDataControl);
        this.examCreatorForm.registerControl("items", new FormArray([itemsGroup]));
        this.examCreatorForm.registerControl("type", new FormControl(null));
        this.examCreatorForm.registerControl("instruction", new FormControl(null, Validators.required));
        this.examCreatorForm.registerControl("topic", new FormControl(null));
        this.examCreatorForm.registerControl("text_breed", new FormControl(null));
        if (this.environmentService.get('text_source_id', 'taskFormColumns')) {
            this.examCreatorForm.registerControl("text_source_id", new FormControl(null));
        }
        this.examCreatorForm.registerControl("format_code_id", new FormControl(null));
        this.examCreatorForm.registerControl("number", new FormControl(0));
        this.examCreatorForm.registerControl("level", new FormControl(this.examLevel.value));
        this.examCreatorForm.registerControl("part", new FormControl(this.examType.value));
        this.examCreatorForm.registerControl("exam_scheme_id", new FormControl(null));
        this.examCreatorForm.registerControl("section", new FormControl(null));
        this.examCreatorForm.registerControl("format", new FormControl(null));

        this.formDefaults();
    }

    private subscribeValueChanges() {
        // console.log('examCreatorForm language value', this.examCreatorForm.controls['language'].value);

        let previousLevelValue, previousPartValue;

        // Predefined level in some cases
        let FixLevel = null;
        if(this.environmentService.get('examType') === 'mmk'){
            FixLevel = 'B2';
            this.examCreatorForm.controls['language'].setValue('English');
        }

        // tslint:disable-next-line:variable-name
        this.examGroup.valueChanges.subscribe(({level, part}) => {
            // console.log('examCreatorForm language value', this.examCreatorForm.controls['language'].value);
            if (FixLevel !== null) {
                level = FixLevel;
            }

            if (previousLevelValue && previousPartValue) {
                if (previousLevelValue !== level || previousPartValue !== part) {
                    this.taskNumber.setValue(null, {emitEvent: false, onlySelf: true});
                }
            }

            if (level !== null && part !== null) {
                this.generateTaskNumberOptions(TASK_CONFIGS[`${part}_${level}`]);

                previousLevelValue = level;
                previousPartValue = part;

                if (this.examGroup.get("number").value === null) {
                    this.examGroup.patchValue({
                        number: 1
                    });
                } else {
                    this.setFilenameValue();
                }
            }
        });

        if(this.environmentService.get('examType') === 'mmk'){
            this.examCreatorForm.controls['format'].valueChanges.subscribe(({format}) => {

                if(isNullOrUndefined(format)){
                    format = this.examCreatorForm.get('format').value;

                }

                if (format !== null) {
                    let types = <Array<any>> MMK_TASK_TYPES;
                    let match = types.filter((item) => item.value === format);

                    if (match[0]) {
                        let part = match[0].main;
                        let routeUrl = match[0].url;
                        let subType = match[0].subType;
                        routeUrl = this.taskId ? routeUrl + "/" + this.taskId : routeUrl;

                        if(this.isDuplicate){
                            routeUrl += '/duplicate';
                        }

                        this.taskRouteUrl = routeUrl;
                        this.selectedCurrentTaskForm.emit(routeUrl);
                        this.examGroup.controls['part'].setValue(part);

                        // FORMAT
                        this.examCreatorForm.controls['type'].setValue(subType);

                        // console.log('examCreatorForm.controls[\'format\'].valueChanges', part, subType, routeUrl, this.examGroup.controls['level']);
                    }
                }
            });
        } else {
            this.examCreatorForm.valueChanges.subscribe(({type}) => {
                if (type !== null) {
                    let types;

                    if (this.examType.value !== null) {
                        types = <Array<any>> TASK_TYPES[this.examType.value];
                    }

                    let match = types.filter((item) => item.value === type);
                    if (match[0]) {
                        let routeUrl = match[0].url;
                        routeUrl = this.taskId ? routeUrl + "/" + this.taskId : routeUrl;

                        if(this.isDuplicate){
                            routeUrl += '/duplicate';
                        }

                        this.selectedCurrentTaskForm.emit(routeUrl);
                    }
                }
            });
        }

        // This should be redundant...
        //this.langControl.valueChanges.subscribe(() => {
        //    this.setFilenameValue();
        //});

        // console.log('examCreatorForm language value', this.examCreatorForm.controls['language'].value);
    }

    private generateTaskNumberOptions(currentTasks: Array<any>) {
        let numbers = [];
        let length = currentTasks.length;

        for (let num = 1; num <= length; num++) {
            numbers.push({
                value: num,
                text: `${num}`
            });
        }

        this.generatedTaskNumber.emit(numbers);

        if (this.taskNumber.disabled) {
            this.taskNumber.enable({emitEvent: false, onlySelf: true});
        }
    }

    private setFilenameValue() {
        // tslint:disable-next-line:variable-name
        let {level, part, number} = this.examGroup.value;
        let language = this.examCreatorForm.get("language").value;

        if (level !== null && language !== null && part !== null && number === null) {
            number = 1;
        }

        if (level !== null && language !== null && part !== null && number !== null) {
            if (isNullOrUndefined(this.fileName.value)) {
                this.taskHttpApi.generateTaskName(level, part, language, number).subscribe((result) => {
                    if (!isNullOrUndefined(this.previousNumber)) {
                        this.previousNumber = number;
                    }
                    this.fileName.setValue(result);
                });
            }
        }
        // console.log('examCreatorForm language value', this.examCreatorForm.controls['language'].value);
    }

    setTaskDataControlValue(value: string) {
        // console.log('examCreatorForm language value', this.examCreatorForm.controls['language'].value);
        this.taskDataControl.setValue(value, {emitEvent: false});
    }

    getTaskDataValue() {
        return JSON.parse(this.taskDataControl.value);
    }

    getItemsValues() {
        let formArray: FormArray = <FormArray> this.examCreatorForm.get("items");
        let itemValues;

        if (formArray !== null) {
            itemValues = formArray.controls.map((controls) =>
                JSON.parse(controls.get("item_data").value)
            );
        }

        return itemValues;
    }

    // tslint:disable-next-line:variable-name
    setExamObject({level, number, part, id, exam_scheme_id, format}) {
        this.taskId = id;
        this.examGroup.patchValue({number, part, level, exam_scheme_id, format});
        console.log('examCreatorForm language value', this.examCreatorForm.controls['language'].value);
    }

    setExistInstructionControl(value) {
        this.examCreatorForm.setControl("instruction", new FormControl(value, Validators.required));
    }

    setPartControl(value) {
        this.examCreatorForm.controls['part'].setValue(value);
        // this.examCreatorForm.setControl("part", new FormControl(value, Validators.required));
    }

    setExamSchemeControl(value) {
        this.examCreatorForm.controls['exam_scheme_id'].setValue(value);
    }

    setSectionControl(value) {
        // this.examCreatorForm.setControl("section", new FormControl(value));
        this.examCreatorForm.controls['section'].setValue(value);
    }
    resetForm() {
        delete this.submittedResult;
        delete this.taskId;
        delete this.previousNumber;

        this.examCreatorForm.reset({value: null}, {emitEvent: false});
        this.examGroup.reset({value: null}, {emitEvent: false});
        this.isDuplicate = false;

        this.formDefaults();
    }

    /**
     * Set default values for our form
     */
    formDefaults() {
        console.log('Setting default form values');
        if(this.environmentService.get('examType') === 'mmk'){
            this.examCreatorForm.controls['level'].setValue('B2');
            this.examCreatorForm.controls['language'].setValue('English');
            this.examCreatorForm.controls['name'].setValue('Task');
            this.examGroup.controls['level'].setValue('B2');
        }
    }

    /**
     * Check if the form is invalid, if not redirect to the main form
     */
    backToMainForm() {
        if (!this.examGroup.valid) {
            console.log('Form is invalid, fall back to main', this.findInvalidControls());
            location.href = "/exam-task-creator";
        }
    }

    public findInvalidControls() {
        const invalid = [];
        const controls = this.examGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                console.log('Invalid form value', name, controls[name].value);
                invalid.push(name);
            }
        }
        return invalid;
    }

    onPreviewTask(itemsArray?: any) {
        const examPart = (this.examType.value) ? this.examType.value : null;
        const examLevel = (this.examLevel.value) ? this.examLevel.value : null;
        const type = (this.examCreatorForm.get("type").value) ? this.examCreatorForm.get("type").value : null;

        let items = (itemsArray) ? itemsArray : null;
        if (type === "writing") {
            items = [{
                id: 1,
                task_id: this.taskId,
                order: 1,
                item_data: null,
                exam_answer: null
            }];
        }

        // const task = (this.examCreatorForm.value) ? this.examCreatorForm.value : null;
        const task = {
            created_at: null,
            filename: this.examCreatorForm.value.filename,
            id: this.taskId,
            instruction: this.examCreatorForm.value.instruction,
            item_count: this.examCreatorForm.value.item_count,
            items: items,
            language: this.examCreatorForm.get("language").value,
            level: examLevel,
            name: this.examCreatorForm.value.name,
            number: 1,
            part: this.examCreatorForm.value.part,
            comment: this.examCreatorForm.value.comment,
            source: this.examCreatorForm.value.source,
            source_type: this.examCreatorForm.value.source_type,
            task_data: this.examCreatorForm.value.task_data,
            text_breed: this.examCreatorForm.value.text_breed,
            format_code_id: this.examCreatorForm.value.format_code_id,
            text_source_id: this.examCreatorForm.value.text_source_id,
            topic: this.examCreatorForm.value.topic,
            type: this.examCreatorForm.value.type,
            updated_at: null,
            user_id: 1,
            view_date: this.examCreatorForm.value.view_date,
            word_count: this.examCreatorForm.value.word_count
        };

        const examTask = {
            id: this.taskId,
            exam_id: 1,
            task_id: this.taskId,
            part: examPart,
            order: 0,
            suborder: null,
            examiner1: null,
            examiner2: null,
            created_at: null,
            updated_at: null,
            task: task
        };

        const candidateExam = {
            id: 1,
            candidate_id: 1,
            exam_id: 1,
            is_finished: 0,
            writing: null,
            reading: null,
            listening: null,
            speaking: null,
            last_task_id: null,
            on_part_end: 0,
            updated_at: null,
            created_at: null,
        };

        const data = {
            data: {
                id: 1,
                language: this.examCreatorForm.get("language").value,
                level: examLevel,
                place: null,
                date: "2017-11-30 20:00:00",
                examTasks: [examTask],
                candidateExam: candidateExam,
                task_id: this.taskId,
                examPart: examPart,
                type: type
            }
        };

        let postUrl = `${this.environmentService.get('apiPrefixUrl')}preview-task`;
        let requestOptions = RestConfig.getReqOpt();

        const jsonData = {data: data};
        this.http.post(postUrl, jsonData, requestOptions).pipe(
            map((response: any) => {
                this.getFileName = response ? response.file_name : null;
            }),
            finalize(() => {
                return window.open(
                    this.environmentService.get('apiPrefixUrlCandidate') + "preview-task?file_name=" + this.getFileName,
                    "PreviewTask",
                    "width=1300,height=700,left=100,top=100,resizable=yes,scrollbars=yes");
            }),).subscribe();

        return true;
    }

    async insertTask(taskArray, itemsArray, isDuplicate){
        console.log('examCreatorFormService.insertTask ');

        await this.taskHttpApi.insert(taskArray, true).toPromise().then((result) => {
            if (result.id) {
                this.taskId = result.id;
            }
        });

        await this.insertItem(itemsArray, isDuplicate);

        return this.taskId;
    }

    async updateTask(taskArray, itemsArray){
        console.log('examCreatorFormService.updateTask ');
        await this.taskHttpApi.update(taskArray, this.taskId, true).subscribe((result) => {});

        return await this.insertItem(itemsArray, false);
    }

    async insertItem(itemsArray, isDuplicate){
        console.log('examCreatorFormService.insertItem ', isDuplicate);

        for(let index = 0; index < itemsArray.value.length; index++) {
            let item = await itemsArray.value[index];

            item.task_id = this.taskId;
            if (item.id && !isDuplicate) {
                let id = item.id;
                delete item.id;
                await this.itemHttpApi.update(item, id, true).toPromise().then(() => {
                    if (itemsArray.value.length === index + 1) {
                        return true;
                    }
                    console.log('update item');
                });
            } else {
                await this.itemHttpApi.insert(item, true).toPromise().then(() => {
                    if (itemsArray.value.length === index + 1) {
                        return true;
                    }
                    console.log('insert item');
                });
            }

            console.log('itemsArray item END');
        }
    }

    getTaskUrl(taskId){
        let routeUrl = null;

        if(taskId !== null){
            this.taskId = taskId;
        }

        if(this.environmentService.get('examType') === 'mmk'){
            let format = this.examCreatorForm.get('format').value;
            // console.log('-format ', format);

            if (format !== null) {
                let types = <Array<any>> MMK_TASK_TYPES;
                let match = types.filter((item) => item.value === format);

                if (match[0]) {
                    let matchPart = match[0].main;
                    let matchRouteUrl = match[0].url;
                    let matchSubType = match[0].subType;
                    routeUrl = this.taskId ? matchRouteUrl + "/" + this.taskId : matchRouteUrl;

                    if(this.isDuplicate){
                        routeUrl += '/duplicate';
                    }
                }
            }
        } else {
            let type = this.examCreatorForm.get('type').value;
            // console.log('-type ', type);

            if (type !== null) {
                let types;

                if (this.examType.value !== null) {
                    types = <Array<any>> TASK_TYPES[this.examType.value];
                }

                let match = types.filter((item) => item.value === type);
                if (match[0]) {
                    routeUrl = match[0].url;
                    routeUrl = this.taskId ? routeUrl + "/" + this.taskId : routeUrl;

                    // Do not redirect after saving
                    // if(this.isDuplicate){
                    //    routeUrl += '/duplicate';
                    // }

                }
            }
        }

        // console.log('-routeUrl ', routeUrl);
        return routeUrl;
    }
}
