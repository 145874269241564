<h3 i18n>Rövid válasz</h3>
<form ngNativeValidate [formGroup]="shortAnswer" (submit)="onSubmit($event)">
  <app-registry-sheet [form-group]="mainForm"></app-registry-sheet>
  <div class="row">
    <div class="col-xs-12 form-group" *ngIf="hasUploadField">
      <label i18n>Válassza ki a feladathoz szükséges forrásanyagot(videó, kép):</label>
      <input type="file" class="form-control"
             (change)="fileUploadService.onChangeInput($event, 'file', 'file_url');">
      <div class="video-loader"
           *ngIf="!shortAnswer.controls.file_url.value && fileUploadService.isVideoLoading === 'file_url'">
        <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
      </div>
      <div
        *ngIf="taskData && taskData.file_url != '' && taskData.file_url != null || shortAnswer.controls.file_url.value !== null">
        <strong>{{taskData?.file_url | filenameConverter}}</strong>
        <button *ngIf="taskData?.file_url != null" class="btn btn-danger" (click)="removeTaskFile('file_url')">Fájl
          törlése
        </button>
        <a class="btn btn-danger" target="_blank"
           href="{{prefixUrl}}{{shortAnswer.controls.file_url.value}}?token={{token}}" i18n>Előnézet</a>
        <video-converter [filename]="taskData.file_url" (newVideoEvent)="updateVideoData($event)"></video-converter>
      </div>
    </div>
    <div class="col-xs-12 form-group" *ngIf="hasUploadField">
      <label i18n>Válassza ki a feladathoz szükséges kiegészítő tartalmat(képet):</label>
      <input type="file" class="form-control"
             (change)="fileUploadService.onChangeInput($event, 'file', 'enlarge_file_url')">
      <div class="video-loader"
           *ngIf="!shortAnswer.controls.enlarge_file_url.value && fileUploadService.isVideoLoading === 'enlarge_file_url'">
        <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
      </div>
      <div
        *ngIf="taskData && taskData.enlarge_file_url != '' && taskData.enlarge_file_url != null || shortAnswer.controls.enlarge_file_url.value !== null">
        <strong>{{taskData?.enlarge_file_url | filenameConverter}}</strong>
        <button *ngIf="taskData?.enlarge_file_url != null" class="btn btn-danger"
                (click)="removeTaskFile('enlarge_file_url')" i18n>Fájl törlése
        </button>
        <a class="btn btn-danger" target="_blank"
           href="{{prefixUrl}}{{shortAnswer.controls.enlarge_file_url.value}}?token={{token}}" i18n>Előnézet</a>
      </div>
    </div>
    <div class="col-xs-12 form-group">
      <label>
        <input [checked]="twoExampleItems.value" type="checkbox" (change)="twoExampleItemsChecked($event)" i18n><span i18n>Két mintamegoldás</span>
      </label>
    </div>
    <div class="col-xs-12"><label i18n="@@copy-source-text-here">Másolja be a feladathoz tartozó forrásszöveget ide:</label></div>
    <div class="col-xs-12 form-group">
      <app-tinymce class="app-tinymce" [formControl]="sourceShortAnswerControl"></app-tinymce>
      <div *ngIf="formErrors.source_short_control" class="alert alert-danger">
        {{ formErrors.source_short_control }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12"><label i18n>A feladat kérdései</label></div>
  </div>
  <section *ngFor="let itemFormGroup of itemFormArrays.controls; let i=index" [formGroup]="itemFormGroup">
    <div class="col-xs-12 form-group group">
      <div class="col-xs-12 form-group">
        <label i18n>Adja meg a feladathoz tartozó kérdéseket és válaszokat.</label>
      </div>
      <div class="col-xs-12 form-group">
        <label i18n="@@question">Kérdés:</label>
        <input type="text" class="form-control"
               formControlName="short_question" [required]="true">
      </div>
      <div class="col-xs-12 form-group">
        <label *ngIf="i === 0" i18n>Példaválasz:</label>
        <label *ngIf="i !== 0" i18n>Instrukció a válasz értékeléséhez:</label>
        <input type="text" class="form-control"
               formControlName="short_instruction" [required]="true">
      </div>

<!--      <short-answer-key-manager-->
<!--        [taskId]="taskId"-->
<!--        [itemData]="itemFormGroup.value"-->
<!--        (newShortInstructionEvent)="updateShortInstruction($event)"-->
<!--      ></short-answer-key-manager>-->

      <key-manager
          *ngIf="taskId"
          [input]="getKeyManagerInputDataForItem(itemFormGroup.value.id)"
          [loading]="keyManagerIsLoading"
          (changed)="updateShortAnswers($event)"
      ></key-manager>

      <div class="col-xs-12 form-group">
        <label i18n>Válasz előtt:</label>
        <input type="text" class="form-control"
               formControlName="before_answer">
      </div>
      <div class="col-xs-12 form-group">
        <label i18n>Válasz után:</label>
        <input type="text" class="form-control"
               formControlName="after_answer">
      </div>
      <div class="col-xs-12 form-group">
        <button type="button" class="btn btn-danger"
                (click)="removedItem(i)" i18n>Eltávolít
        </button>
      </div>
    </div>
  </section>
  <div class="col-xs-12 form-group">
    <button type="button" class="btn btn-primary button"
            (click)="onClickNewOptionBtn($event)" i18n>Új kérdés hozzáadása
    </button>
    <div class="alert alert-danger" *ngIf="isShowError" i18n>Elérted a maximális
      feladatszámot.({{itemFormArrays.length}})
    </div>
  </div>

    <ng-container [ngSwitch]="isDuplicate">
        <ng-container *ngSwitchCase="false">
            <button type="submit" class="btn btn-primary" [disabled]="!isFormValid || !isInputFieldRequired" i18n>Mentés</button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <button type="submit" class="btn btn-warning" [disabled]="!isFormValid || !isInputFieldRequired" i18n>Duplikálás mentése</button>
        </ng-container>
    </ng-container>

    <button type="button" class="btn btn-success" (click)="onPreview()" i18n>Előnézet</button>
  <div class="alert alert-success" *ngIf="this.examCreatorFormService.submittedResult != null && this.taskId != null">
      {{this.examCreatorFormService.submittedResult}}
  </div>
</form>
