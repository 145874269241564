import {Injectable} from "@angular/core";
import {ICurrentExamTask, IExam} from "../interfaces/exam.interface";
import {FormArray, FormControl, FormGroup} from "@angular/forms";
import {isNullOrUndefined} from "util";

@Injectable()
export class WritingSelectionService {

    private writings: Array<IExam>;
    private mainList: Array<IExam>;

    set $writings(datas: Array<IExam>) {
        this.writings = datas;
    }

    private static generateFormArrayFromGroupedTasks(tasks: ICurrentExamTask[]): FormArray {
        const formArray = new FormArray([]);

        // have to add three formGroup to the formArray, because in groups only three tasks can be selected
        for (let i = 0; i < 3; i++) {
            const orderValue = (tasks[i]) ? tasks[i].order : null;
            const suborder = (tasks[i]) ? tasks[i].suborder : null;
            const idValue = (tasks[i]) ? tasks[i].task_id : null;
            const formGroup = new FormGroup({
                order: new FormControl(orderValue),
                suborder: new FormControl(suborder),
                task_id: new FormControl(idValue)
            });

            formArray.push(formGroup);
        }

        return formArray;
    }

    private static createNotGroupedWritingTaskFormGroup(task) {
        return new FormGroup({
            order: new FormControl(task.order),
            suborder: new FormControl(),
            task_id: new FormControl(task.task_id),
        });
    }

    private static createWritingTaskFormGroup(task, groupItems) {
        return new FormGroup({
            order: new FormControl(task.order),
            groupArray: WritingSelectionService.generateFormArrayFromGroupedTasks(groupItems)
        });
    }

    // add "Csoport" selection to the list's end
    private addGroupSelectionToDropdownElements() {
        this.mainList[this.writings.length] = {isGroup: true, taskText: $localize`:@@group:Csoport`};
    }

    createDropdownElements() {
        if (this.writings.length > 0) {

            this.mainList = this.writings
                .map((item) => {
                    item.taskText = `${item.filename} (${item.name})`;
                    return item;
                });

            this.addGroupSelectionToDropdownElements();
        }
    }

    getDropdownElements(): Array<IExam> {
        return this.mainList;
    }

    setPreviouslySelectedTasks(writings: ICurrentExamTask[], formArray: FormArray) {
        let groupItems = [];
        let order = writings[0].order;

        writings.forEach((task, index) => {
            if (task.task.type === "writing") {
                let nextTaskOrder: number;
                let nextTask: ICurrentExamTask = writings[index + 1];

                if (!isNullOrUndefined(nextTask)) {
                    nextTaskOrder = nextTask.order;
                }

                if (!isNullOrUndefined(task.suborder) &&
                    task.order === order) { // if the task is grouped
                    groupItems.push(task);
                }

                if (groupItems.length < 1) {
                    formArray.push(WritingSelectionService.createNotGroupedWritingTaskFormGroup(task));
                    order = nextTaskOrder;
                }

                if (!isNullOrUndefined(nextTaskOrder) && nextTaskOrder !== order && groupItems.length > 0 ||
                    isNullOrUndefined(nextTask) && groupItems.length > 0) {
                    formArray.push(WritingSelectionService.createWritingTaskFormGroup(task, groupItems));
                    groupItems = [];
                    order = nextTaskOrder;
                }
            } else {
                formArray.push(WritingSelectionService.createNotGroupedWritingTaskFormGroup(task));
            }
        });
    }
}
