<!--EXAMINER DIV-->
<ng-container *ngIf="isUserAdmin">
    <div *ngIf="open_examiner === 0 || !isLoaded()" class="examiner-div">
        <a *ngIf="open_examiner === 0" (click)="loadExaminers(); open_examiner = 1" i18n>Vizsgáztatók</a>
    </div>
    <div *ngIf="open_examiner === 1" class="examiner-div">
        <a (click)="open_examiner = 0" i18n>Mégse/Bezár</a>
    </div>
    <div *ngIf="open_examiner === 1 && isLoaded()">
        <ng-container *ngFor="let examinerItem of examinerList[exam_part]; let z = index;">
        <!--examinerList-->
            <br/>
            <!-- FROM -->
            <select multiple *ngIf="isUserAdmin && environmentService.get('simple_identity_check') !== true"
                    [(ngModel)]="examinerItem.candidates"
                    class="candidate-select"
                    >
                <option *ngFor="let candidate of getCandidates()"
                    [value]="exam_part === 'identity_check' ? candidate.candidate_id : candidate.id"
                    [disabled]="setSelectDisabled(exam_id, exam_part, candidate.id)"
                    >
                    <span *ngIf="exam_part !== 'identity_check'">{{ candidate.last_name }} {{ candidate.first_name }} - {{ candidate.user_id }}</span>
                    <span *ngIf="exam_part === 'identity_check'">{{ candidate.name }} - {{ candidate.user_id }}</span>
                    <span *ngIf="!candidate.logged">*</span>
                </option>
            </select>

            <!-- EX 1 -->
            <select [(ngModel)]="examinerItem.examiner_1">
                <option value="NULL"></option>
                <option *ngFor="let examiner of getExaminers()" [value]="examiner.id">
                    {{examiner.first_name}}&nbsp;{{examiner.last_name}}
                </option>
            </select>

            <!-- EX 2 -->
            <select *ngIf="environmentService.get('simple_identity_check') !== true"
                    [(ngModel)]="examinerItem.examiner_2">
                <option value="NULL"></option>
                <option *ngFor="let examiner of getExaminers()" [value]="examiner.id">
                    {{examiner.first_name}}&nbsp;{{examiner.last_name}}
                </option>
            </select>

            <span title="Harmadik értékelő hozzáadása"
                  i18n-title
                  class="mouse-pointer"
                  *ngIf="environmentService.get('simple_identity_check') !== true && !examinerList[exam_part][z].showExaminer3 && !examinerItem.examiner_3"
                  (click)="examinerList[exam_part][z].showExaminer3 = true">
                <i class="fa fa-user"></i>
            </span>
            <!-- EX 3 -->
            <ng-container *ngIf="environmentService.get('simple_identity_check') !== true && (examinerList[exam_part][z].showExaminer3 || examinerItem.examiner_3)">
                <select  [(ngModel)]="examinerItem.examiner_3">
                    <option value="NULL"></option>
                    <option *ngFor="let examiner of getExaminers()" [value]="examiner.id">
                        {{examiner.first_name}}&nbsp;{{examiner.last_name}}
                    </option>
                </select>
            </ng-container>
            <br/>
        </ng-container>
        <span title="Hozzáadás" i18n-title (click)="addNewExaminer(exam_id, exam_part)">
            <i class="fa fa-plus"></i>
        </span>
        <br/>
        <span title="Mentés"
              i18n-title
              class="mouse-pointer"
              (click)="saveExaminerRow(exam_id, exam_part)">
                <i class="fa fa-save" i18n> Mentés</i>
            </span>
        <br/>
    </div>
</ng-container>
<!--EXAMINER DIV-->
