import {Component, OnInit, Input, Output, EventEmitter} from "@angular/core";

@Component({
    moduleId: module.id,
    selector: "close-exam",
    host: {
        id: "close-exam"
    },
    templateUrl: "close-exam.component.html",
    styleUrls: ["close-exam.component.css"]
})

export class CloseExamComponent implements OnInit {
    @Input()
    closeCandidateExam;

    @Output()
    confirmClose = new EventEmitter<any>();

    currentCandidate = null;

    ngOnInit(): void {
        this.currentCandidate = this.closeCandidateExam;
    }

    submitCloseExam() {
        this.confirmClose.emit();
    }
}
