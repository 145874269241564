<div style="display: flex;">
    <aside></aside>
    <div class="container">
        <h2>Exam Sync</h2>

        <table class="table table-hover">
            <thead>
            <tr>
                <th>Vizsga azonosító</th>
                <th>Vizsga időpontja</th>
                <th>Vizsga nyelve</th>
                <th>Vizsga szintje</th>
                <th>Vizsga típusa</th>
                <th>Művelet</th>
            </tr>
            </thead>
            <tbody class="exam-table-body" *ngIf="!isLoading">
                <ng-container *ngFor="let exam of exams; let i = index">
                    <tr>
                        <td>{{ exam.id }}</td>
                        <td>{{ exam.date }}</td>
                        <td>{{ exam.language }}</td>
                        <td>{{ exam.level }}</td>
                        <td>{{ exam.type }}</td>
                        <td>
                            <a (click)="openExamPlaces(i)" [ngSwitch]="areExamsShown[i]">
                                <span *ngSwitchCase="false">Helyszínek</span>
                                <span *ngSwitchCase="true">Elrejt</span>
                            </a>
                            <i id="isLoading-{{ exam.id}}" class="spinner fa fa-cog fa-spin fa-1x fa-fw"></i>
                            <u (click)="setReloadExam(exam.id)" *ngIf="checkPlaces(exam)"> Visszatöltésre jelöl</u>
                        </td>
                    </tr>
                    <tr [class.hide]="!areExamsShown[i]">
                        <td colspan="3"></td>
                        <td colspan="3" class="grey-bkg">
                            <table class="table-model white-bkg">
                                <thead>
                                    <tr>
                                        <th>Helyszín</th>
                                        <th>Státusz</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let place of exam.exam_places; let k = index">
                                        <td>{{ place.place.name}}</td>
                                        <td>
                                            <ng-container [ngSwitch]="place.status">
                                                <span *ngSwitchCase="0" >Letöltés alatt</span>
                                                <span *ngSwitchCase="1" class="yellow">Letöltve helyi szerverre</span>
                                                <span *ngSwitchCase="2" class="red">Várakozás visszatöltésre</span>
                                                <span *ngSwitchCase="3" class="green">Válaszok visszatöltve</span>
                                            </ng-container>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <ng-container *ngIf="pagination.current_page">
            <ul class="pagination">
                <i *ngIf="isLoading" class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                <li *ngIf="pagination.current_page > 1"><button class="btn btn-primary" [disabled]="isLoading" (click)="setPage('pre')">Előző</button></li>
                <li><button class="btn btn-primary">{{ pagination.current_page }}. oldal</button></li>
                <li *ngIf="pagination.current_page < pagination.last_page"><button class="btn btn-primary" [disabled]="isLoading" (click)="setPage('next')">Következő</button></li>
            </ul>
        </ng-container>
    </div>
</div>

