import {ActivatedRoute} from "@angular/router";
import {Component, OnInit, DoCheck, ViewChild, TemplateRef, ViewChildren, QueryList} from "@angular/core";
import {ExamHttpApi} from "../../http_apis/exam.http";
import {AuthService} from "../../services/auth.service";
import {environment} from "../../../environments/environment";
import {RestConfig} from "../../http_apis/rest_config.http";
import {FormControl} from "@angular/forms";
import {isNullOrUndefined} from "util";
import {ExamService} from "../../services/exam_service/exam.service";
import {HttpClient} from "@angular/common/http";
import {WebsocketService} from "../../services/websocket.service";
import {StreamService} from "../../services/stream.service";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../services/api.service";
import {ToastrService} from "ngx-toastr";
import {Environment} from "@angular/compiler-cli/src/ngtsc/typecheck/src/environment";
import {EnvironmentService} from "../../services/environment.service";
import {IdentityCardComponent} from "./identity-card/identity-card.component";


@Component({
    moduleId: module.id,
    selector: "checking-identity",
    host: {
        id: "identity-checking"
    },
    templateUrl: "identity-checking.component.html",
    styleUrls: ["identity-checking.component.css"]
})

export class IdentityCheckingComponent implements OnInit,DoCheck {
    @ViewChild('picker') picker: any;
    @ViewChildren('cards') cards:QueryList<IdentityCardComponent>;
    private examHttpApi: ExamHttpApi;
    examData: any;
    areExamsShown: Array<boolean>;
    loaderShow = true;
    examEdit = null;
    loaderShowText = 'Névsor betöltés alatt...';
    isAdmin:boolean = false;
    inspectors : any;
    pagination: any = {
        current_page: 1,
        last_page: 0,
    };

    matPagination = {
        'page': 0,
        'size': 10,
        'length': 0,
    };

    examTypes: any = {
        'live': $localize`:@@live:Éles`,
        'retake': $localize`:@@retake:Pótvizsga`,
        'pilot': $localize`:@@pilot:Pilot`,
        'online': $localize`:@@online:Online próbavizsga`,
        'local': $localize`:@@local:Helyi próbavizsga`,
        'test': $localize`:@@test:Teszt`,
    };

    constructor(private http: HttpClient,
                private authService: AuthService,
                private apiService: ApiService,
                private toastr: ToastrService,
                private websocketService: WebsocketService,
                private streamService: StreamService,
                public environmentService: EnvironmentService,
                public dialog: MatDialog,
                private examService: ExamService) {

        this.examHttpApi = new ExamHttpApi(http,  "data");
        this.examHttpApi.setDefaultUrl();
        this.geList();
    }


    ngOnInit() {
        console.log('NG oninit of indetity checking');

        // get inspector names
        this.examHttpApi.getInspectors().subscribe((data) => {
            this.inspectors = data;
            console.log("this.inspectors: ", this.inspectors);
        });

        this.examService.candidateExamStatusArray.subscribe((result) => {

            // We received user statuses, but we don't really know for which exam. Loop on all open exams, and set on all of them.
            for (let key in this.examData) {
                if (!this.examData[key] || typeof this.examData[key] === 'undefined' || !this.examData[key].candidates || this.examData[key].candidates === 'undefined') {
                    // This exam is not loaded yet..?
                    continue;
                }
                console.log('Triggering candidate exam status set now', result);
                this.processCandidateStatus({'examIndex': key, 'data': result.data});
            }

            // Also subscribe for exams that are opened afterwards
            this.examService.identityCheckDataLoaded.subscribe((examIndex) => {
                console.log('Triggering exam status array by previous subscription', result);
                this.processCandidateStatus({'examIndex': examIndex, 'data': result.data});
            });
        });

    }

    async processCandidateStatus(result) {
        console.log('Processing candidate statuses:', result);
        result.data.candidates.map((candidate, index) => {
            if (!this.examData[result.examIndex] || !this.examData[result.examIndex].candidates) {
                console.log('Could not process candidate statuses', result);
                return;
            }
            const candidateIndex = this.examData[result.examIndex].candidates.findIndex(item => item.id === candidate.candidate_exam_id);

            if (candidateIndex === -1) {
                // User is not assigned for this exam
                console.log('Could not set user status for exam', candidate.candidate_exam_id);
                return;
            }

            console.log('Finding messages for', result.examIndex, candidateIndex, candidate);

            this.examData[result.examIndex].candidates[candidateIndex].messages = candidate.messages;
            this.examData[result.examIndex].candidates[candidateIndex].candidateExamStatus = candidate.status === 'online';
            this.examData[result.examIndex].candidates[candidateIndex].candidateAppStatus = candidate.appstatus === 'online';

            if (this.websocketService.connected) {
                console.log('Init websocket for user', candidate.candidate_exam_id, candidate);
                this.streamService.initStream(candidate.candidate_exam_id);
            }
        });
        if (result.data.inspectors) {
            this.examData[result.examIndex].inspectors = new Array();
            for (let inspectorKey in result.data.inspectors) {
                for (let candidateExamKey in result.data.inspectors[inspectorKey]) {
                    const candidate = this.examData[result.examIndex].candidates.find((item) => item.id === result.data.inspectors[inspectorKey][candidateExamKey]);
                    if (candidate) {
                        const inspector = this.inspectors.find((insp) => insp.id === parseInt(inspectorKey));
                        if (inspector) {
                            this.examData[result.examIndex].inspectors.push(inspector.last_name + " " + inspector.first_name);
                        }
                    }
                }
            }
        }
        this.examData[result.examIndex].inspectors = [...new Set(this.examData[result.examIndex].inspectors)];
    }

    ngDoCheck() {
        this.isAdmin = this.authService.$userStatus === "admin";
    }

    openStartChangeDialog(templateRef: TemplateRef<any>, exam) {
        this.examEdit = exam;
        const dialogRef = this.dialog.open(templateRef);
        dialogRef.afterClosed().subscribe(result => {
            if (result && typeof exam.date !== 'string') {  // We are waiting for a moment object stored by the picker
                console.log(exam.date);
                exam.date = exam.date.format('YYYY-MM-DD HH:mm:ss');
                this.apiService.changeExamDate(exam.id, exam.date).subscribe(() => {
                    this.toastr.success(exam.id + ' vizsga új kezdeti ideje: ' + exam.date, 'Művelet végrehajtva.', {onActivateTick: true});
                });
            }
        });

    }

    geList(){
        this.loaderShow = true;
        this.apiService.getCheckingIdentityListForInspector(this.matPagination).subscribe((results) => {
            this.examData = results.data;
            this.matPagination['length'] = results.total;
            this.areExamsShown = this.examData.map(() => false);
            this.loaderShow = false;
        });
    }

    private setPage(type: string){
        if(this.loaderShow){
            return;
        }

        this.pagination.current_page = type === 'pre' ? parseInt(this.pagination.current_page) - 1 : parseInt(this.pagination.current_page) + 1;
        this.pagination.current_page = parseInt(this.pagination.current_page) < 1 ? 1 : this.pagination.current_page;

        this.geList();
    }

    openCandidatesList(index: number, examId: number){
        if(!this.areExamsShown[index]) {
            this.examHttpApi.getCandidatesForInspector(examId).subscribe((results) => {
                console.log('candidatesForInspector received ', results);

                this.examData[index].candidates = results;
                this.areExamsShown[index] = !this.areExamsShown[index];

                this.examService.identityCheckDataLoaded.emit(index);
            });
        } else {
            this.areExamsShown[index] = !this.areExamsShown[index];
        }
    }

    muteAll() {
        this.setMuteAll(true);
    }

    unMuteAll() {
        this.setMuteAll(false);
    }

    /**
     * Mute or unmute all cards
     * @param muted
     */
    setMuteAll(muted = true) {
        for (const key in this.cards.toArray()) {
            if (muted) {
                this.cards.toArray()[key].mute();
            } else {
                this.cards.toArray()[key].unMute();
            }
        }
    }

    matPaginate(event){
        this.matPagination['page'] = event.pageIndex;
        this.matPagination['size'] = event.pageSize;
        this.geList();
    }

    openChat(candidate_exam_id) {
        const targetCard = this.cards.find(card => card.candidate.id == candidate_exam_id);
        if (!targetCard) {
            console.error('Could not find chat for ', candidate_exam_id);
            return;
        }
        targetCard.openChat();
    }
}
