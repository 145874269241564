<div id="users-wrapper">
    <aside></aside>
    <div class="container">
        <h2>Felhasználók</h2>

        <button type="submit"
                mat-button
                class="btn-primary"
                (click)="openEditDialog();selectedUser = null">Új felhasználó létrehozása
        </button>

        <ng-template #editUser>
            <edit-user [user]="selectedUser"
                       (saveEvent)="updateUserList()"></edit-user>
        </ng-template>

        <user-list (userEdit)="openEditDialog($event)"></user-list>
    </div>
</div>