<div class="form-task-container">
  <section>
    <h5><strong>Típus:</strong></h5>
    <h5 *ngIf="!isTaskAWebForm">Formanyomtatvány</h5>
    <h5 *ngIf="isTaskAWebForm">Weboldal form</h5>
  </section>

  <section>
    <h5><strong>Logo:</strong></h5>
    <div>
      <img src="{{prefixUrl}}{{task.task_data.logo_file_url}}?token={{token}}"
           *ngIf="task.task_data.logo_file_url">
    </div>
  </section>

  <section *ngIf="task.task_data.company_title">
    <h5><strong>Cég neve:</strong></h5>
    <h5>{{task.task_data.company_title}}</h5>
  </section>

  <section *ngIf="task.task_data.address">
    <h5><strong>Elérhetőségek:</strong></h5>
    <div [innerHTML]="task.task_data.address"></div>
  </section>

  <section *ngIf="task.task_data.site_title">
    <h5><strong>Weboldal címe:</strong></h5>
    <h5>{{task.task_data.site_title}}</h5>
  </section>

  <section>
    <h5><strong>Tartalom képe:</strong></h5>
    <div>
      <img class="content-img"
           src="{{prefixUrl}}{{task.task_data.content_file_url}}?token={{token}}"
           *ngIf="task.task_data.content_file_url">
    </div>
  </section>

  <section>
    <h5><strong>Űrlap neve:</strong></h5>
    <h5>{{task.task_data.form_title}}</h5>
  </section>

  <section *ngIf="task.task_data.form_description">
    <h5><strong>Űrlap leírása:</strong></h5>
    <div [innerHTML]="task.task_data.form_description"></div>
  </section>

  <section>
    <h5><strong i18n>Vizsgázó válasza:</strong></h5>

    <ng-container *ngIf="isTaskAWebForm">
      <ul *ngFor="let answer of task.items[0].exam_answer[0].answer_data; let i = index">
        <li *ngIf="answer.text">
          <label for="text-{{i}}">{{task.items[i].item_data.item_label}}</label>
          <input id="text-{{i}}" *ngIf="answer.text" type="text" [value]="answer.text" readonly>
          <input id="text-{{i}}" *ngIf="!answer.text" type="text" [value]="" readonly>
        </li>
        <li *ngIf="answer.content">
          <label for="textarea-{{i}}">{{task.items[i].item_data.item_label}}</label>
          <textarea id="textarea-{{i}}" readonly>{{answer.content}}</textarea>
        </li>
        <li *ngIf="answer.radio">
          <label for="radio-{{i}}">{{task.items[i].item_data.item_label}}</label>
          <div *ngFor="let inputData of radioDatas">
            <input id="radio-{{i}}" type="radio" disabled
                   [name]="" [checked]="inputData === answer.radio">
            {{inputData}}
          </div>
        </li>
        <li *ngIf="answer.dropdown">
          <label for="dropdown-{{i}}">{{task.items[i].item_data.item_label}}</label>
          <select id="dropdown-{{i}}" disabled>
            <option *ngFor="let dropDownData of dropDownDatas" [ngValue]="dropDownData"
                    [selected]="dropDownData === answer.dropdown">{{dropDownData}}
            </option>
          </select>
        </li>
        <li *ngIf="answer.checkbox">
          <label for="checkbox-{{i}}">{{task.items[i].item_data.item_label}}</label>
          <div *ngFor="let inputData of checkboxDatas">
            <input id="checkbox-{{i}}" type="checkbox" disabled
                   [name]="" [checked]="inputData === answer.checkbox">
            {{inputData}}
          </div>
        </li>
      </ul>
    </ng-container>

    <ng-container *ngIf="!isTaskAWebForm">
      <ul *ngFor="let item of task.items; let i = index">
        <li>
          <h4 *ngIf="areSectionNamesVisible[i]"><strong>{{item.item_data.section_name}}</strong></h4>

          <div>
            <ul *ngFor="let sectionItem of item.item_data.section_items">
              <li *ngIf="sectionItem.item_type === 'radio'">
                <label>{{sectionItem.item_label}}</label>
                <div *ngFor="let inputData of radioDatas; let j = index;">
                  <input [type]="sectionItem.item_type"
                         [name]="sectionItem.item_type" [checked]="inputData === sectionItem.answer">
                  {{inputData}}
                </div>
              </li>

              <li *ngIf="sectionItem.item_type === 'dropdown'">
                <label>{{sectionItem.item_label}}
                  <select>
                    <option *ngFor="let dropDownData of dropDownDatas" [ngValue]="dropDownData"
                            [selected]="dropDownData === sectionItem.answer">{{dropDownData}}
                    </option>
                  </select>
                </label>
              </li>

              <li *ngIf="sectionItem.item_type === 'checkbox'">
                <label>{{sectionItem.item_label}}</label>
                <div *ngFor="let inputData of checkboxDatas; let j = index;">
                  <input [type]="sectionItem.item_type"
                         [name]="sectionItem.item_type" [checked]="sectionItem.answer[j]">
                  {{inputData}}
                </div>
              </li>

              <li *ngIf="sectionItem.item_type === 'text'">
                <label>{{sectionItem.item_label}}
                  <input *ngIf="sectionItem.answer" type="text" [value]="sectionItem.answer" readonly>
                  <input *ngIf="!sectionItem.answer" type="text" [value]="" readonly>
                </label>
              </li>

              <li *ngIf="sectionItem.item_type === 'content'">
                <label>{{sectionItem.item_label}}
                  <textarea readonly>{{sectionItem.answer}}</textarea>
                </label>
              </li>
            </ul>
          </div>

        </li>
      </ul>
    </ng-container>

  </section>
</div>

<section class="rating-container">
  <h5><strong>Értékelés:</strong></h5>

  <form novalidate [formGroup]="reviewByCorrectorForm" (submit)="onSubmit($event)">
    <section>
      <h5 *ngIf="isAdmin"><strong>Értékelő 1: </strong>{{corrector1}}</h5>
      <div>
        <label for="problem_solving1">{{correctionOptions.KER_problem_solving1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="problem_solving1" type="radio" [value]="rate" formControlName="KER_problem_solving1">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="problem_solving2">{{correctionOptions.iTOLC_problem_solving2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="problem_solving2" type="radio" [value]="rate" formControlName="iTOLC_problem_solving2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="grammar1">{{correctionOptions.KER_grammar1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="grammar1" type="radio" [value]="rate" formControlName="KER_grammar1">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="grammar2">{{correctionOptions.iTOLC_grammar2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="grammar2" type="radio" [value]="rate" formControlName="iTOLC_grammar2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="vocabulary1">{{correctionOptions.KER_vocabulary1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="vocabulary1" type="radio" [value]="rate" formControlName="KER_vocabulary1">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="vocabulary2">{{correctionOptions.iTOLC_vocabulary2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="vocabulary2" type="radio" [value]="rate" formControlName="iTOLC_vocabulary2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="structure1">{{correctionOptions.KER_structure1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="structure1" type="radio" [value]="rate" formControlName="KER_structure1">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="structure2">{{correctionOptions.iTOLC_structure2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="structure2" type="radio" [value]="rate" formControlName="iTOLC_structure2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <div class="bg-success text-success saved-label"
         [class.hidden]="!isSavingLabelVisible">
      Mentve {{currentTime}}
    </div>

    <div class="save-btn-container" *ngIf="!isAdmin">
        <button class="btn btn-warning" (click)="getDownloadFile()"><i class="fa fa-download"></i> Skála</button>
      <button class="btn btn-primary" [disabled]="!reviewByCorrectorForm.valid">Mentés</button>
    </div>
  </form>

  <form [formGroup]="reviewByCorrector2Form" *ngIf="isAdmin">
    <section>
      <h5><strong>Értékelő 2: </strong>{{corrector2}}</h5>
      <div>
        <label for="problem_solving1_2">{{correctionOptions.KER_problem_solving1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="problem_solving1_2" type="radio" [value]="rate" formControlName="KER_problem_solving1">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="problem_solving2_2">{{correctionOptions.iTOLC_problem_solving2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="problem_solving2_2" type="radio" [value]="rate" formControlName="iTOLC_problem_solving2">
            <div *ngIf="isAdmin" class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="grammar1_2">{{correctionOptions.KER_grammar1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="grammar1_2" type="radio" [value]="rate" formControlName="KER_grammar1">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="grammar2_2">{{correctionOptions.iTOLC_grammar2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="grammar2_2" type="radio" [value]="rate" formControlName="iTOLC_grammar2">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="vocabulary1_2">{{correctionOptions.KER_vocabulary1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="vocabulary1_2" type="radio" [value]="rate" formControlName="KER_vocabulary1">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="vocabulary2_2">{{correctionOptions.iTOLC_vocabulary2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="vocabulary2_2" type="radio" [value]="rate" formControlName="iTOLC_vocabulary2">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>

    <section>
      <div>
        <label for="structure1_2">{{correctionOptions.KER_structure1}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="structure1_2" type="radio" [value]="rate" formControlName="KER_structure1">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
      <div>
        <label for="structure2_2">{{correctionOptions.iTOLC_structure2}}</label>
        <ul>
          <li *ngFor="let rate of rating;">
            <input id="structure2_2" type="radio" [value]="rate" formControlName="iTOLC_structure2">
            <div class="div-to-disable-input"></div>
            <div>{{rate}}</div>
          </li>
        </ul>
      </div>
    </section>
  </form>

</section>
