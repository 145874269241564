<div mat-dialog-content class="mat-typography">
    <div class="loading-layer" *ngIf="pending">
        <div class="loader-mat-spinner" id="loader-1"></div>
    </div>
    <form [formGroup]="userForm"
          (ngSubmit)="saveUser(userForm.value)"
          class="row">
        <div *ngIf="isUserEmpty() === false" class="edit-hint-box">
            <p class="text-center edit-title-text">{{user.last_name + ' ' + user.first_name}} szerkesztése</p>
            <p class="text-center edit-hint-text">Amennyiben a jelszó változatlan, kérlek hagyd üresen a mezőt!</p>
        </div>

        <mat-form-field
            appearance="fill"
            class="col-xs-12 col-md-4">
            <mat-label>
                Vezetéknév
            </mat-label>
            <input id="last_name"
                   matInput
                   type="text"
                   formControlName="last_name">
        </mat-form-field>

        <mat-form-field
            appearance="fill"
            class="col-xs-12 col-md-4">
            <mat-label>
                Keresztnév
            </mat-label>
            <input id="first_name"
                   matInput
                   type="text"
                   formControlName="first_name">
        </mat-form-field>

        <mat-form-field
            appearance="fill"
            class="col-xs-12 col-md-4">
            <mat-label>
                Email cím
            </mat-label>
            <input id="email"
                   matInput
                   type="email"
                   formControlName="email">
        </mat-form-field>

        <mat-form-field
            appearance="fill"
            class="col-xs-12 col-md-4">
            <mat-label>
                {{isUserEmpty() === false ? 'Jelszó változtatása' : 'Jelszó'}}
            </mat-label>
            <input id="password"
                   matInput
                   type="password"
                   [placeholder]="isUserEmpty() === false ? 'változatlan' : ''"
                   formControlName="password">
        </mat-form-field>

        <mat-form-field
            *ngIf="environmentService.get('allow_analysis_module') === true"
            appearance="fill"
            class="col-xs-12 col-md-4">
            <mat-label>
                NYAT szám
            </mat-label>
            <input id="nyat_number"
                   matInput
                   type="text"
                   formControlName="nyat_number">
        </mat-form-field>

        <mat-form-field
            *ngIf="environmentService.get('allow_analysis_module') === true"
            appearance="fill"
            class="col-xs-12 col-md-4">
            <mat-label>
                Facets group
            </mat-label>
            <input id="facets_group"
                   matInput
                   type="number"
                   formControlName="facets_group">
        </mat-form-field>

        <mat-form-field
            appearance="fill"
            class="col-xs-12 col-md-4">
            <mat-label>
                Szerepkör
            </mat-label>
            <mat-select formControlName="role" [value]="environmentService.get('allow_create_inspector') && !environmentService.get('allow_create_examiner') ? 'inspector' : 'examiner'">
                <mat-option value="examiner" [disabled]="!environmentService.get('allow_create_examiner')">Értékelő</mat-option>
                <mat-option value="inspector" [disabled]="!environmentService.get('allow_create_inspector')">
                    Teremfelügyelő
                </mat-option>
                <mat-option value="admin" disabled>Adminisztrátor</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="col-lg-12"></div>

        <div class="form-group col-xs-12 col-md-4">
            <button mat-button
                    type="submit"
                    [ngClass]="{
                    'btn-warning' : isUserEmpty() === false,
                    'btn-success' : isUserEmpty()
                    }"
                    class="col"> {{ actionButtonText }}
            </button>
            <button mat-button
                    color="basic"
                    class="col margin-left-1" mat-dialog-close>Mégse
            </button>

        </div>
    </form>
</div>
