import {BrowserModule, Title} from "@angular/platform-browser";
import {ErrorHandler, Injector, NgModule} from "@angular/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";

import {AppComponent} from "../components/app.component";
import {AppHeader} from "../components/header/app.header";
import {AppRoutingModule} from "./router.module";
import {AuthService} from "../services/auth.service";
import {TaskCreatorAuthGuardService} from "../services/task-creator-auth-guard.service";
import {AdminAuthGuardService} from "../services/admin-auth-guard.service";
import {ExaminerAuthGuardService} from "../services/examiner-auth-guard.service";
import {InspectorAuthGuardService} from "../services/inspector-auth-guard.service";
import {ExamService} from "../services/exam_service/exam.service";
import {DatePipe} from "@angular/common";
import {WebsocketService} from '../services/websocket.service';
import {EnvironmentService} from '../services/environment.service';
import {ToastrModule, ToastrService} from 'ngx-toastr';
import {ChatComponent} from "../components/chat/chat.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ApiService} from "../services/api.service";
import {MatSliderModule} from "@angular/material/slider";
import {StreamService} from "../services/stream.service";
import {MyHttpErrorHandler} from "../utils/my_http_error_handle";
import {PaginationService} from "../services/pagination.service";
import {WebcamService} from "../services/webcam.service";
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MoveExamAnswerService} from "../components/move-exam-answers/shared/move-exam-answer.service";

registerLocaleData(localeDe, 'de-DE');


@NgModule({
  declarations: [
    AppComponent,
    AppHeader
  ],
  imports: [
    MatInputModule,
    MatFormFieldModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: MyHttpErrorHandler, multi: true  },
    AdminAuthGuardService,
    TaskCreatorAuthGuardService,
    ExaminerAuthGuardService,
    WebsocketService,
    StreamService,
    EnvironmentService,
    InspectorAuthGuardService,
    ExamService,
    ApiService,
    DatePipe,
    PaginationService,
    WebcamService,
    Title,
    MoveExamAnswerService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
