<h3 i18n>Párosítás</h3>
<form ngNativeValidate [formGroup]="pairing" (submit)="onSubmit($event)">
    <app-registry-sheet [form-group]="mainForm"></app-registry-sheet>
    <div class="row">
        <div class="col-xs-12 form-group" *ngIf="hasUploadField">
            <label i18n>Válassza ki a feladathoz szükséges forrásanyagot(videó, kép):</label>
            <input type="file" class="form-control"
                   (change)="fileUploadService.onChangeInput($event, 'file');">
            <div *ngIf="taskData && taskData.file_url != '' && taskData.file_url != null">
                <strong>{{taskData.file_url | filenameConverter}}</strong>
                <button class="btn btn-danger" (click)="removeTaskFile()" i18n>Fájl törlése</button>
                <video-converter [filename]="taskData.file_url" (newVideoEvent)="updateVideoData($event)"></video-converter>
            </div>
        </div>
        <div class="col-xs-12"><label i18n="@@copy-source-text-here">Másolja be a feladathoz tartozó forrásszöveget ide:</label></div>
        <div class="col-xs-12 form-group">
            <app-tinymce class="app-tinymce" [formControl]="sourcePairingControl"
                         [required]="sourceRequired"></app-tinymce>
        </div>
    </div>
    <div class="col-xs-12 form-group type">
        <strong i18n>Típus:</strong>
        <label *ngFor="let type of typeRadios; let i = index">
            <input type="radio" [value]="i" formControlName="type">{{type}}
        </label>
    </div>
    <div class="col-xs-12 form-group">
        <label>
            <input type="checkbox" [formControl]="reusableItem" > <span i18n>Többször felhasználható elemek</span>
        </label>
        <label *ngIf="typeValue === 0">
            <input type="checkbox" formControlName="reverse" > <span i18n>Helyettesítés sorrendjének fordítása</span>
        </label>
        <label *ngIf="typeValue === 0 || typeValue === 3">
            <input [checked]="changeView.value" type="checkbox" (change)="changeViewChecked($event)" > <span i18n>Párosítandó elemek felülre kerüljenek</span>
        </label>
    </div>
    <div class="row">
        <div class="col-xs-12 form-group">
            <label i18n>Adja meg a párosítás opcióit (az üresen hagyott mezők pár nélküli elemet jelentenek)</label>
        </div>
    </div>
    <div class="col-xs-12 form-group pairing-group"
         *ngFor="let itemPairingFormGroup of itemFormArrays.controls; let i = index">
        <section *ngIf="typeValue === 0">
            <div class="col-xs-12 form-group">
                <label>{{firstObjectValue}} <span i18n>Adja meg a szöveget vagy</span>
                    <input type="checkbox" [formControl]="pickExistingFirst"
                           [checked]="itemPairingFormGroup.controls.toggle1.value" (change)="onCheck($event, i, 1)">
                    <span i18n>válasszon egy meglévőt</span>
                </label>
                <input *ngIf="!itemPairingFormGroup.controls.toggle1.value" type="text" class="form-control"
                       [formControl]="itemPairingFormGroup.controls.textInput" [required]="true">
                <select *ngIf="itemPairingFormGroup.controls.toggle1.value" class="form-control"
                        (change)="onChange($event, i, true);" [required]="true">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInput'; let j = index"
                            [selected]="existing.controls.textInput.value === itemPairingFormGroup.controls.textInput.value"
                            [value]="existing.controls.textInput.value">
                        {{existing.controls.textInput.value}}
                    </option>
                </select>
            </div>
            <div class="col-xs-12 form-group">
                <label>{{secondObjectValue}} <span i18n>Adja meg a szöveget vagy</span>
                    <input type="checkbox" [formControl]="pickExistingSecond"
                           [checked]="itemPairingFormGroup.controls.toggle2.value" (change)="onCheck($event, i, 2)">
                    <span i18n>válasszon egy meglévőt</span>
                </label>
                <input *ngIf="!itemPairingFormGroup.controls.toggle2.value" type="text" class="form-control"
                       [formControl]="itemPairingFormGroup.controls.textInputDuplicated">
                <select *ngIf="itemPairingFormGroup.controls.toggle2.value" class="form-control"
                        (change)="onChange($event, i, false);">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInputDuplicated'; let j = index"
                            [selected]="existing.controls.textInputDuplicated.value === itemPairingFormGroup.controls.textInputDuplicated.value"
                            [value]="existing.controls.textInputDuplicated.value">
                        {{existing.controls.textInputDuplicated.value}}
                    </option>
                </select>
            </div>
            <div class="col-xs-12 form-group">
                <button type="button" class="btn btn-danger"
                        (click)="removedItem(i)" i18n>Eltávolít
                </button>
            </div>
        </section>
        <section *ngIf="typeValue === 1">
            <div class="col-xs-12 form-group">
                <label>{{firstObjectValue}} Válassza ki a feladathoz szükséges forrásanyagot (videó, kép) vagy
                    <input type="checkbox" [formControl]="pickExistingSecond"
                           [checked]="itemPairingFormGroup.controls.toggle1.value" (change)="onCheck($event, i, 1)">
                    válasszon egy
                    meglévőt:
                </label>
                <input *ngIf="!itemPairingFormGroup.controls.toggle1.value" type="file" class="form-control"
                       (change)="fileUploadService.onChangeInput($event, 'file', 'first', i)">
                <select *ngIf="itemPairingFormGroup.controls.toggle1.value === true" class="form-control"
                        (change)="onChange($event, i, true);">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInput'; let j = index"
                            [selected]="existing.controls.textInput.value === itemPairingFormGroup.controls.textInput.value"
                            [value]="existing.controls.textInput.value">
                        {{existing.controls.textInput.value | filenameConverter}}
                    </option>
                </select>
                <div class="video-loader"
                     *ngIf="!itemPairingFormGroup.controls.textInput.value && fileUploadService.isVideoLoading === 'first'">
                    <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                </div>
                <div *ngIf="itemPairingFormGroup.controls.textInput.value">
                    <strong>{{itemPairingFormGroup.controls.textInput.value | filenameConverter}}</strong>
                    <button class="btn btn-danger"
                            (click)="removeItemFile(itemPairingFormGroup.controls.id.value, 'textInput')" i18n>
                        Fájl törlése
                    </button>
                    <a *ngIf="itemPairingFormGroup.controls.textInput.value"
                       class="btn btn-danger" target="_blank"
                       href="{{prefixUrl}}{{itemPairingFormGroup.controls.textInput.value}}?token={{token}}" i18n>Előnézet</a>
                </div>
            </div>
            <div class="col-xs-12 form-group">
                <label>{{secondObjectValue}} <span i18n>Adja meg a szöveget vagy</span>
                    <input type="checkbox" [formControl]="pickExistingSecond"
                           [checked]="itemPairingFormGroup.controls.toggle2.value" (change)="onCheck($event, i, 2)">
                    <span i18n>válasszon egy meglévőt</span>
                </label>
                <input *ngIf="!itemPairingFormGroup.controls.toggle2.value" type="text" class="form-control"
                       [formControl]="itemPairingFormGroup.controls.textInputDuplicated">
                <select *ngIf="itemPairingFormGroup.controls.toggle2.value" class="form-control"
                        (change)="onChange($event, i, false);">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInputDuplicated'; let j = index"
                            [selected]="existing.controls.textInputDuplicated.value === itemPairingFormGroup.controls.textInputDuplicated.value"
                            [value]="existing.controls.textInputDuplicated.value">
                        {{existing.controls.textInputDuplicated.value}}
                    </option>
                </select>
            </div>
            <div class="col-xs-12 form-group">
                <button type="button" class="btn btn-danger"
                        (click)="removedItem(i)" i18n>Eltávolít
                </button>
            </div>
        </section>
        <section *ngIf="typeValue === 2">
            <div class="col-xs-12 form-group">
                <label>{{firstObjectValue}} Válassza ki a feladathoz szükséges forrásanyagot (videó, kép) vagy
                    <input type="checkbox" [formControl]="pickExistingSecond"
                           [checked]="itemPairingFormGroup.controls.toggle1.value" (change)="onCheck($event, i, 1)">
                    válasszon egy
                    meglévőt:
                </label>
                <input *ngIf="!itemPairingFormGroup.controls.toggle1.value" type="file" class="form-control"
                       (change)="fileUploadService.onChangeInput($event, 'file', 'first', i)">
                <select *ngIf="itemPairingFormGroup.controls.toggle1.value" class="form-control"
                        (change)="onChange($event, i, true);">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInput'; let j = index"
                            [selected]="existing.controls.textInput.value === itemPairingFormGroup.controls.textInput.value"
                            [value]="existing.controls.textInput.value">
                        {{existing.controls.textInput.value | filenameConverter}}
                    </option>
                </select>
                <div class="video-loader"
                     *ngIf="!itemPairingFormGroup.controls.textInput.value && fileUploadService.isVideoLoading === 'first'">
                    <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                </div>
                <div *ngIf="itemPairingFormGroup.controls.textInput.value">
                    <strong>{{itemPairingFormGroup.controls.textInput.value | filenameConverter}}</strong>
                    <button class="btn btn-danger"
                            (click)="removeItemFile(itemPairingFormGroup.controls.id.value, 'textInput')" i18n>
                        Fájl törlése
                    </button>
                    <a *ngIf="itemPairingFormGroup.controls.textInput.value"
                       class="btn btn-danger" target="_blank"
                       href="{{prefixUrl}}{{itemPairingFormGroup.controls.textInput.value}}?token={{token}}" i18n>Előnézet</a>
                </div>
            </div>
            <div class="col-xs-12 form-group">
                <label>{{secondObjectValue}} Válassza ki a feladathoz szükséges forrásanyagot (videó, kép) vagy
                    <input type="checkbox" [formControl]="pickExistingSecond"
                           [checked]="itemPairingFormGroup.controls.toggle2.value" (change)="onCheck($event, i, 2)">
                    válasszon egy
                    meglévőt:
                </label>
                <input *ngIf="!itemPairingFormGroup.controls.toggle2.value" type="file" class="form-control"
                       (change)="fileUploadService.onChangeInput($event, 'file', 'second', i)">
                <select *ngIf="itemPairingFormGroup.controls.toggle2.value" class="form-control"
                        (change)="onChange($event, i, false);">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInputDuplicated'; let j = index"
                            [selected]="existing.controls.textInputDuplicated.value === itemPairingFormGroup.controls.textInputDuplicated.value"
                            [value]="existing.controls.textInputDuplicated.value">
                        {{existing.controls.textInputDuplicated.value}}
                    </option>
                </select>
                <div class="video-loader"
                     *ngIf="!itemPairingFormGroup.controls.textInputDuplicated.value && fileUploadService.isVideoLoading === 'second'">
                    <i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
                </div>
                <div *ngIf="itemPairingFormGroup.controls.textInputDuplicated.value">
                    <strong>{{itemPairingFormGroup.controls.textInputDuplicated.value | filenameConverter}}</strong>
                    <button class="btn btn-danger"
                            (click)="removeItemFile(itemPairingFormGroup.controls.id.value, 'textInputDuplicated')" i18n>
                        Fájl törlése
                    </button>
                    <a *ngIf="itemPairingFormGroup.controls.textInputDuplicated.value"
                       class="btn btn-danger" target="_blank"
                       href="{{prefixUrl}}{{itemPairingFormGroup.controls.textInputDuplicated.value}}?token={{token}}" i18n>Előnézet</a>
                </div>
            </div>
            <div class="col-xs-12 form-group">
                <button type="button" class="btn btn-danger"
                        (click)="removedItem(i)" i18n>Eltávolít
                </button>
            </div>
        </section>
        <section *ngIf="typeValue === 3">
            <div class="col-xs-12 form-group">
                <label>{{firstObjectValue}} <span i18n>Adja meg a szöveget vagy</span>
                    <input type="checkbox" [formControl]="pickExistingFirst"
                           [checked]="itemPairingFormGroup.controls.toggle1.value" (change)="onCheck($event, i, 1)">
                    <span i18n>válasszon egy meglévőt</span>
                </label>
                <app-tinymce *ngIf="!itemPairingFormGroup.controls.toggle1.value" class="app-tinymce"
                             [formControl]="itemPairingFormGroup.controls.textInput"></app-tinymce>
                <select *ngIf="itemPairingFormGroup.controls.toggle1.value" class="form-control"
                        (change)="onChange($event, i, true);">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInput'; let j = index"
                            [selected]="existing.controls.textInput.value === itemPairingFormGroup.controls.textInput.value"
                            [value]="existing.controls.textInput.value">
                        {{existing.controls.textInput.value}}
                    </option>
                </select>
            </div>
            <div class="col-xs-12 form-group">
                <label>{{secondObjectValue}} <span i18n>Adja meg a szöveget vagy</span>
                    <input type="checkbox" [formControl]="pickExistingSecond"
                           [checked]="itemPairingFormGroup.controls.toggle2.value" (change)="onCheck($event, i, 2)">
                    <span i18n>válasszon egy meglévőt</span>
                </label>
                <input *ngIf="!itemPairingFormGroup.controls.toggle2.value" type="text" class="form-control"
                       [formControl]="itemPairingFormGroup.controls.textInputDuplicated">
                <select *ngIf="itemPairingFormGroup.controls.toggle2.value" class="form-control"
                        (change)="onChange($event, i, false);">
                    <option *ngFor="let existing of itemFormArrays.controls | itemFilter: 'textInputDuplicated'; let j = index"
                            [selected]="existing.controls.textInputDuplicated.value === itemPairingFormGroup.controls.textInputDuplicated.value"
                            [value]="existing.controls.textInputDuplicated.value">
                        {{existing.controls.textInputDuplicated.value}}
                    </option>
                </select>
            </div>
            <div class="col-xs-12 form-group">
                <button type="button" class="btn btn-danger"
                        (click)="removedItem(i)" i18n>Eltávolít
                </button>
            </div>
        </section>
    </div>

    <div class="col-xs-12 form-group">
        <button type="button" class="btn btn-primary"
                (click)="onClickNewOptionBtn($event)" i18n>Új opció hozzáadása
        </button>
    </div>

    <ng-container [ngSwitch]="isDuplicate">
        <ng-container *ngSwitchCase="false">
            <button type="submit" class="btn btn-primary" [disabled]="!isFormValid || !requieredRegSheetForm" i18n>Mentés</button>
        </ng-container>
        <ng-container *ngSwitchCase="true">
            <button type="submit" class="btn btn-warning" [disabled]="!isFormValid || !requieredRegSheetForm" i18n>Duplikálás mentése</button>
        </ng-container>
    </ng-container>

    <button type="button" class="btn btn-success" (click)="onPreview()" i18n>Előnézet</button>
    <div class="alert alert-success" *ngIf="examCreatorFormService.submittedResult != null && taskId != null">
        {{this.examCreatorFormService.submittedResult}}
    </div>
</form>
