import { Component, OnInit, Input, EventEmitter } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";

const TYPES = [{
    value: "text",
    text: "Szöveg"
},{
    value: "radio",
    text: "Radio gombok"
},{
    value: "content",
    text: "Tartalom"
},{
    value: "dropdown",
    text: "Legördülő"
},{
    value: "checkbox",
    text: "Checkbox"
}
];

interface IPreview {
    label: string;
    dropdown?: Array<string>;
    content?: string;
    radios?: Array<string>;
    single?: string;
    checkbox?: Array<string>;
}

export interface IPreviewGenerate {
    preview: IPreview;
    index: number;
}

@Component({
    moduleId: module.id,
    selector: "form-creator-item",
    outputs: ["onRemoveItem","onGeneratePreview"],
    inputs: ["groupIndex"],
    templateUrl: "form-creator-item.component.html"
})

export class FormCreatorItemComponent implements OnInit {

    typeRadios: Array<any> = TYPES;
    isSingle: boolean;
    isMulti: boolean;
    isContent: boolean;
    onRemoveItem: EventEmitter<any> = new EventEmitter();
    onGeneratePreview: EventEmitter<IPreviewGenerate> = new EventEmitter();
    private preview: IPreview;
    private groupIndex: number;

    @Input("form-group") formGroup: FormGroup;

    constructor() {
        this.isSingle = false;
        this.isMulti = false;
        this.isContent = false;

        this.preview = {
            label: null
        };
    }

    ngOnInit() {
        let itemTypeControl: FormControl = <FormControl> this.formGroup.get("item_type");
        let itemLabelControl: FormControl = <FormControl> this.formGroup.get("item_label");

        this.displayExistType(itemLabelControl);

        itemTypeControl.valueChanges.subscribe((value) => {
            let control = new FormControl();

            this.preview = {
                label: itemLabelControl.value
            };

            this.generatePreview();

            switch (value) {
                case "text":
                    this.isMulti = false;
                    this.isContent = false;
                    this.formGroup.removeControl("item_multi");
                    this.formGroup.removeControl("item_content");

                    this.formGroup.addControl("item_single", control);
                    this.isSingle = true;

                    this.subscribeSingleChange(control);
                    break;
                case "content":
                    this.isMulti = false;
                    this.isSingle = false;
                    this.formGroup.removeControl("item_multi");
                    this.formGroup.removeControl("item_single");

                    this.formGroup.addControl("item_content", control);
                    this.isContent = true;

                    this.subscribeContentChange(control);
                    break;
                default:
                    this.isContent = false;
                    this.isSingle = false;
                    this.formGroup.removeControl("item_single");
                    this.formGroup.removeControl("item_content");

                    this.formGroup.addControl("item_multi", control);
                    this.isMulti = true;
                    this.subscribeMultiChange(control, value);
                    break;
            }
        });

        itemLabelControl.valueChanges.subscribe((value) => {
            this.preview.label = value;
            this.generatePreview();
        });
    }

    private generatePreview() {
        this.onGeneratePreview.emit({
            preview: this.preview,
            index: this.groupIndex
        });
    }

    private displayExistType(itemLabelControl: FormControl) {
        this.preview.label = itemLabelControl.value;

        let singleControl = this.formGroup.get("item_single");
        let contentControl = this.formGroup.get("item_content");
        let multiControl = this.formGroup.get("item_multi");

        if (singleControl) {
            this.isSingle = true;
            this.preview.single = singleControl.value;
        }

        if (contentControl) {
            this.isContent = contentControl.value;
        }

        if (multiControl) {
            this.isMulti = true;
            let splitted = multiControl.value.split(/\n/);

            if (itemLabelControl.value === "radio") {
                this.preview.radios = splitted;
            } else if (itemLabelControl.value === "checkbox") {
                this.preview.checkbox = splitted;
            } else {
                this.preview.dropdown = splitted;
            }
        }

        this.generatePreview();
    }

    private subscribeSingleChange(control: FormControl) {
        control.valueChanges.subscribe((value) => {
            this.preview.single = value;
            this.generatePreview();
        });
    }

    private subscribeContentChange(control: FormControl) {
        control.valueChanges.subscribe((value) => {
            this.preview.content = value;
            this.generatePreview();
        });
    }

    private subscribeMultiChange(control: FormControl, multiValue: string) {
        control.valueChanges.subscribe((value: string) => {
            let splitted = value.split(/\n/);

            if (multiValue === "radio") {
                this.preview.radios = splitted;
            } else if (multiValue === "checkbox") {
              this.preview.checkbox = splitted;
            } else {
                this.preview.dropdown = splitted;
            }

            this.generatePreview();
        });
    }

    removeItem() {
        this.onRemoveItem.emit();
    }
}
