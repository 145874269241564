import {environment} from '../../environments/environment';
import { Injectable } from "@angular/core";

/**
 * Configuration files:
 * environment.ts -> Standard environment variables
 * environment.prod.ts -> Standard environment variables for production

 *
 * Example files:
 * environment.ts.example -> Example standard environment variables
 * environment.prod.ts.example -> Example standard environment variables for production
 *
 * environment.ts and environment.prod.ts is automatically loaded by angular based on the build
 * for environment-urls, we will do it manually (should've been imported to the environment, but that's a lot to deploy)
 */

/**
 * Service for providing environment variables
 */
@Injectable()
export class EnvironmentService {

    // The values that are defined here are the default values that can
    // be overridden by envirommnet files.
    private configuration = {
        production: false,
        apiPrefixUrl: "https://itolc-api-dev.test.innodev.info/",
        apiPrefixUrlCandidate: "https://itolc.test.innodev.info/",
        websocket: 'http://vizsgazo.itolc.local:3000',
        examType: 'itolc',
        live_oral_exam: false,
        logo: null,
        hide_places: false,
        adaptive_testing: false, // Display adaptive test results if enabled
        title: 'iTolc Nyelvvizsga',
        live_application: true,
        allow_task_archive: false,
        allow_task_export: false,
        allow_create_inspector: false,
        allow_create_examiner: true,
        allow_analysis_module: true,
        enable_streaming: true,
        hide_inspector_password_generate: false,
        hide_inspector_extend_time: false,
        sections_as_percent: false,
        simple_identity_check: false,   // 1/2/3 person for the whole exam, without partitions
    };

    constructor() {
        // Load var type parameters
        for (let key in environment) {
            this.configuration[key] = environment[key];
        }
    }

    /**
     * Get a configuration parameter
     * @param name
     * @param group, that can be 'var' for regular parameters or 'url' for URL
     */
    public get(name, group = null) {
        if (group === null){
            return (typeof this.configuration[name] !== 'undefined') ? this.configuration[name] : null;
        } else {
            return (this.configuration[group] && typeof this.configuration[group][name] !== 'undefined') ? this.configuration[group][name] : null;
        }
    }
}
