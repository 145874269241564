import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ExamService} from "./exam_service/exam.service";
import {AuthService} from "./auth.service";


declare const navigator: any;

/**
 * This service is used to create webcamera images at specific events, and store them on the server
 */
@Injectable()
export class WebcamService {

    private imageCapture: ImageCapture;
    private videoStreamReady = new Subject<any>();

    constructor(
        private AuthService: AuthService,
        private examService: ExamService,
    ) {
        // Defaults
        this.imageCapture = null;
    }


    /**
     * Initialize webcam, fetch privileges, get tracks
     */
    initWebcam(type, callback = null) {
        let constraints  = <any> {
            video: true,
            audio: (type === 'audiovideo'),
        };

        const supports = navigator.mediaDevices.getSupportedConstraints();
        if (supports['facingMode']) {
            constraints.video = {
                facingMode: 'user'
            };
        }

        // Capture video stream - NO SOUND
        if (type === 'stream_for_identification_nosound') {
            constraints.audio = false;
        }

        navigator.getUserMedia(
            // constraints
            constraints,
            // successCallback
            this.initStream.bind(this, type, callback),
            // errorCallback
            (err) => {
                console.log('Webcamera couldnt be initiated', err);
            }
        );
    }

    /**
     *
     */
    getVideoStreamReadyObservable(): Observable<any> {
        return this.videoStreamReady.asObservable();
    }

    /**
     * Part of the webcam initialization process
     * @param type
     * @param callback
     * @param localMediaStream
     */
    initStream(type, callback, localMediaStream) {
        console.log('Inistream running');

        let tracks = localMediaStream.getVideoTracks();

        if (!tracks || !tracks[0]) {
            console.log('No tracks found at webcamera');
            return false;
        }

        try {
            console.log('InitStream Tracks', tracks);
            const firstTrack: MediaStreamTrack = tracks[0];

            if (callback !== null) {
                callback(localMediaStream, firstTrack);
                return true;
            } else if (type === 'photo') {
                this.imageCapture = new ImageCapture(firstTrack);
            } else {
                this.videoStreamReady.next(localMediaStream);
            }
        } catch (error) {
            console.log(error);
            console.log('Webcamera not supported?');
            return false;
        }
        this.captureImage('init');
        return true;
    }

    /**
     * Init frame creation from webcam
     */
    captureImage(situation: String, callback = null) {

        if (this.imageCapture === null) {
            console.log('Webcamera not initiated, cant capture image');
            return false;
        }

        this.imageCapture.takePhoto()
            .then(bitmap => {
                callback(bitmap);
        }).catch((error) => {
            console.log('Error happened while grabframe', error, typeof error, error.message, this.imageCapture);
        });
    }

}
