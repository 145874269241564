import {Component} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ExamCreatorFormService} from "../../services/shared/exam_creator_form.service";
import {FileUploadService} from "../../services/shared/upload.service";
import {ActivatedRoute, Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {isNullOrUndefined} from "util";
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";
import {ApiService} from "../../services/api.service";
import {EnvironmentService} from "../../services/environment.service";

@Component({
    moduleId: module.id,
    selector: "app-multiple-choice",
    templateUrl: "./multiple-choice.component.html",
    styleUrls: ["./multiple-choice.component.css"]
})
export class MultipleChoiceComponent {

    multipleChoice: FormGroup;
    mainForm: FormGroup;
    taskId: number;
    hasUploadField: boolean;
    multipleSourceControl: FormControl = new FormControl(null);
    itemFormArrays: FormArray = new FormArray([]);
    isShowError: boolean;
    twoExampleItems: FormControl = new FormControl();
    requieredRegSheetForm: boolean;
    taskData: Object;
    sourceRequired: boolean = true;
    prefixUrl: string;
    token: string;
    isFormValid: boolean = true;
    private defaultValue: number;
    isDuplicate: boolean = false;

    constructor(private formBuilder: FormBuilder,
                public examCreatorFormService: ExamCreatorFormService,
                private fileUploadService: FileUploadService,
                private activatedRoute: ActivatedRoute,
                private apiService: ApiService,
                private router: Router,
                public environmentService: EnvironmentService,
    ) {

        console.log('examCreatorForm language value', this.examCreatorFormService.$examCreatorFrom.controls['language'].value);
        this.mainForm = this.examCreatorFormService.$examCreatorFrom;

        console.log('MultipleChoice: Copy main form');

        this.token = window.localStorage.getItem("itolc_token");
        this.prefixUrl = environment.apiPrefixUrl;

        this.multipleChoice = formBuilder.group({
            sourceControl: this.multipleSourceControl,
            two_example_items: this.twoExampleItems
        });

        this.fileUploadService.uploadedFile.subscribe(({url, type}) => {
            console.log('URL: ', url);
            this.multipleChoice.patchValue({
                [type]: url
            });
        });

        this.activatedRoute.data.subscribe(({type}) => {
            this.hasUploadField = type !== "reading";
            this.sourceRequired = type !== "reading";

            if (this.hasUploadField) {
                this.multipleChoice.setControl("file_url", new FormControl());
                this.multipleChoice.setControl("enlarge_file_url", new FormControl());
            } else {
                this.multipleChoice.removeControl("file_url");
                this.multipleChoice.removeControl("enlarge_file_url");
            }
        });

        this.activatedRoute.params.subscribe(({id, duplicate}) => {
            if(!isNullOrUndefined(duplicate)){
                if(duplicate === 'duplicate'){
                    this.isDuplicate = true;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                } else {
                    this.isDuplicate = false;
                    this.examCreatorFormService.$isDuplicate = this.isDuplicate;
                }
            }

            if (!id) {
                this.examCreatorFormService.backToMainForm();
            }

            this.taskId = id;

            let taskValues = this.examCreatorFormService.getTaskDataValue();

            if (taskValues !== null) {
                this.multipleChoice.patchValue(taskValues);
            }

            if (this.taskId) {
                this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {

                    this.taskData = JSON.parse(examTask.task_data);
                    this.multipleChoice.patchValue(this.taskData);

                    this.examCreatorFormService.setExamObject(examTask);
                    this.examCreatorFormService.setExistInstructionControl(examTask.instruction);

                    if(isNotNullOrUndefined(examTask.view_date)){
                        examTask.view_date = examTask.view_date.replace(/:[0-9]+$/, "");
                    }

                    this.mainForm.patchValue(examTask);

                    if (examTask.items) {
                        this.setItemArraysWithExistData(examTask.items);
                    }
                });
            }
        });

        this.examCreatorFormService.requiredRegistryField.subscribe((data) => {
            if (isNullOrUndefined(data)) {
                this.requieredRegSheetForm = true;
            }

            this.requieredRegSheetForm = data;
        });

        this.mainForm.valueChanges.subscribe(() => {
            this.requieredRegSheetForm = this.mainForm.controls.instruction.value !== "" ||
                isNullOrUndefined(this.mainForm.controls.instruction.value);

            this.examCreatorFormService.registryFormSend.emit(this.mainForm);
        });

    }

    removeTaskFile(type) {
        this.taskData[type] = null;
        this.multipleChoice.patchValue(this.taskData);
    }

    onClickNewAnswerOptionBtn(event: Event, index: number, currentItemGroup: FormGroup) {
        event.preventDefault();
        let currentAnswers = <FormArray> currentItemGroup.get("answers");

        currentAnswers.push(new FormGroup({
            multipleAnswerRadio: new FormControl(null),
            multipleAnswerText: new FormControl(null, Validators.required)
        }));

        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    onClickNewOptionBtn(event: Event, index: number) {
        event.preventDefault();
        let currentQuestion = <FormArray> this.itemFormArrays.at(index).get("questions");

        this.isShowError = false;

        currentQuestion.push(new FormGroup({
            multipleQuestion: new FormControl(null, Validators.required),
            answers: new FormArray([])
        }));

        if (this.defaultValue === null) {
            this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
        }
    }

    onClickNewGroupOptionBtn(event: Event) {
        event.preventDefault();

        const length = this.itemFormArrays.length > 0 ? this.itemFormArrays.value[this.itemFormArrays.length - 1].groupId + 1 : 0;

        this.itemFormArrays.push(new FormGroup({
            multipleGroupName: new FormControl(null, Validators.required),
            groupId: new FormControl(length),
            questions: new FormArray([])
        }));

        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    removedQuestionItem(group: number, index: number) {
        let currentQuestion = <FormArray> this.itemFormArrays.at(group).get("questions");

        if (currentQuestion.value[index].id) {
            this.examCreatorFormService.itemHttpApi.del(currentQuestion.value[index].id, true).subscribe(() => {
                console.log("Item removed!");
            });
        }

        currentQuestion.removeAt(index);
    }

    removedAnswerItem(index: number, currentItemGroup: FormGroup) {
        let currentAnswers = <FormArray> currentItemGroup.get("answers");
        currentAnswers.removeAt(index);
    }

    removedGroupItem(index: number) {
        this.itemFormArrays.value[index].questions.forEach((item) => {
            if (item.id) {
                this.examCreatorFormService.itemHttpApi.del(item.id, true).subscribe(() => {
                    console.log("Item removed!");
                });
            }
        });

        this.itemFormArrays.removeAt(index);
        this.mainForm.patchValue({item_count: this.countQuestions()}, {emitEvent: false});
    }

    private setItemArraysWithExistData(items: Array<any>) {
        let _groupId: number,
            _multipleGroupName,
            itemArrays = [], allQuestions: Array<any> = [];

        const tempArray = items.map((item) => {
            const itemData = JSON.parse(item.item_data);
            itemData.id = item.id;
            return itemData;
        });

        const length = tempArray.length - 1;

        tempArray.forEach((tempItem, index) => {

            if (typeof _groupId === "number" && _groupId !== tempItem.groupId) {

                itemArrays.push({
                    groupId: _groupId,
                    multipleGroupName: _multipleGroupName,
                    questions: allQuestions
                });

                allQuestions = [];
            }

            if (tempItem.questions.length > 0) {
                tempItem.questions.forEach((questionItem, index) => {
                    tempItem.questions[index].id = tempItem.id;
                });
            } else {
                tempItem.questions.id = tempItem.id;
            }


            _multipleGroupName = tempItem.multipleGroupName;
            _groupId = tempItem.groupId;
            allQuestions = allQuestions.concat(tempItem.questions);

            if (index === length) {
                itemArrays.push({
                    groupId: _groupId,
                    multipleGroupName: _multipleGroupName,
                    questions: allQuestions
                });
            }
        });

        console.info(itemArrays);

        itemArrays = itemArrays.map((itemData) => {
            let formGroupConfig = {};

            for (let dataItem in itemData) {
                if (itemData.hasOwnProperty(dataItem)) {
                    let value = itemData[dataItem];
                    if (dataItem === "multipleGroupName" || dataItem === "groupId") {
                        formGroupConfig[dataItem] = new FormControl(value);
                    } else if (dataItem === "questions") {
                        formGroupConfig[dataItem] = this.generateQuestionArrays(value);
                    }
                }
            }

            return new FormGroup(formGroupConfig);
        });

        this.itemFormArrays = new FormArray(itemArrays);
    }

    private generateQuestionArrays(questionValues: Array<any>) {
        let formArray = new FormArray([]);

        questionValues.forEach((questionValue) => {
            let formGroupConfig = {};
            formGroupConfig["multipleQuestion"] = new FormControl(questionValue.multipleQuestion);
            formGroupConfig["id"] = new FormControl(questionValue.id);

            let answerArray = questionValue.answers.map((answerValue) => {
                return new FormGroup({
                    multipleAnswerRadio: new FormControl(answerValue.multipleAnswerRadio),
                    multipleAnswerText: new FormControl(answerValue.multipleAnswerText)
                });
            });

            formGroupConfig["answers"] = new FormArray(answerArray);
            formArray.push(new FormGroup(formGroupConfig));
        });

        return formArray;
    }

    twoExampleItemsChecked(event: Event) {
        let element = <HTMLInputElement> event.target;
        this.twoExampleItems.setValue(element.checked);
    }

    onSubmit(event: Event) {
        event.preventDefault();

        if (this.multipleChoice.valid) {
            this.examCreatorFormService.setTaskDataControlValue(JSON.stringify(this.multipleChoice.value));

            let items: Array<FormGroup> = [];
            let cnt = 0;

            this.itemFormArrays.value.forEach((item) => {
                const {groupId, multipleGroupName, questions} = item;

                const _questions = questions.map((questionItem) => {
                    const {id, answers, multipleQuestion} = questionItem;

                    return {
                        id, groupId, multipleGroupName,
                        questions: [{
                            multipleQuestion, answers
                        }]
                    };
                });

                _questions.forEach((questionItem) => {
                    let itemArr = questionItem,
                        id = itemArr.id;

                    delete itemArr.id;

                    items.push(new FormGroup({
                        item_data: new FormControl(JSON.stringify(itemArr)),
                        order: new FormControl(cnt),
                        id: new FormControl(id)
                    }));

                    cnt++;
                });

            });

            const _formArray = new FormArray(items);
            this.mainForm.setControl("items", _formArray);

            if (this.mainForm.valid) {
                // Disable save button.
                this.isFormValid = false;

                if (this.taskId) {
                    if (this.isDuplicate) {
                        this.insertTask(_formArray, true);
                    } else {
                        this.updateTask(_formArray);
                    }
                } else {
                    this.insertTask(_formArray, false);
                }
            } else {
                this.examCreatorFormService.open.emit(true);
            }
        }
        if (!this.mainForm.valid) {
            this.examCreatorFormService.open.emit(true);
        }
    }

    async insertTask(_formArray, isDuplicate) {
        this.taskId = await this.examCreatorFormService.insertTask(this.mainForm.value, _formArray, isDuplicate);
        this.getItems();
        this.submitted(true);
    }

    async updateTask(_formArray){
        await this.examCreatorFormService.updateTask(this.mainForm.value, _formArray);
        this.getItems();
        this.submitted(false);
    }

    private getItems() {
        this.examCreatorFormService.taskHttpApi.read(this.taskId, "data", true).subscribe((examTask) => {
            if (examTask.items) {
                this.setItemArraysWithExistData(examTask.items);
            }
        });
    }

    private submitted(isNew) {
        const submitBtnTextPrefix = isNew ? $localize`:@@8569259932403634104:Mentve` : $localize`:@@5955027786640543423:Frissítve`;
        const dateText = new Date().toLocaleDateString("hu-HU", {
            hour: "numeric",
            hour12: false,
            minute: "numeric"
        });

        if (isNew) {
            let navUrl =  '/exam-task-creator/' + this.examCreatorFormService.getTaskUrl(this.taskId);
            console.log('navUrl: ', navUrl);
            location.href = navUrl;
        } else {
            this.examCreatorFormService.taskHttpApi.rescanExamAnswersByTaskId({task_id: this.taskId}).subscribe(() => {
                console.log("Rescan completed");
            });
        }

        this.examCreatorFormService.submittedResult = `${submitBtnTextPrefix} ${dateText}-kor`;

        // Enable save button.
        this.isFormValid = true;
    }

    ngOnInit() {
        let itemCountControl = this.mainForm.get("item_count");

        this.defaultValue = itemCountControl.value;

        itemCountControl.valueChanges.subscribe((value) => {
            this.defaultValue = value;
        });
    }

    onPreview() {
        let items: Array<FormGroup> = [];
        let cnt = 0;

        this.itemFormArrays.value.forEach((item) => {

            const {groupId, multipleGroupName, questions} = item;

            const _questions = questions.map((questionItem) => {
                const {id, answers, multipleQuestion} = questionItem;

                return {
                    id, groupId, multipleGroupName,
                    questions: [{
                        multipleQuestion, answers
                    }]
                };
            });

            _questions.forEach((questionItem) => {
                let itemArr = questionItem,
                    id = itemArr.id;
                delete itemArr.id;
                items.push(new FormGroup({
                    item_data: new FormControl(JSON.stringify(itemArr)),
                    order: new FormControl(cnt),
                    id: new FormControl(id),
                    exam_answer: new FormControl(null)
                }));
                cnt++;
            });

        });

        const _formArray = new FormArray(items);
        this.examCreatorFormService.onPreviewTask(_formArray.value);
    }

    countQuestions(){
        let sumQuestions = 0;
        this.itemFormArrays.value.forEach((item) => {
            sumQuestions += item.questions.length;
        });

        return sumQuestions;
    }

    destroyTask(){
        if(this.taskId) {
            if (window.confirm("Biztosan törlöd?")) {
                this.apiService.postDestroyTask({task_id: this.taskId}).subscribe((response) => {
                    console.log('response: ', response);
                    this.router.navigateByUrl(`/landing/tasks`);
                });
            }
        }
    }

    /**
     * The video data has changed (possibly by converter), store it in the form
     * @param filename
     */
    updateVideoData(filename: string) {
        this.taskData['file_url'] = `files/videos/${filename}`;
        this.multipleChoice.patchValue(this.taskData);
    }
}
